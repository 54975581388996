import React, { useEffect, useState } from "react";
import { Table } from "antd";
import PageTitle from "../Common/PageTitle";
import * as MedicationsApi from "../../api/MedicationsApi";
import MyBackdropSpinner from "../../common/elements/myBackdropSpinner";

const Medications = ({ patientId }) => {
  const [searchData, setSearchData] = useState();
  const [medicationData, setMedicationData] = useState();
  const [spinner, setSpinner] = useState(false);
  const [query, setQuery] = useState();

  useEffect(() => {
    setSpinner(true);
    const medResponse = MedicationsApi.getMedications(patientId);
    Promise.all([medResponse]).then((values) => {
      let tempMed = [];
      values[0]?.data.forEach((data) => {
        tempMed.push({
          patientId: data.patientId,
          patientName: data.patientLastName + ", " + data.patientFirstName,
          physicianName:
            data.physicianLastName + ", " + data.physicianFirstName,
          physicianNPI: data.physicianNPI,
          directions: data.directions,
          description: data.description,
          strength:
            data.strength == null
              ? " "
              : data.strength + " " + data.strengthUOM,
          dose: data?.doseUOM == null ? " " : data?.dose + " " + data?.doseUOM,
          scheduleType:
            data.scheduleType == "PRN" ? data.scheduleType : "Routine",
          frequency: data.frequency,
          source: "PointClickCare",
        });
      });
      setMedicationData(tempMed);
      setSearchData(medicationData);
      setSpinner(false);
    });
  }, []);

  const admissioncolumns = [
    {
      title: "Patient Name",
      key: "patientName",
      dataIndex: "patientName",
      width: "15%",
    },
    {
      title: "Medication",
      key: "description",
      dataIndex: "description",
      width: "15%",
    },
    {
      title: "Strength",
      key: "strength",
      dataIndex: "strength",
      width: "10%",
    },
    {
      title: "Directions",
      key: "directions",
      dataIndex: "directions",
    },

    {
      title: "Dose",
      key: "dose",
      dataIndex: "dose",
      width: "10%",
    },
    {
      title: "Schedule Type",
      key: "scheduleType",
      dataIndex: "scheduleType",
    },

    {
      title: "Frequency",
      key: "frequency",
      dataIndex: "frequency",
    },
    {
      title: "Ordered By",
      key: "physicianName",
      dataIndex: "physicianName",
      width: "15%",
    },
    {
      title: "Ordered By NPI",
      key: "physicianNPI",
      dataIndex: "physicianNPI",
    },
    {
      title: "Source",
      key: "source",
      dataIndex: "source",
      width: 130,
    },
  ];

  const filteredItems = searchData?.filter((item) => {
    return Object.keys(item).some((key) =>
      (item[key]?.toString()?.toLowerCase() ?? "").includes(
        query?.toLowerCase() ?? ""
      )
    );
  });

  return (
    <div>
      {/* {<PageTitle title={`PointClickCare Medications`} />} */}
      {spinner && <MyBackdropSpinner show={spinner} />}
      <div style={{ color: "var(--bs-gray-800)" }}>
        <div className="tw-flex tw-justify-between tw-border tw-mb-6">
          <div className="tw-flex tw-justify-end tw-space-x-2 tw-basis-2/5"></div>
        </div>
        <div className="md">
          <Table
            className="table-striped-rows"
            columns={admissioncolumns}
            dataSource={medicationData}
            size="large"
            bordered
            pagination={{
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Medications;
