import React, { useEffect, useState } from "react";
import * as pbiReport from "../PowerBIReports";
import { useNavigate, useParams } from "react-router-dom";
import { useMyContext } from "../../common/elements/myContext";
import PageTitle from "../Common/PageTitle";
import MyCustomSelect from "../../common/elements/myCustomSelect";
import * as HRRPatientapi from "../../api/HRRPatientsApi";
import * as ScoreApi from "../../api/scoreApi";
import * as Settings from "../../GlobalConfiguration";
import { DatePicker, Modal, Space } from "antd";
import dayjs from "dayjs";
import { IoArrowBackCircle } from "react-icons/io5";

const CCMDPatient360 = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { getFormattedDateDayJs, converttoDate } = useMyContext();
  const [modal, contextHolder] = Modal.useModal();

  const [capacityList, setCapacityList] = useState({});

  const [filterValue, setFilterValue] = useState("");
  const [polstdate, setPolstDate] = useState("");
  const [ccedate, setCCEDate] = useState("");
  const [palliativecareOption, setPalliativecareOption] = useState("No");
  const [hospicecareOption, setHospicecareOption] = useState("No");
  const [selectedCapacity, setSelectedCapacity] = useState();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const [requestBoby, setRequestBody] = useState({
    mbiid: params?.mbiid || "",
    plotsADLastUpdatedDate: "",
    cceLastUpdatedDate: "",
    isPalliativeCare: "",
    isHospiceCare: "",
    capcityID: "",
  });

  const dateFormat = ["YYYY-MM-DD", "YY-MM-DD", "YYYY/MM/DD", "YY/MM/DD"];

  let newurl;

  if (filterValue !== null && filterValue !== "") {
    if (Settings.MODE === "PRODUCTION") {
      newurl =
        pbiReport.Patient360Prod +
        "&$filter=CCMD_Roster/MBI eq '" +
        filterValue +
        "'";
    } else {
      newurl =
        pbiReport.Patient360 +
        "&$filter=CCMD_Roster/MBI eq '" +
        filterValue +
        "'";
    }
  }

  useEffect(() => {
    setFilterValue(params?.mbiid);
    const patient360Promise = HRRPatientapi.getPatient360(params?.mbiid);
    const capacityPromise = ScoreApi.getPicklistPromise(8);
    Promise.all([patient360Promise, capacityPromise]).then((values) => {
      let result = values[0]?.data;
      setPolstDate(
        result?.plotsADLastUpdatedDate
          ? getFormattedDateDayJs(result?.plotsADLastUpdatedDate)
          : ""
      );
      setCCEDate(
        result?.cceLastUpdatedDate
          ? getFormattedDateDayJs(result?.cceLastUpdatedDate)
          : ""
      );

      //capacity

      const capList = [];
      values[1]?.data.keyValuePair.forEach((item) => {
        capList.push({
          label: item.value,
          value: item.key,
        });
      });
      setCapacityList(capList);

      let tempcap = [];
      tempcap = capList.filter((item) => {
        return item.value === result.capcityID;
      });
      setSelectedCapacity(tempcap);

      setHospicecareOption(result?.isHospiceCare === "Yes" ? "Yes" : "No");
      setPalliativecareOption(
        result?.isPalliativeCare === "Yes" ? "Yes" : "No"
      );
      setRequestBody({
        ...requestBoby,
        plotsADLastUpdatedDate: result?.plotsADLastUpdatedDate
          ? result?.plotsADLastUpdatedDate
          : null,
        cceLastUpdatedDate: result?.cceLastUpdatedDate
          ? result?.cceLastUpdatedDate
          : null,
        capcityID: result.capcityID,
        isHospiceCare: result?.isHospiceCare === "Yes" ? "Yes" : "No",
        isPalliativeCare: result?.isPalliativeCare === "Yes" ? "Yes" : "No",
      });
    });
  }, [params]);

  useEffect(() => {
    setFilterValue(params?.mbiid);
    if (hasUnsavedChanges) {
      window.onbeforeunload = () => {
        return true;
      };
    } else {
      window.onbeforeunload = null;
    }
  }, [hasUnsavedChanges]);

  const onPolstDateChange = (date, dateString) => {
    setPolstDate(dateString);
    setRequestBody({
      ...requestBoby,
      plotsADLastUpdatedDate: converttoDate(date),
    });
    setHasUnsavedChanges(true);
  };
  const onCCEDateChange = (date, dateString) => {
    setCCEDate(dateString);
    setRequestBody({
      ...requestBoby,
      cceLastUpdatedDate: converttoDate(date),
    });
    setHasUnsavedChanges(true);
  };
  const setCapacity = (e) => {
    setSelectedCapacity(e);
    setRequestBody({ ...requestBoby, capcityID: e?.value || "" });
    setHasUnsavedChanges(true);
  };

  // const onClearClick = () => {
  //   setCCEDate("");
  //   setPolstDate("");
  //   setSelectedCapacity("");
  //   setPalliativecareOption("No");
  //   setHospicecareOption("No");
  //   setRequestBody({
  //     ...requestBoby,
  //     plotsADLastUpdatedDate: null,
  //     cceLastUpdatedDate: null,
  //     isPalliativeCare: "",
  //     isHospiceCare: "",
  //     capcityID: "",
  //   });
  // };

  const onSubmitClicked = async (e) => {
    try {
      const response = await HRRPatientapi.patient360(requestBoby);
      let secondsToGo = 9;
      const instance = modal.success({
        title: "Info",
        content: `Your data has been successfully saved and will appear after the next Power BI refresh.  Please see the Power BI report for refresh information. Closing in ${secondsToGo} second.`,
      });
      const timer = setInterval(() => {
        secondsToGo -= 1;
        instance.update({
          content: `Your data has been successfully saved and will appear after the next Power BI refresh.  Please see the Power BI report for refresh information. Closing in ${secondsToGo} second.`,
        });
      }, 1000);
      setTimeout(() => {
        clearInterval(timer);
        instance.destroy();
      }, secondsToGo * 1000);
      setHasUnsavedChanges(false);
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Space align="start">
        <IoArrowBackCircle size="2em" onClick={() => navigate(-1)} />
        <PageTitle title={`Patient 360 for ${requestBoby.mbiid}`} />
      </Space>

      <div
        style={{
          marginTop: "-14px",
          marginBottom: "24px",
          fontSize: "0.875rem",
          color: "var(--bs-gray-600)",
        }}
      >
        To select a new patient return to the CCMD Patient list
      </div>

      <form onSubmit={(e) => e.preventDefault()} noValidate>
        <div>
          <div className="row">
            <div className="col-md-2 col-lg-2 pb">
              <div className="form-group fw-bold">
                <label>POLST/AD Last Update </label>
                <DatePicker
                  className="form-control"
                  placeholder="YYYY-MM-DD"
                  // status={requestBoby?.plotsADLastUpdatedDate || "error"}
                  value={polstdate ? dayjs(polstdate, dateFormat) : ""}
                  format={dateFormat}
                  onChange={onPolstDateChange}
                />
              </div>
            </div>

            <div className="col-md-2 col-lg-2">
              <div className="form-group fw-bold">
                <label>Last Completed CCE </label>
                <DatePicker
                  className="form-control"
                  placeholder="YYYY-MM-DD"
                  value={ccedate ? dayjs(ccedate, dateFormat) : ""}
                  format={dateFormat}
                  onChange={onCCEDateChange}
                />
              </div>
            </div>
            <div className="col-md-2 col-lg-2">
              <div className="form-group">
                <label htmlFor="capacity" className="fw-bold">
                  Capacity
                </label>

                <MyCustomSelect
                  isMulti={false}
                  marginb="2em"
                  selectedValue={selectedCapacity}
                  selectOptions={capacityList}
                  myCallBack={setCapacity}
                ></MyCustomSelect>
              </div>
            </div>

            <div className="col-md-2 col-lg-2">
              <div className="form-group fw-bold">
                <label htmlFor="eligibleOption" className="pb-1">
                  Palliative Care
                </label>

                <fieldset className="fw-normal">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="elibibiltyYes"
                      checked={palliativecareOption === "Yes"}
                      id="pelibibiltyYes"
                      value={"Yes"}
                      onChange={(e) => {
                        setPalliativecareOption(e.currentTarget.value);
                        setRequestBody({
                          ...requestBoby,
                          isPalliativeCare: e.currentTarget.value,
                        });
                      }}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio1">
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="pelibibiltyNo"
                      checked={palliativecareOption === "No"}
                      id="pelibibiltyNo"
                      value={"No"}
                      onChange={(e) => {
                        setPalliativecareOption(e.currentTarget.value);
                        setRequestBody({
                          ...requestBoby,
                          isPalliativeCare: e.currentTarget.value,
                        });
                      }}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio2">
                      No
                    </label>
                  </div>
                </fieldset>
              </div>
            </div>

            <div className="col-md-2 col-lg-2">
              <div className="form-group fw-bold">
                <label htmlFor="eligibleOption" className="pb-1">
                  Hospice Care
                </label>

                <fieldset className="fw-normal">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="helibibiltyYes"
                      checked={hospicecareOption === "Yes"}
                      id="inlineRadio1"
                      value={"Yes"}
                      onChange={(e) => {
                        setHospicecareOption(e.currentTarget.value);
                        setRequestBody({
                          ...requestBoby,
                          isHospiceCare: e.currentTarget.value,
                        });
                      }}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio1">
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="helibibiltyNo"
                      checked={hospicecareOption === "No"}
                      id="helibibiltyNo"
                      value={"No"}
                      onChange={(e) => {
                        setHospicecareOption(e.currentTarget.value);
                        setRequestBody({
                          ...requestBoby,
                          isHospiceCare: e.currentTarget.value,
                        });
                      }}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio2">
                      No
                    </label>
                  </div>
                </fieldset>
              </div>
            </div>
            <div className="col-md-2 col-lg-2">
              <button
                type="submit"
                className="btn btn-success"
                onClick={onSubmitClicked}
              >
                Save
              </button>
              <button
                type="back"
                className="btn btn-secondary"
                onClick={() => navigate(-1)}
                style={{
                  marginLeft: "1em",
                  marginRight: "2em",
                }}
              >
                Back
              </button>
            </div>
          </div>
        </div>
        {contextHolder}
      </form>

      {/* <div>
        {" "}
        <button className="btn btn-success" onClick={setFilter}>
          {" "}
          Show
        </button>
      </div> */}
      <iframe
        id="iFrame"
        src={newurl}
        title="Patient 360"
        width="100%"
        height="750"
        frameBorder="0"
        allowFullScreen
      ></iframe>
    </>
  );
};
export default CCMDPatient360;
