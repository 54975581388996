import React, { useEffect, useMemo, useState } from "react";
import { Table } from "antd";
import { Select } from "antd";
import PageTitle from "../Common/PageTitle";
import * as ProviderGroupApi from "../../api/PatientWorklistApi";
import * as ScoreApi from "../../api/scoreApi";

import { useNavigate } from "react-router-dom";
import Search from "antd/es/input/Search";
import MyBackdropSpinner from "../../common/elements/myBackdropSpinner";

const HCCPatientWorklist = (props) => {
  const navigate = useNavigate();
  const [spinner, setSpinner] = useState(false);

  const [patientListColumn, setPatientListColumns] = useState([]);
  const [patientListMaster, setPatientListMaster] = useState([]);
  const [groupId, setGroupId] = useState("B");
  const [query, setQuery] = useState("");
  const [providerGroupList, setProviderGroupList] = useState([]);

  useEffect(() => {
    getPatientList(groupId);
  }, [groupId]);

  const onProviderChange = (option) => {
    setGroupId(option);
  };

  const providerGorupPromise = useMemo(async () => {
    const response = await ScoreApi.getPicklistPromise(3);
    let pList = [];
    response?.data?.keyValuePair?.forEach((item) => {
      pList.push({
        label: item.value,
        value: item.key,
      });
    });
    setProviderGroupList(pList);
    setGroupId(pList[0].value);
  }, []);

  const getPatientList = async (id) => {
    setSpinner(true);
    try {
      const response = await ProviderGroupApi.getProviderGroupPatients(id);

      let responseData = response?.data;
      setPatientListMaster(responseData);
    } catch (error) {
      console.error(error);
      setPatientListMaster([]);
    } finally {
      setSpinner(false);
    }

    setPatientListColumns([
      {
        title: "MBI",
        dataIndex: "mbiID",
        key: "mbiID",
        width: 100,
        // sorter: (a, b) => a.mbiID - b.mbiID,
        sorter: (a, b) => {
          if (a.mbiID || "" < b.mbiID || "") {
            return -1;
          }
          if (a.mbiID || "" > b.mbiID || "") {
            return 1;
          }
          return 0;
        },
        onFilter: (value, record) => record?.mbiID?.indexOf(value) === 0,
      },
      {
        title: "Provider Group",
        dataIndex: "providerGroupName",
        key: "providerGroupName",
        sorter: (a, b) => {
          const providerA = a.providerGroupName || ""; // Replace null with an empty string for comparison
          const providerB = b.providerGroupName || ""; // Replace null with an empty string for comparison

          // Use the localeCompare method to compare strings in a case-insensitive manner
          return providerA.localeCompare(providerB);
        },
      },
      {
        title: "Last Name",
        dataIndex: "lastName",
        key: "lastName",
        width: 200,
        sorter: (a, b) => {
          if (a.lastName || "" < b.lastName || "") {
            return -1;
          }
          if (a.lastName || "" > b.lastName || "") {
            return 1;
          }
          return 0;
        },
      },
      {
        title: "First Name",
        dataIndex: "firstName",
        key: "firstName",
        width: 200,
        sorter: (a, b) => {
          if (a.firstName || "" < b.firstName || "") {
            return -1;
          }
          if (a.firstName || "" > b.firstName || "") {
            return 1;
          }
          return 0;
        },
      },
      {
        title: "Gender",
        dataIndex: "gender",
        key: "gender",
        width: 100,
        sorter: (a, b) => {
          const genderA = a.gender || ""; // Replace null with an empty string for comparison
          const genderB = b.gender || ""; // Replace null with an empty string for comparison

          // Use the localeCompare method to compare strings in a case-insensitive manner
          return genderA.localeCompare(genderB);
        },
      },
      {
        title: "Rendering Provider",
        dataIndex: "renderingProviderName",
        key: "renderingProviderID",
        sorter: (a, b) => {
          const renderingA = a.renderingProviderName || ""; // Replace null with an empty string for comparison
          const renderingB = b.renderingProviderName || ""; // Replace null with an empty string for comparison

          // Use the localeCompare method to compare strings in a case-insensitive manner
          return renderingA.localeCompare(renderingB);
        },
      },
      {
        title: "Facility Name",
        dataIndex: "facilityName",
        key: "facilityId",
        sorter: (a, b) => {
          const facilityA = a.facilityName || ""; // Replace null with an empty string for comparison
          const facilityB = b.facilityName || ""; // Replace null with an empty string for comparison

          // Use the localeCompare method to compare strings in a case-insensitive manner
          return facilityA.localeCompare(facilityB);
        },
      },
      {
        title: "Reviewed",
        dataIndex: "isReviewCompleted",
        key: "isReviewCompleted",
        sorter: (a, b) => {
          const reviewA = a.isReviewCompleted || ""; // Replace null with an empty string for comparison
          const reviewB = b.isReviewCompleted || ""; // Replace null with an empty string for comparison

          // Use the localeCompare method to compare strings in a case-insensitive manner
          return reviewA.localeCompare(reviewB);
        },
      },
      {
        title: "Action",
        dataIndex: "mbiID",
        key: "action",
        render: (columnItem, rowItem) => {
          return (
            <a
              onClick={(e) => {
                e.preventDefault();
                navigate(
                  `/ScoreNew/${rowItem.mbiID}/${rowItem.firstName}/${rowItem.lastName}/${rowItem.gender}/${rowItem.facilityID}/${rowItem.providerGroupName}/${rowItem.renderingProviderID}/${rowItem.dateOfBirth}`
                );
              }}
              href="#"
            >
              Review
            </a>
          );
        },
      },
    ]);
  };

  const onChange = (pagination, filters, sorter, extra) => {
    //setFilterItems(filters);
  };

  const filteredItems = patientListMaster.filter((item) => {
    return Object.keys(item).some((key) =>
      item[key].toString()?.toLowerCase().includes(query?.toLowerCase())
    );
  });

  return (
    <>
      <PageTitle title={"HCC Patient Worklist"} />
      { spinner && (<MyBackdropSpinner show={spinner} /> )}
      <div className="d-flex d-grid gap-2 my-2 d-md-flex justify-content-md-end">
        <Search
          className="col-md-3"
          size="large"
          placeholder="Search"
          onChange={(e) => setQuery(e.target.value)}
          allowClear
        />
        <Select
          className="col-md-2"
          size="large"
          showSearch
          placeholder="Select a provider"
          optionFilterProp="children"
          defaultValue={groupId}
          onChange={onProviderChange}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={providerGroupList}
        />
      </div>
      <div className="row">
        <Table
          columns={patientListColumn}
          dataSource={filteredItems}
          onChange={onChange}
          size="large"
        />
      </div>
    </>
  );
};
export default HCCPatientWorklist;
