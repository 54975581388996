import React from "react";
import MyCustomSelect from "../../common/elements/myCustomSelect";
import { useState } from "react";
import { DatePicker } from "antd";

const CceTracking = (props) => {
  const [selecdtedEvent, setSelecdtedEvent] = useState();
  const [selectedDateTime, setSelectedDateTime] = useState("");

  const initialReqState = {
    key: "",
    guid: "",
    mbi: props.mbiid || "",
  };

  const onDateChange = (date, dateString) => {
    setSelectedDateTime(date || "");
  };

  return (
    <div className="tw-mt-4">
      <h1 className="tw-text-xl tw-text-orange-700">CCE Tracking</h1>
      <div className="tw-flex tw-place-content-start tw-gap-4">
        <div className="tw-basis-1/3">
          <label htmlFor="patientName" className=" tw-font-bold tw-mb-1">
            CCE Status
          </label>
          <MyCustomSelect
            selectOptions={[
              { value: "dec", label: "Deceased" },
              { value: "hold", label: "Hold/TBD" },
              { value: "noop", label: "No Opp for CCE" },
              { value: "ref", label: "Refused" },
              { value: "sch", label: "Scheduled" },
              { value: "tbsch", label: "To be scheduled" },
            ]}
            selectedValue={selecdtedEvent}
            myCallBack={(e) => setSelecdtedEvent(e)}
          />
          <label
            htmlFor="scheduledDate"
            className=" tw-font-bold tw-mt-4 tw-mb-1"
          >
            Scheduled Date
          </label>
          <DatePicker
            showTime
            use12Hours
            className="form-control"
            onChange={onDateChange}
            format={"YYYY-MM-DD hh:mm A"}
            value={selectedDateTime}
          />
          <label
            htmlFor="patientName"
            className=" tw-font-bold tw-mt-4 tw-mb-1"
          >
            Pre CCE Review
          </label>
          <MyCustomSelect
            selectOptions={[
              { value: "dec", label: "Complete" },
              { value: "hold", label: "In Progress" },
              { value: "noop", label: "Needed" },
              { value: "ref", label: "Not Needed" },
            ]}
            selectedValue={selecdtedEvent}
            myCallBack={(e) => setSelecdtedEvent(e)}
          />
          <label
            htmlFor="patientName"
            className=" tw-font-bold tw-mt-4 tw-mb-1"
          >
            Post CCE Review
          </label>
          <MyCustomSelect
            selectOptions={[
              { value: "dec", label: "Complete" },
              { value: "hold", label: "In Progress" },
              { value: "noop", label: "Needed" },
              { value: "ref", label: "Not Needed" },
            ]}
            selectedValue={selecdtedEvent}
            myCallBack={(e) => setSelecdtedEvent(e)}
          />
          <label
            htmlFor="patientName"
            className=" tw-font-bold tw-mt-4 tw-mb-1"
          >
            Note Signed
          </label>
          <fieldset className="fw-normal">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="medOrderYes"
                checked={"Yes"}
                id="medOrderYes"
                value={"Yes"}
              />
              <label className="form-check-label" htmlFor="inlineRadio1">
                Yes
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="medOrderNo"
                checked={"No"}
                id="medOrderNo"
                value={"No"}
              />
              <label className="form-check-label" htmlFor="inlineRadio2">
                No
              </label>
            </div>
          </fieldset>
        </div>
        <div className="tw-basis-1/3 tw-ml-4">
          <label htmlFor="patientName" className=" tw-font-bold tw-mb-1">
            Activity
          </label>
          <div className="tw-space-y-5">
            <div className="tw-text-zinc-500 tw-text-sm tw-flex tw-justify-between tw-items-start tw-relative">
              <div className="tw-w-0.5 tw-absolute tw-h-full tw-bg-zinc-300 tw-left-[11px] -tw-translate-x-1/2 tw-top-5" />
              <div className="tw-flex tw-items-start">
                <div className="tw-relative tw-flex tw-h-6 tw-w-6 tw-items-center tw-justify-center tw-shrink-0 tw-bg-white">
                  <div className="tw-h-1.5 tw-w-1.5 tw-rounded-full tw-bg-zinc-300"></div>
                </div>
                <div className="tw-flex tw-flex-wrap">
                  <div className="tw-text-zinc-900 tw-font-medium">
                    Poongundran Elavarasan
                  </div>
                  <div className="tw-mx-1">created this task</div>
                </div>
              </div>
              <div className="tw-shrink-0">2024-01-10</div>
            </div>

            <div className="tw-text-zinc-500 tw-text-sm tw-flex tw-justify-between tw-items-start tw-relative">
              <div className="tw-w-0.5 tw-absolute tw-h-full tw-bg-zinc-300 tw-left-[11px] -tw-translate-x-1/2 tw-top-5" />
              <div className="tw-flex tw-items-start">
                <div className="tw-relative tw-flex tw-h-6 tw-w-6 tw-items-center tw-justify-center tw-shrink-0">
                  <div className="tw-h-1.5 tw-w-1.5 tw-rounded-full tw-bg-zinc-300"></div>
                </div>
                <div className="tw-flex tw-flex-wrap">
                  <div className="tw-text-zinc-900 tw-font-medium">
                    Poongundran Elavarasan
                  </div>
                  <div className="tw-mx-1">completed</div>
                  <div className="tw-text-zinc-900 tw-font-medium">
                    Pre-CCE Coder Review
                  </div>
                </div>
              </div>
              <div className="tw-shrink-0">2024-01-12</div>
            </div>

            <div className="tw-text-zinc-500 tw-text-sm tw-flex tw-justify-between tw-items-start tw-relative">
              <div className="tw-flex tw-items-start">
                <div className="tw-relative tw-flex tw-h-6 tw-w-6 tw-items-center tw-justify-center tw-shrink-0">
                  <div className="tw-h-1.5 tw-w-1.5 tw-rounded-full tw-bg-zinc-300"></div>
                </div>
                <div className="tw-flex tw-flex-wrap">
                  <div className="tw-text-zinc-900 tw-font-medium">
                    Rakesh Uthukadu Parthiban
                  </div>
                  <div className="tw-mx-1">edited and added notes</div>
                </div>
              </div>
              <div className="tw-shrink-0">2024-01-13</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CceTracking;
