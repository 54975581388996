import React from "react";
import MyCustomSelectAsync from "../../common/elements/myCustomSelectAsync";
import MyCustomSelect from "../../common/elements/myCustomSelect";
import { useState } from "react";
import { useEffect } from "react";
import MyTextBox from "../../common/elements/myTextbox";
import { AiOutlineDelete } from "react-icons/ai";
import { Space } from "antd";

const ICDWithSource = ({
  title,
  selectedIcdCodes,
  icd10Codes,
  selectedIcdCodeX,
  onecdCodeListChangeX,
  sourcesList,
  index,
  onSourceChangeX,
  selectedSourceX,
  selectedSourceTextX,
  onSourceTextChangeX,
  isRequired = true,
  errorMessages,
  removeSelectedFilter,
}) => {
  const [selctedIcdCodeY, setSelectedIcdCode] = useState({});
  const [refreshKey, setRefreshKey] = useState(1);

  useEffect(() => {
    setRefreshKey(refreshKey + 1);
    // console.log("data changed");
    // console.log(selectedIcdCodeX);
  }, [selectedIcdCodeX]);

  return (
    <React.Fragment key={`${index}-${refreshKey}`}>
      <div className="row pb-2">
        <div className="col-md-6">
          {/* <div className="row">
            <div className={`col-md-1 ${index === 0 ? " mt-4" : "mt-1"}`}>
              {(index > 0 ||
                (index === 0 && Object.keys(selectedIcdCodeX).length >= 2)) &&
                selectedIcdCodeX[index] && (
                  <span
                    onClick={() => {
                      removeSelectedFilter(index);
                    }}
                  >
                    <AiFillCloseCircle size={30} />
                  </span>
                )}
            </div>
            <div className="col-md-11">
              <div className="form-group d-inline">
                {index === 0 && (
                  <label htmlFor="hccCodeList" className="fw-bold">
                    {title}
                  </label>
                )}
                <MyCustomSelectAsync
                  isMulti={false}
                  selectedValues={selectedIcdCodeX[index]}
                  selectOptions={icd10Codes}
                  myCallBack={onecdCodeListChangeX}
                  index={index}
                ></MyCustomSelectAsync>
              </div>
            </div>
          </div> */}

          <div className="form-group d-inline">
            {index === 0 && (
              <label htmlFor="hccCodeList" className="fw-bold">
                {title}
              </label>
            )}
            <MyCustomSelectAsync
              isMulti={false}
              selectedValues={selectedIcdCodeX[index]}
              selectOptions={icd10Codes}
              myCallBack={onecdCodeListChangeX}
              index={index}
            ></MyCustomSelectAsync>
          </div>
        </div>

        <div className="col-md-3">
          {selectedIcdCodeX[index] && (
            <div className="form-group">
              {index === 0 && (
                <label
                  htmlFor="hccCodeListSource"
                  className={`fw-bold ${isRequired === true ? "required" : ""}`}
                >
                  Source
                </label>
              )}

              <MyCustomSelect
                isRequired={true}
                errorMessage={
                  selectedSourceX[index] ? "" : errorMessages[index]
                }
                selectedValue={selectedSourceX[index]}
                myCallBack={onSourceChangeX}
                selectOptions={sourcesList}
                index={index}
              ></MyCustomSelect>
            </div>
          )}
        </div>
        <div className="col-md-3">
          {/* {selectedSourceX[index]?.label === "Other" && (
            <MyTextBox
              label="Other"
              id="sourceOtherText"
              isRequired={true}
              errorMessage={errorMessages[index]}
              maxLength="50"
              showLabel={index === 0}
              value={selectedSourceTextX[index]}
              setValue={(e) => {
                onSourceTextChangeX(e.target.value, index);
              }}
            ></MyTextBox>
          )} */}
          <Space.Compact>
            {selectedSourceX[index]?.label === "Other" && (
              <MyTextBox
                label="Other"
                id="sourceOtherText"
                isRequired={true}
                errorMessage={errorMessages[index]}
                maxLength="50"
                showLabel={index === 0}
                value={selectedSourceTextX[index]}
                setValue={(e) => {
                  onSourceTextChangeX(e.target.value, index);
                }}
                style={{
                  paddingRight: "90px",
                }}
              ></MyTextBox>
            )}

            {(index > 0 ||
              (index === 0 && Object.keys(selectedIcdCodeX).length >= 2)) &&
              selectedIcdCodeX[index] && (
                <div
                  className="d-inline"
                  style={{
                    position: "relative",
                    marginTop: index === 0 ? "28px" : "2px",
                    float:
                      selectedSourceX[index]?.label === "Other"
                        ? "right"
                        : "left",
                    cursor: "pointer",
                    paddingLeft:
                      selectedSourceX[index]?.label === "Other"
                        ? "10px"
                        : "1px",
                  }}
                  onClick={() => {
                    removeSelectedFilter(index);
                  }}
                  key={index}
                >
                  <AiOutlineDelete
                    size={30}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                </div>
              )}
          </Space.Compact>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ICDWithSource;
