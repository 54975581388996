import { DatePicker, Skeleton, Table } from "antd";
import React, { useState, useEffect } from "react";
import PageTitle from "../Common/PageTitle";
import dayjs from "dayjs";
import { getSVASubmitDetails } from "./SVA_Api";
import useSWR from "swr";
import { fetcherhn } from "../../services/fectcher";

const CCCAnalytics = () => {
  const { RangePicker } = DatePicker;

  const [fromDate, setFromDate] = useState(
    dayjs().subtract(1, "day").format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [SVAData, setSVAData] = useState(null);
  const [svaLoading, setSVALoading] = useState(true);
  const [error, setError] = useState(null);

  console.log("fromDate", fromDate);
  console.log("toDate", toDate);

  useEffect(() => {
    const fetchData = async () => {
      setSVALoading(true);
      setError(null);
      try {
        const response = await getSVASubmitDetails(fromDate, toDate);
        setSVAData(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setSVALoading(false);
      }
    };

    fetchData();
  }, [fromDate, toDate]);

  const { data, isLoading } = useSWR(
    `getCareEventSummary?fromDate=${fromDate}&toDate=${toDate}`,
    fetcherhn
  );

  // Provide a default empty array for filtering
  const filteredData = (data || []).filter(
    (item) =>
      item.ActivityName !== "Total" && item.ActivityName !== "Triage Call"
  );

  const triageCalls = (data || []).filter(
    (item) => item.ActivityName === "Triage Call"
  );
  const combinedTriageCallCount =
    triageCalls?.reduce((acc, curr) => acc + curr.count, 0) || 0;

  const modifiedData = [
    ...filteredData,
    {
      activityType: "CARE EVENT / CARE EVENT SCP", // Label to show in table
      ActivityName: "Triage Call",
      count: combinedTriageCallCount,
    },
  ];

  const sortedData = modifiedData
    .sort((a, b) => b.count - a.count)
    .slice(0, 10);

  const careEventTotal = (data || [])
    .filter((item) => item.activityType === "CARE EVENT")
    .reduce((acc, curr) => acc + curr.count, 0);

  const scpTotal = (data || [])
    .filter((item) => item.activityType === "CARE EVENT - SCP")
    .reduce((acc, curr) => acc + curr.count, 0);

  const tocTotal = (data || [])
    .filter((item) => item.activityType === "TOC")
    .reduce((acc, curr) => acc + curr.count, 0);

  const rootCauseAnalysisCount =
    (data || [])
      .filter((item) => item.ActivityName === "Root Cause Analysis")
      .reduce((acc, curr) => acc + curr.count, 0) || 0;

  const SVAcolumns = [
    {
      title: "Provider Name",
      dataIndex: "providerName",
      key: "providerName",
    },
    {
      title: "Beneficiary Name",
      dataIndex: "beneficiaryName",
      key: "beneficiaryName",
    },
    {
      title: "Medicare Number",
      dataIndex: "medicareNumber",
      key: "medicareNumber",
    },
    {
      title: "Print Name",
      dataIndex: "printName",
      key: "printName",
    },
    {
      title: "File Name",
      dataIndex: "fileName",
      key: "fileName",
    },
    {
      title: "Submitted Date",
      dataIndex: "submittedDate",
      key: "submittedDate",
      render: (text) => dayjs(text)?.format("YYYY-MM-DD"),
    },
  ];

  const cccColumns = [
    {
      title: "Activity Type",
      dataIndex: "activityType",
      key: "activityType",
    },
    {
      title: "Name",
      dataIndex: "ActivityName",
      key: "ActivityName",
    },
    {
      title: "Count",
      dataIndex: "count",
      key: "count",
    },
  ];

  if (error) return <div>Error: {error.message}</div>;

  return (
    <div>
      <div className="tw-flex tw-justify-between tw-mb-2 tw-items-center ">
        <PageTitle title={"CCC Analytics"} />
        <RangePicker
          format="YYYY-MM-DD"
          onChange={(date, dateString) => {
            setFromDate(dateString[0]);
            setToDate(dateString[1]);
          }}
          value={[
            fromDate ? dayjs(fromDate) : null,
            toDate ? dayjs(toDate) : null,
          ]}
        />
      </div>

      <div className="tw-flex tw-space-x-10 tw-items-center">
        <h2 className="tw-text-xl tw-font-bold tw-mb-4">
          Care Events Total :{" "}
          <span className="tw-text-green-600">{careEventTotal}</span>
        </h2>
        <h2 className="tw-text-xl tw-font-bold tw-mb-4">
          Care Events SCP Total :{" "}
          <span className="tw-text-green-600">{scpTotal}</span>
        </h2>
        <h2 className="tw-text-xl tw-font-bold tw-mb-4">
          TOC Total : <span className="tw-text-green-600">{tocTotal}</span>
        </h2>
        <h2 className="tw-text-xl tw-font-bold tw-mb-4">
          Root Cause Analysis Count:{" "}
          <span className="tw-text-green-600">{rootCauseAnalysisCount}</span>
        </h2>
      </div>
      <Table bordered dataSource={sortedData} columns={cccColumns} />
      <div>
        <h2 className="tw-text-xl tw-font-bold tw-mb-4">
          No. of SVA Forms Submitted :{" "}
          <span className="tw-text-green-600">{SVAData?.length}</span>
        </h2>
        {svaLoading ? (
          <Skeleton active={svaLoading} />
        ) : (
          <Table
            bordered
            size="middle"
            dataSource={SVAData}
            columns={SVAcolumns}
            rowKey="id"
          />
        )}
      </div>
    </div>
  );
};

export default CCCAnalytics;
