import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import {
  formatOptionLabel,
} from "../../common/elements/myCustomSelect";
import { Table, Select, DatePicker } from "antd";
import Search from "antd/es/input/Search";
import PageTitle from "../Common/PageTitle";
import { fetcherhn } from "../../services/fectcher";
import useSWR from "swr";
import { CSVLink } from "react-csv";
import { BsFiletypeCsv } from "react-icons/bs";

export const OutBoundCalls = () => {  
  const [query, setQuery] = useState("");
  const [provider, setProvider] = useState(["All"]);
  
  const [fromDate, setFromDate] = useState(
    dayjs().subtract(7, "day").format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [sortedData, setSortedData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [activeSorter, setActiveSorter] = useState(null);
 
  const { data: providerList} = useSWR(`GetOutBoundCallProvider`, fetcherhn); 
 
  const { data: detailData } = useSWR(
    `GetOutBoundCallSummaryReport?fromDate=${fromDate}&toDate=${toDate}&assigned_provider=${provider.map(p => `'${p}'`).join(",")}`,
    fetcherhn
  ); 
  
  const disabledDate = (current, { from }) => {
    if (from) {
      return Math.abs(current.diff(from, "days")) >= 31;
    }
    return false;
  };

  const modifiedProviderList = providerList
  ? [{ label: "All", value: "All" }, ...providerList.map(provider => ({
      label: provider?.id,  
      value: provider?.providerNameLabel,     
    }))]
  : [{ label: "All", value: "All" }];

  //Update the onProviderChange function to set "All" when it's selected
  const onProviderChange = (selectedProviders) => {
    if (selectedProviders?.includes("All")) {
      setProvider(["All"]);
    } else {
      setProvider(selectedProviders);
    }
  };  
  
  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter?.order) {
      setActiveSorter(sorter); // Update active sorter state      

      // Perform sorting based on active sorter
      const sorted = [...filteredData].sort((a, b) => {
        const valueA = a[sorter?.field];
        const valueB = b[sorter?.field];        
        if (typeof valueA === "number" && typeof valueB === "number") {
          return sorter?.order === "ascend" ? valueA - valueB : valueB - valueA;
        } else {
          return sorter?.order === "ascend"
            ? valueA?.localeCompare(valueB)
            : valueB?.localeCompare(valueA);
        }
      });
      setSortedData(sorted);
    } else {
      setSortedData(filteredData); // Reset to default filtered data
      setActiveSorter(null); // Clear active sorter
    }
  };

  useEffect(() => {
    if (detailData) {
      const filtered = detailData.filter((item) => {
        return [
          "patientNamewithDOB",
          "nPorPaLabel",
          "providerName",
          "OutboundCalls",
          "billingStatus",
          "tierFlag",
          "callFrequency",
          "teamFlag",
          "preferredLanguage",          
        ].some((key) => {
          const value = item[key]?.toString()?.toLowerCase() ?? "";
          const transformedQuery =
            key === "callFrequency"
              ? (query?.toLowerCase() ?? "")?.replace(/ /g, "_")
              : query?.toLowerCase() ?? "";
          return value.includes(transformedQuery);
        });
      });
      setFilteredData(filtered);
      setSortedData(filtered); // Initialize with filtered data
    }
  }, [detailData, query]);

  useEffect(() => {
    if (query === "") {
      setSortedData(filteredData); // Reset sorted data to unsorted state
      setActiveSorter(null); // Clear active sorting
    }
  }, [query, filteredData]);
  
  const { RangePicker } = DatePicker;
  const columns = [
    {
      key: 1,
      title: "Patient Name",
      dataIndex: "patientNamewithDOB",
      render: (patientNamewithDOB) =>
        formatOptionLabel({ label: patientNamewithDOB }),
      sorter: true,
      sortOrder: activeSorter?.field === "patientNamewithDOB" ? activeSorter?.order : null,
    },
    {
      key: 2,
      title: "NP/PA",
      dataIndex: "nPorPaLabel",
      sorter: true,
      sortOrder: activeSorter?.field === "nPorPaLabel" ? activeSorter?.order : null,
    },
    {
      key: 3,
      title: "Provider",
      dataIndex: "providerName",
      sorter: true,
      sortOrder: activeSorter?.field === "providerName" ? activeSorter?.order : null,
    },
    {
      key: 4,
      title: "Number of Outbound Calls",
      dataIndex: "OutboundCalls",    
      sorter: true,    
      sortOrder: activeSorter?.field === "OutboundCalls" ? activeSorter?.order : null,   
    },
    {
      key: 5,
      title: "Billing Status",
      dataIndex: "billingStatus",
      sorter: true,
      sortOrder: activeSorter?.field === "billingStatus" ? activeSorter?.order : null,
    },
    {
      key: 6,
      title: "Tier",
      dataIndex: "tierFlag",
      sorter: true,
      sortOrder: activeSorter?.field === "tierFlag" ? activeSorter?.order : null,
      
    },
    {
      key: 7,
      title: "Call Frequency",
      dataIndex: "callFrequency",
      render: (text) => <span>{text.replace(/_/g, " ")}</span>,
      sorter: true,
      sortOrder: activeSorter?.field === "callFrequency" ? activeSorter?.order : null,
    },
    {
      key: 8,
      title: "Team",
      dataIndex: "teamFlag",
      sorter: true,
      sortOrder: activeSorter?.field === "teamFlag" ? activeSorter?.order : null,
    },
    {
      key: 9,
      title: "Language",
      dataIndex: "preferredLanguage",
      sorter: true,
      sortOrder: activeSorter?.field === "preferredLanguage" ? activeSorter?.order : null,
    },
  ];

    const csvData = sortedData?.map((item) => ({
      PatientNamewithDOB: item.patientNamewithDOB,
      NPPA:item.nPorPaLabel,
      Provider: item.providerName,
      NumberOfOutboundCalls: item.OutboundCalls,
      BillingStatus: item.billingStatus,
      Tier: item.tierFlag,
      CallFrequency: item.callFrequency.replace(/_/g, " "),
      Team: item.teamFlag,
      Language: item.preferredLanguage,
    }));
    
  
  return (
    <div>
      <div className="tw-flex tw-justify-between tw-mb-4 ">
        
        <PageTitle title={"Outbound Call Summary Report"} />         

        <div className="tw-w-1/2 tw-flex tw-space-x-3 tw-justify-start tw-items-center">

        <Select
            mode="multiple"
            className="tw-w-1/2 tw-min-w-[200px]"
            showSearch
            allowClear
            value={provider}
            defaultValue={"All"}
            placeholder="Select Provider..."
            optionFilterProp="children"
            onChange={onProviderChange}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={modifiedProviderList}
          />    

          <Search
            placeholder="Search"
            value={query} 
            onChange={(e) => setQuery(e.target.value)}
            allowClear
            className="tw-flex-grow md:tw-w-auto tw-w-[200px]"
          />        
                        
          <RangePicker
            className="tw-w-full md:tw-w-auto tw-min-w-[250px]"
            format="YYYY-MM-DD"
            disabledDate={disabledDate}
            onChange={(date, dateString) => {
              setFromDate(dateString[0]);
              setToDate(dateString[1]);
              setQuery("");
            }}
            value={[
              fromDate ? dayjs(fromDate) : "",
              toDate ? dayjs(toDate) : "",
            ]}
          />

          {Array.isArray(csvData) && csvData.length > 0 && (
            <CSVLink data={csvData} filename={"report.csv"}>
              <BsFiletypeCsv size={"2em"} />
            </CSVLink>
          )}
        </div>        
      </div>

      <Table
        size="middle"
        dataSource={sortedData}
        columns={columns}
        onChange={handleTableChange} // Handles sorting
        pagination={{
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
        }}
        scroll={{
          x: 2000,
        }}
      />
    </div>
  );
};
