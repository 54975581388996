import React, { useState } from "react";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import { postTurf } from "../../api/CareEventsApi";
import { openNotification } from "../../common/elements/myNotification";

const dateFormat = ["YYYY-MM-DD"];

export const AddTherapyUtilizationReview = ({ editRecord, callback }) => {
  const [formData, setFormData] = useState(
    editRecord.guid
      ? { ...editRecord, modifiedBy: sessionStorage.getItem("userName") }
      : editRecord
  );

  const [reviewDate, setReviewDate] = useState(
    editRecord.reviewDate || dayjs()
  );
  const [disable, setDisable] = useState(false);
  const [pageError, setPageError] = useState({});

  const handleInputChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
    setPageError({ ...pageError, [name]: value ? "" : "Required" });
  };

  const medicareChange = (name, value) => {
    // Update the formData state based on the checkbox change
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value.target.checked ? "Yes" : "No",
    }));

    const isMedicareASelected = name === "medicareA" && value.target.checked;
    const isMedicareBSelected = name === "medicareB" && value.target.checked;

    const isMedicareA = formData?.medicareA === "Yes";
    const isMedicareB = formData?.medicareB === "Yes";

    // If neither checkbox is selected, set an error message
    if (!isMedicareASelected && !isMedicareBSelected) {
      if (!isMedicareA && !isMedicareB) {
        setPageError({ ...pageError, medicare: "Required" });
      }
    } else {
      // If at least one checkbox is selected, clear the error message
      setPageError({ ...pageError, medicare: "" });
    }
  };

  const handleDateChange = (date, dateString) => {
    setReviewDate(dateString);
    setFormData({ ...formData, reviewDate: dateString ? dateString : "" });
    setPageError({ ...pageError, reviewDate: dateString ? "" : "Required" });
  };

  const onSubmit = (e) => {
    setPageError({});

    const isMedicareASelected = formData?.medicareA === "Yes";
    const isMedicareBSelected = formData?.medicareB === "Yes";
    let pageErrorTemp = {};
    if (
      !formData?.reviewDate ||
      !formData?.hasPatientMetMaximumPotential ||
      (!isMedicareASelected && !isMedicareBSelected)
    ) {
      pageErrorTemp.reviewDate = formData?.reviewDate ? "" : "Required";
      pageErrorTemp.hasPatientMetMaximumPotential =
        formData?.hasPatientMetMaximumPotential ? "" : "Required";
      if (!isMedicareASelected && !isMedicareBSelected) {
        pageErrorTemp.medicare = "Required";
      } else {
        pageErrorTemp.medicare = "";
      }
      setPageError(pageErrorTemp);
      return;
    }

    onSave(e);
  };
  const onSave = async (e) => {
    setDisable(true);

    try {
      const response = await postTurf(formData);
      if (response?.status === 200 && !formData?.guid) {
        openNotification(
          "success",
          "Added",
          "Therapy Utilization Review Added Successfully!"
        );
      } else if (formData?.guid) {
        openNotification(
          "success",
          "Updated",
          "Therapy Utilization Review Updated Successfully!"
        );
      } else {
        openNotification(
          "error",
          "Error",
          "Therapy Utilization Review Failed to Add!"
        );
      }
      setFormData(editRecord);
      setReviewDate(dayjs());
      callback(response);
    } catch (e) {
      return e;
    }
    setDisable(false);
  };

  return (
    <div className="tw-flex tw-flex-col tw-relative">
      <div className="tw-mb-6 fw-bold ">
        <label>Review Date</label>
        <DatePicker
          className="form-control"
          placeholder="YYYY-MM-DD"
          value={reviewDate ? dayjs(reviewDate, dateFormat) : ""}
          format={dateFormat}
          onChange={handleDateChange}
          style={{ borderColor: pageError?.reviewDate ? "red" : "#ddd" }}
        />
      </div>
      <div className="tw-flex tw-mb-6 ">
        <input
          className="tw-mr-3 form-check-input"
          type="checkbox"
          id="medicareA"
          checked={formData?.medicareA === "Yes" ? true : false}
          onChange={(e) => medicareChange("medicareA", e)}
        />
        <label className="tw-font-bold tw-mr-24" htmlFor="medicareA">
          Medicare A
        </label>
        <input
          className="tw-mr-3 form-check-input"
          type="checkbox"
          id="medicareB"
          checked={formData?.medicareB === "Yes" ? true : false}
          onChange={(e) => medicareChange("medicareB", e)}
        />
        <label className="tw-font-bold tw-mr-1" htmlFor="medicareB">
          Medicare B
        </label>
        <span
          className="text-danger"
          style={{ fontWeight: "normal", paddingLeft: "5px" }}
        >
          {pageError?.medicare}
        </span>
      </div>
      <div className="tw-flex tw-flex-col tw-mb-6">
        <label className="tw-font-bold" htmlFor="therapyGoals">
          Goals of Therapy
        </label>
        <textarea
          maxLength={1000}
          className=" form-control tw-py-1 tw-px-1 "
          id="therapyGoals"
          value={formData?.therapyGoals}
          onChange={(e) => handleInputChange("therapyGoals", e.target.value)}
        ></textarea>
      </div>

      <div className="tw-flex tw-flex-col tw-mb-6">
        <label className="tw-font-bold" htmlFor="therapyDetails">
          Therapy Services and frequency
        </label>
        <textarea
          maxLength={1000}
          className=" form-control tw-py-1 tw-px-1 "
          id="therapyDetails"
          value={formData?.therapyDetails}
          onChange={(e) => handleInputChange("therapyDetails", e.target.value)}
        ></textarea>
      </div>
      <div className="tw-flex tw-flex-col tw-mb-6">
        <div className="form-group fw-bold">
          <label htmlFor="medPtOptions" className="pb-1">
            Has the patient met maximum potential?
          </label>

          <fieldset className="fw-normal">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="hasPatientMetMaximumPotential"
                checked={formData?.hasPatientMetMaximumPotential === "Yes"}
                id="hasPatientMetMaximumPotentialYes"
                value={"Yes"}
                onChange={(e) =>
                  handleInputChange(
                    "hasPatientMetMaximumPotential",
                    e.currentTarget.value ? "Yes" : "No"
                  )
                }
                style={{
                  border: pageError?.hasPatientMetMaximumPotential
                    ? "1px solid red"
                    : "",
                }}
              />
              <label
                className="form-check-label"
                htmlFor="hasPatientMetMaximumPotentialYes"
              >
                Yes
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="hasPatientMetMaximumPotential"
                checked={formData?.hasPatientMetMaximumPotential === "No"}
                id="hasPatientMetMaximumPotentialNo"
                value={"No"}
                onChange={(e) =>
                  handleInputChange(
                    "hasPatientMetMaximumPotential",
                    e.currentTarget.value ? "No" : "Yes"
                  )
                }
                style={{
                  border: pageError?.hasPatientMetMaximumPotential
                    ? "1px solid red"
                    : "",
                }}
              />
              <label
                className="form-check-label"
                htmlFor="hasPatientMetMaximumPotentialNo"
              >
                No
              </label>
            </div>
            <span
              className="text-danger"
              style={{ fontWeight: "normal", paddingLeft: "5px" }}
            >
              {pageError?.hasPatientMetMaximumPotential}
            </span>
          </fieldset>
        </div>
      </div>

      <div className="tw-flex tw-flex-col tw-mb-6">
        <label className="tw-font-bold" htmlFor="therapyGoals">
          Case Manager Recommendations
        </label>
        <textarea
          className=" form-control tw-py-1 tw-px-1 "
          id="therapyGoals"
          maxLength={1000}
          value={formData?.caseManagerRecommendations}
          onChange={(e) =>
            handleInputChange("caseManagerRecommendations", e.target.value)
          }
        ></textarea>
      </div>
      <div className="form-group tw-fixed tw-top-2 tw-right-10">
        <button
          className={
            !disable ? "btn btn-success " : "btn btn-success disabled "
          }
          onClick={onSubmit}
        >
          {editRecord?.guid ? "Update" : "Save"}
        </button>
        <button
          className="btn btn-secondary tw-ml-4"
          onClick={() => callback(false)}
        >
          Close
        </button>
      </div>
    </div>
  );
};
