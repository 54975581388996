import React, { useEffect, useState } from "react";
import { Segmented, Select, Table } from "antd";
import PageTitle from "../Common/PageTitle";
import MyBackdropSpinner from "../../common/elements/myBackdropSpinner";
import * as ConditionsApi from "../../api/ConditionsApi";

const Conditions = ({ patientId }) => {
  const [tabState, setTabState] = useState("admit");
  const [allData, setAllData] = useState();
  const [searchData, setSearchData] = useState();
  const [conditionData, setConditionData] = useState();
  const [dischargeData, setDischargeData] = useState();
  const [spinner, setSpinner] = useState(false);
  const [query, setQuery] = useState();

  useEffect(() => {
    setSpinner(true);
    const conditionDataResponse = ConditionsApi.getConditions(patientId);
    Promise.all([conditionDataResponse]).then((values) => {
      let tempCondition = [];
      values[0]?.data.forEach((data) => {
        tempCondition.push({
          Icd10: data.icd10,
          Icd10Description: data.icd10Description,
          RankDescription: data.rankDescription,
          source: "PointClickCare",
        });
      });
      setConditionData(tempCondition);
      setSearchData(conditionData);
      setSpinner(false);
    });
  }, []);

  const admissioncolumns = [
    {
      title: "Icd10",
      key: "Icd10",
      dataIndex: "Icd10",
    },
    {
      title: "Icd10 Description",
      key: "Icd10Description",
      dataIndex: "Icd10Description",
    },

    {
      title: "Rank Description",
      key: "RankDescription",
      dataIndex: "RankDescription",
    },
    {
      title: "Source",
      key: "source",
      dataIndex: "source",
      width: 130,
    },
  ];

  const filteredItems = searchData?.filter((item) => {
    return Object.keys(item).some((key) =>
      (item[key]?.toString()?.toLowerCase() ?? "").includes(
        query?.toLowerCase() ?? ""
      )
    );
  });

  return (
    <div>
      {/* {<PageTitle title={`PointClickCare Conditions`} />} */}
      {spinner && <MyBackdropSpinner show={spinner} />}
      <div style={{ color: "var(--bs-gray-800)" }}>
        <div className="tw-flex tw-justify-between tw-border tw-mb-6">
          <div className="tw-flex tw-justify-end tw-space-x-2 tw-basis-2/5"></div>
        </div>
        <div className="md">
          <Table
            className="table-striped-rows"
            columns={admissioncolumns}
            dataSource={conditionData}
            size="large"
            bordered
            pagination={{
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Conditions;
