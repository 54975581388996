import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import PageTitle from "../Common/PageTitle";
import * as HRRPatientsApi from "../../api/HRRPatientsApi";
import { useMyContext } from "../../common/elements/myContext";
import './CCMDPatientProfile.css';

const CCMDPatientProfile = (props) => {
  const [hrrPatientstMaster, setHRRPatientstMaster] = useState([]);
  const params = useParams();

  const { getFormattedDateDayJs } = useMyContext();

  const [filter, setFilter] = useState({
    mbiid: params?.mbiid || "",
    firstName: "",
    lastName: "",
    facilityName: "",
    region: "",
    assignedProvider: "",
    alignedProvider: "",
    status: "",
  });

  useEffect(() => {
    getHRRPatients(filter);
  }, []);

  const getHRRPatients = async (filter) => {
    try {
      const response = await HRRPatientsApi.getHRRPatients(filter);
      let responseData = response?.data?.patientList;
      setHRRPatientstMaster(responseData);
      //console.log(responseData)
      return responseData;
    } catch (error) {
      console.error(error);
      setHRRPatientstMaster([]);
    }
  };

  return (
    <form>
    <div>
      <PageTitle title={"Patient Profile"} />
      <div
        className="row"
        style={{
          border: "1px solid lightgray",
          padding: "30px",
          boxShadow: "3px 0px 5px 3px #e3e8e5",
        }}
      >
        {hrrPatientstMaster?.map((item) => (
          <>
            <div className="col-md-2">
              <img 
                src={
                  item.gender === "F" ? (
                    "../images/female.png" 
                  ) : item.gender === "M" ? (
                    "../images/male.png"
                  ) : (
                   "../images/person.png"
                  )
                }
                width="150px"/><br/>
                <p></p>
                
            </div>
            <div className="col-md-4">
                <div className="profile-section">
                  <div className = "profile-info">
                    <p><strong>Patient's Name:</strong> {item.lastName}{", "}{item.firstName}</p>
                    <p><strong>Patient's Location: </strong></p>
                    
                    <p>Facility Name:   {item.facilityName} </p>
                    <p>Address:   {item.facilityAddress} </p>
                    <p>City:   {item.facilityCity} </p>
                    <p>State:   {item.facilityState} </p>
                    <p>Zip:   {item.facilityZip} </p>
                    <p>Region:  {item.region} </p>
                  </div>
                </div>
            </div>


            <div className="col-md-3">
                <div className="profile-section">
                  <div className = "profile-info">

                    <p><strong>MBI: </strong> {item.mbiid} </p>
                    <p>Date Of Birth:  {" "} {getFormattedDateDayJs(item.dateOfBirth)} </p>
                    <p>Age:  {item.age} </p>
                    <p>Gender:  {item.gender} </p>
                    <p><strong>Status: </strong>  {item.status} </p>
              
                    <p>Date Of Death: {" "}
                      {item.status === "Deceased"
                        ? getFormattedDateDayJs(item.dateOfdeath)
                        : ""}{" "}
                    </p>

                    <p>Race/Ethnicity:  {item.raceEthnicity} </p>
                    <p>Preferred Language:  {item.preferredLanguage}{" "} </p>
                  </div>
                </div>
            </div>

            <div className="col-md-3">
                <div className="profile-section">
                  <div className = "profile-info">
                  <p>Assigned Provider:   {item.assignedProvider} </p>
                  <p>Aligned Provider:   {item.alignedProvider} </p>
             
                  <p>Alignment Type:  {item.alignmentType} </p>
                  <p>Alignment Date:  {getFormattedDateDayJs(item.alignmentDate)} </p>
                  <p>CMS marked High Risk?:  {item.isCMSMarkedHigRisk=='Y'? 'Yes':'No'} </p>
                  <p>CCMD marked High Risk?:  {item.isCareConnectMarkedHigRisk=='Y'? 'Yes':'No'} </p>
              </div>
                </div>
            </div>

            {/* <div className="col-md-3">
              
            </div> */}
          </>
        ))}
      </div>
    </div>
    </form>
  );
};

export default CCMDPatientProfile;
