import ICDWithSource from "./ICDWithSource";
import React, { useEffect, useState } from "react";

const ICDWithSourceSummary = ({
  myKey,
  title,
  defaultValues,
  selectedValues,
  icd10Codes,
  onecdCodeListChange,
  selectedSource,
  sourcesList,
  onSourceChange,
  selectedSourceText,
  onSourceTextChange,
  errorMessages,
}) => {
  const [icdWithSource, setIcdWithSource] = useState([]);
  const [icdCount, setIcdCount] = useState([1]);

  const [selectedIcdCodeX, setSelectedIcdCodeX] = useState({});
  const [selectedSourceX, setSelectedSourceX] = useState([]);
  const [selectedSourceTextX, setSelectedSourceTextX] = useState([]);

  const [errorMessagesX, setErrorMessagesX] = useState([]);

  useEffect(() => {
    assignDefaultValues();
  }, [defaultValues, errorMessages]);

  const assignDefaultValues = () => {
    // Icd code
    setSelectedIcdCodeX(selectedValues || {});

    let tempCount = [];
    let itemCount = 1;
    Object.keys(selectedValues || {})?.forEach((item) => {
      tempCount.push(itemCount++);
    });
    tempCount.push(itemCount++);
    setIcdCount(tempCount && tempCount.length > 0 ? tempCount : [1]);

    // Source
    setSelectedSourceX(selectedSource || {});

    // Other Source
    setSelectedSourceTextX(selectedSourceText || []);

    setErrorMessagesX(errorMessages);
  };

  // Icd Code Change
  const onecdCodeListChangeX = (e, index) => {
    const tempIcd = { ...selectedIcdCodeX, [index]: e };
    setSelectedIcdCodeX(tempIcd);
    onecdCodeListChange(tempIcd);
  };

  const onIcdCodeListRemoveX = (index) => {
    const tempIcd = {};
    const tempIcdSource = {};
    const tempSourceError = {};
    const tempSourceText = {};

    let tempIndex = 0;
    let tempIndexCount = [];

    Object.keys(selectedIcdCodeX || {})?.forEach((key) => {
      if (index != key) {
        tempIndexCount.push(tempIndex);
        tempIcd[tempIndex] = selectedIcdCodeX[key];
        tempIcdSource[tempIndex] = selectedSourceX[key];
        tempSourceText[tempIndex] = selectedSourceTextX[key];
        tempSourceError[tempIndex] = selectedSourceX[key] ? "" : "Required";
        tempIndex++;
      }
    });

    if (Object.keys(selectedIcdCodeX)?.length < icdCount.length) {
      tempIndex++;
      tempIndexCount.push(tempIndex);
    }

    setIcdCount(tempIndexCount);

    setSelectedIcdCodeX(tempIcd);
    onecdCodeListChange(tempIcd);

    setSelectedSourceX(tempIcdSource);
    onSourceChange(tempIcdSource, tempSourceError);
    setSelectedSourceTextX(tempSourceText);
    onSourceTextChange(tempSourceText, tempSourceError);

    // console.log("on Source Remove");
    // console.log(tempIcd);
    // console.log(tempIcdSource);
    // console.log(tempSourceText);
  };

  // Source Change
  const onSourceChangeX = (e, index) => {
    const tempSource = { ...selectedSourceX, [index]: e };
    setSelectedSourceX(tempSource);

    const tempSourceError = { ...errorMessagesX, [index]: e ? "" : "Required" };
    setErrorMessagesX(tempSourceError);

    if (icdCount?.length - 1 === index) {
      icdCount.push(icdCount + 1);
      setIcdCount(icdCount);
    }
    onSourceChange(tempSource, tempSourceError);
  };

  // Source Text Change
  const onSourceTextChangeX = (e, index) => {
    const tempSource = { ...selectedSourceTextX, [index]: e };

    const tempSourceError = { ...errorMessagesX, [index]: e ? "" : "Required" };
    setErrorMessagesX(tempSourceError);

    setSelectedSourceTextX(tempSource);
    onSourceTextChange(tempSource, tempSourceError);
  };

  return (
    <React.Fragment>
      {icdCount.map((item, index) => {
        return (
          <ICDWithSource
            key={`${myKey}-${index}`}
            index={index}
            selectedIcdCodeX={selectedIcdCodeX}
            icd10Codes={icd10Codes}
            onecdCodeListChangeX={onecdCodeListChangeX}
            sourcesList={sourcesList}
            onSourceChangeX={onSourceChangeX}
            selectedSourceX={selectedSourceX}
            selectedSourceTextX={selectedSourceTextX}
            onSourceTextChangeX={onSourceTextChangeX}
            title={title}
            errorMessages={errorMessagesX}
            removeSelectedFilter={onIcdCodeListRemoveX}
          ></ICDWithSource>
        );
      })}
    </React.Fragment>
  );
};

export default ICDWithSourceSummary;
