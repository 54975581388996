import { AuthenticatedHome } from "./components/AuthenticatedHome";
import Login from "./components/Login/Login";
import HCCPatientWorklist from "./components/HCCReview/HCCPatientWorklist";
import HCCAllPatientReport from "./components/Report/HCCAllPatientReport";
import ScoreNew from "./components/Score/ScoreNew";
import CCMDPatientProfile from "./components/CCMDPatients/CCMDPatientProfile";
import HCCDiagnosisHistory from "./components/Report/HCCDiagnosisHistory";
import VAForm from "./components/Report/VAForm";
import AddTask from "./components/TaskManager/AddTask";
import AllTasks from "./components/TaskManager/AllTasks";
import TaskList from "./components/TaskManager/TaskList";
import ClinicalDashboard from "./components/Dashboard/ClinicalDashboard";
import CCMDPatient360 from "./components/CCMDPatients/CCMDPatient360";
import ReviewChronicCodeList from "./components/HCCReview/ChronicCodeList";
import CCReview from "./components/HCCReview/CCReview";
import { CCMissedOppList } from "./components/HCCReview/CCMissedOppList";
import OptumCensus from "./components/HCCReview/OptumCensus";
import ProspectRAF from "./components/HCCReview/ProspectRAF";
import { NoMatch } from "./components/Common/NoMatch";
import { OptumFacility } from "./components/Census/OptumFacility";
import { CareEventForms } from "./components/CareEvent/CareEventForms";
import Pt360 from "./components/Pt360/Pt360";
import PCCPatients from "./components/PCC/PCCPatients";

import TocView from "./components/ToCare/TocView";
import { AuditLog } from "./components/Report/AuditLog";
import { TocAuditLog } from "./components/Report/TocAuditLog";
import RootCauseAnalysis from "./components/CareEvent/RootCauseAnalysis";
import { SCPForms } from "./components/CareEvent-SCP/SCPForms";
import { SCPAuditLog } from "./components/Report/SCPAuditLog";
import { TriageCallLog } from "./components/Report/TriageCallLog";
import { CCMDPatientsAll } from "./components/CCMDPatients/CCMDPatientsAll";
import CaseManagementDashboard from "./components/CaseMgmtDashboard/CaseManagementDashboard";
import { RCADeatilsReport } from "./components/Report/RCADeatilsReport";
import { useNewPt360 } from "./GlobalConfiguration";
import { CareEventReport } from "./components/Report/CareEventReport";
import CCE from "./components/CCE/CCE";
import { ChartPathRefresh } from "./components/CCMDPatients/ChartPathRefresh";
import { OutBoundCalls } from "./components/Report/Outbundcallreport";
import SVAHome from "./components/SVA/SVAHome";
import CCCAnalytics from "./components/Analytics/CCC_Analytics";

const AppRoutes = [
  {
    path: "*",
    element: <NoMatch />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/hcc/scorenew",
    element: <ScoreNew />,
  },
  {
    path: "/hcc/scorenew/:mbiID/:firstName/:lastName/:gender/:facilityID/:providerGroupName/:renderingProviderID/:dateOfBirth",
    element: <ScoreNew />,
  },
  {
    path: "/hcc/scorenew/:guid",
    element: <ScoreNew />,
  },
  {
    path: "/hcc/HCCAllPatientReport",
    element: <HCCAllPatientReport />,
  },
  {
    path: "/hcc/HCCPatientWorklist",
    element: <HCCPatientWorklist />,
  },

  {
    path: "/AllPatients",
    element: <CCMDPatientsAll />,
  },
  {
    path: "/CCMDPatientProfile/:mbiid",
    element: <CCMDPatientProfile />,
  },
  {
    path: "/CCMDPatient360/:mbiid",
    element: <CCMDPatient360 />,
  },
  {
    path: useNewPt360 === false ? "/Pt360/:patientId" : "/Pt360/:mbiid/:lob",
    element: <Pt360 />,
  },
  {
    path: "/",
    element: <AuthenticatedHome />,
  },
  {
    path: "reports/hccdiagnosis",
    element: <HCCDiagnosisHistory />,
  },
  {
    path: "/VAForm",
    element: <VAForm />,
  },
  {
    path: "/ClinicalDashboard",
    element: <ClinicalDashboard />,
  },
  { path: "/taskManager/addTask", element: <AddTask /> },
  { path: "/taskManager/taskList", element: <TaskList /> },
  { path: "/taskManager/allTasks", element: <AllTasks /> },
  {
    path: "/taskManager/AddTask/:taskId/:patientId/:taskPriority/:taskStatus/:taskType/:notes/:assigneeId/:dueDate",
    element: <AddTask />,
  },
  {
    path: "/taskManager/AddTask/:taskId/:patientId/:taskPriority/:taskStatus/:taskType/:assigneeId/:dueDate",
    element: <AddTask />,
  },
  {
    path: "/reviewccl",
    element: <ReviewChronicCodeList />,
  },
  {
    path: "/reviewmo",
    element: <CCReview />,
  },
  {
    path: "/hcc/ccmo",
    element: <CCMissedOppList />,
  },
  {
    path: "/hcc/ccmo/:mbiid",
    element: <CCReview />,
  },
  {
    path: "/optum/optumcensus",
    element: <OptumCensus />,
  },
  {
    path: "/care/dash",
    element: <CaseManagementDashboard />,
  },
  {
    path: "/raf",
    element: <ProspectRAF />,
  },
  {
    path: "/PointClickCarePatients",
    element: <PCCPatients />,
  },

  {
    path: "care/tocview",
    element: <TocView />,
  },
  {
    path: "optum/optumfacility",
    element: <OptumFacility />,
  },
  {
    path: "care/careevent",
    element: <CareEventForms />,
  },
  {
    path: "care/careeventscp",
    element: <SCPForms />,
  },
  {
    path: "reports/cereport",
    element: <CareEventReport />,
  },
  {
    path: "reports/ceauditlog",
    element: <AuditLog />,
  },
  {
    path: "reports/tocauditlog",
    element: <TocAuditLog />,
  },
  {
    path: "reports/scpauditlog",
    element: <SCPAuditLog />,
  },
  {
    path: "rca/report/:guid",
    element: <RootCauseAnalysis />,
  },
  {
    path: "rca/:guid",
    element: <RootCauseAnalysis />,
  },
  {
    path: "reports/triagecalllog",
    element: <TriageCallLog />,
  },
  {
    path: "reports/rcareports",
    element: <RCADeatilsReport />,
  },
  {
    path: "Reports/Outbundcallreport",
    element: <OutBoundCalls />,
  },
  {
    path: "cce",
    element: <CCE />,
  },
  {
    path: "cprefresh",
    element: <ChartPathRefresh />,
  },
  {
    path: "sva",
    element: <SVAHome />,
  },
  {
    path: "analytics",
    element: <CCCAnalytics />,
  },
];

export default AppRoutes;
