import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { WarningOutlined } from "@ant-design/icons";
import { useMsal } from "@azure/msal-react";

const SessionTimeOut = () => {
  const [loggedIn, setLoggedIn] = useState(true);
  const [countdown, setCountdown] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { instance } = useMsal();

  const handleLogoutRedirect = () => {
    instance.logoutRedirect().catch((error) => console.error(error));
    sessionStorage.removeItem("Bearer");
  };

  const checkForInactivity = () => {
    const expireTime = sessionStorage.getItem("expireTime");
    const currentTime = Date.now();
    if (expireTime < currentTime) {
      setLoggedIn(false);
    } else {
      const remainingTime = Math.floor((expireTime - currentTime) / 1000);
      if (remainingTime <= 30) {
        setCountdown(remainingTime);
      } else {
        setCountdown(0);
      }
    }
  };

  const updateExpireTime = () => {
    const expireTime = Date.now() + 600000;
    sessionStorage.setItem("expireTime", expireTime);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      checkForInactivity();
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    updateExpireTime();

    window.addEventListener("click", updateExpireTime);
    window.addEventListener("keypress", updateExpireTime);
    window.addEventListener("scroll", updateExpireTime);
    window.addEventListener("mousemove", updateExpireTime);

    return () => {
      window.removeEventListener("click", updateExpireTime);
      window.removeEventListener("keypress", updateExpireTime);
      window.removeEventListener("scroll", updateExpireTime);
      window.removeEventListener("mousemove", updateExpireTime);
    };
  }, []);

  useEffect(() => {
    if (countdown > 0) {
      const countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
        setIsModalOpen(true);
      }, 1000);

      return () => clearInterval(countdownInterval);
    }
  }, [countdown]);

  useEffect(() => {
    if (countdown === 0 && !loggedIn) {
      handleLogoutRedirect();
    }
  }, [countdown, loggedIn]);

  return (
    <div>
      {countdown > 0 && (
        <Modal open={isModalOpen} onCancel={handleCancel} footer={null}>
          <div className="tw-text-center">
            <WarningOutlined className="tw-text-8xl tw-text-red-500 tw-mb-4 tw-mt-8" />
            <div>
              <h5>You have been inactive for 10 minutes.</h5>
              <p className="tw-text-xl tw-mb-2">
                The page will sign out in{" "}
                <span className="tw-font-bold">{countdown}</span> seconds
              </p>
              <div className="tw-text-base tw-text-gray-500">
                Press Any Key or Move your Mouse to stay Signed In.
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default SessionTimeOut;
