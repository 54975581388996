import { axiosInstance, axiosInstancehn } from "./AxiosInstance";

export async function getToc(mbi) {
  return axiosInstance
    .get(`/api/ToC?mbiID=${mbi}`)
    .then(function (response) {
      //debugger;
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function getTocTimeline(mbi) {
  return axiosInstance
    .get(`/api/ToCTimeLine?mbiID=${mbi}`)
    .then(function (response) {
      //debugger;
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
