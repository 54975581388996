import React, { useState, useEffect } from "react";

const MyTextBox = ({
  id,
  label,
  subLabel,
  value,
  setValue,
  isRequired,
  maxLength,
  placeholder,
  additionalStyle,
  disabled,
  errorMessage,
  error,
  showLabel = true,
  isPhoneNumber = false, // New prop to enable phone number formatting
  onValidChange, // New prop to pass validation status
}) => {
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    if (onValidChange) {
      onValidChange(isValid);
    }
  }, [isValid]);

  const handleInputChange = (e) => {
    let value = e.target.value.replace(/\D/g, "");
    const originalLength = value.length;

    if (isPhoneNumber) {
      if (value.length >= 7) {
        value = value.replace(/(\d{3})(\d{3})(\d{0,4})/, "$1-$2-$3");
      } else if (value.length >= 4) {
        value = value.replace(/(\d{3})(\d{0,3})/, "$1-$2");
      } else {
        value = value.replace(/(\d{0,3})/, "$1");
      }

      // Update the phone value only if the user is not deleting
      if (e.target.value.length >= originalLength) {
        const formattedValue = value.substring(0, 12);
        setValue({ target: { value: formattedValue } });
        validatePhone(formattedValue);
      } else {
        setValue({ target: { value: value } });
      }
    } else {
      setValue(e);
    }
  };

  const validatePhone = (value) => {
    const phonePattern = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/;
    const valid = phonePattern.test(value);
    setIsValid(valid);
  };

  return (
    <div className="form-group fw-bold">
      {showLabel && (
        <label
          htmlFor={id}
          className={`${isRequired === true ? "required" : ""}`}
        >
          {label}
          <small style={{ fontWeight: "normal", paddingLeft: "5px" }}>
            {subLabel}
          </small>
        </label>
      )}
      <input
        style={{ border: errorMessage ? "1px solid red" : "" }}
        type="text"
        className="form-control"
        disabled={disabled === true}
        onChange={handleInputChange}
        value={value}
        id={id}
        name={id}
        placeholder={isPhoneNumber ? "000-000-0000" : placeholder}
        maxLength={isPhoneNumber ? 12 : maxLength ? maxLength : 200}
      />
      {!isValid && (
        <span
          className="text-danger"
          style={{ fontWeight: "normal", paddingLeft: "5px" }}
        >
          Please enter a valid phone number.
        </span>
      )}
    </div>
  );
};

export default MyTextBox;
