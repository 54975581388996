import React, { useState } from "react";
import PageTitle from "../Common/PageTitle";
import MyCustomSelect, {
  formatOptionLabel,
} from "../../common/elements/myCustomSelect";
import { TuckInCall } from "./TuckInCall";
import MyBackdropSpinner from "../../common/elements/myBackdropSpinner";
import { ChangeinConditionNote } from "./ChangeinConditionNote";
import { TherapyUtilizationReview } from "./TherapyUtilizationReview";
import { IPHospitalCommNote } from "./IPHospitalCommNote";
import CceTracking from "./CceTracking";
import AdmReAdm from "./AdmReAdm";
import HighRisk from "./HighRisk";
import { Notes } from "./Notes";
import useSWR from "swr";
import { fetcher } from "../../services/fectcher";
import TriageCall from "./TriageCall";
import { usePickList } from "../../services/queries";

export const CareEventForms = () => {
  const { data: allPatients, isLoading } = useSWR(
    `GetPatientsListNew?lob=${"HN,PAC"}`,
    fetcher
  );
  const formOptions = usePickList(35).data;

  const [selecdtedPatient, setSelecdtedPatient] = useState();
  const [selecdtedEvent, setSelecdtedEvent] = useState();

  return (
    <>
      <PageTitle title={"Care Event"} />
      {isLoading && <MyBackdropSpinner show={isLoading} />}
      <div className="tw-flex tw-place-content-start tw-gap-4">
        <div className="tw-basis-1/3">
          <label htmlFor="patientName" className=" fw-bold">
            Patient
          </label>
          <MyCustomSelect
            selectOptions={allPatients}
            selectedValue={selecdtedPatient}
            myCallBack={(e) => {
              setSelecdtedPatient(e);
              setSelecdtedEvent("");
            }}
            formatOptionLabel={formatOptionLabel}
          />
        </div>
        <div className="tw-basis-1/3">
          <label htmlFor="eventType" className=" fw-bold">
            Care Event Forms
          </label>
          <MyCustomSelect
            selectOptions={formOptions}
            selectedValue={selecdtedEvent}
            disable={selecdtedPatient?.value ? false : true}
            myCallBack={(e) => setSelecdtedEvent(e)}
            // errorMessage={pageError?.patientId}
          />
        </div>
      </div>
      <div>
        {selecdtedEvent?.value === "01_Tuck_In_Call" && (
          <TuckInCall
            mbiid={selecdtedPatient?.value}
            patient={selecdtedPatient}
          />
        )}
      </div>
      <div>
        {selecdtedEvent?.value === "02_Therapy_Utilization_Review" && (
          <TherapyUtilizationReview
            mbiid={selecdtedPatient?.value}
            patient={selecdtedPatient}
          />
        )}
      </div>
      <div>
        {selecdtedEvent?.value === "03_Change_in_Condition_Note" && (
          <ChangeinConditionNote
            mbiid={selecdtedPatient?.value}
            patient={selecdtedPatient}
          />
        )}
      </div>
      <div>
        {selecdtedEvent?.value === "04_General_Notes" && (
          <Notes mbiid={selecdtedPatient?.value} patient={selecdtedPatient} />
        )}
      </div>
      <div>
        {selecdtedEvent?.value ===
          "05_Inpatient_Hospital_Communication_Note" && (
          <IPHospitalCommNote
            mbiid={selecdtedPatient?.value}
            patient={selecdtedPatient}
          />
        )}
      </div>
      <div>
        {selecdtedEvent?.value === "cce" && (
          <CceTracking
            mbiid={selecdtedPatient?.value}
            patient={selecdtedPatient}
          />
        )}
      </div>
      <div>
        {selecdtedEvent?.value === "06_Admission_Re-Admission" && (
          <AdmReAdm patient={selecdtedPatient} />
        )}
      </div>
      <div>
        {selecdtedEvent?.value === "07_High_Risk_Assessment" && (
          <HighRisk
            mbiid={selecdtedPatient?.value}
            patient={selecdtedPatient}
          />
        )}
      </div>
      <div>
        {selecdtedEvent?.value === "08_Triage_Call" && (
          <TriageCall
            mbiid={selecdtedPatient?.value}
            patient={selecdtedPatient}
          />
        )}
      </div>
    </>
  );
};
