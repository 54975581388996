import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Segmented, Select, Table } from "antd";
import PageTitle from "../Common/PageTitle";
import MyBackdropSpinner from "../../common/elements/myBackdropSpinner";
import { getTocTimeline } from "../../api/TocApi";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

//debugger;
const TOCTimeLine = ({ mbi }) => {
  const series = [
    {
      name: "SNF Admit",
      data: [
        {
          x: "SNF Admit",
          y: [],
        },
      ],
    },
    {
      name: "Hospital Admission",
      data: [
        {
          x: "Hospital Admission",
          y: [],
        },
      ],
    },
    {
      name: "ED Visit",
      data: [
        {
          x: "ED Visit",
          y: [],
        },
      ],
    },
  ];

  const options = {
    chart: {
      height: 100,
      type: "rangeBar",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,

      // formatter: function (val, opt) {
      //   debugger;
      //   var label = "";
      //   opt.w.globals.initialSeries.forEach((d) => {
      //     d.data.forEach((dt) => {
      //       if (dt.y[0] == val[0] || dt.y[1] == val[0])
      //         if (d.name == "Hospital Admission" && opt.seriesIndex == 1)
      //           label = "HA";
      //         else if (d.name == "SNF Admit" && opt.seriesIndex == 0)
      //           label = "SA";
      //         else if (d.name == "ED Visit" && opt.seriesIndex == 2)
      //           label = "EV";
      //     });
      //     //alert(opt.seriesIndex);
      //   });
      //   var a = dayjs(val[0]);
      //   var b = dayjs(val[1]);
      //   var diff = b.diff(a, "days");
      //   if (diff == 0) return "";
      //   return label; //+ ": " + diff + (diff > 1 ? " days" : " day");
      // },
      style: {
        colors: ["black"],
        //fontSize: "10px",
      },
    },

    xaxis: {
      labels: {
        format: "MMM/y",
      },
      type: "datetime",

      min: new Date(
        new Date().setFullYear(
          new Date().getFullYear() - 1,
          new Date().getMonth(),
          1
        )
      ).getTime(),
      max: new Date().getTime(),
    },
    legend: {
      show: false,
    },

    colors: ["#8ad6cb"], // #b5d3cc
  };

  const [TocData, setTocData] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const [showGrid, setShowGrid] = useState(false);
  // const params = useParams();

  useEffect(() => {
    setSpinner(true);
    const tocdataResponse = getTocTimeline(mbi);
    Promise.all([tocdataResponse]).then((values) => {
      //debugger;

      let toc = [];
      let objIndex;
      values[0]?.data.forEach((tlmdata) => {
        //alert(tlmdata.data[index].y);
        setShowGrid(true);
        objIndex = series.findIndex((obj) => obj.name == tlmdata.name);
        tlmdata.data.forEach((tdata) => {
          var dates = [
            new Date(tdata.y[0]).getTime(),
            new Date(tdata.y[1]).getTime(),
          ];
          if (dates[0] == dates[1])
            dates[0] = new Date(tdata.y[0]).getTime() + 50000000; //to display same day bar some diff is needed
          tdata.y = dates;
        });
        series[objIndex] = tlmdata;
      });
      // setTocData(values[0]?.data);
      setTocData(series);
      setSpinner(false);
    });
  }, []);

  return (
    <div>
      {/* {<PageTitle title={`Transitions of Care`} />} */}
      {spinner && <MyBackdropSpinner show={spinner} />}
      {TocData != null && showGrid && (
        <ReactApexChart
          options={options}
          series={TocData}
          type="rangeBar"
          height={350}
        />
      )}
      {showGrid == false && <div style={{ height: "200px" }}></div>}
    </div>
  );
};

export default TOCTimeLine;
