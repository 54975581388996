import React, { useEffect, useState } from "react";
import { Navbar } from "reactstrap";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { loginRequest, tokenRequest } from "./authConfig";
import { useNavigate, useLocation } from "react-router-dom";
import "./NavMenu.css";
import { FaRegUserCircle } from "react-icons/fa";
import { Menu, Button, Dropdown } from "antd";
import { MODE } from "../GlobalConfiguration";
import { fetcher } from "../services/fectcher";
import MyBackdropSpinner from "../common/elements/myBackdropSpinner";
import useSWRImmutable from "swr/immutable";

export const NavMenu = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { instance } = useMsal();
  const [current, setCurrent] = useState(1);
  const [menuItems, setMenuItems] = useState();
  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    let currentItem = data?.find((item) =>
      item.key === "/"
        ? item.key === location.pathname
        : location.pathname.startsWith(item.key)
    );
    if (currentItem) {
      setCurrent(currentItem?.key);
    }
    if (location.pathname === "/") {
      setCurrent("/");
    }

    let filMenuItems = data?.map((item) => {
      if (item.children && MODE === "PRODUCTION") {
        item.children = item?.children?.filter(
          (c) => c?.key != "/PointClickCarePatients"
        );
      }
      return item;
    });
    setMenuItems(filMenuItems);
  }, [location.pathname]);

  let activeAccount = instance?.getActiveAccount();

  // if (activeAccount) {
  //   instance.acquireTokenSilent(tokenRequest).then((response) => {
  //     const accessToken = response.accessToken;
  //     sessionStorage.setItem("Bearer", accessToken);
  //     sessionStorage.setItem("userName", activeAccount.username);
  //   });
  // }
  useEffect(() => {
    if (activeAccount) {
      instance.acquireTokenSilent(tokenRequest).then((response) => {
        const accessToken = response.accessToken;
        sessionStorage.setItem("Bearer", accessToken);
        sessionStorage.setItem("userName", activeAccount.username);
        setAccessToken(accessToken);
      });
    }
  }, [activeAccount, instance]);

  const { data, isLoading } = useSWRImmutable(
    accessToken ? `/GetMenu?token=${accessToken}` : null,
    fetcher
  );

  useEffect(() => {
    if (data) {
      setMenuItems(data);
    }
  }, [data]);
  const handleLoginClick = () => {
    instance
      .loginRedirect(loginRequest)
      .then(() => {})
      .catch((error) => console.error(error));
  };

  const handleLogoutRedirect = () => {
    instance.logoutRedirect().catch((error) => console.error(error));
    sessionStorage.removeItem("Bearer");
  };
  const onClick = (e) => {
    if (e.keyPath[1] === "/ScoreNew") setCurrent((e.redirect = "/ScoreNew"));
    else setCurrent(e.keyPath[1]);
    navigate(e.item.props.redirect);
  };

  const items = [
    {
      label: "Sign Out",
      key: "2",
      onClick: activeAccount ? handleLogoutRedirect : undefined,
    },
  ];

  return (
    <header className="print:tw-hidden">
      {isLoading && <MyBackdropSpinner show={isLoading} />}
      <Navbar className="ng-white border-bottom box-shadow mb-3 tw-px-20">
        <div
          className="d-flex justify-content-between tw-items-center"
          style={{ width: "100%" }}
        >
          <img
            className=" tw-cursor-pointer"
            src="../images/CareConnectMD_horizontal.png"
            style={{ height: "50px" }}
            alt="Logo"
            onClick={() => navigate("/")}
          ></img>

          <div className="d-flex  ">
            <AuthenticatedTemplate>
              {activeAccount && (
                <div className="d-none d-lg-block">
                  <div key={menuItems?.key}>
                    <Menu
                      style={{ border: "none", fontsize: "medium" }}
                      mode="horizontal"
                      items={menuItems}
                      onClick={onClick}
                      selectedKeys={current !== "/" ? [current] : []}
                      disabledOverflow
                    />
                  </div>
                </div>
              )}
            </AuthenticatedTemplate>
          </div>

          <UnauthenticatedTemplate>
            <div className="justify-content-end">
              <Button
                style={{
                  backgroundColor: "#f58b2e",
                  color: "white",
                  border: "none",
                }}
                className="justify-content-end ml-auto"
                title="Sign In"
                drop="start"
                onClick={handleLoginClick}
              >
                {" "}
                Sign In
              </Button>
            </div>
          </UnauthenticatedTemplate>

          <AuthenticatedTemplate>
            {/* <ButtonGroup className="justify-content-end">
              <FaRegUser
                size={22}
                className="tw-text-gray-400 tw-mt-3"
                as={ButtonGroup}
              />
              <DropdownButton
                as={ButtonGroup}
                title={activeAccount ? activeAccount.name : "Unknown"}
                variant="warning"
                id="bg-nested-dropdown"
              >
                <Dropdown.Item onClick={handleLogoutRedirect}>
                  Sign out
                </Dropdown.Item>
              </DropdownButton>
            </ButtonGroup> */}
            <Dropdown menu={{ items }}>
              <Button
                type="text"
                className="tw-flex tw-space-x-2 tw-item-center"
              >
                <FaRegUserCircle size={20} className=" tw-cursor-pointer" />
                {activeAccount?.name.split(" ")[0]}
              </Button>
            </Dropdown>
          </AuthenticatedTemplate>
        </div>
      </Navbar>
    </header>
  );
};
