import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import React, { createContext, useContext } from "react";

const MyContext = createContext();

dayjs.extend(utc);

export const useMyContext = () => useContext(MyContext);

export const MyContextProvider = ({ children }) => {
  const getFormattedDateDayJs = (date) => {
    try {
      return dayjs(date).format("YYYY-MM-DD");
    } catch (e) {
      return "";
    }
  };

  const calculateDateRange = () => {
    const currentDate = dayjs();
    //const sixWeeksAgo = currentDate.subtract(6, "month");
    const fromDate = "2023-10-01";
    const toDate = currentDate.format("YYYY-MM-DD");

    return { fromDate, toDate };
  };

  const getFormattedDateTimeDayJs = (date) => {
    try {
      return dayjs(date).format("YYYY-MM-DD HH:mm");
    } catch (e) {
      return "";
    }
  };
  const converttoDate = (date) => {
    try {
      return dayjs(date).format("YYYY-MM-DDTHH:mm:ss");
    } catch (e) {
      return "";
    }
  };

  const converttoCompare = (date) => {
    try {
      return dayjs.utc(date).format("YYYY-MM-DD");
    } catch (e) {
      return "";
    }
  };

  const getFromattedDateMonthJs = (date) => {
    try {
      return dayjs(date).format("dddd, MMMM DD");
    } catch (e) {
      return e;
    }
  };

  const CalculateAge = (birthdayStr) => {
    try {
      if (birthdayStr && birthdayStr.length === 10) {
        let birthday = new Date(birthdayStr);

        if (new Date() < birthday) return null;
        let ageDifMs = Date.now() - birthday.getTime();
        let ageDate = new Date(ageDifMs); // miliseconds from epoch
        let ageinYear = Math.abs(ageDate.getUTCFullYear() - 1970);

        return {
          birthday,
          age: ageinYear,
          valid: ageinYear >= 0,
          error: ageinYear >= 0 ? "" : "Invalid Date Format",
        };
      } else {
        return {
          age: null,
          valid: birthdayStr?.length > 0,
          error: birthdayStr?.length > 0 ? "Invalid Date" : "",
        };
      }
    } catch (e) {
      console.error(e);
      return {
        age: null,
        valid: false,
        error: "Unable to Calculate Age",
      };
    }
  };

  const utcToLocal = (date) => {
    try {
      return dayjs(date).utc("z").local().format("YYYY-MM-DD hh:mm A");
    } catch (e) {}
  };

  const formatPhoneNumber = (value) => {
    if (!value) return "";
    const phoneNumber = value?.replace(/[^\d]/g, "");

    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    }
    return `+1(${phoneNumber.slice(0, 3)})-${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  };

  const contextValue = {
    getFormattedDateDayJs,
    getFormattedDateTimeDayJs,
    getFromattedDateMonthJs,
    CalculateAge,
    converttoDate,
    converttoCompare,
    calculateDateRange,
    utcToLocal,
    formatPhoneNumber,
  };

  return (
    <MyContext.Provider value={contextValue}>{children}</MyContext.Provider>
  );
};
