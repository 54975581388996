import { axiosInstance } from "../../api/AxiosInstance";

export async function getSVASubmitDetails(fromDate, toDate) {
  return axiosInstance
    .get(`api/SVASubmitDetails?startDate=${fromDate}&endDate=${toDate}`)
    .then(function (response) {
      return response; // Return the response data
    })
    .catch(function (error) {
      return error; // Return the error
    });
}
