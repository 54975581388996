import { axiosInstance } from "./AxiosInstance";

export async function getAllTasks() {
  return axiosInstance
    .get("/api/TaskScheduler/getalltasks")
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function getMyTasks(filter) {
  return axiosInstance
    .get("/api/TaskScheduler/gettasks", {
      params: {
        dueDate: filter,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
export async function postMyTasks(task) {
  return axiosInstance
    .post("/api/TaskScheduler/savetask", task)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
export async function editMyTasks(task) {
  return axiosInstance
    .post("/api/TaskScheduler/modifytask", task)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function deleteTask(taskid) {
  return axiosInstance
    .post("/api/TaskScheduler/deletetask", taskid)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
export async function updateTask(task) {
  return axiosInstance
    .post("/api/TaskScheduler/modifyTask", task)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}