import { DatePicker } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import MyCustomSelect from "../../common/elements/myCustomSelect";
import { postHRA } from "../../api/CareEventsApi";
import { usePickList } from "../../services/queries";
import { openNotification } from "../../common/elements/myNotification";

const dateFormat = ["YYYY-MM-DD hh:mm A"];

const AddHighRisk = ({ editRecord, callback, noMedList }) => {
  const [formData, setFormData] = useState(
    editRecord?.guid
      ? {
          ...editRecord,
          modifiedBy: sessionStorage.getItem("userName"),
        }
      : editRecord
  );

  const [pageError, setPageError] = useState({});
  const [disable, setDisable] = useState(false);

  const [numberOfMedications, setNumberOfMedications] = useState([]);

  useEffect(() => {
    if (editRecord?.guid) {
      let noMedTemp = [];
      if (editRecord?.numberOfMedicationsType) {
        noMedTemp = noMedList?.filter((item) => {
          return item.value === editRecord?.numberOfMedicationsType;
        });
      }
      setNumberOfMedications(noMedTemp);
    }
  }, [editRecord]);

  const onDateChange = (date, dateString) => {
    setFormData({
      ...formData,
      assessmentDateTime: dateString ? dateString : "",
    });
    setPageError({
      ...pageError,
      assessmentDateTime: dateString ? "" : "Required",
    });
  };

  const handleInputChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
    setPageError({ ...pageError, [name]: value ? "" : "Required" });
    if (name === "isErHospitalVisit" && value === "No") {
      // If 'No' is selected for ER visit, clear the erHospitalVisitNotes
      setFormData({
        ...formData,
        [name]: value,
        erHospitalVisitNotes: "", // Clearing the erHospitalVisitNotes
      });
    }
    if (name === "isCareCoordinationNeeds" && value === "No") {
      // If 'No' is selected for ER visit, clear the erHospitalVisitNotes
      setFormData({
        ...formData,
        [name]: value,
        careCoordinationNeedsNotes: "", // Clearing the erHospitalVisitNotes
      });
    }
    if (name === "isSocialWorkerNeeds" && value === "No") {
      // If 'No' is selected for ER visit, clear the erHospitalVisitNotes
      setFormData({
        ...formData,
        [name]: value,
        socialWorkerNeedsNotes: "", // Clearing the erHospitalVisitNotes
      });
    }
  };

  const onNoMedChange = (e) => {
    setNumberOfMedications(e);
    setFormData({ ...formData, numberOfMedicationsType: e ? e.value : "" });
    setPageError({
      ...pageError,
      numberOfMedicationsType: e ? "" : "Required",
    });
  };

  const onSubmit = (e) => {
    setPageError({});

    let pageErrorTemp = {};
    if (
      !formData?.assessmentDateTime ||
      !formData?.highRiskCriteria ||
      !formData?.isCCECompletedThisYear ||
      !formData?.numberOfMedicationsType
    ) {
      pageErrorTemp.highRiskCriteria = formData?.highRiskCriteria
        ? ""
        : "Required";
      pageErrorTemp.isCCECompletedThisYear = formData?.isCCECompletedThisYear
        ? ""
        : "Required";
      pageErrorTemp.assessmentDateTime = formData?.assessmentDateTime
        ? ""
        : "Required";
      pageErrorTemp.numberOfMedicationsType = formData?.numberOfMedicationsType
        ? ""
        : "Required";
      setPageError(pageErrorTemp);
      return;
    }

    onSave(e);
  };

  const onSave = async (e) => {
    setDisable(true);
    try {
      const response = await postHRA(formData);
      if (response?.status === 200 && !formData?.guid) {
        openNotification(
          "success",
          "Added",
          "High Risk Assessment Added Successfully!"
        );
      } else if (formData?.guid) {
        openNotification(
          "success",
          "Updated",
          "High Risk Assessment Updated Successfully!"
        );
      } else {
        openNotification(
          "error",
          "Error",
          "High Risk Assessment Failed to Add!"
        );
      }
      setFormData(editRecord);

      callback(response);
    } catch (e) {
      return e;
    }
    setDisable(false);
  };

  return (
    <div className="tw-relative">
      <div className="tw-flex ">
        <label className="tw-font-bold" htmlFor="doa">
          Assessment Date & Time
        </label>
      </div>
      <DatePicker
        showTime
        use12Hours
        className="form-control tw-mb-3"
        onChange={onDateChange}
        format={dateFormat}
        value={
          formData?.assessmentDateTime ? dayjs(formData.assessmentDateTime) : ""
        }
        style={{
          border: pageError?.assessmentDateTime ? "1px solid red" : "",
        }}
        id="assessmentDateTime"
      />
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="hospitalname">
          Summary
        </label>
        <textarea
          className=" form-control"
          type="text"
          id="summary"
          maxLength={3500}
          value={formData?.summary}
          onChange={(e) => handleInputChange("summary", e.target.value)}
        />
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="hospitalname">
          High Risk Criteria
        </label>
        <fieldset className="fw-normal">
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="highRiskCriteria"
              checked={formData?.highRiskCriteria === "One"}
              id="highRiskCriteriaYes"
              value={"One"}
              onChange={(e) =>
                handleInputChange(
                  "highRiskCriteria",
                  e.currentTarget.value ? "One" : "Two"
                )
              }
            />
            <label className="form-check-label" htmlFor="highRiskCriteriaYes">
              One
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="highRiskCriteria"
              checked={formData?.highRiskCriteria === "Two"}
              id="highRiskCriteriaNo"
              value={"Two"}
              onChange={(e) =>
                handleInputChange(
                  "highRiskCriteria",
                  e.currentTarget.value ? "Two" : "One"
                )
              }
            />
            <label className="form-check-label" htmlFor="highRiskCriteriaNo">
              Two
            </label>
          </div>
          <span
            className="text-danger"
            style={{ fontWeight: "normal", paddingLeft: "5px" }}
          >
            {pageError?.highRiskCriteria}
          </span>
        </fieldset>
      </div>

      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="hospitalname">
          Was a CCE completed within this year?
        </label>
        <fieldset className="fw-normal">
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="isCCECompletedThisYear"
              checked={formData?.isCCECompletedThisYear === "Yes"}
              id="isCCECompletedThisYearYes"
              value={"Yes"}
              onChange={(e) =>
                handleInputChange(
                  "isCCECompletedThisYear",
                  e.currentTarget.value ? "Yes" : ""
                )
              }
            />
            <label
              className="form-check-label"
              htmlFor="isCCECompletedThisYearYes"
            >
              Yes
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="isCCECompletedThisYear"
              checked={formData?.isCCECompletedThisYear === "No"}
              id="isCCECompletedThisYearNo"
              value={"No"}
              onChange={(e) =>
                handleInputChange(
                  "isCCECompletedThisYear",
                  e.currentTarget.value ? "No" : ""
                )
              }
            />
            <label
              className="form-check-label"
              htmlFor="isCCECompletedThisYearNo"
            >
              No
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="isCCECompletedThisYear"
              checked={formData?.isCCECompletedThisYear === "Not Found In EMR"}
              id="isCCECompletedThisYearNotFound"
              value={"Not Found In EMR"}
              onChange={(e) =>
                handleInputChange(
                  "isCCECompletedThisYear",
                  e.currentTarget.value ? "Not Found In EMR" : ""
                )
              }
            />
            <label
              className="form-check-label"
              htmlFor="isCCECompletedThisYearNotFound"
            >
              Not Found in EMR
            </label>
          </div>
          <span
            className="text-danger"
            style={{ fontWeight: "normal", paddingLeft: "5px" }}
          >
            {pageError?.isCCECompletedThisYear}
          </span>
        </fieldset>
      </div>

      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="hospitalname">
          Cognition
        </label>
        <input
          maxLength={200}
          className=" form-control"
          type="text"
          id="cognition"
          value={formData?.cognition}
          onChange={(e) => handleInputChange("cognition", e.target.value)}
        />
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="hospitalname">
          Physician
        </label>
        <input
          maxLength={100}
          className=" form-control"
          type="text"
          id="physician"
          value={formData?.physician}
          onChange={(e) => handleInputChange("physician", e.target.value)}
        />
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="hospitalname">
          NP
        </label>
        <input
          maxLength={200}
          className=" form-control"
          type="text"
          id="nP"
          value={formData?.nP}
          onChange={(e) => handleInputChange("nP", e.target.value)}
        />
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="hospitalname">
          Specialist
        </label>
        <input
          maxLength={200}
          className=" form-control"
          type="text"
          id="specialist"
          value={formData?.specialist}
          onChange={(e) => handleInputChange("specialist", e.target.value)}
        />
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="hospitalname">
          Family
        </label>
        <input
          maxLength={200}
          className=" form-control"
          type="text"
          id="family"
          value={formData?.family}
          onChange={(e) => handleInputChange("family", e.target.value)}
        />
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="hospitalname">
          DPOA
        </label>
        <input
          maxLength={200}
          className=" form-control"
          type="text"
          id="dPOA"
          value={formData?.dPOA}
          onChange={(e) => handleInputChange("dPOA", e.target.value)}
        />
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="hospitalname">
          Email
        </label>
        <input
          maxLength={100}
          className=" form-control"
          type="text"
          id="eMail"
          value={formData?.eMail}
          onChange={(e) => handleInputChange("eMail", e.target.value)}
        />
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="hospitalname">
          Best Contact
        </label>
        <input
          maxLength={200}
          className=" form-control"
          type="text"
          id="bestContact"
          value={formData?.bestContact}
          onChange={(e) => handleInputChange("bestContact", e.target.value)}
        />
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="hospitalname">
          POLST
        </label>
        <input
          maxLength={200}
          className=" form-control"
          type="text"
          id="pOlst"
          value={formData?.pOlst}
          onChange={(e) => handleInputChange("pOlst", e.target.value)}
        />
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="hospitalname">
          Advanced Directives
        </label>
        <input
          maxLength={200}
          className=" form-control"
          type="text"
          id="advancedDirectives"
          value={formData?.advancedDirectives}
          onChange={(e) =>
            handleInputChange("advancedDirectives", e.target.value)
          }
        />
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="hospitalname">
          Number Of Medications
        </label>
        <MyCustomSelect
          selectOptions={noMedList}
          myCallBack={onNoMedChange}
          selectedValue={numberOfMedications}
          errorMessage={pageError?.numberOfMedicationsType}
          id={"noMed"}
        />
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="hospitalname">
          ED / Hospital Visit
        </label>
        <fieldset className="fw-normal">
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="isErHospitalVisit"
              checked={formData?.isErHospitalVisit === "Yes"}
              id="isErHospitalVisitYes"
              value={"Yes"}
              onChange={(e) =>
                handleInputChange(
                  "isErHospitalVisit",
                  e.currentTarget.value ? "Yes" : "No"
                )
              }
            />
            <label className="form-check-label" htmlFor="isErHospitalVisitYes">
              Yes
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="isErHospitalVisit"
              checked={formData?.isErHospitalVisit === "No"}
              id="isErHospitalVisitNo"
              value={"Two"}
              onChange={(e) =>
                handleInputChange(
                  "isErHospitalVisit",
                  e.currentTarget.value ? "No" : "Yes"
                )
              }
            />
            <label className="form-check-label" htmlFor="isErHospitalVisitNo">
              No
            </label>
          </div>
        </fieldset>
        {formData?.isErHospitalVisit === "Yes" && (
          <input
            className=" form-control"
            type="text"
            maxLength={200}
            id="isErHospitalVisitNotes"
            value={formData?.erHospitalVisitNotes}
            onChange={(e) =>
              handleInputChange("erHospitalVisitNotes", e.target.value)
            }
          />
        )}
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="hospitalname">
          Care Coordination Needs
        </label>
        <fieldset className="fw-normal">
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="isCareCoordinationNeeds"
              checked={formData?.isCareCoordinationNeeds === "Yes"}
              id="isCareCoordinationNeedsYes"
              value={"Yes"}
              onChange={(e) =>
                handleInputChange(
                  "isCareCoordinationNeeds",
                  e.currentTarget.value ? "Yes" : "No"
                )
              }
            />
            <label
              className="form-check-label"
              htmlFor="isCareCoordinationNeedsYes"
            >
              Yes
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="isCareCoordinationNeeds"
              checked={formData?.isCareCoordinationNeeds === "No"}
              id="isCareCoordinationNeedsNo"
              value={"Two"}
              onChange={(e) =>
                handleInputChange(
                  "isCareCoordinationNeeds",
                  e.currentTarget.value ? "No" : "Yes"
                )
              }
            />
            <label
              className="form-check-label"
              htmlFor="isCareCoordinationNeedsNo"
            >
              No
            </label>
          </div>
        </fieldset>
        {formData?.isCareCoordinationNeeds === "Yes" && (
          <input
            className=" form-control"
            type="text"
            maxLength={200}
            id="careCoordinationNeedsNotes"
            value={formData?.careCoordinationNeedsNotes}
            onChange={(e) =>
              handleInputChange("careCoordinationNeedsNotes", e.target.value)
            }
          />
        )}
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="hospitalname">
          Social Worker Needs
        </label>
        <fieldset className="fw-normal">
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="isSocialWorkerNeeds"
              checked={formData?.isSocialWorkerNeeds === "Yes"}
              id="isSocialWorkerNeedsYes"
              value={"Yes"}
              onChange={(e) =>
                handleInputChange(
                  "isSocialWorkerNeeds",
                  e.currentTarget.value ? "Yes" : "No"
                )
              }
            />
            <label
              className="form-check-label"
              htmlFor="isSocialWorkerNeedsYes"
            >
              Yes
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="isSocialWorkerNeedsNo"
              checked={formData?.isSocialWorkerNeeds === "No"}
              id="isSocialWorkerNeedsNo"
              value={"Two"}
              onChange={(e) =>
                handleInputChange(
                  "isSocialWorkerNeeds",
                  e.currentTarget.value ? "No" : "Yes"
                )
              }
            />
            <label className="form-check-label" htmlFor="isSocialWorkerNeedsNo">
              No
            </label>
          </div>
        </fieldset>
        {formData?.isSocialWorkerNeeds === "Yes" && (
          <input
            className=" form-control"
            type="text"
            maxLength={200}
            id="isSocialWorkerNeedsNotes"
            value={formData?.socialWorkerNeedsNotes}
            onChange={(e) =>
              handleInputChange("socialWorkerNeedsNotes", e.target.value)
            }
          />
        )}
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="hospitalname">
          Notes
        </label>
        <input
          className=" form-control"
          type="text"
          id="notes"
          maxLength={200}
          value={formData?.notes}
          onChange={(e) => handleInputChange("notes", e.target.value)}
        />
      </div>
      <div className="form-group tw-fixed tw-top-2 tw-right-10">
        <button
          className={
            !disable ? "btn btn-success " : "btn btn-success disabled "
          }
          onClick={onSubmit}
        >
          {editRecord?.guid ? "Update" : "Save"}
        </button>
        <button
          className="btn btn-secondary tw-ml-4"
          onClick={() => callback(false)}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default AddHighRisk;
