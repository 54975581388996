import useSWR from "swr";
import PageTitle from "../Common/PageTitle";
import { RiFileAddLine, RiEditLine, RiDeleteBinLine } from "react-icons/ri";
import { BsFiletypeCsv } from "react-icons/bs";
import { SearchOutlined } from "@ant-design/icons";
import { fetcherhn } from "../../services/fectcher";
import dayjs from "dayjs";
import { useState } from "react";
import MyBackdropSpinner from "../../common/elements/myBackdropSpinner";
import { CSVLink } from "react-csv";
import { useMyContext } from "../../common/elements/myContext";
import { DatePicker, Input } from "antd";
import { formatOptionLabel } from "../../common/elements/myCustomSelect";

export const AuditLog = () => {
  const { RangePicker } = DatePicker;

  const [query, setQuery] = useState("");

  const { converttoCompare } = useMyContext();
  const [fromDate, setFromDate] = useState(
    dayjs().subtract(1, "weeks").format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState(dayjs().format("YYYY-MM-DD"));

  const disabledDate = (current, { from }) => {
    if (from) {
      return Math.abs(current.diff(from, "days")) >= 8;
    }
    return false;
  };
  //const toDate = dayjs().format("YYYY-MM-DD");
  //const fromDate = dayjs().subtract(1, "weeks").format("YYYY-MM-DD");

  const { data, isLoading } = useSWR(
    `GetCareEventAuditDetails?fromDate=${fromDate}&toDate=${toDate}`,
    fetcherhn
  );

  const filteredData = data
    ? data?.filter((item) => {
        return [
          "patientNamewithDOB",
          "auditActivityName",
          "activityDate",
          "activityby",
        ].some((key) => {
          if (key === "activityDate") {
            // Extract the date portion (YYYY-MM-DD) from the activityDate value
            const datePortion = item[key].split("T")[0];
            // Check if the date portion matches the format YYYY-MM-DD
            return datePortion.includes(query?.toString().toLowerCase());
          } else {
            return item[key]
              ?.toString()
              .toLowerCase()
              .includes(query?.toString().toLowerCase());
          }
        });
      })
    : "";

  const csvData = filteredData
    ? filteredData?.map((item) => ({
        ActivityName: item.auditActivityName,
        PatientName: item.patientNamewithDOB,
        MBI: item.mbi,
        Activity: item.activity,
        ActivityBy: item.activityby.split("@")[0],
        ActivityDate: item.activityDate
          ? converttoCompare(item.activityDate)
          : "",
      }))
    : "";

  if (isLoading) {
    return <MyBackdropSpinner spinner={true} />;
  }

  return (
    <>
      <PageTitle title="Care Event Audit Log" />
      <div className="tw-w-2/3 tw-mx-auto tw-flex tw-justify-between  tw-space-x-4 tw-items-center tw-pb-6">
        <Input
          className="tw-basis-3/4"
          addonAfter={<SearchOutlined />}
          autoFocus
          placeholder="Search"
          size="large"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <RangePicker
          disabledDate={disabledDate}
          format="YYYY-MM-DD"
          size="large"
          onChange={(date, dateString) => {
            setFromDate(dateString[0]);
            setToDate(dateString[1]);
            setQuery("");
          }}
          value={[fromDate ? dayjs(fromDate) : "", toDate ? dayjs(toDate) : ""]}
        />
        <CSVLink data={csvData} filename={"report.csv"}>
          <BsFiletypeCsv size={"2em"} />
        </CSVLink>
      </div>

      {filteredData?.length > 0 &&
        filteredData?.map((log) => (
          <div className="tw-w-2/3 tw-mx-auto ">
            {log.activity === "INSERT" && (
              <div
                className="tw-flex tw-py-4 tw-justify-between tw-items-start tw-text-zinc-900 border-bottom"
                key={log.key}
              >
                <div className="tw-flex tw-space-x-3 tw-items-start">
                  <div className="tw-rounded-full tw-bg-green-100 tw-text-green-700 tw-h-6 tw-w-6 tw-flex tw-items-center tw-justify-center">
                    <RiFileAddLine />
                  </div>
                  <div>
                    <div className="tw-font-medium">
                      {log.activityby.split("@")[0]}
                      <span className="tw-font-normal tw-text-zinc-500 tw-px-1">
                        added an entry
                      </span>
                    </div>
                    <div className="tw-text-zinc-500 tw-text-sm tw-mt-1">
                      {formatOptionLabel({ label: log.patientNamewithDOB })}
                    </div>
                  </div>
                </div>

                <div className="tw-text-right">
                  <div className="tw-font-medium">{log.auditActivityName}</div>
                  <div className="tw-text-zinc-500 tw-text-sm tw-mt-1">
                    {dayjs(log.activityDate).format("YYYY-MM-DD")}
                  </div>
                </div>
              </div>
            )}
            {log.activity === "UPDATE" && (
              <div
                className="tw-flex tw-py-4 tw-justify-between tw-items-start tw-text-zinc-900 border-bottom"
                key={log.key}
              >
                <div className="tw-flex tw-space-x-3 tw-items-start">
                  <div className="tw-rounded-full tw-bg-blue-100 tw-text-blue-700 tw-h-6 tw-w-6 tw-flex tw-items-center tw-justify-center">
                    <RiEditLine />
                  </div>
                  <div>
                    <div className="tw-font-medium">
                      {log.activityby.split("@")[0]}
                      <span className="tw-font-normal tw-text-zinc-500 tw-px-1">
                        edited an entry
                      </span>
                    </div>
                    <div className="tw-text-zinc-500 tw-text-sm tw-mt-1">
                      {formatOptionLabel({ label: log.patientNamewithDOB })}
                    </div>
                  </div>
                </div>

                <div className="tw-text-right">
                  <div className="tw-font-medium">{log.auditActivityName}</div>
                  <div className="tw-text-zinc-500 tw-text-sm tw-mt-1">
                    {dayjs(log.activityDate).format("YYYY-MM-DD")}
                  </div>
                </div>
              </div>
            )}
            {log.activity === "DELETED" && (
              <div
                className="tw-flex tw-py-4 tw-justify-between tw-items-start tw-text-zinc-900 border-bottom"
                key={log.key}
              >
                <div className="tw-flex tw-space-x-3 tw-items-start">
                  <div className="tw-rounded-full tw-bg-red-100 tw-text-red-700 tw-h-6 tw-w-6 tw-flex tw-items-center tw-justify-center">
                    <RiDeleteBinLine />
                  </div>
                  <div>
                    <div className="tw-font-medium">
                      {log.activityby.split("@")[0]}
                      <span className="tw-font-normal tw-text-zinc-500 tw-px-1">
                        deleted an entry
                      </span>
                    </div>
                    <div className="tw-text-zinc-500 tw-text-sm tw-mt-1">
                      {formatOptionLabel({ label: log.patientNamewithDOB })}
                    </div>
                  </div>
                </div>

                <div className="tw-text-right">
                  <div className="tw-font-medium">{log.auditActivityName}</div>
                  <div className="tw-text-zinc-500 tw-text-sm tw-mt-1">
                    {dayjs(log.activityDate).format("YYYY-MM-DD")}
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
    </>
  );
};
