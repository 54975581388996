import React, { useEffect, useState } from "react";
import { Table, DatePicker, Space, Radio, Select } from "antd";
import Search from "antd/es/input/Search";
import { CSVLink } from "react-csv";
import { BsFiletypeCsv } from "react-icons/bs";
import useSWR from "swr";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useMyContext } from "../../common/elements/myContext";
import {
  formatOptionLabel,
  getLobColors,
} from "../../common/elements/myCustomSelect";
import MyBackdropSpinner from "../../common/elements/myBackdropSpinner";
import { fetcherhn } from "../../services/fectcher";
import GetModalDetails from "../CareEvent/GetModalDetails";
import { lobList } from "../CCMDPatients/CCMDPatientsAll";
import PageTitle from "../Common/PageTitle";
import { CEOptions, SCPOptions } from "../CareEvent/ColumnsGroup";
import DetailedCSV from "../../common/elements/DetailedCSV";

dayjs.extend(utc);

const { RangePicker } = DatePicker;

export const CareEventReport = () => {
  const [fromDate, setFromDate] = useState(
    dayjs().subtract(1, "day").format("YYYY-MM-DD")
  );
  const { converttoCompare } = useMyContext();
  const [toDate, setToDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [query, setQuery] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [modalTitle, setModalTitle] = useState("");
  const [selectedKey, setSelectedKey] = useState(null);
  const [apiNode, setApiNode] = useState("");
  const [lob, setLob] = useState("HN");
  const [event, setEvent] = useState("All");
  const [detailTrue, setDetailTrue] = useState(false);

  const { data, isLoading } = useSWR(
    `getCareEventSummaryReport?fromDate=${fromDate}&toDate=${toDate}&lob=${lob}&careevent=${event}`,
    fetcherhn
  );

  const { data: detailData, isLoading: detailLoading } = useSWR(
    detailTrue
      ? `getCareEventSummaryReport?fromDate=${fromDate}&toDate=${toDate}&lob=${lob}&careevent=${event}&detrep=${detailTrue}`
      : null,
    fetcherhn
  );

  const fullData = data ? Object.values(data).flat() : [];

  const fullDetailData = detailData ? Object.values(detailData).flat() : [];

  const sortedFullData = fullData.sort(
    (a, b) => new Date(b.createdDate) - new Date(a.createdDate)
  );

  const sortedFullDetailData = fullDetailData.sort(
    (a, b) => new Date(b.createdDate) - new Date(a.createdDate)
  );

  const apiNodeMap = {
    "Tuck In Call": "GetTuckInCall",
    "Therapy Utilization Review": "GetTherapyUtilizationReview",
    "Change in Condition Note": "GetChangeInCondition",
    "Inpatient Hospital Communication Note": "GetIpHospitalCommunicationNote",
    "Admission / Re-Admission": "GetTriggerAdmissionReadmission",
    "High Risk Assessment": "GetHighRiskAssessment",
    "Grievance Template": "GetGriveanceTemplate",
    "ED Visit Template": "GetERVisit",
    "Request for Tier Change": "GetRequestForTierChange",
    "Order / Request Template": "GetOrderRequest",
    "DME Request": "GetDmeRequest",
    "Nurse Request Template": "GetNurseRequest",
    "Hospital Admission Template": "GetHospitalAdmissionTemplate",
    "Hospital Discharge Template": "GetHospitalDischargeTemplate",
    "Outbound Call": "GetOutBoundCall",
  };

  const getApiNode = (modalTitle) => {
    if (modalTitle.includes("General Notes")) {
      return "GetCareManagementNote";
    }
    return apiNodeMap[modalTitle] || null;
  };

  useEffect(() => {
    const apiNode = getApiNode(modalTitle);
    if (apiNode) {
      setApiNode(apiNode);
    }
  }, [isModalVisible, modalTitle]);

  const columns = [
    {
      key: 1,
      title: "Date Created",
      width: 100,
      dataIndex: "createdDate",
      render: (createdDate) =>
        createdDate ? dayjs(createdDate).format("YYYY-MM-DD") : "",
    },
    {
      key: 2,
      title: "Care Event Type",
      dataIndex: "careEventType",

      render: (careEventType, record) => (
        <button
          className="tw-border-none tw-bg-transparent tw-text-blue-600"
          onClick={() => showModal(record, careEventType)}
        >
          {careEventType}
        </button>
      ),
    },
    {
      key: 3,
      title: "Patient",
      dataIndex: "patientNamewithDOB",
      render: (patientNamewithDOB) =>
        formatOptionLabel({ label: patientNamewithDOB }),
    },
    {
      key: 4,
      title: "Facility",
      dataIndex: "facilityName",
    },
    {
      key: 5,
      title: "Creator",
      width: 160,
      dataIndex: "createdBy",
      render: (createdBy) => createdBy.split("@")[0],
    },
    {
      key: 6,
      title: "Last Edit",
      width: 160,
      dataIndex: "modifiedDate",
      render: (modifiedDate) =>
        modifiedDate ? dayjs(modifiedDate).format("YYYY-MM-DD") : "",
    },
    {
      key: 7,
      title: "Editor",
      width: 160,
      dataIndex: "modifiedBy",
      render: (modifiedBy) => modifiedBy.split("@")[0],
    },
  ];

  const [patientName, setPatientName] = useState(null);

  const showModal = (record, careEventType) => {
    setSelectedRecord(record);
    setModalTitle(careEventType);
    setPatientName(record?.patientNamewithDOB);
    setIsModalVisible(true);
    setSelectedKey(record.key);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const tableFiltered = sortedFullData?.filter((item) => {
    return [
      "patientNamewithDOB",
      "createdBy",
      "modifiedBy",
      "createdDate",
      "modifiedDate",
      "careEventType",
      "facilityName",
    ].some((key) =>
      item[key]?.toString().toLowerCase().includes(query?.toLowerCase())
    );
  });

  const csvData = tableFiltered?.map((item) => ({
    DateCreated: item.createdDate ? converttoCompare(item.createdDate) : "",
    CareEventType: item.careEventType,
    Patient: item.patientNamewithDOB,
    Facility: item.facilityName,
    Creator: item.createdBy.split("@")[0],
    LastEdit: item.modifiedDate ? converttoCompare(item.modifiedDate) : "",
    Editor: item.modifiedBy.split("@")[0],
  }));

  const onEventChange = (e) => {
    setEvent(e);
    setQuery("");
    if (e !== "All" || e !== null || e !== undefined || e !== "") {
      setDetailTrue(true);
    }
  };

  const onLobChange = (e) => {
    setLob(e.target.value);
    setEvent("All");
    setQuery("");
  };
 
  const filteredDetailData = sortedFullDetailData?.reduce((acc, detailItem) => {
    // Find the matching item in tableFiltered
    const matchingItem = tableFiltered?.find(
      (filteredItem) =>
        filteredItem?.patientNamewithDOB === detailItem?.patientNamewithDOB &&
        (filteredItem?.guid === detailItem?.guid || filteredItem?.key === detailItem?.key)
    );  
    // If a matching item is found, add the detailItem with createdDate,modifiedDate and modifiedBy to the accumulator
    if (matchingItem) {
      acc.push({
        ...detailItem,
        createdDate: matchingItem.createdDate || null, 
        modifiedDate: matchingItem.modifiedDate || null,
        modifiedBy: matchingItem.modifiedBy || null
      });
    }  
    return acc;
  }, []);

  if (isLoading || detailLoading) {
    return <MyBackdropSpinner spinner={true} />;
  }

  return (
    <div>
      <div className="tw-flex tw-justify-between tw-mb-2 tw-items-center">
        <Space>
          <PageTitle title={"Care Events Summary Report"} />
          <Radio.Group
            onChange={onLobChange}
            value={lob}
            className="tw-mb-2 tw-ml-2"
          >
            {lobList.map((i) => (
              <Radio key={i.value} value={i.value}>
                <span
                  className={`tw-py-0.5 tw-px-2 tw-text-xs tw-rounded ${getLobColors(
                    i.value
                  )}`}
                >
                  {i.label}
                </span>
              </Radio>
            ))}
          </Radio.Group>
          <Select
            className="tw-w-40"
            showSearch
            allowClear
            placeholder="Select Care Event Type"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={
              lob === "All"
                ? [
                    ...new Map(
                      [...CEOptions, ...SCPOptions].map((option) => [
                        option.value,
                        option,
                      ])
                    ).values(),
                  ]
                : lob === "SCP"
                ? SCPOptions
                : CEOptions
            }
            onChange={onEventChange}
            value={event}
          />
        </Space>

        <div className="tw-flex tw-justify-end ">
          <Space>
            <Search
              placeholder="Search"
              onChange={(e) => setQuery(e.target.value)}
              allowClear
              className="tw-w-40"
              value={query}
            />
            <RangePicker
              className="tw-w-full"
              format="YYYY-MM-DD"
              onChange={(date, dateString) => {
                setFromDate(dateString[0]);
                setToDate(dateString[1]);
                setQuery("");
              }}
              value={[
                fromDate ? dayjs(fromDate) : "",
                toDate ? dayjs(toDate) : "",
              ]}
            />
            {event === "All" ||
            event === null ||
            event === undefined ||
            event === "" ? (
              <CSVLink data={csvData} filename={"report.csv"}>
                <BsFiletypeCsv size={"2em"} />
              </CSVLink>
            ) : (
              <DetailedCSV
                detailData={filteredDetailData || []}
                event={event}
                fromDate={fromDate}
                toDate={toDate}
              />
            )}
          </Space>
        </div>
      </div>
      <Table
        virtual
        size="middle"
        dataSource={tableFiltered}
        columns={columns}
        pagination={{
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
        }}
        scroll={{
          x: 1500,
          y: 600,
        }}
      />
      {isModalVisible && (
        <GetModalDetails
          keyProp={selectedKey}
          mbi={selectedRecord?.mbi}
          isVisible={isModalVisible}
          handleOk={handleOk}
          handleCancel={handleCancel}
          modalTitle={modalTitle}
          apiNode={apiNode}
          patientName={patientName}
        />
      )}
    </div>
  );
};
