import { Table } from "antd";
import React, { useEffect, useState } from "react";
import PageTitle from "../Common/PageTitle";
import * as PatientsApi from "../../api/patientlistApi";
import Search from "antd/es/input/Search";
import { useNavigate } from "react-router-dom";
import MyBackdropSpinner from "../../common/elements/myBackdropSpinner";
import dayjs from "dayjs";

const statuskey = ["status"];

const PCCPatients = (props) => {
  const [hrrPatientstMaster, setHRRPatientstMaster] = useState([]);
  const [hrrPatientsColumns, setHRRPatientsColumns] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [pcount, setCount] = useState([]);

  const [filter, setFilter] = useState({
    mbiid: "",
    firstName: "",
    lastName: "",
    facilityName: "",
    status: "",
    patientId: "",
  });

  useEffect(() => {
    getPatients(filter);
  }, [filter]);

  const getPatients = async (filter) => {
    setSpinner(true);
    try {
      const response = await PatientsApi.getPCCPatientlist();
      let responseData = response?.data;

      //MBI
      const uniqueMBITemp = [...new Set(responseData?.map((item) => item.mbi))];

      const uniqueMBIList = [];
      const count = {};

      uniqueMBITemp.forEach((element) => {
        uniqueMBIList.push({
          text: element,
          value: element,
        });
      });

      //FirstName
      const uniqueFirstNameTemp = [
        ...new Set(responseData?.map((item) => item.firstName)),
      ];

      const uniqueFirstNameList = [];

      uniqueFirstNameTemp.forEach((element) => {
        uniqueFirstNameList.push({
          text: element,
          value: element,
        });
      });

      //LastName
      const uniqueLastNameTemp = [
        ...new Set(responseData?.map((item) => item.lastName)),
      ];

      const uniqueLastNameList = [];

      uniqueLastNameTemp.forEach((element) => {
        uniqueLastNameList.push({
          text: element,
          value: element,
        });
      });

      //Facility Name
      const uniqueFacilityNameTemp = [
        ...new Set(responseData?.map((item) => item.facilityName)),
      ];

      const uniqueFacilityNameList = [];

      uniqueFacilityNameTemp.forEach((element) => {
        uniqueFacilityNameList.push({
          text: element,
          value: element,
        });
      });

      //Patient Status
      const uniquePatientStatusTemp = [
        ...new Set(responseData?.map((item) => item.status)),
      ];

      const uniquePatientStatusList = [];

      uniquePatientStatusTemp.forEach((element) => {
        uniquePatientStatusList.push({
          text: element,
          value: element,
        });
      });

      setHRRPatientstMaster(responseData);
      setFilteredData(responseData);
      //debugger;
      setCount(responseData?.length);

      setHRRPatientsColumns([
        {
          title: "Name",
          dataIndex: "firstName",
          fixed: "left",
          width: 80,
          ellipsis: true,
          render: (columnItem, rowItem) => {
            return (
              <a
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/Pt360/${rowItem.patientId}`);
                }}
                href="#"
              >
                {rowItem.lastName + ", " + rowItem.firstName}
              </a>
            );
          },
        },
        {
          title: "DOB",
          dataIndex: "birthDate",
          fixed: "left",
          width: 40,
          render: (columnItem, rowItem) => {
            return (
              <div>{dayjs(rowItem?.birthDate).utc().format("YYYY-MM-DD")}</div>
            );
          },
        },
        {
          title: "MBI",
          dataIndex: "medicareBeneficiaryIdentifier",
          fixed: "left",
          width: 50,
          render: (columnItem, rowItem) => {
            return <div>{rowItem.medicareBeneficiaryIdentifier}</div>;
          },
        },

        {
          title: "Facility",
          dataIndex: "facilityName",
          width: 120,
        },

        {
          title: "PatientId",
          dataIndex: "patientId",
          hidden: true,
          width: 30,
        },
        {
          title: "Patient Status",
          dataIndex: "patientStatus",
          width: 50,
        },
      ]);
      setSpinner(false);
      return responseData;
    } catch (error) {
      console.error(error);
      setHRRPatientstMaster([]);
    }
  };

  const onStatusChange = (e) => {
    if (e !== "") {
      let Items = hrrPatientstMaster?.filter((item) => {
        return statuskey.some((key) =>
          (item[key]?.toString()?.toLowerCase() ?? "").includes(
            e?.toLowerCase() ?? ""
          )
        );
      });
      setFilteredData(Items);
    } else {
      setFilteredData(hrrPatientstMaster);
    }
  };

  const filteredItems = filteredData.filter((item) => {
    return Object.keys(item).some((key) =>
      (item[key]?.toString()?.toLowerCase() ?? "").includes(
        query?.toLowerCase() ?? ""
      )
    );
  });

  return (
    <>
      <div className="tw-flex tw-justify-between tw-mb-2">
        <PageTitle title={"Patient List (" + pcount + ")"} />
        {spinner && <MyBackdropSpinner show={spinner} />}
      </div>
      <div className="d-grid gap-2 my-2 d-md-flex justify-content-end ">
        <Search
          className="col-md-2"
          placeholder="Search"
          onChange={(e) => setQuery(e.target.value)}
          allowClear
        />
      </div>
      <div className="row">
        <Table
          columns={hrrPatientsColumns}
          dataSource={filteredItems}
          size="middle"
        />
      </div>
    </>
  );
};

export default PCCPatients;
