import { UnauthenticatedTemplate } from "@azure/msal-react";
import { Figure } from "react-bootstrap";
import { Container } from "reactstrap";

export const UnauthenticatedHome = () => {

    return (
        <Container>
            <UnauthenticatedTemplate>
                <h3 className="py-3">
                    Welcome to the Clinical Command Center
                </h3>
                <Figure>
                    <Figure.Image
                        src="https://images.squarespace-cdn.com/content/v1/627d42d59ab8d10f8c9e684c/1652774108969-582D1CC8SVHOREV8UBOK/GettyImages-1334648126-min.jpg?format=1500w"
                    /></Figure>
            </UnauthenticatedTemplate>
        </Container>
    );
}