// List of embeding report link must be added here
export const HCCOpportunity =
  "https://app.powerbi.com/reportEmbed?reportId=6c806644-1dea-4c23-92ad-0565e8d8d19a&autoAuth=true&ctid=54f55ff8-42e3-48db-a263-aa717e16fe12&pageName=ReportSection1c0c9e3a28b58e82d4ce";
export const HCCDiagnosisHistoryReport =
  "https://app.powerbi.com/reportEmbed?reportId=dcd2f628-c505-44ba-91a8-34fc36f569ed&autoAuth=true&ctid=54f55ff8-42e3-48db-a263-aa717e16fe12";

export const ClinicalExecutiveDashboard =
  "https://app.powerbi.com/reportEmbed?reportId=b20cb906-955c-45c4-a927-2fabfdb39860&autoAuth=true&ctid=54f55ff8-42e3-48db-a263-aa717e16fe12";

export const Patient360x =
  "https://app.powerbi.com/reportEmbed?reportId=958bf686-3e61-434f-b5d5-a8fdf82725b9&autoAuth=true&ctid=54f55ff8-42e3-48db-a263-aa717e16fe12";

export const Patient360 =
  "https://app.powerbi.com/reportEmbed?reportId=d6be181e-3580-467b-934a-87169060ed43&autoAuth=true&ctid=54f55ff8-42e3-48db-a263-aa717e16fe12";

export const Patient360Prod =
  "https://app.powerbi.com/reportEmbed?reportId=c836b4ac-97e8-40ae-a66d-f1ba335f6b13&autoAuth=true&ctid=54f55ff8-42e3-48db-a263-aa717e16fe12";
