import { axiosInstance } from "./AxiosInstance";

export async function getScoreWithPotential(scoreRequestDto) {
  return axiosInstance
    .post("/api/CalculateRiskFactorsWithPotentialICD", scoreRequestDto)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function saveEligibilityInfo(scoreRequestDto) {
  return axiosInstance
    .put("/api/CalculateRiskFactors", scoreRequestDto)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function getIcd10CodesPromise() {
  return axiosInstance
    .get("/api/ICD10CodeList")
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function getPicklistPromise(mastercode) {
  return axiosInstance
    .get("/api/picklist", {
      params: {
        mastercode: mastercode,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function SavePickList(picklistData) {
  return axiosInstance
    .post("/api/picklist", picklistData)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function getDatabase() {
  return axiosInstance
    .get("/Footer")
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

// Get Prediction Report

export async function getScorePredictionReport(filter) {
  return axiosInstance
    .post("/api/RiskPredictionReport", filter)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
