import { axiosInstance } from "./AxiosInstance";

export async function getPatientInfo(mbi, lob) {
  return axiosInstance
    .get(`/api/PatientInfo?mbi=${mbi}&lob=${lob}`)
    .then(function (response) {
      //debugger;
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function getPatientInfoFromMbi(mbi) {
  return axiosInstance
    .get(`/api/PatientInfo/${mbi}`)
    .then(function (response) {
      //debugger;
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
