import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PageTitle from "../Common/PageTitle";
import MyCustomSelect from "../../common/elements/myCustomSelect";
import * as Taskapi from "../../api/TaskManagerApi";
import * as UserApi from "../../api/UserLoginApi";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import MyBackdropSpinner from "../../common/elements/myBackdropSpinner";
import { DatePicker, message, Checkbox } from "antd";
import { useParams } from "react-router-dom";
import { useMyContext } from "../../common/elements/myContext";
import { usePickList } from "../../services/queries";
import { fetcher } from "../../services/fectcher";
import useSWR from "swr";

dayjs.extend(utc);

const PriorityOptions = [
  { label: "Critical", value: "Critical" },
  { label: "High", value: "High" },
  { label: "Medium", value: "Medium" },
  { label: "Low", value: "Low" },
];

const dateFormat = ["YYYY-MM-DD"];
const tempdate = new Date();

const AddTask = () => {
  const [spinner, setSpinner] = useState(false);
  const params = useParams();
  const Navigate = useNavigate();

  const taskTypeList = usePickList(7).data;

  const { data: patientList } = useSWR(
    `GetPatientsListNew?lob=${"HN"}`,
    fetcher
  );

  const { converttoCompare, converttoDate } = useMyContext();

  const [dueDate, setDueDate] = useState("");
  const [selectedTaskType, setSelectedTaskType] = useState("");
  const [selecdtedPatient, setSelecdtedPatient] = useState();
  const [assigneeList, setAssigneeList] = useState();
  const [selectedAssignee, setSelectedAssignee] = useState();
  const [selectedPriority, setSelectedPriority] = useState("");
  const [hasValueChanged, setHasValueChanged] = useState(false);

  const [pageError, setPageError] = useState({});

  const [requestBody, setRequestBody] = useState({
    startDate: tempdate,
    assigneeId: params?.assigneeId || null,
    assigneeDisplayName: "",
    taskStatus: "Active",
    etc: dayjs(params?.dueDate) || tempdate,
    patientId: params?.patientId || "",
    notes: params?.notes || "",
    taskPriority: params?.taskPriority || "",
    taskTypeId: "",
    description: params?.description || "",
  });

  useEffect(() => {
    setSpinner(true);
    const usersPromise = UserApi.getUsers();
    Promise.all([usersPromise]).then((values) => {
      // TaskType List
      let filterTask = [];
      if (params?.taskType) {
        const decodeType = decodeURIComponent(params?.taskType);
        filterTask = taskTypeList.filter((item) => {
          return item.label === decodeType;
        });
      }
      setSelectedTaskType(filterTask);

      // Patient List

      if (params?.patientId) {
        let filterP = patientList?.filter((item) => {
          return item.value === params?.patientId;
        });
        setSelecdtedPatient(filterP);
        setDueDate(converttoCompare(params?.dueDate) || "");
        setSelectedPriority({
          label: params?.taskPriority,
          value: params?.taskPriority,
        });
      }

      // Assginee List
      let tempUsers = [];
      values[0]?.data.forEach((item) => {
        tempUsers.push({
          label: item.displayName,
          value: item.mail,
        });
      });
      setAssigneeList(tempUsers);

      let filterUser = [];
      if (params?.patientId) {
        filterUser = tempUsers.filter((item) => {
          return item.value === params?.assigneeId;
        });
        setSelectedAssignee(filterUser);
        setRequestBody({
          ...requestBody,
          assigneeId: params?.assigneeId || "",
          assigneeDisplayName: filterUser[0]?.label || "",
          taskTypeId: filterTask[0].value,
          taskId: params?.taskId,
          taskStatus: "Edited",
        });
      }
      setSpinner(false);
    });
    if (!params?.patientId) {
      setSelectedTaskType("");
      setDueDate();
      setSelecdtedPatient("");
      setSelectedPriority("");
      setSelectedAssignee("");
      setRequestBody({
        ...requestBody,
        assigneeId: "",
        taskPriority: "",
        etc: "",
        patientId: "",
        notes: "",
        assigneeDisplayName: "",
        description: "",
      });
    }
  }, [params]);

  const disabledDate = (current) => {
    return current && current < dayjs().subtract(6, "month");
  };

  const onPatientChange = (e) => {
    setSelecdtedPatient(e);
    setPageError({
      ...pageError,
      patientId: e ? "" : "Required",
    });
    setRequestBody({ ...requestBody, patientId: e ? e.value : "" });
  };
  const onTaskTypeChange = (e) => {
    setSelectedTaskType(e);
    setHasValueChanged(true);
    setRequestBody({ ...requestBody, taskTypeId: e ? e.value : "" });
    setPageError({
      ...pageError,
      taskTypeId: e ? "" : "Required",
    });
  };
  const onDescChange = (e) => {
    setHasValueChanged(true);
    setRequestBody({ ...requestBody, description: e ? e.target.value : " " });
    setPageError({
      ...pageError,
      description: e.target.value ? "" : "Required",
    });
  };

  const onPriorityChange = (e) => {
    setSelectedPriority(e);
    setHasValueChanged(true);
    setPageError({
      ...pageError,
      taskPriority: e ? "" : "Required",
    });
    setRequestBody({ ...requestBody, taskPriority: e ? e.value : "" });
  };
  const onAssigneeChange = (e) => {
    setSelectedAssignee(e);
    setHasValueChanged(true);
    setRequestBody({
      ...requestBody,
      assigneeId: e ? e.value : "",
      assigneeDisplayName: e?.label,
    });
    setPageError({
      ...pageError,
      assigneeId: e ? "" : "Required",
    });
  };
  const onDueDateChange = (date, dateString) => {
    setDueDate(dateString);
    setPageError({
      ...pageError,
      etc: dateString ? "" : "Required",
    });
    setHasValueChanged(true);
    setRequestBody({ ...requestBody, etc: date ? converttoDate(date) : "" });
  };
  const onNotesChange = (e) => {
    setHasValueChanged(true);
    setRequestBody({ ...requestBody, notes: e ? e.target.value : " " });
  };

  const onSubmit = (e) => {
    setPageError({});
    let pageErrorTemp = {};
    if (
      !requestBody?.patientId ||
      !requestBody?.taskPriority ||
      !requestBody?.assigneeId ||
      !requestBody?.etc ||
      !requestBody?.taskTypeId ||
      !requestBody?.description
    ) {
      pageErrorTemp.patientId = requestBody?.patientId ? "" : "Required";
      pageErrorTemp.taskPriority = requestBody?.taskPriority ? "" : "Required";
      pageErrorTemp.assigneeId = requestBody?.assigneeId ? "" : "Required";
      pageErrorTemp.etc = requestBody?.etc ? "" : "Required";
      pageErrorTemp.taskTypeId = requestBody?.taskTypeId ? "" : "Required";
      pageErrorTemp.description = requestBody?.description ? "" : "Required";
      setPageError(pageErrorTemp);
      return;
    }
    onSave(e);
  };

  const onSave = async (e) => {
    try {
      setSpinner(true);
      if (e.target.name === "Update" && hasValueChanged) {
        const editresult = await Taskapi.editMyTasks(requestBody);
        message.success("Task Updated Successfully!");
        setSelectedTaskType("");
        setSelecdtedPatient("");
        setDueDate("");
        setSelectedPriority("");
        setSelectedAssignee("");
        setRequestBody({
          ...requestBody,
          assigneeId: "",
          taskPriority: "",
          etc: "",
          patientId: "",
          notes: "",
          assigneeDisplayName: "",
          description: "",
        });
        Navigate("/Addtask");
        setSpinner(false);
        return editresult;
      } else if (e.target.name === "Save") {
        const result = await Taskapi.postMyTasks(requestBody);
        message.success("Task Saved Successfully!");
        setSelectedTaskType("");
        setSelecdtedPatient("");
        setDueDate("");
        setSelectedPriority("");
        setSelectedAssignee("");
        setRequestBody({
          ...requestBody,
          assigneeId: "",
          taskPriority: "",
          etc: "",
          patientId: "",
          notes: "",
          assigneeDisplayName: "",
          description: "",
        });
        setSpinner(false);
        return result;
      } else {
        setSpinner(false);
        Navigate("/Addtask");
        message.warning("No updates done!");
        return "No Update";
      }
    } catch (error) {
      console.log(error);
    }
  };
  const onClear = () => {
    setPageError({});
    setSelecdtedPatient("");
    setDueDate("");
    setSelectedPriority("");
    setSelectedTaskType("");
    setSelectedAssignee("");
    setRequestBody({
      ...requestBody,
      assigneeId: "",
      taskPriority: "",
      etc: "",
      patientId: "",
      notes: "",
      assigneeDisplayName: "",
      taskTypeId: "",
      description: "",
    });
  };

  const onCancel = (event) => {
    const buttonName = event.target.name;
    if (buttonName === "Clear") {
      onClear();
    } else {
      //  if (location.state?.referrer === "MyTask") {
      //   Navigate(`/TaskList`);
      // } else {
      //   Navigate(`/AllTasks`);
      // }
      Navigate(-1);
    }
  };

  return (
    <div style={{ minHeight: "90vh" }}>
      <div className="col-lg-10 col-md-12 mx-auto">
        {params?.patientId ? (
          <PageTitle title={"Edit Task"} />
        ) : (
          <PageTitle title={"Add Task"} />
        )}
        {spinner && <MyBackdropSpinner show={spinner} />}
        <div className="tw-flex tw-justify-between tw-items-start tw-overflow-scroll no-scrollbar px-2">
          <div className="tw-w-2/5 tw-space-y-5">
            <div className="form-group">
              <label htmlFor="patientName" className="fw-bold">
                Patient
              </label>
              <MyCustomSelect
                selectOptions={patientList}
                selectedValue={selecdtedPatient}
                myCallBack={onPatientChange}
                errorMessage={pageError?.patientId}
              />
            </div>
            <div className="form-group">
              <label htmlFor="taskType" className="fw-bold">
                Type
              </label>
              <MyCustomSelect
                selectOptions={taskTypeList}
                selectedValue={selectedTaskType}
                myCallBack={onTaskTypeChange}
                errorMessage={pageError?.taskTypeId}
              />
            </div>
            {/* {selectedTaskType?.value && ( */}
            <div className="form-group fw-bold">
              <label htmlFor="description">Description </label>
              <textarea
                className="form-control"
                maxLength={"1000"}
                onChange={onDescChange}
                value={requestBody.description}
                style={{
                  borderColor: pageError?.description ? "red" : "#ddd",
                }}
              />
            </div>
            {/* )} */}
            <div className="form-group fw-bold">
              <label>Due Date</label>
              <DatePicker
                className="form-control"
                placeholder="YYYY-MM-DD"
                disabledDate={disabledDate}
                value={dueDate ? dayjs(dueDate, dateFormat) : ""}
                format={dateFormat}
                onChange={onDueDateChange}
                style={{ borderColor: pageError?.etc ? "red" : "#ddd" }}
              />
            </div>
            <div className="form-group">
              <label htmlFor="assignee" className="fw-bold">
                Assignee
              </label>
              <MyCustomSelect
                selectOptions={assigneeList}
                selectedValue={selectedAssignee}
                myCallBack={onAssigneeChange}
                errorMessage={pageError?.assigneeId}
              />
            </div>
            <div className="form-group">
              <label htmlFor="priority" className="fw-bold">
                Priority
              </label>
              <MyCustomSelect
                selectOptions={PriorityOptions}
                selectedValue={selectedPriority}
                myCallBack={onPriorityChange}
                errorMessage={pageError?.taskPriority}
              />
            </div>
            <div className="form-group">
              <label
                htmlFor="priority"
                className="tw-fw-bold tw-flex tw-justify-between"
              >
                <div className="tw-flex tw-items-center">
                  <div className="fw-bold">Notes</div>
                  <div
                    style={{
                      fontWeight: "normal",
                      fontSize: "0.8rem",
                      color: "var(--bs-gray-600)",
                      paddingLeft: "6px",
                    }}
                  >
                    {"(Optional)"}
                  </div>
                </div>
                <div
                  style={{
                    fontWeight: "normal",
                    fontSize: "0.8rem",
                    color: "var(--bs-gray-600)",
                  }}
                >
                  {`${requestBody.notes?.length || 0}/1000`}
                </div>
              </label>
              <textarea
                className="form-control"
                maxLength={"1000"}
                onChange={onNotesChange}
                value={requestBody.notes}
              />
            </div>

            {/* <MyTextArea
          label="Notes"
          subLabel={`${requestBody.notes?.length || 0}/1000 (Optional)`}
          maxLength="1000"
          value={requestBody.notes}
          setValue={onNotesChange}
        /> */}
            <div className="form-group">
              <button
                className="btn btn-success"
                htmlFor="save"
                name={params?.patientId ? "Update" : "Save"}
                onClick={onSubmit}
              >
                {params?.patientId ? "Update" : "Save"}
              </button>
              <button
                className="btn btn-secondary"
                htmlFor="cancel"
                name={params?.patientId ? "Cancel" : "Clear"}
                style={{
                  marginLeft: "1em",
                }}
                onClick={onCancel}
              >
                {params?.patientId ? "Cancel" : "Clear"}
              </button>
            </div>
          </div>

          <div
            className="tw-w-5/12"
            style={{
              visibility: "hidden",
            }}
          >
            <div className="tw-font-medium">
              Subtasks for this featured task
            </div>
            <div className="tw-flex tw-flex-col tw-space-y-5 mt-2 border tw-rounded tw-px-4 tw-py-3">
              <Checkbox>Census Activities Complete</Checkbox>
              <Checkbox>Pre-CCE Coder Review Complete</Checkbox>
              <Checkbox>Pre-CCE Provider Review</Checkbox>
              <Checkbox>CCE Visit Scheduled</Checkbox>
              <Checkbox>CCE Visit Complete</Checkbox>
              <Checkbox>CCE Visit Incomplete</Checkbox>
              <Checkbox>Post CCE Provider Review</Checkbox>
              <Checkbox>Note Signed</Checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTask;
