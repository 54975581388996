import { Button, Drawer, Popconfirm, Space, Table, Typography } from "antd";
import React, { useState } from "react";
import { FaClipboardList } from "react-icons/fa";
import useSWR from "swr";
import MyBackdropSpinner from "../../common/elements/myBackdropSpinner";
import Search from "antd/es/input/Search";
import dayjs from "dayjs";
import { fetcher } from "../../services/fectcher";
import { usePickList } from "../../services/queries";
import { WarningOutlined } from "@ant-design/icons";
import { useMyContext } from "../../common/elements/myContext";
import IPEdit from "./IPEdit";
import { deleteIPAdmit } from "../../api/ToCareApi";
import { MdEventNote } from "react-icons/md";
import { openNotification } from "../../common/elements/myNotification";
import { Link } from "react-router-dom";
import { handleToggle } from "../../common/elements/toggleSeeMore";

const IPAdmissionTable = ({ patient }) => {
  const intialReqState = {
    key: "",
    guid: "",
    mbi: patient.value,
    notificationSource: "",
    notificationSourceOtherNotes: "",
    hospitalName: "",
    reasonAdmittingDx: "",
    admissionPlanned: "",
    admitDate: "",
    dischargeDate: "",
    dischargeDx: "",
    deceasedDate: "",
    dischargeDisposition: "",
    dischargeDispositionOtherNotes: "",
    createdBy: sessionStorage.getItem("userName") || "",
    modifiedBy: "",
    createdDate: dayjs().format("YYYY-MM-DD hh:mm A"),
  };

  const notificationSourceLabel = (notificationSource) => {
    const notifValue = sourceOfNotificationOptions?.find(
      (item) => item.value === notificationSource
    );
    return notifValue ? notifValue.label : "";
  };
  const dischargeDispositionLabel = (dischargeDisposition) => {
    const dischargeValue = dischargeDispositionOptions?.find(
      (item) => item.value === dischargeDisposition
    );
    return dischargeValue ? dischargeValue.label : "";
  };

  const { calculateDateRange } = useMyContext();
  const { fromDate, toDate } = calculateDateRange();

  const sourceOfNotificationOptions = usePickList(9).data;
  const dischargeDispositionOptions = usePickList(14).data;

  const [editRecord, setEditRecord] = useState(intialReqState);
  const [query, setQuery] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data, mutate, isLoading } = useSWR(
    `getIpAdmissionForDateRange?mbi=${patient.value}&fromDate=${fromDate}&toDate=${toDate}`,
    fetcher
  );

  const handleCancel = (e) => {
    setIsModalOpen(false);
    setEditRecord(intialReqState);
  };

  const edit = (record) => {
    setIsModalOpen(true);
    setEditRecord(record);
  };

  const handleOk = async (e) => {
    if (e) {
      mutate();
    }
    setIsModalOpen(false);
    setEditRecord(intialReqState);
  };

  const handleDelete = async (record) => {
    const body = { ...record, modifiedBy: sessionStorage.getItem("userName") };
    const deleteResult = await deleteIPAdmit(body);
    if (deleteResult.status === 200) {
      mutate();
      openNotification(
        "success",
        "Deleted",
        "IP Admission Deleted Successfully!"
      );
    } else {
      openNotification("error", "Error", "IP Admission failed to Delete!");
    }
  };

  const [expandedRows, setExpandedRows] = useState([]);

  const columns = [
    {
      key: 1,
      title: "Notification Source",
      //dataIndex: "notificationSource",
      width: 40,
      render: (_, data) => {
        return (
          <>
            <div>{notificationSourceLabel(data.notificationSource)}</div>
            {(data.notificationSource === "Other" ||
              data.notificationSource === "HIE_-_Other") && (
              <div className=" tw-text-gray-500">
                <MdEventNote size={20} className=" tw-mr-1 tw-text-gray-400" />
                {data.notificationSourceOtherNotes}
              </div>
            )}
          </>
        );
      },
    },
    {
      key: 3,
      title: "Hospital Name",
      dataIndex: "hospitalName",
      width: 30,
    },
    {
      key: 4,
      title: "Reason/Admitting Dx",
      dataIndex: "reasonAdmittingDx",
      width: 30,
      render: (text, record) => {
        const isExpanded = expandedRows.includes(record.key);
        const content =
          text.length > 100 ? (
            <span>
              {isExpanded ? text : `${text.substring(0, 100)}...`}
              <Button
                type="link"
                onClick={() => handleToggle(record.key, setExpandedRows)}
              >
                {isExpanded ? "See less" : "See more"}
              </Button>
            </span>
          ) : (
            text
          );

        return <div>{content}</div>;
      },
    },
    {
      key: 10,
      title: "Planned/Unplanned",
      dataIndex: "admissionPlanned",
      width: 30,
      render: (admissionPlanned, rowItem) => {
        return admissionPlanned === "UNPLANNED" ? (
          <div className="tw-flex tw-space-x-2">
            <Link
              to={`/rca/${rowItem.guid}`}
              state={{ patient: patient, type: "ipa" }}
            >
              {admissionPlanned}
            </Link>
            <div className="-tw-mt-0.5 tw-ml-2" style={{ color: "#f97316" }}>
              <FaClipboardList />
            </div>
          </div>
        ) : (
          <div>{admissionPlanned}</div>
        );
      },
    },
    {
      key: 5,
      title: "Admit Date",
      dataIndex: "admitDate",
      width: 70,
    },
    {
      key: 5,
      title: "Discharge Dx",
      dataIndex: "dischargeDx",
      width: 30,
    },
    {
      key: 6,
      title: "Discharge Date",
      dataIndex: "dischargeDate",
      width: 30,
    },
    {
      key: 8,
      title: "Deceased Date",
      dataIndex: "deceasedDate",
      width: 30,
    },
    {
      key: 9,
      title: "Discharge Disposition",
      dataIndex: "dischargeDisposition",
      width: 30,
      render: (_, data) => {
        return (
          <>
            <div>{dischargeDispositionLabel(data.dischargeDisposition)}</div>
            {data.dischargeDisposition === "Other" && (
              <div className=" tw-text-gray-500">
                <MdEventNote size={20} className=" tw-mr-1 tw-text-gray-400" />
                {data.dischargeDispositionOtherNotes}
              </div>
            )}
          </>
        );
      },
    },

    {
      key: "key",
      title: "Operation",
      dataIndex: "guid",
      width: 10,
      fixed: "right",
      render: (_, record) => (
        <Space>
          <Typography.Link onClick={() => edit(record)}>Edit</Typography.Link>
          <Typography.Link type="danger">
            <Popconfirm
              title="Ok to Delete?"
              onConfirm={() => handleDelete(record)}
              icon={
                <WarningOutlined
                  style={{
                    color: "red",
                  }}
                />
              }
            >
              Delete
            </Popconfirm>
          </Typography.Link>
        </Space>
      ),
    },
  ];

  const finalResult = data
    ? data.filter((item) => {
        return [
          "notificationSource",
          "notificationSourceOtherNotes",
          "hospitalName",
          "reasonAdmittingDx",
          "admissionPlanned",
          "admitDate",
          "dischargeDate",
          "dischargeDx",
          "deceasedDate",
          "dischargeDisposition",
          "dischargeDispositionOtherNotes",
          "createdBy",
        ].some((key) => {
          if (key === "notificationSource") {
            const normalizedQuery =
              query?.replace(/\s/g, "_")?.toLowerCase() ?? "";
            const sourceValue =
              item[key]?.toString()?.replace(/\s/g, "_")?.toLowerCase() ?? "";
            return sourceValue.includes(normalizedQuery);
          } else if (key === "dischargeDisposition") {
            const normalizedQuery =
              query?.replace(/\s/g, "_")?.toLowerCase() ?? "";
            const sourceValue =
              item[key]?.toString()?.replace(/\s/g, "_")?.toLowerCase() ?? "";
            return sourceValue.includes(normalizedQuery);
          } else {
            return (item[key]?.toString()?.toLowerCase() ?? "").includes(
              query?.toLowerCase() ?? ""
            );
          }
        });
      })
    : data;

  return (
    <div>
      <div className="tw-flex tw-justify-between ">
        <p
          className="pt-3"
          style={{
            color: "#D2691E",
            fontWeight: "600",
          }}
        >
          IP Admission
        </p>

        {isLoading && <MyBackdropSpinner show={isLoading} />}

        <div className="tw-flex tw-justify-end  tw-mt-3 ">
          <Space>
            <Search
              className=" tw-mb-4"
              size="middle"
              placeholder="Search"
              onChange={(e) => setQuery(e.target.value)}
              allowClear
            />
            <Button
              type="primary"
              onClick={() => setIsModalOpen(true)}
              className="tw-mb-4"
            >
              Add
            </Button>
          </Space>
        </div>
      </div>
      {isModalOpen && (
        <Drawer
          title="IP Admission"
          width={800}
          onClose={handleCancel}
          open={isModalOpen}
        >
          <IPEdit
            editRecord={editRecord}
            callback={handleOk}
            son={sourceOfNotificationOptions}
            ddo={dischargeDispositionOptions}
          />
        </Drawer>
      )}

      <Table
        bordered
        size="middle"
        dataSource={finalResult}
        columns={columns}
      />
    </div>
  );
};

export default IPAdmissionTable;
