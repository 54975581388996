import React, { useEffect, useState } from "react";
import { Segmented, Select, Table } from "antd";
import PageTitle from "../Common/PageTitle";
import MyBackdropSpinner from "../../common/elements/myBackdropSpinner";
import { getToc } from "../../api/TocApi";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

const TOC = ({ mbi }) => {
  const [TocData, setTocData] = useState();
  const [spinner, setSpinner] = useState(false);
  const params = useParams();

  useEffect(() => {
    setSpinner(true);
    const tocdataResponse = getToc(params?.mbiid == null ? "0" : params.mbiid);
    Promise.all([tocdataResponse]).then((values) => {
      let toc = [];
      values[0]?.data.forEach((data) => {
        toc.push({
          eventType: data?.eventType,
          admitDate: dayjs(data?.admitDate).utc().format("YYYY-MM-DD"),
          admittingDX: data?.admittingDX,
          dischargeDX: data?.dischargeDX,
          dischargeDate:
            data?.dischargeDate == null
              ? ""
              : dayjs(data?.dischargeDate).utc().format("YYYY-MM-DD"),
          dischargeDisposition: data?.dischargeDisposition,
          readmit30: data?.readmit30 == 0 ? "No" : "Yes",
          los: data?.los,
          hospitalName: data?.hospitalName,
          snfServices: data?.snfServices,
          source: "CCC",
        });
      });
      setTocData(toc);
      setSpinner(false);
    });
  }, []);

  const admissioncolumns = [
    {
      title: "Event Type",
      key: "eventType",
      dataIndex: "eventType",
    },
    {
      title: "Admit Date",
      key: "admitDate",
      dataIndex: "admitDate",
      width: 100,
    },
    {
      title: "Admitting Dx",
      key: "admittingDX",
      dataIndex: "admittingDX",
    },
    {
      title: "Discharge Date",
      key: "dischargeDate",
      dataIndex: "dischargeDate",
      width: 100,
    },
    {
      title: "Discharge Dx",
      key: "dischargeDX",
      dataIndex: "dischargeDX",
    },
    {
      title: "Discharge Disposition",
      key: "dischargeDisposition",
      dataIndex: "dischargeDisposition",
    },
    {
      title: "ReAdmit30",
      key: "readmit30",
      dataIndex: "readmit30",
    },
    {
      title: "LOS",
      key: "los",
      dataIndex: "los",
    },
    {
      title: "Patient Location",
      key: "hospitalName",
      dataIndex: "hospitalName",
    },
    {
      title: "SNF Services",
      key: "snfServices",
      dataIndex: "snfServices",
    },
    {
      title: "Source",
      key: "source",
      dataIndex: "source",
      width: 130,
    },
  ];

  return (
    <div>
      {/* {<PageTitle title={`Transitions of Care`} />} */}
      {spinner && <MyBackdropSpinner show={spinner} />}
      <div style={{ color: "var(--bs-gray-800)" }}>
        <div className="tw-flex tw-justify-between tw-border tw-mb-6">
          <div className="tw-flex tw-justify-end tw-space-x-2 tw-basis-2/5"></div>
        </div>
        <div className="md">
          <Table
            className="table-striped-rows"
            columns={admissioncolumns}
            dataSource={TocData}
            size="large"
            bordered
            pagination={{
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default TOC;
