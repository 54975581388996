import React, { useState } from "react";
import PageTitle from "../Common/PageTitle";
import { Button, Skeleton, Table } from "antd";
import useSWR from "swr";
import { fetcher } from "../../services/fectcher";
import dayjs from "dayjs";

// const DummyData = {
//   MTD: [
//     {
//       id: 1,
//       providerName: "Provider A",
//       beneficiaryName: "John Doe",
//       medicareNumber: "123456789",
//       submittedDate: "2024-09-20",
//       printName: "MTD Print 1",
//       fileName: "mtd_document_1.pdf",
//     },
//     {
//       id: 2,
//       providerName: "Provider B",
//       beneficiaryName: "Jane Smith",
//       medicareNumber: "987654321",
//       submittedDate: "2024-09-21",
//       printName: "MTD Print 2",
//       fileName: "mtd_document_2.pdf",
//     },
//   ],
//   WTD: [
//     {
//       id: 1,
//       providerName: "Provider C",
//       beneficiaryName: "Alice Johnson",
//       medicareNumber: "456123789",
//       submittedDate: "2024-09-22",
//       printName: "WTD Print 1",
//       fileName: "wtd_document_1.pdf",
//     },
//     {
//       id: 2,
//       providerName: "Provider D",
//       beneficiaryName: "Bob Brown",
//       medicareNumber: "321654987",
//       submittedDate: "2024-09-23",
//       printName: "WTD Print 2",
//       fileName: "wtd_document_2.pdf",
//     },
//   ],
//   Today: [
//     {
//       id: 1,
//       providerName: "Provider E",
//       beneficiaryName: "Charlie Davis",
//       medicareNumber: "789456123",
//       submittedDate: "2024-09-24",
//       printName: "Today Print 1",
//       fileName: "today_document_1.pdf",
//     },
//     {
//       id: 2,
//       providerName: "Provider F",
//       beneficiaryName: "Dana Lee",
//       medicareNumber: "654789321",
//       submittedDate: "2024-09-24",
//       printName: "Today Print 2",
//       fileName: "today_document_2.pdf",
//     },
//   ],
// };

const SVAHome = () => {
  const [selectedOption, setSelectedOption] = useState("MTD");

  const getDates = () => {
    const today = dayjs().format("YYYY-MM-DD");
    let startDate = today;

    if (selectedOption === "MTD") {
      startDate = dayjs().startOf("month").format("YYYY-MM-DD");
    } else if (selectedOption === "WTD") {
      startDate = dayjs().startOf("week").format("YYYY-MM-DD");
    }

    return { startDate, endDate: today };
  };

  const { startDate, endDate } = getDates();

  console.log("startDate", startDate);
  console.log("endDate", endDate);

  const { data, isLoading } = useSWR(
    `https://hccqualifier-service.azurewebsites.net/api/SVASubmitDetails?startDate=${startDate}&endDate=${endDate}`,
    fetcher
  );

  console.log("data", data);

  const columns = [
    {
      title: "Provider Name",
      dataIndex: "providerName",
      key: "providerName",
    },
    {
      title: "Beneficiary Name",
      dataIndex: "beneficiaryName",
      key: "beneficiaryName",
    },
    {
      title: "Medicare Number",
      dataIndex: "medicareNumber",
      key: "medicareNumber",
    },

    {
      title: "Print Name",
      dataIndex: "printName",
      key: "printName",
    },
    {
      title: "File Name",
      dataIndex: "fileName",
      key: "fileName",
    },
    {
      title: "Submitted Date",
      dataIndex: "submittedDate",
      key: "submittedDate",
      render: (text) => dayjs(text)?.format("YYYY-MM-DD"),
    },
  ];

  return (
    <>
      <PageTitle title={"SVA Forms"} />
      <div className="tw-flex tw-flex-col">
        <div className="tw-flex tw-justify-between tw-w-full  tw-py-2 tw-border-b tw-border-gray-300">
          <h2 className="tw-text-xl tw-font-bold tw-mb-4">
            No. of SVA Forms Submitted - {selectedOption} : {data?.length}
          </h2>
          <div className="tw-w-1/3 tw-flex tw-justify-end tw-space-x-2">
            <Button
              type={selectedOption === "MTD" ? "primary" : ""}
              onClick={() => setSelectedOption("MTD")}
            >
              MTD
            </Button>
            <Button
              type={selectedOption === "WTD" ? "primary" : ""}
              onClick={() => setSelectedOption("WTD")}
            >
              WTD
            </Button>
            <Button
              type={selectedOption === "Today" ? "primary" : ""}
              onClick={() => setSelectedOption("Today")}
            >
              Today
            </Button>
          </div>
        </div>

        {isLoading ? (
          <Skeleton active={isLoading} />
        ) : (
          <Table
            bordered
            size="middle"
            dataSource={data}
            columns={columns}
            rowKey="id"
          />
        )}
      </div>
    </>
  );
};

export default SVAHome;
