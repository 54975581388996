import React, { useState } from "react";
import SignaturePad from "./SignaturePad";
import { DatePicker } from "antd";

const VAForm = () => {
  const [signature, setSignature] = useState("");

  const handleSignatureEnd = (dataURL) => {
    setSignature(dataURL);
  };

  const onSave = () => {
  alert("VA Form Submitted Successfully");
  }

  return (
    <form
      style={{
        padding: 10,
        borderBlockColor: "black",
        marginLeft: "10em",
        marginRight: "10em",
        border: "solid",
      }}
    >
      <img src="./images/atHome.png" width={"200"} style={{ float: "right" }} />{" "}
      <br />
      <br />
      <br />
      <br />
      <br />
      <h2>Dear Medicare Beneficiary: </h2>
      <h3>
        Please complete this form to confirm our practice is the main place you
        go for routine care.
      </h3>
      <p>
        Medicare has started an initiative where health care providers who share
        a common set of goals aimed at improving patient care can work together
        more effectively. This initiative brings together health care
        professionals in an Accountable Care Organization (ACO), to work
        together with Medicare to give you more coordinated care and services.
      </p>
      <p>
        Our practice is voluntarily taking part in this new initiative by
        joining <b>CareConnectMD At Home</b> because we think it will help us
        provide better quality care for our patients.
      </p>
      <p>
        You are receiving this letter and form because your doctor or other
        health care professional thinks that you might benefit from care
        coordination and preventive services offered by CareConnectMD At Home.
      </p>
      <p>
        You can use this form to confirm that our practice is the main doctor or
        other health care professional you see or the main place you go for
        routine care, to help determine if CareConnectMD At Home should help
        coordinate your care. Routine care can include regular care and
        check-ups you get from a doctor or other health care professional and
        care for other chronic health problems, such as asthma, diabetes, and
        hypertension.
      </p>
      <p>
        Alternatively, instead of returning this form, you can also log into
        Medicare.gov and select your main doctor or other health care
        professional to determine whether CareConnectMD At Home should help with
        coordinating your care. If you make a selection on this form and make a
        different selection through Medicare.gov, Medicare will prioritize the
        most recently submitted selection.
      </p>
      <b>
        Your benefits will NOT change, and you can visit any doctor, other
        health care professional, or hospital.
      </b>
      <p>
        Whether or not you complete this form or select a doctor or other health
        care professional through Medicare.gov, you remain eligible to receive
        the same Medicare benefits and you still have the right to use any
        doctor, other health care professional, or hospital that accepts
        Medicare, at any time. If you have questions, feel free to ask your
        doctor or other health care professional, call CareConnectMD At Home at
        888-874- 0818, or call Medicare at 1-800-MEDICARE (1-800-633-4227) to
        ask about ACOs. TTY users should call 1-877-486-2048.
      </p>
      <p>
        Whether or not you complete this form or select a doctor or other health
        care professional through Medicare.gov, you remain eligible to receive
        the same Medicare benefits and you still have the right to use any
        doctor, other health care professional, or hospital that accepts
        Medicare, at any time. If you have questions, feel free to ask your
        doctor or other health care professional, call CareConnectMD At Home at
        888-874- 0818, or call Medicare at 1-800-MEDICARE (1-800-633-4227) to
        ask about ACOs. TTY users should call 1-877-486-2048.
      </p>
      <p>
        <b>
          Completing this form or selecting a doctor or other health care
          professional through Medicare.gov is your choice AND you can change
          your mind.
        </b>
        If you choose to complete this form or select a doctor or other health
        care professional through Medicare.gov you should do so yourself. No one
        else should complete this for you.
      </p>
      <p>
        No one is allowed to attempt to influence your choice to complete this
        form or select a doctor or other health care professional through
        Medicare.gov by offering or withholding anything in exchange for you to
        complete or not complete the form or to make a selection online. If you
        feel pressured to sign or not sign this form or to make a selection
        online, please call 1-800-MEDICARE (1-800-633-4227). TTY users should
        call 1-877-486-2048.
      </p>
      <p>
        Please call 888-874-0818 or update your online selection if you change
        your mind later about whether you consider our practice to be the main
        doctor or other health care professional you see or the main place you
        go for routine care.
      </p>
      <p>
        Sincerely,
        <br /> CareConnectMD At Home
      </p>
      <b>Confirmation of Main Doctor or Other Healthcare Professional Form</b>
      <form style={{ border: "solid", margin: 10 }}>
        <div className="pgn">
          <div className="pgn-label">
            <h1>1.Confirm</h1>
            <p>
              By signing below, I am confirming that my main doctor or other
              healthcare professional – or the main place I go to for routine
              medical care – is:
            </p>
            <label>
              ProviderName and
              <br />
              /Or Medical Group :{" "}
            </label>
            <input type="text" /> <br />
            <br />
            <label>Beneficiary Name </label>
            <input type="text" /> <br />
            <label>Signature : </label>
            <SignaturePad onEnd={handleSignatureEnd} />
            <label>Date :</label>
            <DatePicker
              style={{ margin: "1em", padding: 6, width: "20%" }}
            ></DatePicker>
            <br />
            <input type="text" /> <br />
            <label>Print Name</label> <br /> <br />
            <input type="text" /> <br />
            <label>Medicare Number</label>
          </div>
        </div>{" "}
        <br />
        <button className="btn btn-success" onClick={onSave}>
          Submit
        </button>
      </form>
      <h1>2. Return</h1>
      <p>Return this form to our practice via secure fax or mail to</p>
      <p>CareConnectMD at Home</p>
      <p> 3090 Bristol St Ste 200</p>
      <p> Costa Mesa, CA 92626 </p>
      <p>Ph.Number: 877-738-0868</p>
      <b>
        Note: Completing and returning this form is voluntary. It won’t affect
        your Medicare benefits.<br/> This form is not valid unless it includes both a
        signature and date.
      </b>
    </form>
  );
};
export default VAForm;
