import React, { useState, useEffect, Suspense } from "react";
import * as ScoreApi from "../api/scoreApi";

import { Container } from "react-bootstrap";
import "react-loading-skeleton/dist/skeleton.css";
import { Content } from "antd/es/layout/layout";

const Footer = (props) => {
  const [dbName, setDbName] = useState([]);

  const [footerInfo, setFooterInfo] = useState({});

  const getDatabase = () => {
    ScoreApi.getDatabase().then((result) => {
      const resultData = result?.data;
      setDbName(resultData);

      setFooterInfo({
        dbName: resultData || "N/A",
        companyInfo:
          {
            name: "CareConnectMD",
            url: "https://careconnectmd.com",
          } || resultData?.connection?.companyInfo,
        policyText: resultData?.connection?.policyText || "All rights reserved",
        supportInfo: {
          privacyPolicy: "https://www.careconnectmd.com/privacy-policy",
          supportMail: "support@careconnectmd.com",
          supportPhoneNo: "(844) 636-1540",
        },
        release: {
          majorVerion: resultData?.connection?.majorVersion || "1",
          minorVersion: resultData?.connection?.majorVersion || "1",
          releaseDate: resultData?.connection?.majorVersion || "01/01/2023",
        },
      });
    });

    // return new Promise((resolve) => {
    //   setTimeout(() => {
    //     resolve("Hello, world!");
    //   }, 5000);
    // });
  };

  useEffect(() => {
    getDatabase();
  }, []);

  return (
    <div className="footer py-5 print:tw-hidden">
      <footer>
        <center>
          {footerInfo?.dbName} |
          <a
            href={footerInfo?.companyInfo?.url}
            rel="noopener noreferrer"
            target="_blank"
          >
            {"   "}
            {footerInfo?.companyInfo?.name}
          </a>
          {"   |"} {footerInfo?.policyText}
          <a href={footerInfo?.supportInfo?.privacyPolicy} target="_blank">
            {" "}
            {"  | "}Privacy Policy{" "}
          </a>
          <a
            href={"mailto:" + footerInfo?.supportInfo?.supportMail}
            target="_blank"
          >
            | Support{" "}
          </a>{" "}
          {"|"} <span> {footerInfo?.supportInfo?.supportPhoneNo} </span>
        </center>
      </footer>
    </div>
  );
};

export default Footer;
