import React, { useEffect, useState } from "react";
import MyTextBox from "../../common/elements/myTextbox";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import TextArea from "antd/es/input/TextArea";
import { openNotification } from "../../common/elements/myNotification";
import { addOutboundCall } from "../../api/CareEventSCPApi";
import MyCustomSelect from "../../common/elements/myCustomSelect";

const OutboundCallAdd = ({
  editRecord,
  callback,
  teamOptions,
  nppaOptions,
  callFrequencyOptions,
}) => {
  const [pageError, setPageError] = useState({});
  const [disable, setDisable] = useState(false);

  const [formData, setFormData] = useState(
    editRecord?.guid
      ? {
          ...editRecord,
          modifiedBy: sessionStorage.getItem("userName"),
        }
      : editRecord
  );
  const [isPhoneValid, setIsPhoneValid] = useState(true);

  const [npPi, setNpPi] = useState([]);

  const npPiChange = (e) => {
    setNpPi(e);
    handleSelectChange("nporpaValue", e);
    if (e?.value !== "Other") {
      setFormData({
        ...formData,
        nporpaValue: e ? e.value : "",
        nporpaLabel: e ? e.label : "",
        nporpaOther: "",
      });
    }
  };

  const [provider, setProvider] = useState([]);

  const providerChange = (e) => {
    setProvider(e);
    handleSelectChange("providerNameValue", e);
    if (e?.value !== "Other") {
      setFormData({
        ...formData,
        providerNameLabel: e ? e.label : "",
        providerNameValue: e ? e.value : "",
        providerNameOther: "",
      });
    }
  };

  const [team, setTeam] = useState([]);

  const teamChange = (e) => {
    setTeam(e);
    handleSelectChange("team", e);
  };

  const [callFrequency, setCallFrequency] = useState([]);

  const callFrequencyChange = (e) => {
    setCallFrequency(e);
    handleSelectChange("callFrequency", e);
  };

  const handleInputChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
    setPageError({ ...pageError, [name]: value ? "" : "Required" });
  };

  const handleSelectChange = (fieldName, e) => {
    if (fieldName === "nporpaValue") {
      setFormData({
        ...formData,
        nporpaValue: e ? e.value : "",
        nporpaLabel: e ? e.label : "",
      });
    } else if (fieldName === "providerNameValue") {
      setFormData({
        ...formData,
        providerNameValue: e ? e.value : "",
        providerNameLabel: e ? e.label : "",
      });
    } else {
      setFormData({
        ...formData,
        [fieldName]: e ? e.value : "",
      });
    }

    setPageError({
      ...pageError,
      [fieldName]: e ? "" : "Required",
    });
  };

  useEffect(() => {
    const filterAndSet = (dataField, options, setter) => {
      if (formData?.[dataField]) {
        const filteredData = options?.filter(
          (item) => item.value === formData?.[dataField]
        );
        setter(filteredData);
      } else {
        setter([]);
      }
    };

    filterAndSet("nporpaValue", nppaOptions, setNpPi);
    filterAndSet("providerNameValue", nppaOptions, setProvider);
    filterAndSet("team", teamOptions, setTeam);
    filterAndSet("callFrequency", callFrequencyOptions, setCallFrequency);
  }, []);

  const handleDateChange = (fieldName, dateString) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: dateString ? dateString : "",
    }));
    setPageError((prevErrors) => ({
      ...prevErrors,
      [fieldName]: dateString ? "" : "Required",
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setPageError({});
    let pageErrorTemp = {};

    if (!formData?.changeOfCondition) {
      pageErrorTemp.changeOfCondition = "Required";
    }

    if (!formData?.lastNurseVisit) {
      pageErrorTemp.lastNurseVisit = "Required";
    }

    if (!formData?.erVisitHospitaliastion) {
      pageErrorTemp.erVisitHospitaliastion = "Required";
    }

    if (!formData?.callFrequency) {
      pageErrorTemp.callFrequency = "Required";
    }

    if (
      formData?.providerNameValue === "Other" &&
      !formData?.providerNameOther
    ) {
      pageErrorTemp.providerNameOther = "Required";
    }

    if (formData?.nporpaValue === "Other" && !formData?.nporpaOther) {
      pageErrorTemp.nporpaOther = "Required";
    }

    if (formData?.phoneNumbers && !isPhoneValid) {
      pageErrorTemp.phoneNumbers = "Required";
    }

    if (Object.keys(pageErrorTemp).length > 0) {
      setPageError(pageErrorTemp);
      return;
    }

    setDisable(true);
    onSave(e);
  };

  const onSave = async (e) => {
    try {
      const response = await addOutboundCall(formData);
      if (response?.status === 200 && !formData?.guid) {
        openNotification(
          "success",
          "Added",
          "Outbound Call Added Successfully!"
        );
      } else if (formData?.guid) {
        openNotification(
          "success",
          "Updated",
          "Outbound Call Updated Successfully!"
        );
      } else {
        openNotification("error", "Error", "Outbound Call Failed to Add!");
      }
      setFormData(editRecord);
      setDisable(false);
      callback(response);
    } catch (e) {
      return e;
    }
  };

  return (
    <div className="tw-relative tw-px-2">
      <div className="form-group tw-mb-3 tw-mr-16 tw-pr-2">
        <label className="tw-block tw-font-bold">NP / PA</label>
        <MyCustomSelect
          selectOptions={nppaOptions}
          selectedValue={npPi}
          myCallBack={npPiChange}
        />
      </div>
      {npPi === "Other" ||
        (formData?.nporpaValue === "Other" && (
          <div className="form-group tw-mb-3 tw-mr-16 tw-pr-2">
            <MyTextBox
              label={"Other"}
              value={formData?.nporpaOther}
              errorMessage={pageError?.nporpaOther}
              setValue={(e) => handleInputChange("nporpaOther", e.target.value)}
              maxLength={100}
            />
          </div>
        ))}
      <div className="form-group tw-mb-3 tw-mr-16 tw-pr-2">
        <label className="tw-block tw-font-bold">Provider</label>
        <MyCustomSelect
          selectOptions={nppaOptions}
          selectedValue={provider}
          myCallBack={providerChange}
        />
      </div>
      {provider === "Other" ||
        (formData?.providerNameValue === "Other" && (
          <div className="form-group tw-mb-3 tw-mr-16 tw-pr-2">
            <MyTextBox
              label={"Other"}
              value={formData?.providerNameOther}
              errorMessage={pageError?.providerNameOther}
              setValue={(e) =>
                handleInputChange("providerNameOther", e.target.value)
              }
              maxLength={100}
            />
          </div>
        ))}
      <div className="form-group tw-mb-3 tw-mr-16 tw-pr-2">
        <MyTextBox
          label={"Phone Number"}
          value={formData?.phoneNumbers}
          errorMessage={pageError?.phoneNumbers}
          setValue={(e) => handleInputChange("phoneNumbers", e.target.value)}
          isPhoneNumber={true}
          onValidChange={(e) => setIsPhoneValid(e)}
        />
      </div>
      <div className="form-group tw-mb-3 tw-mr-16 tw-pr-2">
        <label className="tw-block tw-font-bold">Team</label>
        <MyCustomSelect
          selectOptions={teamOptions}
          selectedValue={team}
          myCallBack={teamChange}
        />
      </div>
      <div className="form-group tw-mb-3 tw-mr-16 tw-pr-2">
        <MyTextBox
          label={"Change of Condition"}
          value={formData?.changeOfCondition}
          errorMessage={pageError?.changeOfCondition}
          setValue={(e) =>
            handleInputChange("changeOfCondition", e.target.value)
          }
          maxLength={100}
        />
      </div>
      <div className="col-md-11 col-lg-11 form-group tw-mr-2">
        <div className="tw-flex">
          <label className="tw-font-bold" htmlFor="dateTime">
            Last Nurse Visit
          </label>
        </div>
        <DatePicker
          use12Hours
          className="form-control tw-mb-3"
          onChange={(date, dateString) =>
            handleDateChange("lastNurseVisit", dateString)
          }
          format={"YYYY-MM-DD"}
          value={formData?.lastNurseVisit ? dayjs(formData.lastNurseVisit) : ""}
          style={{
            border: pageError?.lastNurseVisit ? "1px solid red" : "",
          }}
        />
      </div>
      <div className="form-group tw-mb-3 tw-mr-16 tw-pr-2">
        <MyTextBox
          label={"ER Visit / Hospitalization"}
          value={formData?.erVisitHospitaliastion}
          errorMessage={pageError?.erVisitHospitaliastion}
          setValue={(e) =>
            handleInputChange("erVisitHospitaliastion", e.target.value)
          }
          maxLength={100}
        />
      </div>

      <div className="form-group tw-mb-3 tw-mr-16 tw-pr-2">
        <label className="tw-block tw-font-bold">Call Frequency</label>
        <MyCustomSelect
          selectOptions={callFrequencyOptions}
          selectedValue={callFrequency}
          myCallBack={callFrequencyChange}
          errorMessage={pageError?.callFrequency}
        />
      </div>

      {/* <div className="form-group">
        <label className="tw-block tw-font-bold">Call Frequency</label>
        <fieldset className="fw-normal tw-mt-2">
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="callFrequency"
              checked={formData?.callFrequency === "High Tier"}
              id="callFrequencyHighTier"
              value="High Tier"
              onChange={(e) =>
                handleInputChange("callFrequency", e.currentTarget.value)
              }
            />
            <label className="form-check-label" htmlFor="callFrequencyHighTier">
              High Tier - 1 call per week
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="callFrequency"
              checked={formData?.callFrequency === "Low Tier"}
              id="callFrequencyLowTier"
              value="Low Tier"
              onChange={(e) =>
                handleInputChange("callFrequency", e.currentTarget.value)
              }
            />
            <label className="form-check-label" htmlFor="callFrequencyLowTier">
              Low Tier - 2 calls per week
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="callFrequency"
              checked={formData?.callFrequency === "Do Not Call"}
              id="callFrequencyDoNotCall"
              value="Do Not Call"
              onChange={(e) =>
                handleInputChange("callFrequency", e.currentTarget.value)
              }
            />
            <label
              className="form-check-label"
              htmlFor="callFrequencyDoNotCall"
            >
              Do Not Call, Remove from List
            </label>
          </div>
          <span
            className="text-danger"
            style={{ fontWeight: "normal", paddingLeft: "5px" }}
          >
            {pageError?.callFrequency}
          </span>
        </fieldset>
      </div> */}

      <div className="form-group tw-mr-16 tw-pr-2">
        <label htmlFor="selectedOption" className="fw-bold">
          Comments
        </label>
        <TextArea
          className="form-control"
          maxLength={"1000"}
          value={formData?.comments}
          onChange={(e) => handleInputChange("comments", e.target.value)}
        />
      </div>

      <div className="form-group tw-fixed tw-top-2 tw-right-10">
        <button
          className={!disable ? "btn btn-success" : "btn btn-success disabled"}
          onClick={onSubmit}
        >
          {editRecord?.guid ? "Update" : "Save"}
        </button>
        <button
          className="btn btn-secondary tw-ml-4"
          onClick={() => callback(false)}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default OutboundCallAdd;
