const ScoreFilterSummary = ({ scoreFilter }) => {
  const emptyString = "-";

  return (
    <div className="row">
      <div className="col-md-12">
        <p>Searched for :</p>
        <div className="row">
          <div className="col-md-6 col-lg-3 pb-2">
            <b className="d-block">CCMD Provider</b>{" "}
            {scoreFilter?.ccmdProvider
              ? scoreFilter?.ccmdProvider
              : emptyString}
          </div>
          <div className="col-md-6 col-lg-3 pb-2">
            <b className="d-block">MBI</b>{" "}
            {scoreFilter?.mbi ? scoreFilter?.mbi : emptyString}
          </div>
          <div className="col-md-6 col-lg-3 pb-2">
            <b className="d-block">Rendering Provider</b>{" "}
            {scoreFilter?.renderingProvider?.label
              ? scoreFilter?.renderingProvider?.label
              : emptyString}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-3 pb-2">
            <b className="d-block">First Name</b>{" "}
            {scoreFilter?.firstName ? scoreFilter?.firstName : emptyString}
          </div>
          <div className="col-md-6 col-lg-3 pb-2">
            <b className="d-block">Last Name</b>{" "}
            {scoreFilter?.lastName ? scoreFilter?.lastName : emptyString}
          </div>

          <div className="col-md-6 col-lg-3 pb-2">
            <b className="d-block">DOB</b>{" "}
            {`${scoreFilter?.dob ? scoreFilter?.dob : emptyString}`}
          </div>

          <div className="col-md-6 col-lg-3 pb-2">
            <b className="d-block">Age</b>{" "}
            {`${scoreFilter?.age ? scoreFilter?.age : emptyString}`}
          </div>

          <div className="col-md-6 col-lg-3 pb-2">
            <b className="d-block"> Gender </b>
            {scoreFilter?.gender?.label
              ? scoreFilter?.gender?.label
              : emptyString}
          </div>

          <div className="col-md-6 col-lg-3 pb-2">
            <b className="d-block">Provider Group</b>{" "}
            {scoreFilter?.providerGroup
              ? scoreFilter?.providerGroup
              : emptyString}
          </div>

          <div className="col-md-6 col-lg-3 pb-2">
            <b className="d-block">Facility</b>{" "}
            {scoreFilter?.facility?.label
              ? scoreFilter?.facility?.label
              : emptyString}
          </div>

          <div className="col-md-6 col-lg-3 pb-2">
            <b className="d-block">Region</b>{" "}
            {scoreFilter?.region?.label
              ? scoreFilter?.region?.label
              : emptyString}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-3 pb-2">
            <b className="d-block"> Recent Hospitalization Info </b>
            {scoreFilter?.recentHospitalizationInfo
              ? scoreFilter?.recentHospitalizationInfo
              : emptyString}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScoreFilterSummary;
