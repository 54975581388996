import React, { useState } from "react";
import useSWR from "swr";
import { fetcherhn } from "../../services/fectcher";
import { Button, Space, Table, Popconfirm, Drawer, Tooltip } from "antd";
import dayjs from "dayjs";
import Search from "antd/es/input/Search";
import MyBackdropSpinner from "../../common/elements/myBackdropSpinner";
import { usePickList } from "../../services/queries";
import { deleteTriageCall } from "../../api/CareEventsApi";
import { WarningOutlined } from "@ant-design/icons";
import { openNotification } from "../../common/elements/myNotification";
import TriageCallAdd from "./TriageCallAdd";
import { MdEventNote } from "react-icons/md";
import { FaEdit, FaMobileAlt, FaRegTrashAlt } from "react-icons/fa";
import { useMyContext } from "../../common/elements/myContext";
import { FiSend } from "react-icons/fi";
import SendEmail from "../../common/elements/SendEmail";

const TriageCall = (props) => {
  const initialReqState = {
    key: "",
    guid: "",
    mbi: props.mbiid,
    lob: "",
    providerNameLabel: "",
    providerNameValue: "",
    providerNameOther: "",
    nporpaLabel: "",
    nporpaValue: "",
    nporpaOther: "",
    facilityName: "",
    callType: "",
    isThisUrgent: "",
    isThisHnPatient: "",
    callerName: "",
    callerRelationshipPatient: "",
    nurseStation: "",
    callBackNumber: "",
    callerNeedCallback: "No",
    message: "",
    messageSentTo: "",
    callPatchedTo: "",
    onCallProvider: "",
    onCallProviderResponse: "",
    providerResponseDateTime: "",
    createdBy: sessionStorage.getItem("userName") || "",
    modifiedBy: "",
    roomNumber: "",
    policyNumber: "",
    healthPlan: "",
    attachmentLinks: "",
    callBackNumberExt: "",
    dateTimeCreated: "",
    skilledOrCustodial: "",
    patientHomeAddress: "",
  };
  const { formatPhoneNumber } = useMyContext();

  const callTypeList = usePickList(25).data;
  const LevelOfCareOptions = usePickList(39).data;

  const callTypeLabel = (callType) => {
    const callValue = callTypeList?.find((item) => item.value === callType);
    return callValue ? callValue.label : "";
  };

  const [editRecord, setEditRecord] = useState(initialReqState);
  const [query, setQuery] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data, mutate, isLoading } = useSWR(
    `getTriageRecord?id=${props.mbiid}`,
    fetcherhn
  );

  const { data: nppa } = useSWR("GetNpPaList", fetcherhn);

  const handleCancel = (e) => {
    setIsModalOpen(false);
    setEditRecord(initialReqState);
  };

  const handleModalClose = (e) => {
    setEditRecord(initialReqState);
    setMail(false);
  };

  const handleOk = async (e) => {
    if (e) {
      mutate();
    }
    setIsModalOpen(false);
    setEditRecord(initialReqState);
  };

  const edit = (record) => {
    setIsModalOpen(true);
    setEditRecord(record);
  };

  const handleDelete = async (record) => {
    const body = { ...record, modifiedBy: sessionStorage.getItem("userName") };
    const deleteResult = await deleteTriageCall(body);
    if (deleteResult.status) {
      mutate();
      openNotification(
        "success",
        "Deleted",
        "Triage Call Deleted Successfully!"
      );
    }
  };

  const columns = [
    {
      key: 1,
      title: "Call Type",
      dataIndex: "callType",
      width: 150,
      render: (_, data) => {
        return <div>{callTypeLabel(data.callType)}</div>;
      },
    },
    {
      key: 13,
      title: "Date/Time Created",
      dataIndex: "dateTimeCreated",
      render: (_, { dateTimeCreated, createdBy }) => {
        const [name] = createdBy.split("@");
        return (
          <>
            <div>
              {dateTimeCreated && /\d/.test(dateTimeCreated) // Check if dateTimeCreated contains numbers
                ? dayjs(dateTimeCreated).format("YYYY-MM-DD hh:mm A")
                : ""}
            </div>
            <div>{name}</div>
          </>
        );
      },
      width: 250,
    },
    {
      key: 2,
      title: "Is this urgent",
      dataIndex: "isThisUrgent",
      width: 150,
    },
    {
      key: 3,
      title: "Is this a HN Patient",
      dataIndex: "isThisHnPatient",
      width: 200,
    },
    {
      key: 3,
      title: "Level Of Care",
      dataIndex: "skilledOrCustodial",
      width: 200,
    },
    {
      key: 3,
      title: "Patient Home Address",
      dataIndex: "patientHomeAddress",
      width: 200,
    },
    {
      key: "nppa",
      title: "NP/PA",
      dataIndex: "nporpaLabel",
      width: 150,
      render: (_, data) => {
        return (
          <>
            <div>{data.nporpaLabel}</div>
            {data.nporpaLabel === "Other" && (
              <div className=" tw-text-gray-500">
                <MdEventNote size={20} className=" tw-mr-1 tw-text-gray-400" />
                {data.nporpaOther}
              </div>
            )}
          </>
        );
      },
    },
    {
      key: "pn",
      title: "Provider",
      dataIndex: "providerNameLabel",
      width: 150,
      render: (_, data) => {
        return (
          <>
            <div>{data.providerNameLabel}</div>
            {data.providerNameLabel === "Other" && (
              <div className=" tw-text-gray-500">
                <MdEventNote size={20} className=" tw-mr-1 tw-text-gray-400" />
                {data.providerNameOther}
              </div>
            )}
          </>
        );
      },
    },
    {
      key: "fn",
      title: "Facility",
      dataIndex: "facilityName",
      width: 200,
    },
    {
      key: 4,
      title: "Health Plan / Medical Group",
      dataIndex: "healthPlan",
      width: 150,
    },
    {
      key: 4,
      title: "Policy Number",
      dataIndex: "policyNumber",
      width: 150,
    },
    {
      key: 4,
      title: "Caller Name",
      dataIndex: "callerName",
      width: 150,
    },
    {
      key: 5,
      title: "Caller Relationship to Patient",
      dataIndex: "callerRelationshipPatient",
      width: 250,
    },
    {
      key: 5,
      title: "Room Number",
      dataIndex: "roomNumber",
      width: 250,
    },
    {
      key: 6,
      title: "Nurse Station",
      dataIndex: "nurseStation",
      width: 150,
    },
    {
      key: 7,
      title: "Call back number",
      dataIndex: "callBackNumber",
      width: 200,
      render: (_, { callBackNumber, callBackNumberExt }) => (
        <>
          {callBackNumber ? (
            <div
              style={{
                fontSize: "0.9rem",
                color: "var(--bs-gray-600)",
                marginBottom: "0.3rem",
                marginTop: "0.3rem",
                overflowWrap: "break-word",
              }}
            >
              <FaMobileAlt
                size={20}
                style={{
                  color: "var(--bs-gray-500)",
                  marginRight: "0.5rem",
                  marginTop: "-2px",
                }}
              />
              <a href={`tel:${formatPhoneNumber(callBackNumber)}`}>
                {formatPhoneNumber(callBackNumber)}
              </a>
              <p className="tw-text-black tw-mt-2">
                {callBackNumberExt && <> Ext. {callBackNumberExt}</>}
              </p>
            </div>
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      key: 8,
      title: "Does the caller need a call back",
      dataIndex: "callerNeedCallback",
      width: 150,
    },
    {
      key: 9,
      title: "Message",
      dataIndex: "message",
      width: 200,
    },
    {
      key: 10,
      title: "Message sent to",
      dataIndex: "messageSentTo",
      width: 150,
    },
    {
      key: 11,
      title: "Call Patched To",
      dataIndex: "callPatchedTo",
      width: 150,
    },
    {
      key: 14,
      title: "On Call Provider",
      dataIndex: "onCallProvider",
      width: 150,
    },
    {
      key: 12,
      title: "Provider Response",
      dataIndex: "onCallProviderResponse",
      width: 150,
    },
    {
      key: 9,
      title: "Attachment Links",
      dataIndex: "attachmentLinks",
      width: 200,
      render: (text) => (
        <>
          {text?.split(",").map((link, index) => (
            <span key={index}>
              <a href={link.trim()}>{link.trim()}</a>
              {index < text.split(",").length - 1 ? ", " : ""}
            </span>
          ))}
        </>
      ),
    },
    {
      key: 13,
      title: "Provider Response Date/Time",
      dataIndex: "providerResponseDateTime",
      render: (_, { providerResponseDateTime }) => {
        return (
          <>
            <div>
              {providerResponseDateTime &&
              dayjs(providerResponseDateTime).format("YYYY-MM-DD hh:mm A") !==
                "1900-01-01 12:00 AM"
                ? dayjs(providerResponseDateTime).format("YYYY-MM-DD hh:mm A")
                : ""}
            </div>
          </>
        );
      },
      width: 250,
    },
    {
      key: "key",
      title: "Operation",
      dataIndex: "guid",
      fixed: "right",
      width: 110,
      render: (_, record) => (
        <div>
          <Space>
            <Tooltip title="Edit">
              <FaEdit
                size={20}
                className=" tw-cursor-pointer"
                onClick={() => edit(record)}
                color="blue"
              />
            </Tooltip>
            <Tooltip title="Delete">
              <Popconfirm
                title="Ok to Delete?"
                onConfirm={() => handleDelete(record)}
                icon={
                  <WarningOutlined
                    style={{
                      color: "red",
                    }}
                  />
                }
              >
                <FaRegTrashAlt
                  size={20}
                  className=" tw-cursor-pointer"
                  color="red"
                />
              </Popconfirm>
            </Tooltip>
            <Tooltip title="Send Email">
              <FiSend
                size={20}
                className=" tw-cursor-pointer"
                onClick={() => sendDatatoEmail(record)}
                color="green"
              />
            </Tooltip>
          </Space>
        </div>
      ),
    },
  ];
  const [mail, setMail] = useState(false);

  const sendDatatoEmail = (record) => {
    setMail(true);
    setEditRecord(record);
  };

  const finalResult = data
    ? data.filter((item) => {
        return [
          "callTypeLabel",
          "providerNameLabel",
          "nporpaLabel",
          "isThisUrgent",
          "isThisHnPatient",
          "callerName",
          "facilityName",
          "callerRelationshipPatient",
          "nurseStation",
          "callBackNumber",
          "callerNeedCallback",
          "message",
          "messageSentTo",
          "callPatchedTo",
          "onCallProvider",
          "onCallProviderResponse",
          "providerResponseDateTime",
          "createdBy",
          "nporpaOther",
          "providerNameOther",
          "healthPlan",
          "policyNumber",
          "roomNumber",
          "callBackNumberExt",
          "attachmentLinks",
          "dateTimeCreated",
          "skilledOrCustodial",
          "patientHomeAddress",
        ].some((key) =>
          (
            item[key]?.toString()?.toLowerCase().replace(/_/g, " ") ?? ""
          ).includes(query?.toLowerCase().replace(/_/g, " ") ?? "")
        );
      })
    : data;

  return (
    <div>
      <div className="tw-flex tw-justify-between ">
        <p
          className="pt-3"
          style={{
            color: "#D2691E",
            fontWeight: "600",
          }}
        >
          Triage Call
        </p>

        {isLoading && <MyBackdropSpinner show={isLoading} />}

        <div className="tw-flex tw-justify-end  tw-mt-3 ">
          <Space>
            <Search
              className=" tw-mb-4"
              size="middle"
              placeholder="Search"
              onChange={(e) => setQuery(e.target.value)}
              allowClear
            />
            <Button
              type="primary"
              onClick={() => setIsModalOpen(true)}
              className="tw-mb-4 "
            >
              Add
            </Button>
          </Space>
        </div>
      </div>
      {isModalOpen && (
        <Drawer
          open={isModalOpen}
          title={"Add/Update Triage Call"}
          onClose={handleCancel}
          width={800}
        >
          <TriageCallAdd
            mbiid={props.mbiid}
            editRecord={editRecord}
            callback={handleOk}
            nppa={nppa}
            LevelOfCareOptions={LevelOfCareOptions}
            callTypeList={callTypeList}
          />
        </Drawer>
      )}
      <Table
        virtual
        bordered
        dataSource={finalResult}
        columns={columns}
        scroll={{
          x: 2000,
          y: 1000,
        }}
      />

      {mail && (
        <SendEmail
          open={mail}
          close={handleModalClose}
          editRecord={editRecord}
          modalTitle={"Triage Call"}
          patientName={props?.patient?.label}
        />
      )}

      {/* {summary && (
        <GetModalDetails
          keyProp={selectedKey}
          mbi={selectedRecord?.mbi}
          isVisible={summary}
          handleOk={() => setSummary(false)}
          handleCancel={() => setSummary(false)}
          modalTitle={"Triage Call"}
          apiNode={"getTriageRecord"}
          patientName={props?.patient?.label}
        />
      )} */}
    </div>
  );
};

export default TriageCall;
