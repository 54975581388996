import { axiosInstance } from "./AxiosInstance";

export async function getHRRPatients(filter) {
  return axiosInstance
    .post("/api/HighNeedsPatient", filter)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
export async function patient360 (filter) {
  return axiosInstance
    .post("/api/Patient360", filter)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
export async function getPatient360(mbiid) {
  return axiosInstance
    .get("/api/patient360", {
      params: {
        mbiID: mbiid,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    })
  }