import React, { Suspense } from "react";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { NavMenu } from "./NavMenu";
import { Container } from "react-bootstrap";
import { Route, Routes } from "react-router-dom";
import AppRoutes from "../AppRoutes";
import { UnauthenticatedHome } from "./UnauthenticatedHome";
import Footer from "./Footer";
import SessionTimeOut from "./Login/SessionTimeOut";

export const Layout = (props) => {
  const footerFallback = (
    <>
      <p className="placeholder-glow">
        <span className="placeholder col-6"></span>
      </p>
    </>
  );
  return (
    <>
      <NavMenu />
      <UnauthenticatedHome />
      <AuthenticatedTemplate>
        <SessionTimeOut />
        <div className="tw-px-20">
          <Routes>
            {AppRoutes.map((route, index) => {
              const { element, ...rest } = route;
              return <Route key={index} {...rest} element={element} />;
            })}
          </Routes>
        </div>
      </AuthenticatedTemplate>
      <Suspense fallback={<div>Loading</div>}>
        <Footer></Footer>
      </Suspense>
    </>
  );
};
