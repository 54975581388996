import { Modal, Table } from "antd";
import React, { useEffect, useState } from "react";
import PageTitle from "../Common/PageTitle";
import * as ScoreApi from "../../api/scoreApi";
import { useReactToPrint } from "react-to-print";
import { AiFillPrinter } from "react-icons/ai";
import { useMyContext } from "../../common/elements/myContext";
import { Button } from "antd";

import Search from "antd/es/input/Search";
import { CSVLink } from "react-csv";
import { useNavigate } from "react-router-dom";
import MyBackdropSpinner from "../../common/elements/myBackdropSpinner";

const HCCAllPatientReport = (props) => {
  const navigate = useNavigate();

  const [spinner, setSpinner] = useState(false);

  const [scorePredictionMaster, setScorePredictionMaster] = useState([]);
  const [size, setSize] = useState("large"); // default is 'middle'
  const [uniqueNames, setUniqueNames] = useState([]);
  const [sortedInfo, setSortedInfo] = useState({});
  const [filterItems, setFilterItems] = useState({});
  const [query, setQuery] = useState("");

  const { getFormattedDateTimeDayJs } = useMyContext();

  const [scorePredictionColumns, setscorePredictionColumns] = useState([]);

  //Table Properties
  const [resetDefaultFilter, setResetDefaultFilter] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [request, setRequest] = useState({
    providerGroupID: "",
    renderingProviderID: "",
    facilityId: "",
    region: "",
    userName: "",
    fromDate: "2021-01-01T12:12:11.477Z",
    toDate: new Date(),
  });

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    getPredictionRiskReport(request);
  }, []);

  const tableRef = React.useRef(null);

  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
  });

  const getPredictionRiskReport = async (filter) => {
    setSpinner(true);
    try {
      const response = await ScoreApi.getScorePredictionReport(filter);
      let responseData = response?.data?.recordList;
      const uniqueNameTemp = [
        ...new Set(
          responseData?.map((item) => {
            return item.lastName + "," + item.firstName;
          })
        ),
      ];

      //Todo : Move this list logic alternate method
      //Full Name
      const uniqueColumnList = [];

      uniqueNameTemp.forEach((element) => {
        uniqueColumnList.push({
          text: element,
          value: element,
        });
      });

      //Reviewer
      const uniqueReviewerTemp = [
        ...new Set(responseData?.map((item) => item.physicianName)),
      ];

      const uniqueReviewerList = [];

      uniqueReviewerTemp.forEach((element) => {
        uniqueReviewerList.push({
          text: element,
          value: element,
        });
      });

      //Region
      const uniqueRegionTemp = [
        ...new Set(responseData?.map((item) => item.region)),
      ];

      const uniqueRegionList = [];

      uniqueRegionTemp.forEach((element) => {
        uniqueRegionList.push({
          text: element,
          value: element,
        });
      });

      //Facility
      const uniqueFacilityTemp = [
        ...new Set(responseData?.map((item) => item.facilityName)),
      ];

      const uniqueFacilityList = [];

      uniqueFacilityTemp.forEach((element) => {
        uniqueFacilityList.push({
          text: element,
          value: element,
        });
      });

      //MBI
      const uniqueMbiTemp = [
        ...new Set(responseData?.map((item) => item.mbiid)),
      ];

      const uniqueMbiList = [];

      uniqueMbiTemp.forEach((element) => {
        uniqueMbiList.push({
          text: element,
          value: element,
        });
      });

      //ccmd provider
      const uniqueProviderTemp = [
        ...new Set(responseData?.map((item) => item.physicianName)),
      ];

      const uniqueProviderList = [];

      uniqueProviderTemp.forEach((element) => {
        uniqueProviderList.push({
          text: element,
          value: element,
        });
      });

      //rendering provider
      const uniqueRenderingProviderTemp = [
        ...new Set(responseData?.map((item) => item.renderingProviderName)),
      ];

      const uniqueRenderingProviderList = [];

      uniqueRenderingProviderTemp.forEach((element) => {
        uniqueRenderingProviderList.push({
          text: element,
          value: element,
        });
      });

      //provider Group
      const uniqueProviderGroupTemp = [
        ...new Set(responseData?.map((item) => item.providerGroupName)),
      ];

      const uniqueProviderGroupList = [];

      uniqueProviderGroupTemp.forEach((element) => {
        uniqueProviderGroupList.push({
          text: element,
          value: element,
        });
      });

      //Eligibility
      const uniqueEligibilityTemp = [
        ...new Set(responseData?.map((item) => item.isEligible)),
      ];

      const uniqueEligibilityList = [];

      uniqueEligibilityTemp.forEach((element) => {
        uniqueEligibilityList.push({
          text: element,
          value: element,
        });
      });

      setUniqueNames(uniqueColumnList);
      setScorePredictionMaster(responseData);

      const mySort = (a, b, key) => {
        const valueA = a[key] || "";
        const valueB = b[key] || "";
        return valueA.localeCompare(valueB);
      };

      setscorePredictionColumns([
        {
          title: "MBI",
          dataIndex: "mbiid",
          fixed: "left",
          width: 100,
          sorter: (a, b) => {
            return mySort(a, b, "mbiid");
          },
          //filters: uniqueMbiList,
          filterSearch: true,
          onFilter: (value, record) => record?.mbiid?.indexOf(value) === 0,
        },
        {
          title: "Name",
          dataIndex: "fullName",
          fixed: "left",
          width: 200,
          sorter: (a, b) => {
            return mySort(a, b, "fullName");
          },
          ellipsis: true,
          //filters: uniqueColumnList,
          onFilter: (value, record) => record?.fullName?.indexOf(value) === 0,
          render: (columnItem, rowItem) => {
            return (
              <a
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/ScoreNew/${rowItem.key}`);
                }}
                href="#"
              >
                {columnItem}
              </a>
            );
          },
        },
        {
          title: "Age",
          dataIndex: "age",
          sorter: (a, b) => a.age - b.age,
          width: 100,
        },
        {
          title: "Gender",
          dataIndex: "gender",
          width: 100,
          sorter: (a, b) => {
            return mySort(a, b, "gender");
          },
        },
        {
          title: "CCMD Provider",
          dataIndex: "physicianName",
          width: 170,
          //filters: uniqueProviderList,
          onFilter: (value, record) =>
            record?.physicianName?.indexOf(value) === 0,
          sorter: (a, b) => {
            return mySort(a, b, "physicianName");
          },
        },
        {
          title: "Rendering Provider",
          dataIndex: "renderingProviderName",
          width: 170,
          //filters: uniqueRenderingProviderList,
          onFilter: (value, record) =>
            record?.renderingProviderName?.indexOf(value) === 0,
          sorter: (a, b) => {
            return mySort(a, b, "renderingProviderName");
          },
        },
        {
          title: "Provider Group",
          dataIndex: "providerGroupName",
          width: 170,
          //filters: uniqueProviderGroupList,
          onFilter: (value, record) =>
            record?.providerGroupName?.indexOf(value) === 0,
          sorter: (a, b) => {
            return mySort(a, b, "providerGroupName");
          },
        },
        {
          title: "Facility",
          dataIndex: "facilityName",
          width: 150,
          //filters: uniqueFacilityList,
          onFilter: (value, record) =>
            record?.facilityName?.indexOf(value) === 0,
          sorter: (a, b) => {
            return mySort(a, b, "facilityName");
          },
        },
        {
          title: "Region",
          dataIndex: "region",
          width: 100,
          //filters: uniqueRegionList,
          onFilter: (value, record) => {
            return record?.region?.indexOf(value) === 0;
          },
          sorter: (a, b) => {
            return mySort(a, b, "region");
          },
        },
        // {
        //   title: "CC ICD10s",
        //   dataIndex: "icd10List",
        //   width: 200,
        // },
        // {
        //   title: "Suspect ICD10s",
        //   dataIndex: "potentialIcd10List",
        //   width: 200,
        // },
        {
          title: "Total Score",
          dataIndex: "totalScore",
          sorter: (a, b) => a.totalScore - b.totalScore,
          width: 150,
        },
        {
          title: "Potential Score",
          dataIndex: "totalScoreWithPotential",
          sorter: (a, b) =>
            a.totalScoreWithPotential - b.totalScoreWithPotential,
          width: 150,
        },
        {
          title: "Eligibility",
          dataIndex: "isEligible",
          width: 120,
          //filters: uniqueEligibilityList,
          onFilter: (value, record) => record?.isEligible?.indexOf(value) === 0,
          sorter: (a, b) => {
            return mySort(a, b, "isEligible");
          },
        },
        {
          title: "Notes",
          dataIndex: "notes",
          width: 150,
        },
        {
          title: "Date/Time of Review",
          dataIndex: "requestTime",
          width: 200,
          sorter: (a, b) => {
            if (a.requestTime || "" < b.requestTime || "") {
              return -1;
            }
            if (a.requestTime || "" > b.requestTime || "") {
              return 1;
            }
            return 0;
          },
        },
        {
          title: "Name of Reviewer",
          dataIndex: "displayName",
          width: 170,
          sorter: (a, b) => {
            return mySort(a, b, "displayName");
          },
        },
      ]);

      responseData.map((el) => {
        el.requestTime = getFormattedDateTimeDayJs(el.requestTime);
      });
      return responseData;
    } catch (error) {
      console.error(error);
      setScorePredictionMaster([]);
    } finally {
      setSpinner(false);
    }
  };

  const onChange = (pagination, filters, sorter, extra) => {};

  //Todo : convert this into different Logic

  const filterLabel = {
    region: "Region",
    mbiid: "MBI",
    isEligible: "Eligible",
    fullName: "Name",
    physicianName: "CCMD Provider",
    providerGroup: "Provider Group",
    facilityName: "Facility",
  };

  const tableFiltered = scorePredictionMaster.filter((item) => {
    return Object.keys(item).some((key) =>
      item[key]?.toString().toLowerCase().includes(query?.toLowerCase())
    );
  });

  const expandedRowRender = (record) => {
    const nestedColumns = [
      {
        title: "ICD10 Code",
        dataIndex: "icd10",
        key: "icd10",
      },
      {
        title: "ICD10 Description",
        dataIndex: "icdDescription",
        key: "icdDescription",
      },
      {
        title: "HCC",
        dataIndex: "hcc",
        key: "hcc",
      },
      {
        title: "HCC Description",
        dataIndex: "description",
        key: "description",
      },
      {
        title: "Source",
        dataIndex: "icD10SourceAddnlInfo",
        key: "icD10SourceAddnlInfo",
      },
      {
        title: "Potential",
        dataIndex: "isPotential",
        key: "isPotential",
      },
    ];

    return (
      <Table
        bordered
        dataSource={record.icD10Codes} // Use the nested data for the nested table
        columns={nestedColumns}
        pagination={false} // Optional: Set to false if you don't want pagination in the nested table
      />
    );
  };

  const csvData = tableFiltered.map((item) => ({
    MBI: item.mbiid,
    Name: item.fullName,
    Age: item.age,
    Gender: item.gender,
    CCMDProvider: item.physicianName,
    RenderingProvider: item.renderingProviderName,
    ProviderGroup: item.providerGroupName,
    Facility: item.facilityName,
    Region: item.region,
    CCICD10s: item.icd10List,
    SuspectICD10s: item.potentialIcd10List,
    TotalScore: item.totalScore,
    PotentialScore: item.totalScoreWithPotential,
    Eligibility: item.isEligible,
    Notes: item.notes,
    DateTimeofReview: getFormattedDateTimeDayJs(item.requestTime),
    NameofReviewer: item.displayName,
  }));

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const handleExpand = (expanded, record) => {
    if (expanded) {
      setExpandedRowKeys([record.key]);
    } else {
      setExpandedRowKeys([]);
    }
  };

  return (
    <>
      <PageTitle title={"HCC All Patient Report"} />
      {spinner && <MyBackdropSpinner show={spinner} />}

      <div className="d-flex justify-content-between">
        <div className="d-flex">
          {/* {Object.keys(filterItems).length > 0 && (
            <div
              className="p-2"
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                setFilterItems({});
              }}
            >
              <Tag
                className=""
                icon={<AiOutlineCloseCircle color="black" size={18} />}
                color="default"
              >
                <span className="ps-2">Clear Filters </span>
              </Tag>
            </div>
          )}

          <div className="p-2">
            {Object.keys(filterItems)?.map((item) => {
              return (
                filterItems[item]?.length > 0 && (
                  <Tag
                    className=""
                    icon={<AiOutlineCloseCircle color="black" size={18} />}
                    color="processing"
                  >
                    <span className="ps-2">
                      {filterLabel[item]} :{" "}
                      {filterItems[item]?.map((innerItem, index) => {
                        return (
                          <>
                            {index < filterItems[item]?.length - 1
                              ? innerItem + ","
                              : innerItem}
                          </>
                        );
                      })}
                    </span>
                  </Tag>
                )
              );
            })}
            {Object.keys(filterItems).length > 0 && (
              <Button type="primary" onClick={showModal} size={size}>
                Save
              </Button>
            )}
          </div> */}
        </div>

        <div className="d-grid gap-2 my-2 d-md-flex justify-content-md-end">
          <Search
            className="col-md-8"
            size={size}
            placeholder="Search"
            onChange={(e) => setQuery(e.target.value)}
            allowClear
          />

          <Button onClick={handlePrint} icon={<AiFillPrinter />} size={size}>
            Print
          </Button>
          <CSVLink data={csvData} filename={"HighRiskReview_Report.csv"}>
            <Button size={size}>Export to CSV</Button>
          </CSVLink>
        </div>
      </div>

      <Table
        columns={scorePredictionColumns}
        dataSource={tableFiltered}
        expandable={{
          expandedRowRender: expandedRowRender,
          expandRowByClick: true,
          expandedRowKeys: expandedRowKeys,
          onExpand: handleExpand,
        }}
        rowKey="key"
        onChange={onChange}
        size="large"
        scroll={{ x: "calc(700px + 50%)" }}
      />
      <Table
        className="print-only-table"
        ref={tableRef}
        pagination={false}
        columns={scorePredictionColumns}
        dataSource={scorePredictionMaster}
        scroll={{ x: "max-content" }}
      />

      {/* <Modal
        title="Save Filter"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="row">
          <div className="col-md-12 pb-3">
            <MyTextBox
              label="Title"
              id="title"
              isRequired={true}
              maxLength="50"
            ></MyTextBox>
          </div>
          <div className="col-md-12 pb-3">
            <MyTextBox
              label="Description"
              id="description"
              isRequired={false}
              maxLength="50"
            ></MyTextBox>
          </div>
        </div>
      </Modal> */}
    </>
  );
};

export default HCCAllPatientReport;
