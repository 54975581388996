import React, { useEffect } from "react";
import { useState } from "react";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import { postAdmRadm } from "../../api/CareEventsApi";
import { openNotification } from "../../common/elements/myNotification";
import MyCustomSelect from "../../common/elements/myCustomSelect";
import MyTextBox from "../../common/elements/myTextbox";

const AddAdmReAdm = ({ editRecord, callback, nppa }) => {
  const [formData, setFormData] = useState(
    editRecord.guid
      ? {
          ...editRecord,
          modifiedBy: sessionStorage.getItem("userName"),
          createdDateTime: undefined,
        }
      : editRecord
  );
  const [disable, setDisable] = useState(false);
  const [selectedDoaTime, setSelectedDoaTime] = useState(
    editRecord.guid ? dayjs(editRecord?.dateOfAdmission) : dayjs()
  );

  const [admOpt, setAdmOpt] = useState(
    editRecord.admissionType ? editRecord.admissionType : "Admission"
  );

  const [skilledCust, setSkilledCust] = useState(
    editRecord.skilledOrCustodial ? editRecord.skilledOrCustodial : "Skilled"
  );

  const [npPa, setNpPa] = useState();
  const [provider, setProvider] = useState();
  const [isPhoneValid, setIsPhoneValid] = useState(true);

  useEffect(() => {
    if (editRecord.guid) {
      let npPaTemp = [];
      if (editRecord?.nporpaValue) {
        npPaTemp = nppa?.filter((item) => {
          return item.value === editRecord?.nporpaValue;
        });
        setNpPa(npPaTemp);
      }

      let providerTemp = [];
      if (editRecord?.providerNameValue) {
        providerTemp = nppa?.filter((item) => {
          return item.value === editRecord?.providerNameValue;
        });
        setProvider(providerTemp);
      }
    }
  }, [editRecord]);

  const onNpPaChange = (e) => {
    setNpPa(e);
    setFormData({
      ...formData,
      nporpaValue: e ? e.value : "",
      nporpaLabel: e ? e.label : "",
    });
    setPageError({ ...pageError, nppa: e ? "" : "Required" });
    if (e?.value !== "Other") {
      setFormData({
        ...formData,
        nporpaValue: e ? e.value : "",
        nporpaLabel: e ? e.label : "",
        nporpaOther: "",
      });
    }
  };

  const onProviderChange = (e) => {
    setProvider(e);
    setFormData({
      ...formData,
      providerNameLabel: e ? e.label : "",
      providerNameValue: e ? e.value : "",
    });
    setPageError({ ...pageError, provider: e ? "" : "Required" });
    if (e?.value !== "Other") {
      setFormData({
        ...formData,
        providerNameLabel: e ? e.label : "",
        providerNameValue: e ? e.value : "",
        providerNameOther: "",
      });
    }
  };

  const admOptChange = (e) => {
    setAdmOpt(e.currentTarget.value);
    setFormData({
      ...formData,
      admissionType: e ? e.currentTarget.value : "",
    });
  };

  const skilledCustChange = (e) => {
    setSkilledCust(e.currentTarget.value);
    setFormData({
      ...formData,
      skilledOrCustodial: e ? e.currentTarget.value : "",
    });
  };

  const [pageError, setPageError] = useState({});

  const handleInputChange = (name, value) => {
    if (name === "callBackNumberExt") {
      const numericValue = value.replace(/[^0-9]/g, "");
      const isValid = value === numericValue; // Check if the entire input is numeric

      setFormData({
        ...formData,
        [name]: numericValue,
      });

      setPageError({
        ...pageError,
        [name]: isValid ? "" : "Only numbers allowed",
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
      setPageError({ ...pageError, [name]: value ? "" : "Required" });
    }
  };

  const onDoaChange = (date, dateString) => {
    setSelectedDoaTime(date || "");
    setFormData({
      ...formData,
      dateOfAdmission: dateString ? dateString : "",
    });
    setPageError({
      ...pageError,
      dateOfAdmission: dateString ? "" : "Required",
    });
  };
  const onSubmit = (e) => {
    setPageError({});
    let pageErrorTemp = {};
    if (!formData?.dateOfAdmission) {
      pageErrorTemp.dateOfAdmission = formData?.dateOfAdmission
        ? ""
        : "Required";

      setPageError(pageErrorTemp);
      return;
    }

    for (let key in formData) {
      if (formData[key] === null) {
        formData[key] = "";
      }
      if (key === "skilledOrCustodial" && !formData[key]) {
        formData[key] = "skilled";
      }
    }

    onSave(e);
  };
  const onSave = async (e) => {
    setDisable(true);

    try {
      const response = await postAdmRadm(formData);
      if (response?.status === 200 && !formData?.guid) {
        openNotification(
          "success",
          "Added",
          "Admission / Re-Admission Added Successfully!"
        );
      } else if (formData?.guid) {
        openNotification(
          "success",
          "Updated",
          "Admission / Re-Admission Updated Successfully!"
        );
      } else {
        openNotification(
          "error",
          "Error",
          "Admission / Re-Admission Failed to Add!"
        );
      }
      setFormData(editRecord);
      setSelectedDoaTime(dayjs());
      callback(response);
    } catch (e) {
      return e;
    }
    setDisable(false);
  };

  return (
    <div className="tw-relative">
      {/* <label className="tw-font-bold" htmlFor="dateTime">
        Admission / Re-Admission
      </label> */}
      <label className="tw-font-bold" htmlFor="dateTime">
        Admission / Re-Admission
      </label>
      <fieldset className="fw-normal">
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="adm"
            checked={admOpt === "Admission"}
            id="adm"
            value={"Admission"}
            onChange={admOptChange}
          />
          <label className="form-check-label" htmlFor="adm">
            Admission
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="reAdm"
            checked={admOpt === "Re-Admission"}
            id="reAdm"
            value={"Re-Admission"}
            onChange={admOptChange}
          />
          <label className="form-check-label" htmlFor="reAdm">
            Re-Admission
          </label>
        </div>
      </fieldset>
      <div>
        <label className="tw-font-bold" htmlFor="dateTime">
          Date
        </label>
        <DatePicker
          className="form-control tw-mb-3"
          onChange={onDoaChange}
          format={"YYYY-MM-DD"}
          value={selectedDoaTime}
          style={{
            border: pageError?.dateOfAdmission ? "1px solid red" : "",
          }}
          id="dateOfAdmission"
        />
      </div>
      <div>
        <label className="tw-font-bold" htmlFor="dateTime">
          Skilled / Custodial
        </label>
        <fieldset className="fw-normal">
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="skilled"
              checked={skilledCust === "Skilled"}
              id="skilled"
              value={"Skilled"}
              onChange={skilledCustChange}
            />
            <label className="form-check-label" htmlFor="skilled">
              Skilled
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="custodial"
              checked={skilledCust === "Custodial"}
              id="custodial"
              value={"Custodial"}
              onChange={skilledCustChange}
            />
            <label className="form-check-label" htmlFor="custodial">
              Custodial
            </label>
          </div>
        </fieldset>
      </div>

      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="changeCondition">
          Patient Home Address
        </label>
        <input
          className=" form-control"
          id="patientLocation"
          value={formData?.patientLocation}
          onChange={(e) => handleInputChange("patientLocation", e.target.value)}
          maxLength={100}
        ></input>
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="patientPlan">
          Facility
        </label>
        <input
          className=" form-control"
          id="facility"
          value={formData?.facilityName}
          onChange={(e) => handleInputChange("facilityName", e.target.value)}
          maxLength={100}
        ></input>
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="patientPlan">
          Room Number
        </label>
        <input
          className=" form-control"
          id="roomNumber"
          value={formData?.roomNumber}
          onChange={(e) => handleInputChange("roomNumber", e.target.value)}
          maxLength={100}
        ></input>
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="patientPlan">
          Health Plan / Medical Group
        </label>
        <input
          className=" form-control"
          id="healthPlan"
          maxLength={200}
          value={formData?.healthPlan}
          onChange={(e) => handleInputChange("healthPlan", e.target.value)}
        ></input>
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="patientPlan">
          Policy Number
        </label>
        <input
          className=" form-control"
          id="policyNumber"
          maxLength={100}
          value={formData?.policyNumber}
          onChange={(e) => handleInputChange("policyNumber", e.target.value)}
        ></input>
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="patientPlan">
          SSN
        </label>
        <input
          className=" form-control"
          id="ssn"
          maxLength={100}
          value={formData?.ssn}
          onChange={(e) => handleInputChange("ssn", e.target.value)}
        ></input>
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="hospitalname">
          NP/PA
        </label>
        <MyCustomSelect
          selectOptions={nppa}
          myCallBack={onNpPaChange}
          selectedValue={npPa}
          id={"npPa"}
          //errorMessage={pageError?.nppa}
        />
      </div>
      {formData?.nporpaValue === "Other" && (
        <div className="tw-flex tw-flex-col tw-mb-3">
          <label className="tw-font-bold" htmlFor="nppa">
            Other NP/PA
          </label>
          <input
            className=" form-control"
            type="text"
            maxLength={100}
            id="npOrPaOther"
            value={formData?.nporpaOther}
            onChange={(e) => handleInputChange("nporpaOther", e.target.value)}
            style={{
              border: pageError?.nporpaOther ? "1px solid red" : "",
            }}
          />
        </div>
      )}
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="provider">
          Provider
        </label>
        <MyCustomSelect
          selectOptions={nppa}
          myCallBack={onProviderChange}
          selectedValue={provider}
          id={"provider"}
          //errorMessage={pageError?.provider}
        />
      </div>
      {formData?.providerNameValue === "Other" && (
        <div className="tw-flex tw-flex-col tw-mb-3">
          <label className="tw-font-bold" htmlFor="providerOther">
            Other Provider
          </label>
          <input
            className=" form-control"
            type="text"
            id="providerNameOther"
            maxLength={100}
            value={formData?.providerNameOther}
            onChange={(e) =>
              handleInputChange("providerNameOther", e.target.value)
            }
            style={{
              border: pageError?.providerNameOther ? "1px solid red" : "",
            }}
          />
        </div>
      )}
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="hospitalname">
          Caller Name
        </label>
        <input
          className=" form-control"
          type="text"
          id="callerName"
          maxLength={100}
          value={formData?.callerName}
          onChange={(e) => handleInputChange("callerName", e.target.value)}
          style={{
            border: pageError?.callerName ? "1px solid red" : "",
          }}
        />
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="hospitalname">
          Caller relationship to patient
        </label>
        <input
          className=" form-control"
          type="text"
          id="callerRelationshipPatient"
          maxLength={100}
          value={formData?.callerRelationshipPatient}
          onChange={(e) =>
            handleInputChange("callerRelationshipPatient", e.target.value)
          }
        />
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="hospitalname">
          Nurse Station
        </label>
        <input
          className=" form-control"
          type="text"
          id="nurseStation"
          maxLength={100}
          value={formData?.nurseStation}
          onChange={(e) => handleInputChange("nurseStation", e.target.value)}
        />
      </div>
      <div className="tw-flex tw-mb-3 tw-space-x-6">
        <div className="tw-w-1/2">
          <MyTextBox
            label={"Call Back Number#"}
            value={formData?.callBackNumber}
            errorMessage={pageError?.callBackNumber}
            setValue={(e) =>
              handleInputChange("callBackNumber", e.target.value)
            }
            isPhoneNumber={true}
            onValidChange={(e) => setIsPhoneValid(e)}
          />
        </div>
        <div className="">
          <label className="tw-font-bold" htmlFor="hospitalname">
            Ext
          </label>
          <input
            className=" form-control"
            type="text"
            id="callBackNumberExt"
            maxLength={6}
            value={formData?.callBackNumberExt}
            onChange={(e) =>
              handleInputChange("callBackNumberExt", e.target.value)
            }
            style={{
              border: pageError?.callBackNumberExt ? "1px solid red" : "",
            }}
          />
          {pageError?.callBackNumberExt && (
            <span
              className="text-danger"
              style={{ fontWeight: "normal", paddingLeft: "5px" }}
            >
              {pageError.callBackNumberExt}
            </span>
          )}
        </div>
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="hospitalname">
          Message
        </label>
        <textarea
          className=" form-control"
          type="text"
          id="message"
          maxLength={1000}
          value={formData?.message}
          onChange={(e) => handleInputChange("message", e.target.value)}
        />
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="hospitalname">
          Message Sent To
        </label>
        <input
          className=" form-control"
          type="text"
          id="messageSentTo"
          maxLength={100}
          value={formData?.messageSentTo}
          onChange={(e) => handleInputChange("messageSentTo", e.target.value)}
        />
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="hospitalname">
          Add Additional Links Here
        </label>
        <textarea
          className=" form-control"
          type="text"
          id="attachmentLinks"
          maxLength={1000}
          value={formData?.attachmentLinks}
          onChange={(e) => handleInputChange("attachmentLinks", e.target.value)}
        />
      </div>
      <div className="form-group tw-fixed tw-top-2 tw-right-10">
        <button
          className={
            !disable ? "btn btn-success " : "btn btn-success disabled "
          }
          onClick={onSubmit}
        >
          {editRecord?.guid ? "Update" : "Save"}
        </button>
        <button
          className="btn btn-secondary tw-ml-4"
          onClick={() => callback(false)}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default AddAdmReAdm;
