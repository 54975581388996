import React, { useEffect } from "react";

import Select from "react-select";
import CreatableSelect from "react-select/creatable";

const MyCustomSelectCreatable = ({
  selectOptions,
  selectedValue,
  myCallBack,
  defaultValue,
  placeholder,
}) => {
  const filterOptions = (inputValue) => {
    return selectOptions?.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const onChange = (option, actionMeta) => {
    myCallBack(option);
  };

  return (
    <CreatableSelect
      styles={{ menu: (baseStyles) => ({ ...baseStyles, zIndex: 100 }) }}
      isClearable
      value={selectedValue || "Select"}
      defaultValue={selectedValue || "Select"}
      onChange={onChange}
      options={selectOptions}
      placeholder={placeholder}
    />
  );
};

export default MyCustomSelectCreatable;
