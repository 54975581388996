import React, { useEffect, useState } from "react";
import { Segmented, Select, Table } from "antd";
import Search from "antd/es/input/Search";
import PageTitle from "../Common/PageTitle";

import * as ADTApi from "../../api/ADTApi";
import MyBackdropSpinner from "../../common/elements/myBackdropSpinner";
import { commoncolumns } from "./ADTAllColumns";
import dayjs from "dayjs";
import Medications from "../Medications/Medications";
import Conditions from "../Conditions/Conditions";

const ADT = ({ patientId }) => {
  const [tabState, setTabState] = useState("admit");
  const [allData, setAllData] = useState();
  const [searchData, setSearchData] = useState();
  const [admissionData, setAdmissionData] = useState();
  const [dischargeData, setDischargeData] = useState();
  const [spinner, setSpinner] = useState(false);
  const [query, setQuery] = useState();

  useEffect(() => {
    setSpinner(true);

    const disChargeresponse = ADTApi.getADTRecords(patientId);
    Promise.all([disChargeresponse]).then((values) => {
      let tempCurrent = [];
      let tempAdmit = [];
      let tempDischarge = [];
      //const admission = (value) => value.standardActionType == "Admission";
      //const discharge = (value) => value.standardActionType == "Discharge";

      values[0]?.data.forEach((data) => {
        tempAdmit.push({
          patientName: data.patientLastName + ", " + data.patientFirstName,
          actionType: data.actionType,
          facilityName: data.facilityName,
          origin: data.origin,
          admissionsource: data.admissionSource,
          admissionType: data.admissionType,
          origintype: data.originType,
          effdate: dayjs(data.effectiveDateTime).utc().format("YYYY-MM-DD"),
          dischargeStatus: data.dischargeStatus,
          stopbillingdate:
            data.stopBillingDate == null
              ? ""
              : dayjs(data.stopBillingDate).utc().format("YYYY-MM-DD"),
          destinationtype: data.destinationType,
          destination: data.destination,
          source: "PointClickCare",
        });
      });
      setAdmissionData(tempAdmit);

      // values[0]?.data.forEach((data) => {
      //   tempDischarge.push({
      //     patientName: data.patientLastName + ", " + data.patientFirstName,
      //     actionType: data.actionType,
      //     facilityName: data.facilityName,
      //     dateOfBirth: dayjs(data.dateOfBirth).format("YYYY-MM-DD"),
      //     actioncode: data.actionCode,
      //     dischargeStatus: data.dischargeStatus,
      //     outPatient: data.outPatient.toString(),
      //     stopbillingdate: dayjs(data?.stopBillingDate)?.format("YYYY-MM-DD"),
      //     destinationtype: data.destinationType,
      //     destination: data.destination,
      //     effdate: dayjs(data.effectiveDateTime).format("YYYY-MM-DD"),
      //   });
      // });
      // setDischargeData(tempDischarge);
      if (tabState === "admit") {
        setSearchData(tempAdmit);
      } else if (tabState === "discharge") {
        setSearchData(tempDischarge);
      } else {
        setSearchData(tempCurrent);
      }
      setSpinner(false);
    });
  }, []);

  const admissioncolumns = [
    // {
    //   title: "Payer Name",
    //   key: "payerName",
    //   dataIndex: "payerName",
    // },

    // {
    //   title: "Payer Type",
    //   key: "payerType",
    //   dataIndex: "payerType",
    // },
    {
      title: "Admission Type",
      key: "admissionType",
      dataIndex: "admissionType",
    },
    {
      title: "Admission Source",
      key: "admissionsource",
      dataIndex: "admissionsource",
    },
    {
      title: "Origin",
      key: "origin",
      dataIndex: "origin",
    },
    {
      title: "Origin Type",
      key: "origintype",
      dataIndex: "origintype",
    },
  ];

  const dischargecolumns = [
    {
      title: "Discharge Status",
      key: "dischargeStatus",
      dataIndex: "dischargeStatus",
    },
    {
      title: "Destination Type",
      key: "destinationtype",
      dataIndex: "destinationtype",
    },
    {
      title: "Destination",
      key: "destination",
      dataIndex: "destination",
    },
    {
      title: "Stop Billing Date",
      key: "stopbillingdate",
      dataIndex: "stopbillingdate",
    },
  ];

  const items = [
    {
      key: "med",
      label: "Medications",
      children: (
        <div>
          <Medications />
        </div>
      ),
    },
    {
      key: "cond",
      label: "Conditions",
      children: (
        <div>
          {" "}
          <Conditions />
        </div>
      ),
    },
  ];

  const filteredItems = searchData?.filter((item) => {
    return Object.keys(item).some((key) =>
      (item[key]?.toString()?.toLowerCase() ?? "").includes(
        query?.toLowerCase() ?? ""
      )
    );
  });

  return (
    <div>
      {/* <PageTitle title={`PointClickCare ADT`} /> */}
      {spinner && <MyBackdropSpinner show={spinner} />}
      <div style={{ color: "var(--bs-gray-800)" }}>
        <div className="tw-flex tw-justify-between tw-border tw-mb-6">
          {/* <Segmented
            options={[
              { label: "Admission", value: "admit" },
              { label: "Discharge", value: "discharge" },
            ]}
            onChange={onSegmentChange}
          /> */}
          <div className="tw-flex tw-justify-end tw-space-x-2 tw-basis-2/5">
            {/* <Search
              className="col-md-6"
              size="medium"
              placeholder="Search"
              allowClear
              onChange={(e) => setQuery(e.target.value)}
            /> */}
          </div>
        </div>
        <div className="md">
          <Table
            className="table-striped-rows"
            columns={
              tabState === "admit" ? [...commoncolumns] : [...commoncolumns]
            }
            dataSource={filteredItems}
            size="large"
            bordered
            scroll={{ x: "2000px" }}
            pagination={{
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ADT;
