import React from 'react';
import * as pbiReport from "../PowerBIReports";


 const ClinicalDashboard = () => {
  return (
    <iframe
      id="iFrame"
      src={pbiReport.ClinicalExecutiveDashboard}
      title="Clinical Executive Dashboard"
      width="100%"
      height="750"
      frameBorder="0"
      allowFullScreen
    ></iframe>
  );
}
export default ClinicalDashboard;