import {
  Button,
  Drawer,
  Popconfirm,
  Skeleton,
  Space,
  Table,
  Tooltip,
} from "antd";
import Search from "antd/es/input/Search";
import React, { useState } from "react";
import useSWR from "swr";
import { fetcherhn } from "../../services/fectcher";
import { WarningOutlined } from "@ant-design/icons";
import { openNotification } from "../../common/elements/myNotification";
import { OrderRequestAdd } from "./OrderRequestAdd";
import { deleteOrderRequest } from "../../api/CareEventSCPApi";
import dayjs from "dayjs";
import { handleToggle } from "../../common/elements/toggleSeeMore";
import { FaEdit, FaRegTrashAlt } from "react-icons/fa";
import { FiSend } from "react-icons/fi";
import SendEmail from "../../common/elements/SendEmail";

export const OrderRequest = (props) => {
  const intialReqState = {
    key: "",
    guid: "",
    mbi: props.mbiid,
    urgent: "",
    allergy: "",
    palliativeProviderOrder: "",
    dateOfOrderSubmoission: "",
    palliativeProviderName: "",
    reasonForOrder: "",
    IPA: "",
    serviceProvider: "",
    authoriastionNo: "",
    orderStatus: "",
    comments: "",
    createdBy: sessionStorage.getItem("userName"),
    modifiedBy: "",
  };

  const [editRecord, setEditRecord] = useState(intialReqState);
  const [query, setQuery] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data, mutate, isLoading } = useSWR(
    `GetOrderRequest?id=${props.mbiid}`,
    fetcherhn
  );

  const handleCancel = (e) => {
    setIsModalOpen(false);
    setEditRecord(intialReqState);
  };

  const [mail, setMail] = useState(false);

  const sendDatatoEmail = (record) => {
    setMail(true);
    setEditRecord(record);
  };

  const handleOk = async (e) => {
    if (e) {
      mutate();
    }
    setIsModalOpen(false);
    setEditRecord(intialReqState);
  };

  const handleModalClose = (e) => {
    setEditRecord(intialReqState);
    setMail(false);
  };

  const edit = (record) => {
    setIsModalOpen(true);
    setEditRecord(record);
  };
  const handleDelete = async (record) => {
    const body = { ...record, modifiedBy: sessionStorage.getItem("userName") };
    const deleteResult = await deleteOrderRequest(body);
    if (deleteResult.status) {
      mutate();
      openNotification(
        "success",
        "Deleted",
        "Order/Request Template Deleted Successfully!"
      );
    }
    setEditRecord(intialReqState);
  };

  const [expandedRows, setExpandedRows] = useState([]);

  const columns = [
    {
      key: 1,
      title: "Urgent",
      dataIndex: "urgent",
    },
    {
      key: 2,
      title: "Allergy",
      dataIndex: "allergy",
    },
    {
      key: 3,
      title: "Palliative Provider's Order",
      dataIndex: "palliativeProviderOrder",
    },
    {
      key: 4,
      title: "Date of Order Submission",
      dataIndex: "dateOfOrderSubmoission",
      width: 120,
      render: (_, { dateOfOrderSubmoission }) => {
        return (
          <div>
            {dayjs(dateOfOrderSubmoission).format("YYYY-MM-DD") !==
            "1900-01-01" ? (
              dayjs(dateOfOrderSubmoission).format("YYYY-MM-DD")
            ) : (
              <>
                {dayjs(dateOfOrderSubmoission).format("YYYY-MM-DD")}

                <span className="tw-ml-2" style={{ color: "red" }}>
                  <WarningOutlined />
                </span>
              </>
            )}
          </div>
        );
      },
    },
    {
      key: 5,
      title: "Name of Ordering Palliative Provider",
      dataIndex: "palliativeProviderName",
    },
    {
      key: 6,
      title: "Reason for Order",
      dataIndex: "reasonForOrder",
    },
    {
      key: 7,
      title: "IPA",
      dataIndex: "IPA",
    },
    {
      key: 8,
      title: "Servicing Provider",
      dataIndex: "serviceProvider",
    },
    {
      key: 9,
      title: "Authorization Number",
      dataIndex: "authoriastionNo",
    },
    {
      key: 10,
      title: "Order Status",
      dataIndex: "orderStatus",
    },
    {
      key: 11,
      title: "Comments",
      dataIndex: "comments",
      width: 500,
      render: (text, record) => {
        const isExpanded = expandedRows.includes(record.key);
        const content =
          text.length > 100 ? (
            <span>
              {isExpanded ? text : `${text.substring(0, 100)}...`}
              <Button
                type="link"
                onClick={() => handleToggle(record.key, setExpandedRows)}
              >
                {isExpanded ? "See less" : "See more"}
              </Button>
            </span>
          ) : (
            text
          );

        return <div>{content}</div>;
      },
    },
    {
      key: 12,
      title: "Created By",
      dataIndex: "createdBy",
      render: (createdBy) => <div>{createdBy.split("@")[0]}</div>,
    },
    {
      key: "key",
      title: "Operation",
      dataIndex: "guid",
      fixed: "right",
      width: 110,
      render: (_, record) => (
        <div>
          <Space>
            <Tooltip title="Edit">
              <FaEdit
                size={20}
                className=" tw-cursor-pointer"
                onClick={() => edit(record)}
                color="blue"
              />
            </Tooltip>
            <Tooltip title="Delete">
              <Popconfirm
                title="Ok to Delete?"
                onConfirm={() => handleDelete(record)}
                icon={
                  <WarningOutlined
                    style={{
                      color: "red",
                    }}
                  />
                }
              >
                <FaRegTrashAlt
                  size={20}
                  className=" tw-cursor-pointer"
                  color="red"
                />
              </Popconfirm>
            </Tooltip>
            <Tooltip title="Send Email">
              <FiSend
                size={20}
                className=" tw-cursor-pointer"
                onClick={() => sendDatatoEmail(record)}
                color="green"
              />
            </Tooltip>
          </Space>
        </div>
      ),
    },
  ];

  const finalResult = data
    ? data.filter((item) => {
        return [
          "urgent",
          "allergy",
          "palliativeProviderOrder",
          "dateOfOrderSubmoission",
          "palliativeProviderName",
          "reasonForOrder",
          "IPA",
          "serviceProvider",
          "authoriastionNo",
          "orderStatus",
          "comments",
          "createdBy",
        ].some((key) =>
          (item[key]?.toString()?.toLowerCase() ?? "").includes(
            query?.toLowerCase() ?? ""
          )
        );
      })
    : data;

  if (isLoading) {
    return <Skeleton active={isLoading} />;
  }

  return (
    <div>
      <div className="tw-flex tw-justify-between ">
        <p
          className="pt-3"
          style={{
            color: "#D2691E",
            fontWeight: "600",
          }}
        >
          Order/Request Template
        </p>

        <div className="tw-flex tw-justify-end  tw-mt-3 ">
          <Space>
            <Search
              className=" tw-mb-4"
              size="middle"
              placeholder="Search"
              onChange={(e) => setQuery(e.target.value)}
              allowClear
            />
            <Button
              type="primary"
              onClick={() => setIsModalOpen(true)}
              className="tw-mb-4 "
            >
              Add
            </Button>
          </Space>
        </div>
      </div>
      {isModalOpen && (
        <Drawer
          open={isModalOpen}
          title={"Add/Update Order/Request Template"}
          onClose={handleCancel}
          width={900}
        >
          <OrderRequestAdd callback={handleOk} editRecord={editRecord} />
        </Drawer>
      )}
      <Table
        bordered
        size="middle"
        dataSource={finalResult}
        columns={columns}
        virtual
        scroll={{
          x: 2000,
          y: 1000,
        }}
      />

      {mail && (
        <SendEmail
          open={mail}
          close={handleModalClose}
          editRecord={editRecord}
          modalTitle={"Order / Request Template"}
          patientName={props?.patient?.label}
        />
      )}
    </div>
  );
};
