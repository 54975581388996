import React, { useState } from "react";
import PageTitle from "../Common/PageTitle";
import * as pbiReport from "../PowerBIReports";

const HCCDiagnosisHistory = () => {
  const [filterValue, setFilterValue] = useState("SMITH, JAMES");

  const click = () => {
    let newurl =
      "https://app.powerbi.com/reportEmbed?reportId=199f87c4-1538-4f7f-8b41-3a22e90acc37&autoAuth=true&ctid=54f55ff8-42e3-48db-a263-aa717e16fe12&pageName=Visits&$filter=vw_DCE_AHS_AvoidableEDVisits/Patient_Name eq 'SMITH, JAMES'";
    let newurl1 =
      "https://app.powerbi.com/reportEmbed?reportId=199f87c4-1538-4f7f-8b41-3a22e90acc37&autoAuth=true&ctid=54f55ff8-42e3-48db-a263-aa717e16fe12&pageName=Visits";

    if (filterValue !== null && filterValue !== "") {
      newurl1 +=
        "&$filter=vw_DCE_AHS_AvoidableEDVisits/Patient_Name eq '" +
        filterValue +
        "'";
    }
    const report = document.getElementById("iFrame");
    report.src = newurl1;
  };
  return (
    <>
      <iframe
        id="iFrame"
        src={pbiReport.HCCDiagnosisHistoryReport}
        title="Single Patient HCC Capture Opportunity Drill Down"
        width="100%"
        height="750"
        frameBorder="0"
        allowFullScreen
      ></iframe>
    </>
  );
};

export default HCCDiagnosisHistory;
