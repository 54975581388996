export const commoncolumns = [
  {
    title: "Patient Name",
    key: "patientName",
    dataIndex: "patientName",
    ellipsis: true,
    width: 200,
    // fixed: "left",
  },
  {
    title: "Facility Name",
    key: "facilityName",
    dataIndex: "facilityName",
    width: 250,
    ellipsis: true,
    // fixed: "left",
  },
  {
    title: "Action Type",
    key: "actionType",
    dataIndex: "actionType",
  },
  {
    title: "Effective Date",
    key: "effdate",
    dataIndex: "effdate",
    width: 100,
  },
  {
    title: "Admission Type",
    key: "admissionType",
    dataIndex: "admissionType",
  },
  {
    title: "Admission Source",
    key: "admissionsource",
    dataIndex: "admissionsource",
    width: 200,
  },
  {
    title: "Origin",
    key: "origin",
    dataIndex: "origin",
  },
  {
    title: "Origin Type",
    key: "origintype",
    dataIndex: "origintype",
    width: 200,
  },
  {
    title: "Discharge Status",
    key: "dischargeStatus",
    dataIndex: "dischargeStatus",
  },
  {
    title: "Destination Type",
    key: "destinationtype",
    dataIndex: "destinationtype",
    width: 200,
  },
  {
    title: "Destination",
    key: "destination",
    dataIndex: "destination",
  },
  {
    title: "Source",
    key: "source",
    dataIndex: "source",
    width: 130,
  },
];

export default {
  commoncolumns,
};
