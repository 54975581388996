import React, { useEffect, useState } from "react";
import * as TocApi from "../../api/ToCareApi";
import { DatePicker } from "antd";
import MyCustomSelect from "../../common/elements/myCustomSelect";
import MyTextBox from "../../common/elements/myTextbox";
import MyTextArea from "../../common/elements/myTextArea";
import dayjs from "dayjs";
import { openNotification } from "../../common/elements/myNotification";

const HospiceEdit = ({ editRecord, callback, son, ddo }) => {
  const [formData, setFormData] = useState(
    editRecord?.guid
      ? {
          ...editRecord,
          modifiedBy: sessionStorage.getItem("userName"),
        }
      : editRecord
  );

  const sourceOfNotificationOptions = son;
  const dischargeReasonOptions = ddo;

  const [selectedSon, setSelectedSon] = useState([]);
  const [dischargeReason, setDischargeReason] = useState();

  const [pageError, setPageError] = useState({});

  useEffect(() => {
    if (editRecord.guid) {
      let sonTemp = [];
      if (editRecord?.notificationSource) {
        sonTemp = sourceOfNotificationOptions?.filter((item) => {
          return item.value === editRecord?.notificationSource;
        });
        setSelectedSon(sonTemp[0]);
      }

      let ddTemp = [];
      if (editRecord?.dischargeReason) {
        ddTemp = dischargeReasonOptions?.filter((item) => {
          return item.value === editRecord?.dischargeReason;
        });
      }
      setDischargeReason(ddTemp[0]);
    }
  }, [editRecord]);

  const onStartDate = (date, dateString) => {
    setFormData({
      ...formData,
      startDate: dateString ? dateString : "",
    });
    setPageError({
      ...pageError,
      startDate: dateString ? "" : "Required",
    });
  };
  const onEndDate = (date, dateString) => {
    setFormData({
      ...formData,
      endDate: dateString ? dateString : "",
    });
    const admitDate = formData.startDate ? dayjs(formData.startDate) : null;
    const endDate = dateString ? dayjs(dateString) : null;
    if (admitDate && endDate && endDate.isBefore(admitDate)) {
      setPageError({
        ...pageError,
        endDate: "End date cannot be before start date",
      });
    } else {
      setPageError({
        ...pageError,
        endDate: "",
      });
    }
  };
  const onDeceasedDate = (date, dateString) => {
    setFormData({
      ...formData,
      deceasedDate: dateString ? dateString : "",
    });
    const deceasedDate = dateString ? dayjs(dateString) : null;
    const admitDate = formData.startDate ? dayjs(formData.startDate) : null;

    if (admitDate && deceasedDate && deceasedDate.isBefore(admitDate)) {
      setPageError({
        ...pageError,
        deceasedDate: "Deceased date cannot be before start date",
      });
    } else {
      setPageError({
        ...pageError,
        deceasedDate: "",
      });
    }
  };

  const onDischargeDate = (date, dateString) => {
    setFormData({
      ...formData,
      dischargeDate: dateString ? dateString : "",
    });
    const dischargeDate = dateString ? dayjs(dateString) : null;
    const admitDate = formData.startDate ? dayjs(formData.startDate) : null;

    if (admitDate && dischargeDate && dischargeDate.isBefore(admitDate)) {
      setPageError({
        ...pageError,
        dischargeDate: "Discharge date cannot be before start date",
      });
    } else {
      setPageError({
        ...pageError,
        dischargeDate: "",
      });
    }
  };
  const onSonChange = (e) => {
    setSelectedSon(e);
    setFormData({ ...formData, notificationSource: e ? e.value : "" });
    setPageError({
      ...pageError,
      notificationSource: e ? "" : "Required",
    });
    if (e?.value !== "Other" || e?.value !== "HIE_-_Other") {
      setFormData({
        ...formData,
        notificationSource: e ? e.value : "",
        notificationSourceOtherNotes: "",
      });
    }
  };
  const onDRChange = (e) => {
    setDischargeReason(e);
    setFormData({ ...formData, dischargeReason: e ? e.value : "" });
    setPageError({
      ...pageError,
      dischargeReason: e ? "" : "Required",
    });
    if (e?.value !== "Other") {
      setFormData({
        ...formData,
        dischargeReason: e ? e.value : "",
        dischargeReasonOtherNotes: "",
      });
    }
  };

  const handleInputChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
    setPageError({ ...pageError, [name]: value ? "" : "Required" });
  };

  const onSubmit = (e) => {
    let pageErrorTemp = {};
    if (
      !formData?.notificationSource ||
      !formData?.startDate ||
      ((formData?.notificationSource === "Other" ||
        formData?.notificationSource === "HIE_-_Other") &&
        !formData?.notificationSourceOtherNotes) ||
      (formData?.dischargeReason === "Other" &&
        !formData?.dischargeReasonOtherNotes) ||
      !formData?.agencyName ||
      !formData?.referringPhysician ||
      !formData?.hospiceDx
    ) {
      pageErrorTemp.notificationSource = formData?.notificationSource
        ? ""
        : "Required";
      pageErrorTemp.startDate = formData?.startDate ? "" : "Required";
      pageErrorTemp.agencyName = formData?.agencyName ? "" : "Required";
      pageErrorTemp.referringPhysician = formData?.referringPhysician
        ? ""
        : "Required";
      pageErrorTemp.hospiceDx = formData?.hospiceDx ? "" : "Required";
      if (
        formData?.notificationSource === "Other" ||
        formData?.notificationSource === "HIE_-_Other"
      ) {
        pageErrorTemp.notificationSourceOtherNotes =
          formData?.notificationSourceOtherNotes ? "" : "Required";
      }
      if (formData?.dischargeReason === "Other") {
        pageErrorTemp.dischargeReasonOtherNotes =
          formData?.dischargeReasonOtherNotes ? "" : "Required";
      }
    }

    const startDate = formData.startDate ? dayjs(formData.startDate) : null;

    const deceasedDate = formData.deceasedDate
      ? dayjs(formData.deceasedDate)
      : null;

    if (startDate && deceasedDate && deceasedDate.isBefore(startDate)) {
      pageErrorTemp.deceasedDate = "Deceased date cannot be before start date";
    }

    const dischargeDate = formData.dischargeDate
      ? dayjs(formData.dischargeDate)
      : null;
    if (startDate && dischargeDate && dischargeDate.isBefore(startDate)) {
      pageErrorTemp.dischargeDate =
        "Discharge date cannot be before start date";
    }

    const endDate = formData.endDate ? dayjs(formData.endDate) : null;
    if (startDate && endDate && endDate.isBefore(startDate)) {
      pageErrorTemp.endDate = "End date cannot be before start date";
    }
    setPageError(pageErrorTemp);

    if (Object.keys(pageErrorTemp).length === 0) {
      onSave(e);
    }
  };

  const onSave = async () => {
    callback(true);
    try {
      const response = await TocApi.postHospice(formData);
      if (!editRecord?.guid) {
        setFormData([]);
        if (response?.data?.mbi === formData?.mbi) {
          openNotification(
            "error",
            "Error",
            "There is a current Hospice without a End Date or Deceased Date. You cannot create a new record without closing the current one."
          );
        } else if (response?.status === 200) {
          openNotification("success", "Added", "Hospice Added Successfully!");
        } else {
          openNotification("error", "Error", "Hospice failed to add.");
        }
      } else {
        openNotification("success", "Updated", "Hospice Updated Successfully!");
      }
      callback(response);
    } catch (e) {
      console.log("e", e);
    }
  };

  return (
    <div className="tw-relative">
      <div className="col-md-10 col-lg-auto pb-4">
        <div className="form-group">
          <label htmlFor="selectedOption" className="fw-bold">
            Source of Notification
          </label>
          <MyCustomSelect
            selectOptions={sourceOfNotificationOptions}
            selectedValue={selectedSon}
            myCallBack={onSonChange}
            errorMessage={pageError?.notificationSource}
          />
        </div>
      </div>
      {(selectedSon?.value === "Other" ||
        selectedSon?.value === "HIE_-_Other") && (
        <div className="col-md-10 col-lg-auto pb-4">
          <div className="form-group">
            <MyTextBox
              maxLength={100}
              label={"Source of Notification Other"}
              value={formData.notificationSourceOtherNotes}
              setValue={(e) =>
                handleInputChange(
                  "notificationSourceOtherNotes",
                  e.target.value
                )
              }
              errorMessage={pageError?.notificationSourceOtherNotes}
            />
          </div>
        </div>
      )}
      <div className="col-md-10 col-lg-auto pb-4">
        <div className="form-group">
          <MyTextBox
            maxLength={100}
            label={"Hospice Agency Name"}
            value={formData.agencyName}
            setValue={(e) => handleInputChange("agencyName", e.target.value)}
            errorMessage={pageError?.agencyName}
          />
        </div>
      </div>
      <div className="col-md-10 col-lg-auto pb-4">
        <div className="form-group">
          <MyTextBox
            maxLength={100}
            label={"Referring Physician"}
            value={formData.referringPhysician}
            setValue={(e) =>
              handleInputChange("referringPhysician", e.target.value)
            }
            errorMessage={pageError?.referringPhysician}
          />
        </div>
      </div>
      <div className="col-md-10 col-lg-auto pb-4">
        <div className="form-group">
          <MyTextArea
            maxLength={1000}
            label={"Hospice Dx"}
            value={formData.hospiceDx}
            setValue={(e) => handleInputChange("hospiceDx", e)}
            errorMessage={pageError?.hospiceDx}
          />
        </div>
      </div>
      <div className="col-md-10 col-lg-auto pb-4">
        <div className="form-group fw-bold">
          <label>Hospice Start Date</label>
          <DatePicker
            className="form-control"
            placeholder="YYYY-MM-DD"
            format={"YYYY-MM-DD"}
            value={formData?.startDate ? dayjs(formData.startDate) : ""}
            onChange={onStartDate}
            style={{
              borderColor: pageError?.startDate ? "red" : "#ddd",
            }}
          />
        </div>
      </div>
      <div className="col-md-10 col-lg-auto pb-4">
        <div className="form-group fw-bold">
          <label>Hospice End Date</label>
          <DatePicker
            className="form-control"
            placeholder="YYYY-MM-DD"
            format={"YYYY-MM-DD"}
            value={formData?.endDate ? dayjs(formData.endDate) : ""}
            onChange={onEndDate}
            style={{
              borderColor: pageError?.endDate ? "red" : "#ddd",
            }}
          />
        </div>
        {pageError?.endDate && (
          <span
            className="text-danger"
            style={{ fontWeight: "normal", paddingLeft: "5px" }}
          >
            {pageError?.endDate}
          </span>
        )}
      </div>
      <div className="col-md-10 col-lg-auto pb-4">
        <div className="form-group">
          <label htmlFor="dischargeReason" className="fw-bold">
            Patient Disposition
          </label>
          <MyCustomSelect
            selectOptions={dischargeReasonOptions}
            selectedValue={dischargeReason}
            myCallBack={onDRChange}
            //errorMessage={pageError?.dischargeReason}
          />
        </div>
      </div>
      {dischargeReason?.value === "Other" && (
        <div className="col-md-10 col-lg-auto pb-4">
          <div className="form-group">
            <MyTextBox
              maxLength={100}
              label={"Patient Disposition Other"}
              value={formData.dischargeReasonOtherNotes}
              setValue={(e) =>
                handleInputChange("dischargeReasonOtherNotes", e.target.value)
              }
              errorMessage={pageError?.dischargeReasonOtherNotes}
            />
          </div>
        </div>
      )}
      <div className="col-md-10 col-lg-auto pb-4">
        <div className="form-group fw-bold">
          <label>Discharge Date</label>
          <DatePicker
            className="form-control"
            placeholder="YYYY-MM-DD"
            format={"YYYY-MM-DD"}
            onChange={onDischargeDate}
            value={formData?.dischargeDate ? dayjs(formData.dischargeDate) : ""}
            style={{ borderColor: pageError?.dischargeDate ? "red" : "#ddd" }}
          />
        </div>
        {pageError?.dischargeDate && (
          <span
            className="text-danger"
            style={{ fontWeight: "normal", paddingLeft: "5px" }}
          >
            {pageError?.dischargeDate}
          </span>
        )}
      </div>
      <div className="col-md-10 col-lg-auto pb-4">
        <div className="form-group fw-bold">
          <label>Deceased Date</label>
          <DatePicker
            className="form-control"
            placeholder="YYYY-MM-DD"
            format={"YYYY-MM-DD"}
            onChange={onDeceasedDate}
            value={formData?.deceasedDate ? dayjs(formData.deceasedDate) : ""}
            style={{ borderColor: pageError?.deceasedDate ? "red" : "#ddd" }}
          />
        </div>
        {pageError?.deceasedDate && (
          <span
            className="text-danger"
            style={{ fontWeight: "normal", paddingLeft: "5px" }}
          >
            {pageError?.deceasedDate}
          </span>
        )}
      </div>

      <div className="form-group tw-fixed tw-top-2 tw-right-10">
        <button
          className="btn btn-success"
          htmlFor="save"
          name={"Save"}
          type="submit"
          onClick={onSubmit}
        >
          {editRecord?.guid ? "Update" : "Save"}
        </button>
        <button
          className="btn btn-secondary tw-ml-4"
          htmlFor="close"
          name={"Close"}
          onClick={() => callback(true)}
        >
          {"Close"}
        </button>
      </div>
    </div>
  );
};

export default HospiceEdit;
