import React, { useState } from "react";
import TextArea from "antd/es/input/TextArea";
import MyTextBox from "../../common/elements/myTextbox";
import { openNotification } from "../../common/elements/myNotification";
import { addNurseRequest } from "../../api/CareEventSCPApi";
import { DatePicker } from "antd";
import dayjs from "dayjs";

const dateFormat = ["YYYY-MM-DD"];

export const NurseRequestAdd = ({ editRecord, callback }) => {
  const [formData, setFormData] = useState(
    editRecord?.guid
      ? {
          ...editRecord,
          modifiedBy: sessionStorage.getItem("userName"),
        }
      : editRecord
  );
  const [pageError, setPageError] = useState({});
  const [selectedDate, setSelectedDate] = useState();
  const [disable, setDisable] = useState(false);

  const handleInputChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
    setPageError({ ...pageError, [name]: value ? "" : "Required" });
  };

  const onDateChange = (date, dateString) => {
    setSelectedDate(date || "");
    setFormData({
      ...formData,
      dateRequestSent: dateString ? dateString : "",
    });
    setPageError({
      ...pageError,
      dateRequestSent: dateString ? "" : "Required",
    });
  };

  const onSubmit = (e) => {
    setPageError({});
    let pageErrorTemp = {};
    if (!formData?.requestingNurseName || !formData?.requestungDetails) {
      pageErrorTemp.requestingNurseName = formData?.requestingNurseName
        ? ""
        : "Required";
      pageErrorTemp.requestungDetails = formData?.requestungDetails
        ? ""
        : "Required";

      setPageError(pageErrorTemp);
      return;
    }
    setDisable(true);
    onSave(e);
  };

  const onSave = async (e) => {
    try {
      const response = await addNurseRequest(formData);
      if (response?.status === 200 && !formData?.guid) {
        openNotification(
          "success",
          "Added",
          "Nurse Request Template Added Successfully!"
        );
      } else if (formData?.guid) {
        openNotification(
          "success",
          "Updated",
          "Nurse Request Template Updated Successfully!"
        );
      } else {
        openNotification(
          "error",
          "Error",
          "Nurse Request Template Failed to Add!"
        );
      }

      setFormData(editRecord);
      setDisable(false);
      callback(response);
    } catch (e) {
      return e;
    }
  };

  return (
    <div className="tw-relative tw-px-2">
      <div className="col-md-11 col-lg-11">
        <div className="form-group tw-mb-3">
          <MyTextBox
            label={"Name of Requesting Nurse"}
            value={formData?.requestingNurseName}
            setValue={(e) =>
              handleInputChange("requestingNurseName", e.target.value)
            }
            errorMessage={pageError?.requestingNurseName}
            maxLength={100}
          />
        </div>
      </div>
      <div className="col-md-11 col-lg-11 form-group">
        <div className="tw-flex ">
          <label className="tw-font-bold" htmlFor="dateTime">
            Date request was sent to team
          </label>
        </div>
        <DatePicker
          use12Hours
          className="form-control tw-mb-3"
          onChange={onDateChange}
          format={dateFormat}
          value={
            formData?.dateRequestSent ? dayjs(formData.dateRequestSent) : ""
          }
        />
      </div>

      <div className="col-md-11 col-lg-11">
        <div className="form-group ">
          <label
            htmlFor="priority"
            className="tw-fw-bold tw-flex tw-justify-between"
          >
            <div className="tw-flex tw-items-center">
              <div className="fw-bold">Request Details</div>
            </div>
          </label>
          <TextArea
            autoSize={{
              minRows: 10,
              maxRows: 20,
            }}
            className="form-control "
            maxLength={"1000"}
            value={formData?.requestungDetails}
            onChange={(e) =>
              handleInputChange("requestungDetails", e.target.value)
            }
            style={{
              border: pageError?.requestungDetails ? "1px solid red" : "",
            }}
          />
        </div>
      </div>

      <div className="form-group tw-fixed tw-top-2 tw-right-10">
        <button
          className={!disable ? "btn btn-success" : "btn btn-success disabled"}
          onClick={onSubmit}
        >
          {editRecord?.guid ? "Update" : "Save"}
        </button>
        <button
          className="btn btn-secondary tw-ml-4"
          onClick={() => callback(false)}
        >
          Close
        </button>
      </div>
    </div>
  );
};
