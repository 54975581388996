import React, { useState } from "react";
import MyBackdropSpinner from "../../common/elements/myBackdropSpinner";
import dayjs from "dayjs";
import {
  formatOptionLabel,
  getLobColors,
} from "../../common/elements/myCustomSelect";
import useSWR from "swr";
import { useMyContext } from "../../common/elements/myContext";
import { Table, DatePicker, Space, Radio } from "antd";
import { fetcherhn } from "../../services/fectcher";
import Search from "antd/es/input/Search";
import { MdEventNote } from "react-icons/md";
import { lobList } from "../CCMDPatients/CCMDPatientsAll";
import PageTitle from "../Common/PageTitle";
import { renderExpandRow } from "../../common/elements/toggleSeeMore";
import { FaMobileAlt } from "react-icons/fa";
import DetailedCSV from "../../common/elements/DetailedCSV";

export const TriageCallLog = () => {
  const { RangePicker } = DatePicker;

  const [fromDate, setFromDate] = useState(
    dayjs().subtract(1, "day").format("YYYY-MM-DD")
  );
  const [lob, setLob] = useState("HN");

  const [toDate, setToDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [query, setQuery] = useState("");

  const { data, isLoading } = useSWR(
    `getTriageRecordsForDateRange?fromDate=${fromDate}&toDate=${toDate}&lob=${lob}`,
    fetcherhn
  );
  const [expandedRows, setExpandedRows] = useState([]);
  const [expandedRows1, setExpandedRows1] = useState([]);

  const { formatPhoneNumber } = useMyContext();

  const columns = [
    {
      key: 1,
      title: "Patient",
      dataIndex: "patientNamewithDOB",
      width: 300,
      render: (patientNamewithDOB) =>
        formatOptionLabel({ label: patientNamewithDOB }),
    },

    {
      key: 2,
      title: "Call Type",
      dataIndex: "callTypeLabel",
    },
    {
      key: 13,
      title: "Date/Time Created",
      dataIndex: "dateTimeCreated",
      render: (_, { dateTimeCreated, createdBy }) => {
        const [name] = createdBy.split("@");
        return (
          <>
            <div>
              {dateTimeCreated && /\d/.test(dateTimeCreated) // Check if dateTimeCreated contains numbers
                ? dayjs(dateTimeCreated).format("YYYY-MM-DD hh:mm A")
                : ""}
            </div>
            <div>{name}</div>
          </>
        );
      },
    },
    {
      title: "Is this Urgent",
      dataIndex: "isThisUrgent",
    },
    {
      key: "is",
      title: "Is this a HN Patient",
      dataIndex: "isThisHnPatient",
    },
    {
      title: "Level Of Care",
      dataIndex: "skilledOrCustodial",
    },
    {
      title: "Patient Home Address",
      dataIndex: "patientHomeAddress",
    },
    {
      key: "nppa",
      title: "NP/PA",
      dataIndex: "nporpaLabel",
      render: (_, data) => {
        return (
          <>
            <div>{data.nporpaLabel}</div>
            {data.nporpaLabel === "Other" && (
              <div className=" tw-text-gray-500">
                <MdEventNote size={20} className=" tw-mr-1 tw-text-gray-400" />
                {data.nporpaOther}
              </div>
            )}
          </>
        );
      },
    },
    {
      key: "pn",
      title: "Provider",
      dataIndex: "providerNameLabel",
      render: (_, data) => {
        return (
          <>
            <div>{data.providerNameLabel}</div>
            {data.providerNameLabel === "Other" && (
              <div className=" tw-text-gray-500">
                <MdEventNote size={20} className=" tw-mr-1 tw-text-gray-400" />
                {data.providerNameOther}
              </div>
            )}
          </>
        );
      },
    },
    {
      key: "fn",
      title: "Facility",
      dataIndex: "facilityName",
    },
    {
      key: "hpmg",
      title: "Health Plan / Medical Group",
      dataIndex: "healthPlan",
    },
    {
      key: "pn",
      title: "Policy Number",
      dataIndex: "policyNumber",
    },
    {
      key: 4,
      title: "Caller Name",
      dataIndex: "callerName",
    },
    {
      key: 5,
      title: "Caller Relationship to Patient",
      dataIndex: "callerRelationshipPatient",
    },
    {
      key: "rn",
      title: "Room Number",
      dataIndex: "roomNumber",
    },
    {
      key: 6,
      title: "Nurse Station",
      dataIndex: "nurseStation",
    },
    {
      key: 7,
      title: "Call back number",
      dataIndex: "callBackNumber",
      width: 200,
      render: (_, { callBackNumber, callBackNumberExt }) => (
        <>
          {callBackNumber ? (
            <div
              style={{
                fontSize: "0.9rem",
                color: "var(--bs-gray-600)",
                marginBottom: "0.3rem",
                marginTop: "0.3rem",
                overflowWrap: "break-word",
              }}
            >
              <FaMobileAlt
                size={20}
                style={{
                  color: "var(--bs-gray-500)",
                  marginRight: "0.5rem",
                  marginTop: "-2px",
                }}
              />
              <a href={`tel:${formatPhoneNumber(callBackNumber)}`}>
                {formatPhoneNumber(callBackNumber)}
              </a>
              <p className="tw-text-black tw-mt-2">
                {callBackNumberExt && <> Ext. {callBackNumberExt}</>}
              </p>
            </div>
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      key: 8,
      title: "Does the caller need a call back",
      dataIndex: "callerNeedCallback",
    },
    {
      key: 9,
      title: "Message",
      dataIndex: "message",
      render: (text, record) => (
        <div>
          {renderExpandRow(text, record, expandedRows1, setExpandedRows1)}
        </div>
      ),
    },
    {
      key: 10,
      title: "Message sent to",
      dataIndex: "messageSentTo",
    },
    {
      key: 11,
      title: "Call Patched To",
      dataIndex: "callPatchedTo",
    },
    {
      key: 12,
      title: "On Call Provider",
      dataIndex: "onCallProvider",
    },
    {
      key: 13,
      title: "Provider Response",
      dataIndex: "onCallProviderResponse",
      render: (text, record) => (
        <div>
          {renderExpandRow(text, record, expandedRows, setExpandedRows)}
        </div>
      ),
    },
    {
      key: 9,
      title: "Attachment Links",
      dataIndex: "attachmentLinks",
      width: 200,
      render: (text) => (
        <>
          {text?.split(",").map((link, index) => (
            <span key={index}>
              <a href={link.trim()}>{link.trim()}</a>
              {index < text.split(",").length - 1 ? ", " : ""}
            </span>
          ))}
        </>
      ),
    },
    {
      title: "Provider Response Date/Time",
      dataIndex: "providerResponseDateTime",
      render: (_, { providerResponseDateTime }) => {
        return (
          <>
            <div>
              {providerResponseDateTime &&
              dayjs(providerResponseDateTime).format("YYYY-MM-DD hh:mm A") !==
                "1900-01-01 12:00 AM"
                ? dayjs(providerResponseDateTime).format("YYYY-MM-DD hh:mm A")
                : ""}
            </div>
          </>
        );
      },
    },
  ];
  const finalResult = data
    ? data.filter((item) => {
        return [
          "patientNamewithDOB",
          "providerNameLabel",
          "providerNameOther",
          "nporpaLabel",
          "nporpaOther",
          "callTypeLabel",
          "isThisUrgent",
          "isThisHnPatient",
          "callerName",
          "facilityName",
          "callerRelationshipPatient",
          "nurseStation",
          "callBackNumber",
          "callerNeedCallback",
          "message",
          "messageSentTo",
          "callPatchedTo",
          "onCallProvider",
          "onCallProviderResponse",
          "providerResponseDateTime",
          "createdBy",
          "healthPlan",
          "policyNumber",
          "roomNumber",
          "callBackNumberExt",
          "attachmentLinks",
          "dateTimeCreated",
          "skilledOrCustodial",
          "patientHomeAddress",
        ].some((key) =>
          (item[key]?.toString()?.toLowerCase() ?? "").includes(
            query?.toLowerCase() ?? ""
          )
        );
      })
    : data;

  const filteredDetailData = finalResult?.filter((item) => {
    return Object?.keys(item).some((key) =>
      item[key]?.toString().toLowerCase().includes(query?.toLowerCase())
    );
  });

  if (isLoading) {
    return <MyBackdropSpinner spinner={true} />;
  }
  return (
    <div>
      <div className="tw-flex tw-justify-between tw-mb-4 ">
        <Space>
          <PageTitle title={"Triage Call Log"} />
          <Radio.Group
            onChange={(e) => setLob(e.target.value)}
            value={lob}
            className="tw-mb-3 tw-ml-2"
          >
            {lobList.map((i) => (
              <Radio key={i.value} value={i.value}>
                <span
                  className={`tw-py-0.5 tw-px-2 tw-text-xs tw-rounded ${getLobColors(
                    i.value
                  )}`}
                >
                  {i.label}
                </span>
              </Radio>
            ))}
          </Radio.Group>
        </Space>

        <div className="tw-flex tw-justify-end ">
          <Space>
            <Search
              placeholder="Search"
              onChange={(e) => setQuery(e.target.value)}
              allowClear
            />
            <RangePicker
              className="tw-w-full"
              format="YYYY-MM-DD"
              onChange={(date, dateString) => {
                setFromDate(dateString[0]);
                setToDate(dateString[1]);
                setQuery("");
              }}
              value={[
                fromDate ? dayjs(fromDate) : "",
                toDate ? dayjs(toDate) : "",
              ]}
            />
            <DetailedCSV
              detailData={filteredDetailData || []}
              event={"Triage Call"}
              fromDate={fromDate}
              toDate={toDate}
            />
          </Space>

          {/* <CSVLink data={csvData} filename={"report.csv"}>
            <BsFiletypeCsv size={"2em"} />
          </CSVLink> */}
        </div>
      </div>
      <Table
        size="middle"
        dataSource={finalResult}
        columns={columns}
        pagination={{
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
        }}
        scroll={{
          x: 3000,
        }}
      />
    </div>
  );
};
