import React from 'react';
import { loginRequest } from '../authConfig';
import { Button, Container } from 'react-bootstrap';
import ScoreNew from '../Score/ScoreNew';
import { AuthenticatedTemplate, useMsal, UnauthenticatedTemplate } from '@azure/msal-react';


function Login(props) {

    const { instance } = useMsal();

    const activeAccount = instance.getActiveAccount();

    const handleLoginClick = () => {
        instance.loginPopup({
            ...loginRequest,
            redirectUri: 'https://localhost:1991/',
        })
            .catch((error) => console.error(error));;
    }

    const handleLogoutPopup = () => {
        instance
            .logoutPopup({
                mainWindowRedirectUri: '/', // redirects the top level app after logout
                account: instance.getActiveAccount(),
            })
            .catch((error) => console.error(error));
    };

    return (
        <div>
            <AuthenticatedTemplate>
                {activeAccount ? (
                    <Container>
                        <Button variant="secondary" className="justify-content-end ml-auto" onClick={handleLogoutPopup}>Logout</Button>
                        <ScoreNew />
                    </Container>
                ) : null}
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <h5 className="card-title">Please sign-in to see your profile information.</h5>
                <button onClick={handleLoginClick}>Login</button>

            </UnauthenticatedTemplate>
        </div>
    );
}
export default Login;