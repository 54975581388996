import React, { useEffect, useState } from "react";
import { Modal, Table } from "antd";
import PageTitle from "../Common/PageTitle";
import * as raf from "../../api/ProspectiveRAFApi";
import ICDWithSourceSummary from "../Score/ICDWithSourceSummary";
import ICDWithSource from "../Score/ICDWithSource";
import MyTextBox from "../../common/elements/myTextbox";
import MyCustomSelect from "../../common/elements/myCustomSelect";

const elig = [
  { label: "CPA", value: "CPA" },
  { label: "CPD", value: "CPD" },
  { label: "CFD", value: "CFD" },
  { label: "CFA", value: "CFA" },
];
const genderList = [
  { label: "Male", value: "M" },
  { label: "Female", value: "F" },
];

const ProspectRAF = () => {
  const [requestBoby, setRequestBody] = useState({
    icd10_list: "C028",
    sex: "M",
    age: "10",
    elig: "CPA",
    medicaid: true,
    orec: "1",
  });

  const [icd10List, setIcd10List] = useState();
  const [selectedElig, setSelectedElig] = useState();
  const [medicaid, setMedicaid] = useState();
  const [selectedGender, setSelectedGender] = useState();
  const [finalScore, setFinalScore] = useState();
  const [finalScoreColumns, setFinalScoreColumns] = useState();

  useEffect(() => {
    // const icdPromise = ScoreApi.getIcd10CodesPromise();

    // Promise.resolve(icdPromise).then((values) => {

    // })
    const icdlist = localStorage.getItem("apiData");
    setIcd10List(icdlist);

    const response = raf.getProspectiveRAF(requestBoby);
    console.log("response", response?.data);
    setFinalScore(response?.data);

    setFinalScoreColumns([
      {
        title: "Score",
        dataIndex: "risk_score",
      },
      {
        title: "Score Adj",
        dataIndex: "risk_score_adj",
      },
    ]);
  }, []);

  const onEligChange = (e) => {
    setSelectedElig(e);
    setRequestBody({ ...requestBoby, elig: e.value });
  };

  const onSubmitClicked = async () => {
    const response = await raf.getProspectiveRAF(requestBoby);

    setFinalScore(response?.data);
  };

  return (
    <div style={{ minHeight: "90vh" }}>
      <PageTitle title={"Prospective RAF"} />
      <div className="row">
        <div className="col-md-6 col-lg-3 pb-3">
          <MyTextBox
            label={"ICD"}
            placeholder={"Enter ICD LIST Comma Sepearated"}
            setValue={(e) =>
              setRequestBody({ ...requestBoby, icd10_list: e.target.value })
            }
          />
        </div>
        <div className="col-md-6 col-lg-3 pb-3">
          <label style={{ fontWeight: "bold" }}>Eligible</label>
          <MyCustomSelect
            selectOptions={elig}
            selectedValue={selectedElig}
            myCallBack={onEligChange}
          />
        </div>
        <div className="col-md-6 col-lg-3 pb-3">
          <label style={{ fontWeight: "bold" }}>Gender</label>
          <MyCustomSelect
            selectOptions={genderList}
            selectedValue={selectedGender}
            myCallBack={(e) => {
              setRequestBody({ ...requestBoby, sex: e.value });
              setSelectedGender(e);
            }}
          />
        </div>
        <div className="form-group fw-bold col-md-6 col-lg-3 pb-3">
          <label htmlFor="medicaidOption" className="pb-1">
            Medicaid
          </label>

          <fieldset className="fw-normal">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="medicaidtrue"
                checked={medicaid === "true"}
                id="inlineRadio1"
                value={"true"}
                onChange={(e) => {
                  setMedicaid(e.currentTarget.value);
                  setRequestBody({
                    ...requestBoby,
                    medicaid: e.currentTarget.value,
                  });
                }}
              />
              <label className="form-check-label" htmlFor="inlineRadio1">
                True
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="medicaidfalse"
                checked={medicaid === "false"}
                id="inlineRadio1"
                value={"false"}
                onChange={(e) => {
                  setMedicaid(e.currentTarget.value);
                  setRequestBody({
                    ...requestBoby,
                    medicaid: e.currentTarget.value,
                  });
                }}
              />
              <label className="form-check-label" htmlFor="inlineRadio1">
                False
              </label>
            </div>
          </fieldset>
        </div>
      </div>
      <button
        type="submit"
        className="btn btn-primary"
        onClick={onSubmitClicked}
      >
        {"Get"}
      </button>
      <div className="row">
        <table className="table table-responsive">
          <thead>
            <tr>
              <th scope="col">Score</th>
              <th scope="col">Adj</th>
              <th scope="col">HCC</th>
              <th scope="col">Message</th>
            </tr>
          </thead>
          <tbody>
            {finalScore && (
              <tr>
                <td>{finalScore.risk_score}</td>
                <td>{finalScore.risk_score_adj}</td>
                <td>{finalScore.hcc_lst[0]}</td>
                <td>{finalScore.message}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProspectRAF;
