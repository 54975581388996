import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { IoArrowBackCircle } from "react-icons/io5";
import { openNotification } from "../../common/elements/myNotification";
import { fetcher } from "../../services/fectcher";
import useSWR from "swr";

export const ChartPathRefresh = () => {
  const navigate = useNavigate();
  const [fetch, setFetch] = useState(false);

  const { data } = useSWR(
    fetch ? `/GetCharpathRefreshData?RefreshRequired=Yes` : null,
    fetcher
  );

  useEffect(() => {
    if (data === "Refresh Complete") {
      openNotification(
        "success",
        "Success",
        "Patient Refresh Completed Successfully!"
      );
      setFetch(false);
    }
  }, [data]);

  return (
    <div>
      <IoArrowBackCircle
        size="2em"
        onClick={() => navigate(-1)}
        className="tw-mr-4"
      />
      <span>Click this button to Refresh ChartPath data to CCC: </span>
      <Button type="primary" size="large" onClick={() => setFetch(true)}>
        Refresh
      </Button>
    </div>
  );
};
