import React, { useState } from "react";
import TextArea from "antd/es/input/TextArea";
import MyTextBox from "../../common/elements/myTextbox";
import { openNotification } from "../../common/elements/myNotification";
import { addGrievanceTemplate } from "../../api/CareEventSCPApi";

export const GrievanceTemplateAdd = ({ editRecord, callback }) => {
  const [isPhoneValid, setIsPhoneValid] = useState(true);

  const [formData, setFormData] = useState(
    editRecord?.guid
      ? {
          ...editRecord,
          modifiedBy: sessionStorage.getItem("userName"),
        }
      : editRecord
  );
  const [pageError, setPageError] = useState({});
  const [disable, setDisable] = useState(false);

  const handleInputChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
    setPageError({ ...pageError, [name]: value ? "" : "Required" });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setPageError({});
    let pageErrorTemp = {};

    if (!formData?.callerName) {
      pageErrorTemp.callerName = "Required";
    }

    if (!formData?.callerMessage) {
      pageErrorTemp.callerMessage = "Required";
    }

    if (!formData?.callerContact) {
      pageErrorTemp.callerContact = "Required";
    } else if (!isPhoneValid) {
      pageErrorTemp.callerContact = "Invalid phone number";
    }

    if (Object.keys(pageErrorTemp).length > 0) {
      setPageError(pageErrorTemp);
      return;
    }

    setDisable(true);
    onSave(e);
  };

  const onSave = async (e) => {
    try {
      const response = await addGrievanceTemplate(formData);
      if (response?.status === 200 && !formData?.guid) {
        openNotification(
          "success",
          "Added",
          "Grievance Template Added Successfully!"
        );
      } else if (formData?.guid) {
        openNotification(
          "success",
          "Updated",
          "Grievance Template Updated Successfully!"
        );
      } else {
        openNotification("error", "Error", "Grievance Template Failed to Add!");
      }
      setFormData(editRecord);
      setDisable(false);
      callback(response);
    } catch (e) {
      return e;
    }
  };

  return (
    <div className="tw-relative tw-px-2">
      <div className="col-md-11 col-lg-11">
        <div className="form-group tw-mb-3">
          <MyTextBox
            label={"Caller Name"}
            value={formData?.callerName}
            setValue={(e) => handleInputChange("callerName", e.target.value)}
            errorMessage={pageError?.callerName}
            maxLength={100}
          />
        </div>
      </div>
      <div className="col-md-11 col-lg-11">
        <div className="form-group tw-mb-3">
          <MyTextBox
            label={"Caller Contact #"}
            value={formData?.callerContact}
            setValue={(e) => handleInputChange("callerContact", e.target.value)}
            errorMessage={pageError?.callerContact}
            isPhoneNumber={true}
            onValidChange={(e) => setIsPhoneValid(e)}
          />
        </div>
      </div>
      <div className="col-md-11 col-lg-11">
        <div className="form-group ">
          <label
            htmlFor="priority"
            className="tw-fw-bold tw-flex tw-justify-between"
          >
            <div className="tw-flex tw-items-center">
              <div className="fw-bold">Message</div>
            </div>
          </label>
          <TextArea
            autoSize={{
              minRows: 10,
              maxRows: 20,
            }}
            className="form-control "
            maxLength={"1000"}
            value={formData?.callerMessage}
            onChange={(e) => handleInputChange("callerMessage", e.target.value)}
            style={{ border: pageError.callerMessage ? "1px solid red" : "" }}
          />
        </div>
      </div>

      <div className="form-group tw-fixed tw-top-2 tw-right-10">
        <button
          className={!disable ? "btn btn-success" : "btn btn-success disabled"}
          onClick={onSubmit}
        >
          {editRecord?.guid ? "Update" : "Save"}
        </button>
        <button
          className="btn btn-secondary tw-ml-4"
          onClick={() => callback(false)}
        >
          Close
        </button>
      </div>
    </div>
  );
};
