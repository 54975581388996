import { axiosInstance } from "./AxiosInstance";

export async function getProviderGroupPatients(providergroupid) {
  return axiosInstance
    .post(`/api/ProviderGroupPatientsList?providerGroupID=${providergroupid}`, {
      providergroupid,
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
