export const Api_Url = process.env.REACT_APP_API_URL;
export const MODE = process.env.REACT_APP_MODE || "DEVELOPMENT";

export const cccgate_patient = "https://ccc-gateway.azure-api.net/patient";
export const cccgate_qatpatient =
  "https://ccc-gateway.azure-api.net/patientqat";
//export const pccqa = "https://ccc-gateway.azure-api.net/hn";https://ccmdpccdata.azurewebsites.net";
export const cccgate_qacareevent =
  "https://ccc-gateway.azure-api.net/careeventqa";
export const cccgate_careevent = "https://ccc-gateway.azure-api.net/careevent";

export const subscriptionKey = "4524690d7c3645bca26810c8f43db437";
export const useNewPt360 = true;
//const baseUrl = 'https://localhost:7006';
//const productionUrl = 'https://ccmdcdi-service.azurewebsites.net';
//const qaUrl = 'https://hccqualifier-service.azurewebsites.net';
