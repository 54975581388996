import { useEffect, useState } from "react";
import MyCustomSelect from "../../common/elements/myCustomSelect";
import MyTextBox from "../../common/elements/myTextbox";
import MyCustomSelectCreatable from "../../common/elements/myCustomSelectCreatable";
import * as FacilityApi from "../../api/FacilityApi";
import MyDatePicker from "../../common/elements/myDatePicker";
import ICDWithSourceSummary from "./ICDWithSourceSummary";
import * as scoreApi from "../../api/scoreApi";
import { useMyContext } from "../../common/elements/myContext";
import dayjs from "dayjs";
const ScoreFilter = ({
  defalutFilter,
  submitClicked,
  regionList,
  genderList,
  loading,
  icd10Codes,
  listOfSources,
  facilityList,
  renderingProviderList,
}) => {
  const [scoreFilter, setScoreFilter] = useState({
    ccmdProvider: "",
    mbi: "",
    firstName: "",
    lastName: "",
    providerGroup: "",
  });
  const setGender = (e) => {
    setSelectedGender(e);
  };
  const setRegion = (e) => {
    setSelectedRegion(e);
  };

  const { CalculateAge } = useMyContext();
  const [selectedGender, setSelectedGender] = useState();
  const [selectedFacility, setSelectedFacility] = useState();
  const [selectedRegion, setSelectedRegion] = useState();
  const [selectedRenderingProvider, setSelectedRenderingProvider] = useState();

  //icd
  const [defaultIcdCodes, setDefaultIcdCodes] = useState([]);
  const [selectedIcdCodes, setSelectedIcdCodes] = useState({});
  const [selectedIcdCodeSource, setSelectedIcdCodeSource] = useState({});
  const [selectedIcdCodeSourceText, setSelectedIcdCodeSourceText] = useState(
    {}
  );

  const [selectedPIcdCodes, setSelectedPIcdCodes] = useState({});
  const [selectedPIcdCodeSource, setSelectedPIcdCodeSource] = useState({});
  const [selectedPIcdCodeSourceText, setSelectedPIcdCodeSourceText] = useState(
    {}
  );

  const [sourceIcdErrorMessages, setSourceIcdErrorMessages] = useState({});
  const [sourcePicdErrorMessages, setSourcePicdErrorMessages] = useState({});

  const [newFacilityList, setNewFacilityList] = useState([]);
  const [NewRenderingProviderList, setNewRenderingProviderList] = useState([]);

  const [pageError, setPageError] = useState({});
  const [timeoutDelay, setTimeoutDelay] = useState();

  const [eligibiltyOption, setEligibilityOption] = useState("Yes");

  //Dob
  const [dobObject, setDobObject] = useState({});
  const [dobDate, setDobDate] = useState("");
  const [dobAge, setDobAge] = useState("");

  useEffect(() => {
    setScoreFilter(defalutFilter);

    setNewFacilityList(facilityList);
    setNewRenderingProviderList(renderingProviderList);

    setDobObject({
      age: defalutFilter?.age ? +defalutFilter?.age : "",
      birthDate: defalutFilter?.dob ? defalutFilter?.dob : "",
    });
    setDobDate(defalutFilter?.dob ? defalutFilter?.dob : "2000-01-01");
    setDobAge(defalutFilter?.age ? defalutFilter?.age : "");

    setSelectedGender(defalutFilter?.gender);
    setSelectedFacility(defalutFilter?.facility);
    setSelectedRegion(defalutFilter?.region);

    setSelectedPIcdCodes(defalutFilter?.picd10CodesOriginal);
    setSelectedPIcdCodeSource(defalutFilter?.picd10Source);
    setSelectedPIcdCodeSourceText(defalutFilter?.picd10SourceText);

    setSelectedIcdCodeSource(defalutFilter?.icd10Source);
    setSelectedIcdCodeSourceText(defalutFilter?.icd10SourceText);
    setSelectedIcdCodes(defalutFilter?.icd10CodesOriginal);
    setDefaultIcdCodes(defalutFilter?.icd10CodesOriginal);
    setSelectedRenderingProvider(defalutFilter?.renderingProvider);
    setEligibilityOption(defalutFilter?.eligibleOption);
  }, [facilityList, defalutFilter, renderingProviderList]);

  const onDobChange = (birthDate) => {
    setDobDate(birthDate);
    setDobAge({});
    setDobAge("");

    if (timeoutDelay) {
      clearTimeout(timeoutDelay);
      setTimeoutDelay(null);
    }

    let timeout = setTimeout(() => {
      let calculatedAge = CalculateAge(birthDate);

      setDobAge("");
      setDobDate(birthDate);

      setDobObject(calculatedAge);

      if (calculatedAge?.valid) {
        //set Value
        setDobAge(calculatedAge?.age >= 0 ? calculatedAge.age.toString() : "");

        setScoreFilter({
          ...scoreFilter,
          age: calculatedAge?.age >= 0 ? calculatedAge.age.toString() : "",
          dob: calculatedAge?.age >= 0 ? birthDate : "",
        });
      } else {
        setDobDate("");
        setScoreFilter({ ...scoreFilter, age: "" });
        setScoreFilter({ ...scoreFilter, dob: "" });
      }
    }, 1000);
    setTimeoutDelay(timeout);
  };

  const onSubmitClicked = (props) => {
    let pageErrorTemp = {};
    setPageError({});
    let sourceMessagesTemp = [];
    let sourceMessagesPIcdTemp = [];

    // Validate ICD Code source is selected or not

    selectedIcdCodes &&
      Object.keys(selectedIcdCodes)?.forEach((key) => {
        // if No source selected
        if (
          !selectedIcdCodeSource ||
          Object.keys(selectedIcdCodeSource)?.length <= 0 ||
          !selectedIcdCodeSource[key]
        ) {
          sourceMessagesTemp[key] = "Required";
          return;
        }

        // Other Source Selected but not selected the Other Source Text
        if (
          selectedIcdCodeSource[key]?.label === "Other" &&
          (!selectedIcdCodeSourceText || !selectedIcdCodeSourceText[key])
        ) {
          //Other
          sourceMessagesTemp[key] = "Required";
        }
      });

    // Validate Potential ICD Code source is selected or not
    selectedPIcdCodes &&
      Object.keys(selectedPIcdCodes)?.forEach((key) => {
        // if No source selected
        if (
          !selectedPIcdCodeSource ||
          Object.keys(selectedPIcdCodeSource)?.length <= 0 ||
          !selectedPIcdCodeSource[key]
        ) {
          sourceMessagesPIcdTemp[key] = "Required";
          return;
        }

        // Other Source Selected but not selected the Other Source Text
        if (
          selectedPIcdCodeSource[key]?.label === "Other" &&
          (!selectedPIcdCodeSourceText || !selectedPIcdCodeSourceText[key])
        ) {
          //Other
          sourceMessagesPIcdTemp[key] = "Required";
        }
      });

    setSourceIcdErrorMessages(sourceMessagesTemp);
    setSourcePicdErrorMessages(sourceMessagesPIcdTemp);

    if (
      !scoreFilter?.lastName ||
      !scoreFilter?.firstName ||
      sourceMessagesTemp?.length > 0 ||
      sourceMessagesPIcdTemp?.length > 0
    ) {
      pageErrorTemp.firstName = scoreFilter?.firstName ? "" : "Required";
      pageErrorTemp.lastName = scoreFilter?.lastName ? "" : "Required";

      setPageError(pageErrorTemp);
      return;
    }

    let selectedOption = [];

    selectedIcdCodes &&
      Object.keys(selectedIcdCodes)?.forEach((key) => {
        selectedOption.push({
          icd10: selectedIcdCodes[key]?.value,
          icdDescription: selectedIcdCodes[key]?.label,
          icD10SourceID: selectedIcdCodeSource[key]?.value,
          icD10SourceAddnlInfo:
            selectedIcdCodeSource[key]?.label === "Other"
              ? selectedIcdCodeSourceText
                ? selectedIcdCodeSourceText[key]
                : "Other"
              : selectedIcdCodeSource[key]?.label,
        });
      });

    let selectedOptionPicd = [];

    selectedPIcdCodes &&
      Object.keys(selectedPIcdCodes)?.forEach((key) => {
        selectedOptionPicd.push({
          icd10: selectedPIcdCodes[key]?.value,
          icdDescription: selectedPIcdCodes[key]?.label,
          icD10SourceID: selectedPIcdCodeSource[key]?.value,
          icD10SourceAddnlInfo:
            selectedPIcdCodeSource[key]?.label === "Other"
              ? selectedPIcdCodeSourceText
                ? selectedPIcdCodeSourceText[key]
                : "Other"
              : selectedPIcdCodeSource[key]?.label,
        });
      });

    let scoreFilterOnClick = {
      ...scoreFilter,
      facility: selectedFacility,
      region: selectedRegion,
      renderingProvider: selectedRenderingProvider,

      icd10List: selectedOption,
      Picd10List: selectedOptionPicd,

      icd10CodesOriginal: selectedIcdCodes,
      icd10Source: selectedIcdCodeSource,
      icd10SourceText: selectedIcdCodeSourceText,

      picd10CodesOgiginal: selectedPIcdCodes,
      picd10Source: selectedPIcdCodeSource,
      picd10SourceText: selectedPIcdCodeSourceText,

      gender: selectedGender,
      isEligible: eligibiltyOption === "Yes" || eligibiltyOption === "Partial",
      isFull: eligibiltyOption === "Yes",
    };
    setScoreFilter(scoreFilterOnClick);

    submitClicked(scoreFilterOnClick);
  };

  const onecdCodeListChange = (ecdSelectedValues) => {
    setSelectedIcdCodes(ecdSelectedValues);
  };

  const onSourceChange = (ecdSelectedValues, errorMessageTemp) => {
    setSelectedIcdCodeSource(ecdSelectedValues);
    setSourceIcdErrorMessages(errorMessageTemp);
  };

  const onSourceTextChange = (source, errorMessageTemp) => {
    setSelectedIcdCodeSourceText(source);
    setSourceIcdErrorMessages(errorMessageTemp);
  };

  const onPIcdCodeChange = (picdSelectedValues) => {
    setSelectedPIcdCodes(picdSelectedValues);
  };

  const onPIcdSourceChange = (ecdSelectedValues, errorMessageTemp) => {
    setSelectedPIcdCodeSource(ecdSelectedValues);
    setSourcePicdErrorMessages(errorMessageTemp);
  };

  const onPicdSourceTextChange = (source, errorMessageTemp) => {
    setSelectedPIcdCodeSourceText(source);
    setSourcePicdErrorMessages(errorMessageTemp);
  };

  const saveFacility = async (facilityName) => {
    try {
      const facilityRequest = {
        facilityName,
      };

      const response = await FacilityApi.SaveFacility(facilityRequest);

      return response?.data;
    } catch (error) {}
  };

  const oneFacilityChange = async (facilityOption) => {
    if (facilityOption && facilityOption.__isNew__) {
      const response = await saveFacility(facilityOption.label);
      const tempFacilityList = newFacilityList;

      const tempFacilityOption = {
        label: response.facilityName,
        value: response.facilityId,
      };

      setNewFacilityList(tempFacilityList);

      setSelectedFacility(tempFacilityOption);

      tempFacilityList.push(tempFacilityOption);
    } else {
      setSelectedFacility(facilityOption);
    }
  };

  const saveRenderingGroup = async (value) => {
    try {
      const renderingRequest = {
        pickListMastercode: "2",
        value,
      };

      const response = await scoreApi.SavePickList(renderingRequest);
      return response?.data;
    } catch (error) {}
  };

  const onRenderingProviderChange = async (renderingProviderOption) => {
    if (renderingProviderOption && renderingProviderOption.__isNew__) {
      const response = await saveRenderingGroup(renderingProviderOption.label);
      const tempRenderingGroup = NewRenderingProviderList;

      const tempRenderingGroupOption = {
        label: response.detailRow.value,
        value: response.detailRow.key,
      };
      setNewRenderingProviderList(tempRenderingGroup);
      setSelectedRenderingProvider(tempRenderingGroupOption);
      tempRenderingGroup.push(tempRenderingGroupOption);
    } else {
      setSelectedRenderingProvider(renderingProviderOption);
    }
  };

  return (
    <>
      <form onSubmit={(e) => e.preventDefault()} noValidate>
        <div>
          <div className="row">
            <div className="col-md-6 col-lg-6 pb-3">
              <MyTextBox
                label="CCMD Provider"
                id="physicianIdentifier"
                value={scoreFilter.ccmdProvider}
                isRequired={false}
                maxLength="50"
                setValue={(e) => {
                  setScoreFilter({
                    ...scoreFilter,
                    ccmdProvider: e.target.value,
                  });
                }}
              ></MyTextBox>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-6 pb-3">
              <div className="form-group">
                <label htmlFor="renderingprovider" className="fw-bold">
                  Rendering Provider
                </label>
                <MyCustomSelectCreatable
                  selectOptions={NewRenderingProviderList}
                  selectedValue={selectedRenderingProvider}
                  myCallBack={onRenderingProviderChange}
                ></MyCustomSelectCreatable>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 pb-3">
              <MyTextBox
                label="MBI"
                id="mbi"
                value={scoreFilter.mbi}
                isRequired={false}
                maxLength="50"
                setValue={(e) => {
                  setScoreFilter({
                    ...scoreFilter,
                    mbi: e.target.value,
                  });
                }}
              ></MyTextBox>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-3 pb-3">
              <MyTextBox
                label="First Name"
                id="firstName"
                value={scoreFilter.firstName}
                isRequired={true}
                maxLength="25"
                errorMessage={pageError?.firstName}
                setValue={(e) => {
                  setPageError({
                    ...pageError,
                    firstName: e.target.value ? "" : "Required",
                  });
                  setScoreFilter({
                    ...scoreFilter,
                    firstName: e.target.value,
                  });
                }}
              ></MyTextBox>
            </div>
            <div className="col-md-6 col-lg-3 pb-3">
              <MyTextBox
                label="Last Name"
                id="lastName"
                errorMessage={pageError?.lastName}
                value={scoreFilter.lastName}
                isRequired={true}
                maxLength="25"
                setValue={(e) => {
                  setPageError({
                    ...pageError,
                    lastName: e.target.value ? "" : "Required",
                  });
                  setScoreFilter({
                    ...scoreFilter,
                    lastName: e.target.value,
                  });
                }}
              ></MyTextBox>
            </div>
            <div className="col-md-6 col-lg-3 pb-3">
              <MyDatePicker
                label="DOB"
                subLabel="(YYYY-MM-DD)"
                id="dob"
                value={dobDate}
                disabled={(date) => date && date > dayjs()}
                isRequired="true"
                maxLength="10"
                errorMessage={
                  dobObject?.isValid === true ? "" : dobObject.error
                }
                setValue={(e) => {
                  onDobChange(e);
                }}
              ></MyDatePicker>
            </div>

            <div className="col-md-6 col-lg-3 pb-3">
              <MyTextBox
                label="Age"
                id="age"
                value={dobAge}
                isRequired="false"
                disabled={true}
              ></MyTextBox>
            </div>

            <div className="col-md-6 col-lg-3 pb-3">
              <div className="form-group">
                <label htmlFor="gender" className="fw-bold">
                  Gender
                </label>
                <MyCustomSelect
                  isMulti={false}
                  selectedValue={selectedGender}
                  selectOptions={genderList}
                  myCallBack={setGender}
                ></MyCustomSelect>
              </div>
            </div>

            <div className="col-md-6 col-lg-3 pb-3">
              <MyTextBox
                label="Provider Group/PCP"
                id="providerGroup"
                value={scoreFilter.providerGroup}
                isRequired="false"
                maxLength="50"
                setValue={(e) => {
                  setScoreFilter({
                    ...scoreFilter,
                    providerGroup: e.target.value,
                  });
                }}
              ></MyTextBox>
            </div>

            <div className="col-md-6 col-lg-3 pb-3">
              <div className="form-group">
                <label htmlFor="facility" className="fw-bold">
                  Facility
                </label>
                <MyCustomSelectCreatable
                  selectOptions={newFacilityList}
                  myCallBack={oneFacilityChange}
                  selectedValue={selectedFacility}
                ></MyCustomSelectCreatable>
              </div>
            </div>

            <div className="col-md-6 col-lg-3 pb-3">
              <div className="form-group">
                <label htmlFor="region" className="fw-bold">
                  Region
                </label>

                <MyCustomSelect
                  isMulti={false}
                  selectedValue={selectedRegion}
                  selectOptions={regionList}
                  myCallBack={setRegion}
                ></MyCustomSelect>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-12 pb-3">
              <ICDWithSourceSummary
                myKey="icdList"
                title="Chronic Conditions/Systemic ICD10"
                defaultValues={defaultIcdCodes}
                selectedValues={selectedIcdCodes}
                icd10Codes={icd10Codes}
                onecdCodeListChange={onecdCodeListChange}
                selectedSource={selectedIcdCodeSource}
                sourcesList={listOfSources}
                onSourceChange={onSourceChange}
                selectedSourceText={selectedIcdCodeSourceText}
                onSourceTextChange={onSourceTextChange}
                errorMessages={sourceIcdErrorMessages}
              ></ICDWithSourceSummary>
            </div>

            <div className="col-md-12 col-lg-12  pb-3">
              <div className="form-group">
                <ICDWithSourceSummary
                  myKey="potentialIcd10"
                  title="Potential/Suspect ICD10"
                  defaultValues={selectedPIcdCodes}
                  selectedValues={selectedPIcdCodes}
                  icd10Codes={icd10Codes}
                  onecdCodeListChange={onPIcdCodeChange}
                  selectedSource={selectedPIcdCodeSource}
                  sourcesList={listOfSources}
                  onSourceChange={onPIcdSourceChange}
                  selectedSourceText={selectedPIcdCodeSourceText}
                  onSourceTextChange={onPicdSourceTextChange}
                  errorMessages={sourcePicdErrorMessages}
                ></ICDWithSourceSummary>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-6 pb-4">
              <div className="form-group">
                <label htmlFor="recentHi" className="fw-bold">
                  Recent Hospitalization Info
                </label>
                <textarea
                  className="form-control"
                  onChange={(e) => {
                    setScoreFilter({
                      ...scoreFilter,
                      recentHospitalizationInfo: e.target.value,
                    });
                  }}
                  id="recentHi"
                  value={scoreFilter.recentHospitalizationInfo}
                  rows="3"
                ></textarea>
              </div>
            </div>
            {scoreFilter.guid && (
              <>
                <div className="col-md-3 col-lg-3 pb-4">
                  <div className="form-group">
                    <label htmlFor="notes" className="fw-bold">
                      Notes
                    </label>
                    <textarea
                      className="form-control"
                      onChange={(e) => {
                        setScoreFilter({
                          ...scoreFilter,
                          notes: e.target.value,
                        });
                      }}
                      id="notes"
                      value={scoreFilter.notes}
                      rows="3"
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-3 col-lg-3 pb-2">
                  <div className="form-group fw-bold">
                    <label htmlFor="eligibleOption" className="pb-1">
                      Eligible
                    </label>

                    <fieldset className="fw-normal">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="elibibiltyYes"
                          checked={eligibiltyOption === "Yes"}
                          id="inlineRadio1"
                          value={"Yes"}
                          onChange={(e) => {
                            setEligibilityOption(e.currentTarget.value);
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio1"
                        >
                          Yes
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="elibibiltyPartial"
                          checked={eligibiltyOption === "Partial"}
                          id="elibibiltyPartial"
                          value={"Partial"}
                          onChange={(e) => {
                            setEligibilityOption(e.currentTarget.value);
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio3"
                        >
                          Partial
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="elibibiltyNo"
                          checked={eligibiltyOption === "No"}
                          id="elibibiltyNo"
                          value={"No"}
                          onChange={(e) => {
                            setEligibilityOption(e.currentTarget.value);
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio2"
                        >
                          No
                        </label>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="row" style={{ marginBottom: "-70px" }}>
            <div className="col-md-12 text-center">
              <button
                type="submit"
                className="btn btn-primary round-6"
                onClick={onSubmitClicked}
                style={{
                  borderRadius: "70%",
                  width: "100px",
                  height: "100px",
                }}
              >
                {loading === true ? "Loading..." : "Get Score"}
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default ScoreFilter;
