import React, { useState } from "react";
import PageTitle from "../Common/PageTitle";
import MyCustomSelect, {
  formatOptionLabel,
} from "../../common/elements/myCustomSelect";
import MyBackdropSpinner from "../../common/elements/myBackdropSpinner";
import useSWR from "swr";
import { fetcher } from "../../services/fectcher";
import { GrievanceTemplate } from "./GrievanceTemplate";
import { EDVisit } from "./EDVisit";
import { OrderRequest } from "./OrderRequest";
import { RequestforTierChange } from "./RequestforTierChange";
import DMERequest from "./DMERequest";
import { NurseRequest } from "./NurseRequest";
import CareHospitalAdmission from "./CareHospitalAdmission";
import CareHospitalDischarge from "./CareHospitalDischarge";
import OutboundCall from "./OutboundCall";
import TriageCall from "../CareEvent/TriageCall";
import AdmReAdm from "../CareEvent/AdmReAdm";
import { usePickList } from "../../services/queries";

export const SCPForms = () => {
  const { data: allPatients, isLoading } = useSWR(
    `GetPatientsListNew?lob=${"SCP"}`,
    fetcher
  );
  const formOptions = usePickList(36).data;

  const [selecdtedPatient, setSelecdtedPatient] = useState();
  const [selecdtedEvent, setSelecdtedEvent] = useState();

  return (
    <>
      <PageTitle title={"Care Event - Palliative"} />
      {isLoading && <MyBackdropSpinner show={isLoading} />}
      <div className="tw-flex tw-place-content-start tw-gap-4">
        <div className="tw-basis-1/3">
          <label htmlFor="patientName" className=" fw-bold">
            Patient
          </label>
          <MyCustomSelect
            selectOptions={allPatients}
            selectedValue={selecdtedPatient}
            myCallBack={(e) => {
              setSelecdtedPatient(e);
              setSelecdtedEvent("");
            }}
            formatOptionLabel={formatOptionLabel}
          />
        </div>
        <div className="tw-basis-1/3">
          <label htmlFor="eventType" className=" fw-bold">
            Care Event Forms
          </label>
          <MyCustomSelect
            selectOptions={formOptions}
            selectedValue={selecdtedEvent}
            disable={selecdtedPatient?.value ? false : true}
            myCallBack={(e) => setSelecdtedEvent(e)}
          />
        </div>
      </div>
      <div>
        {selecdtedEvent?.value === "01_Grievance_Template" && (
          <GrievanceTemplate
            mbiid={selecdtedPatient?.value}
            patient={selecdtedPatient}
          />
        )}
      </div>
      <div>
        {selecdtedEvent?.value === "02_ED_Visit_Template" && (
          <EDVisit mbiid={selecdtedPatient?.value} patient={selecdtedPatient} />
        )}
      </div>
      <div>
        {selecdtedEvent?.value === "03_Request_for_Tier_Change" && (
          <RequestforTierChange
            mbiid={selecdtedPatient?.value}
            patient={selecdtedPatient}
          />
        )}
      </div>
      <div>
        {selecdtedEvent?.value === "04_Order_Request_Template" && (
          <OrderRequest
            mbiid={selecdtedPatient?.value}
            patient={selecdtedPatient}
          />
        )}
      </div>
      <div>
        {selecdtedEvent?.value === "05_DME_Request" && (
          <DMERequest
            mbiid={selecdtedPatient?.value}
            patient={selecdtedPatient}
          />
        )}
      </div>
      <div>
        {selecdtedEvent?.value === "06_Nurse_Request_Template" && (
          <NurseRequest
            mbiid={selecdtedPatient?.value}
            patient={selecdtedPatient}
          />
        )}
      </div>
      <div>
        {selecdtedEvent?.value === "07_0Hospital_Admission_Template" && (
          <CareHospitalAdmission
            mbiid={selecdtedPatient?.value}
            patient={selecdtedPatient}
          />
        )}
      </div>
      <div>
        {selecdtedEvent?.value === "08_Hospital_Discharge_Template" && (
          <CareHospitalDischarge
            mbiid={selecdtedPatient?.value}
            patient={selecdtedPatient}
          />
        )}
      </div>
      <div>
        {selecdtedEvent?.value === "09_Outbound_Call" && (
          <OutboundCall
            mbiid={selecdtedPatient?.value}
            patient={selecdtedPatient}
          />
        )}
      </div>
      <div>
        {selecdtedEvent?.value === "10_Triage_Call" && (
          <TriageCall
            mbiid={selecdtedPatient?.value}
            patient={selecdtedPatient}
          />
        )}
      </div>
      <div>
        {selecdtedEvent?.value === "11_Admission_Re-Admission" && (
          <AdmReAdm patient={selecdtedPatient} />
        )}
      </div>
    </>
  );
};
