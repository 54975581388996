import React, { useEffect, useState } from "react";
import { Button, Dropdown, Space, Table, message } from "antd";
import { IoArrowBackCircle } from "react-icons/io5";
import PageTitle from "../Common/PageTitle";
import { useNavigate, useParams } from "react-router-dom";
import * as CCP from "../../api/ChronicCodePatientsApi";
import MyBackdropSpinner from "../../common/elements/myBackdropSpinner";
import { BsThreeDotsVertical } from "react-icons/bs";
import { AiFillPrinter } from "react-icons/ai";

const items = [
  {
    key: "Resolved",
    label: "Resolved",
  },
  {
    key: "NA",
    label: "N/A",
  },
];

const CCReview = () => {
  const [spinner, setSpinner] = useState(false);
  const [cclist, setCCList] = useState();
  const [patientInfo, setPatientInfo] = useState();
  const params = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    getMemberCC(params?.mbiid);
  }, [params]);

  const getMemberCC = async (id) => {
    setSpinner(true);
    try {
      const response = await CCP.getMemberCC(id);
      const tempPatient = response?.data;
      const tempCCList = response?.data.chronicCodeList;
      setCCList(tempCCList);
      setPatientInfo(tempPatient);
      setSpinner(false);
    } catch (e) {}
  };
  const onClick = (e, rowItem) => {
    message.success(e.key);
  };

  const columns = [
    {
      title: "ICD details (Reported years)",
      key: "icd_code",
      //className: "print:tw-hidden",
      render: (_, { icd_code, icd_description, reportedYears }) => (
        <>
          <div className="tw-text-base tw-font-medium tw-text-black">
            <div>{icd_code} </div>
            <span className="tw-font-normal tw-text-sm tw-text-gray-600">
              ({reportedYears})
            </span>
          </div>
          <div className="tw-text-sm tw-text-gray-500">{icd_description}</div>
        </>
      ),
    },
    {
      title: "HCC details",
      className: "print:tw-hidden",
      key: "hcc",
      render: (_, { hcc, hcc_description }) => (
        <>
          <div className="tw-text-gray-800 tw-font-medium tw-text-base">
            {hcc}
          </div>
          <div className="tw-text-sm tw-text-gray-500">{hcc_description}</div>
        </>
      ),
    },
    {
      title: "CCMD choice",
      key: "ccmdChoice",
      dataIndex: "operation",
      className: "print:tw-hidden",
      render: (_, rowItem, index) => (
        <>
          <div className="d-flex" style={{ cursor: "pointer" }}>
            <Button
              target="Active"
              onClick={(e) => {
                message.success("Active");
              }}
            >
              Active
            </Button>
            <Dropdown
              menu={{
                items,
                onClick: (e) => onClick(e, rowItem),
              }}
              placement="bottomRight"
            >
              <div className="mx-3" style={{ cursor: "pointer" }}>
                <BsThreeDotsVertical />
              </div>
            </Dropdown>
          </div>
        </>
      ),
    },
    {
      title: "Associated Provider",
      key: "ap",
      dataIndex: "npiProvider",
      className: "print:tw-table-cell tw-hidden tw-text-wrap",
      render: (npiProvider) => <div>{npiProvider.split(":")[1]}</div>,
    },
    {
      title: "Specialty",
      key: "taxonomy",
      dataIndex: "taxonomy",
      className: "print:tw-table-cell tw-hidden tw-text-wrap",
      render: (taxonomy) => <div>{taxonomy.split(":")[1]}</div>,
    },
    {
      title: "Date of Encounter",
      key: "doe",
      dataIndex: "lastClaimDate",
      className: "print:tw-table-cell tw-hidden",
    },
    {
      title: "CCMD choice",
      key: "ccmdChoice",
      className: "print:tw-table-cell tw-hidden",
      render: (_, rowItem, index) => (
        <div className="tw-flex tw-space-x-1 tw-items-end">
          <div>
            <div className="tw-flex tw-items-center">
              <input
                className="form-check-input tw-mr-2"
                type="checkbox"
                name="Active"
              />
              <label>Active</label>
            </div>

            <div className="tw-flex tw-items-center">
              <input
                className="form-check-input tw-mr-2"
                type="checkbox"
                name="Resolved"
              />
              <label>Resolved</label>
            </div>
            <div className="tw-flex tw-items-center">
              <input
                className="form-check-input tw-mr-2"
                type="checkbox"
                name="na"
              />
              <label>N/A</label>
            </div>
          </div>
          <div>________________________</div>
        </div>
      ),
    },
  ];

  // const onChoiceChange = (rowItem) => {
  //   console.log("rowItem", rowItem);
  // };

  return (
    <div style={{ minHeight: "90vh" }}>
      <div className="tw-flex print:tw-hidden tw-justify-between">
        <Space align="start">
          <IoArrowBackCircle size="2em" onClick={() => navigate(-1)} />
          <PageTitle title={"Chronic Code Review"} />
        </Space>
        <Button
          onClick={() => window.print()}
          icon={<AiFillPrinter />}
          size={"middle"}
        >
          Print
        </Button>
      </div>
      {/* <PageTitle title={`Chronic Code Review`} />
      <IoArrowBackCircle size="2em" onClick={() => navigate(-1)} /> */}

      {spinner && <MyBackdropSpinner show={spinner} />}

      <div className=" tw-text-gray-700 tw-bg-gray-100 p-3 tw-rounded-md tw-border-gray-300 tw-border-solid border-1 print:tw-hidden">
        <div className="d-flex">
          <div className="tw-font-medium tw-text-xl  tw-basis-1/6 ">
            Personal Details
          </div>
          <div className="tw-basis-2/6 border-end">
            <div>
              <span className=" tw-font-medium">Name: </span>
              <span>
                {patientInfo?.patientName} ({patientInfo?.gender}) /
                <span className="tw-font-medium">Age : </span>
                {patientInfo?.age}
              </span>
            </div>
            <div className="mt-1">
              <span className=" tw-font-medium">MBI: </span>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/CCMDPatient360/${params?.mbiid}`);
                }}
              >
                {params?.mbiid}
              </a>
            </div>
            <div className="mt-1">
              <span className=" tw-font-medium">DOB: </span>
              <span>{patientInfo?.dateOfBirth}</span>
            </div>
            {/* <div className="mt-1">
              <span className=" tw-font-medium">Age: </span>
              <span>{[patientInfo?.age]}</span>
            </div> */}
            {/* <div className="mt-1">
              <span className=" tw-font-medium">Gender: </span>
              <span>{patientInfo?.gender === "M" ? "Male" : "Female"}</span>
            </div> */}
          </div>
          {/* </div>

        <div className="d-flex pt-3"> */}
          <div className=" tw-font-medium tw-text-xl  tw-ml-6 tw-basis-1/6">
            CCMD Details
          </div>
          <div>
            <div>
              <span className=" tw-font-medium">CCMD Assigned Provider: </span>
              <span>{patientInfo?.ccmdProvider}</span>
            </div>
            {/* <div className="mt-1">
              <span className=" tw-font-medium">CMS marked High Risk: </span>
              <span>{patientInfo?.cmsHrFlag === "Y" ? "Yes" : "No"}</span>
            </div>
            <div className="mt-1">
              <span className=" tw-font-medium">CCMD marked High Risk: </span>
              <span>{patientInfo?.emrHrFlag === "Y" ? "Yes" : "No"}</span>
            </div> */}
          </div>
        </div>
      </div>

      <div className="mt-3 print:tw-hidden">
        <Table columns={columns} dataSource={cclist} size="middle" />
      </div>
      <div className=" tw-text-gray-700 tw-bg-gray-100 p-3 tw-rounded-md tw-border-gray-300 tw-border-solid border-1 tw-hidden print:tw-block">
        <div className="d-flex">
          <div className="tw-font-medium tw-text-xl  tw-basis-1/6 ">
            Personal Details
          </div>
          <div className="tw-basis-2/6 border-end">
            <div>
              <span className=" tw-font-medium">Name: </span>
              <span>
                {patientInfo?.patientName} ({patientInfo?.gender}) /
                <span className="tw-font-medium">Age : </span>
                {patientInfo?.age}
              </span>
            </div>
            <div className="mt-1">
              <span className=" tw-font-medium">MBI: </span>

              {params?.mbiid}
            </div>
            <div className="mt-1">
              <span className=" tw-font-medium">DOB: </span>
              <span>{patientInfo?.dateOfBirth}</span>
            </div>
            {/* <div className="mt-1">
              <span className=" tw-font-medium">Age: </span>
              <span>{[patientInfo?.age]}</span>
            </div> */}
            {/* <div className="mt-1">
              <span className=" tw-font-medium">Gender: </span>
              <span>{patientInfo?.gender === "M" ? "Male" : "Female"}</span>
            </div> */}
          </div>
          {/* </div>

        <div className="d-flex pt-3"> */}
          <div className=" tw-font-medium tw-text-xl  tw-ml-6 tw-basis-1/6">
            CCMD Details
          </div>
          <div>
            <div>
              <span className=" tw-font-medium">CCMD Provider: </span>
              <span>{patientInfo?.ccmdProvider}</span>
            </div>
            {/* <div className="mt-1">
              <span className=" tw-font-medium">CMS marked High Risk: </span>
              <span>{patientInfo?.cmsHrFlag === "Y" ? "Yes" : "No"}</span>
            </div>
            <div className="mt-1">
              <span className=" tw-font-medium">CCMD marked High Risk: </span>
              <span>{patientInfo?.emrHrFlag === "Y" ? "Yes" : "No"}</span>
            </div> */}
          </div>
        </div>
      </div>
      <div className="mt-3 tw-hidden print:tw-block">
        <Table
          columns={columns}
          dataSource={cclist}
          size="middle"
          pagination={false}
        />
      </div>

      <div className="print:tw-flex tw-hidden tw-py-5 tw-space-x-6 tw-mt-4">
        <div>Sign : ______________________________</div>
        <div>Date: ________________</div>
      </div>

      <div className="print:tw-block tw-hidden">
        Disclaimer: It is CCMD’s Policy to always comply with coding regulations
        and guidelines. All codes assigned to a patient must be supported by
        clear and accurate documentation in the medical record. This report is
        only a reference and ultimately it is the provider’s decision to select
        and assign the diagnostic patient codes pertinent to the conditions
        existing at the time of the encounter. Please exercise your independent
        professional judgment. The fact that a list of codes/diagnosis(es) are
        indicated here for your review does not imply that any particular
        diagnosis(es) is/are desired or expected.
      </div>
    </div>
  );
};

export default CCReview;
