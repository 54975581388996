import { Button } from "antd";

export const handleToggle = (key, setExpandedRows) => {
  setExpandedRows((prev) => {
    if (prev.includes(key)) {
      return prev.filter((item) => item !== key);
    }
    return [...prev, key];
  });
};

export const renderExpandRow = (
  text,
  record,
  expandedRows,
  setExpandedRows
) => {
  const isExpanded = expandedRows?.includes(record.key);
  const content =
    text.length > 100 ? (
      <span>
        {isExpanded ? text : `${text.substring(0, 100)}...`}
        <Button
          type="link"
          onClick={() => handleToggle(record.key, setExpandedRows)}
        >
          {isExpanded ? "See less" : "See more"}
        </Button>
      </span>
    ) : (
      text
    );

  return <div>{content}</div>;
};
