import React from "react";
import MyCustomSelect from "../../common/elements/myCustomSelect";
import MyTextBox from "../../common/elements/myTextbox";
import MyTextArea from "../../common/elements/myTextArea";
import { DatePicker } from "antd";
import dayjs from "dayjs";

export const AdmissionForm = ({
  form,
  sourceOfNotificationOptions,
  dischargeDispositionOptions,
  selectedSon,
  onSonChange,
  handleInputChange,
  onAdmitDate,
  onDeceasedDate,
  onDischargeDate,
  onDischargeDispotionChange,
  selectedDD,
  pageError,
  formData,
}) => {
  return (
    <>
      <div className="col-md-10 col-lg-auto pb-4">
        <div className="form-group">
          <label htmlFor="selectedOption" className="fw-bold">
            Source of Notification
          </label>
          <MyCustomSelect
            selectOptions={sourceOfNotificationOptions}
            selectedValue={selectedSon}
            myCallBack={onSonChange}
            errorMessage={pageError?.notificationSource}
          />
        </div>
      </div>
      {(selectedSon?.value === "Other" ||
        selectedSon?.value === "HIE_-_Other") && (
        <div className="col-md-10 col-lg-auto pb-4">
          <div className="form-group">
            <MyTextBox
              maxLength={100}
              label={"Source of Notification Other"}
              value={formData.notificationSourceOtherNotes}
              setValue={(e) =>
                handleInputChange(
                  "notificationSourceOtherNotes",
                  e.target.value
                )
              }
              errorMessage={pageError?.notificationSourceOtherNotes}
            />
          </div>
        </div>
      )}
      <div className="col-md-10 col-lg-auto pb-4">
        <div className="form-group">
          <MyTextBox
            label={"Hospital Name"}
            maxLength={100}
            value={formData.hospitalName}
            setValue={(e) => handleInputChange("hospitalName", e.target.value)}
            errorMessage={pageError?.hospitalName}
          />
        </div>
      </div>
      <div className="col-md-10 col-lg-auto pb-4">
        <div className="form-group">
          <MyTextArea
            label={"Reason/Admitting Dx"}
            maxLength={1000}
            rows={4}
            value={formData.reasonAdmittingDx}
            setValue={(e) => handleInputChange("reasonAdmittingDx", e)}
            errorMessage={pageError?.reasonAdmittingDx}
          />
        </div>
      </div>
      {form === "IP" && (
        <div className="tw-flex tw-flex-col tw-mb-3">
          <fieldset className="fw-normal">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="planned"
                checked={formData?.admissionPlanned === "PLANNED"}
                id="planned"
                value={"PLANNED"}
                onChange={(e) =>
                  handleInputChange(
                    "admissionPlanned",
                    e.currentTarget.value ? "PLANNED" : "UNPLANNED"
                  )
                }
              />
              <label className="form-check-label" htmlFor="planned">
                Planned
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="unplanned"
                checked={formData?.admissionPlanned === "UNPLANNED"}
                id="unplanned"
                value={"UNPLANNED"}
                onChange={(e) =>
                  handleInputChange(
                    "admissionPlanned",
                    e.currentTarget.value ? "UNPLANNED" : "PLANNED"
                  )
                }
              />
              <label className="form-check-label" htmlFor="unplanned">
                Unplanned
              </label>
            </div>
            <span
              className="text-danger"
              style={{ fontWeight: "normal", paddingLeft: "5px" }}
            >
              {pageError?.admissionPlanned}
            </span>
          </fieldset>
        </div>
      )}
      <div className="col-md-10 col-lg-auto pb-4">
        <div className="form-group fw-bold">
          <label>Admit Date</label>
          <DatePicker
            className="form-control"
            placeholder="YYYY-MM-DD"
            format={"YYYY-MM-DD"}
            value={formData?.admitDate ? dayjs(formData.admitDate) : ""}
            onChange={onAdmitDate}
            style={{ borderColor: pageError?.admitDate ? "red" : "#ddd" }}
          />
        </div>
      </div>
      <div className="col-md-10 col-lg-auto pb-4">
        <div className="form-group">
          <MyTextBox
            label={"Discharge Dx"}
            value={formData.dischargeDx}
            setValue={(e) => handleInputChange("dischargeDx", e.target.value)}
          />
        </div>
      </div>
      <div className="col-md-10 col-lg-auto pb-4">
        <div className="form-group fw-bold">
          <label>Discharge Date</label>
          <DatePicker
            className="form-control"
            placeholder="YYYY-MM-DD"
            format={"YYYY-MM-DD"}
            value={formData?.dischargeDate ? dayjs(formData.dischargeDate) : ""}
            onChange={onDischargeDate}
            style={{ borderColor: pageError?.dischargeDate ? "red" : "#ddd" }}
          />
        </div>
        {pageError?.dischargeDate && (
          <span
            className="text-danger"
            style={{ fontWeight: "normal", paddingLeft: "5px" }}
          >
            {pageError?.dischargeDate}
          </span>
        )}
      </div>
      <div className="col-md-10 col-lg-auto pb-4">
        <div className="form-group fw-bold">
          <label>Deceased Date</label>
          <DatePicker
            className="form-control"
            placeholder="YYYY-MM-DD"
            format={"YYYY-MM-DD"}
            onChange={onDeceasedDate}
            value={formData?.deceasedDate ? dayjs(formData.deceasedDate) : ""}
            style={{ borderColor: pageError?.deceasedDate ? "red" : "#ddd" }}
          />
        </div>
        {pageError?.deceasedDate && (
          <span
            className="text-danger"
            style={{ fontWeight: "normal", paddingLeft: "5px" }}
          >
            {pageError?.deceasedDate}
          </span>
        )}
      </div>
      <div className="col-md-10 col-lg-auto pb-4">
        <div className="form-group">
          <label htmlFor="dischargeDis" className="fw-bold">
            Discharge Disposition
          </label>
          <MyCustomSelect
            selectOptions={dischargeDispositionOptions}
            selectedValue={selectedDD}
            myCallBack={onDischargeDispotionChange}
            //errorMessage={pageError?.dischargeDisposition}
          />
        </div>
      </div>
      {selectedDD?.value === "Other" && (
        <div className="col-md-10 col-lg-auto pb-4">
          <div className="form-group">
            <MyTextBox
              maxLength={100}
              label={"Discharge Disposition Other"}
              value={formData.dischargeDispositionOtherNotes}
              setValue={(e) =>
                handleInputChange(
                  "dischargeDispositionOtherNotes",
                  e.target.value
                )
              }
              errorMessage={pageError?.dischargeDispositionOtherNotes}
            />
          </div>
        </div>
      )}
    </>
  );
};
