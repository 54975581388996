import React, { useEffect, useState } from "react";
import { Segmented, Select, Table } from "antd";
import Search from "antd/es/input/Search";
import PageTitle from "../Common/PageTitle";
import * as Optum from "../../api/OptumCensusApi";
import MyBackdropSpinner from "../../common/elements/myBackdropSpinner";
import { CSVLink } from "react-csv";
import { BsFiletypeCsv } from "react-icons/bs";

const OptumCensus = () => {
  const [tabState, setTabState] = useState("admit");
  const [allData, setAllData] = useState();
  const [searchData, setSearchData] = useState();
  const [admissionData, setAdmissionData] = useState();
  const [dischargeData, setDischargeData] = useState();
  const [spinner, setSpinner] = useState(false);
  const [day, setDay] = useState(0);
  const [query, setQuery] = useState();

  useEffect(() => {
    setSpinner(true);
    const response = Optum.getOptumCurrent(day);
    const admitresponse = Optum.getOptumAdmit(day);
    const disChargeresponse = Optum.getOptumDischarge(day);
    Promise.all([response, admitresponse, disChargeresponse]).then((values) => {
      let tempCurrent = [];
      let tempAdmit = [];
      let tempDischarge = [];
      const allResult = values[0]?.data;
      allResult.forEach((data) => {
        tempCurrent.push({
          fullName: data.MemberName,
          age: data.Age,
          facilityName: data.FacilityName,
          dateOfBirth: data.MemberDOB,
          admitDate: data.AdmitDate,
          admitDiag: data.AdmitDiag,
          totalBedDay: data.TotalBedDay,
          careFlags: data.CareFlags,
          assignedProvider: data.AdmitProvider,
          npPa: data.np_pa,
        });
      });
      setAllData(tempCurrent);

      values[1]?.data.forEach((data) => {
        tempAdmit.push({
          fullName: data.MemberName,
          age: data.Age,
          facilityName: data.FacilityName,
          dateOfBirth: data.MemberDOB,
          admitDate: data.AdmitDate,
          admitDiag: data.AdmitDiag,
          totalBedDay: data.TotalBedDay,
          careFlags: data.CareFlags,
          assignedProvider: data.AdmitProvider,
          npPa: data.np_pa,
        });
      });
      setAdmissionData(tempAdmit);

      values[2]?.data.forEach((data) => {
        tempDischarge.push({
          fullName: data.MemberName,
          age: data.Age,
          facilityName: data.FacilityName,
          dateOfBirth: data.MemberDOB,
          admitDate: data.AdmitDate,
          dischargeDate: data.DischargeDate,
          admitDiag: data.AdmitDiag,
          totalBedDay: data.TotalBedDay,
          careFlags: data.CareFlags,
          assignedProvider: data.AdmitProvider,
          npPa: data.np_pa,
        });
      });
      setDischargeData(tempDischarge);
      if (tabState === "admit") {
        setSearchData(tempAdmit);
      } else if (tabState === "discharge") {
        setSearchData(tempDischarge);
      } else {
        setSearchData(tempCurrent);
      }
      setSpinner(false);
    });
  }, [day]);

  const columns = [
    {
      title: "Patient Name",
      key: "fullName",
      dataIndex: "fullName",
      width: 165,
    },

    {
      title: "DOB",
      key: "dob",
      dataIndex: "dateOfBirth",
      width: 105,
    },
    {
      title: "Age",
      key: "age",
      dataIndex: "age",
    },

    tabState === "discharge"
      ? {
          title: "Discharge Date",
          key: "dischargeDate",
          dataIndex: "dischargeDate",
        }
      : {
          title: "Admission Date",
          key: "admitDate",
          dataIndex: "admitDate",
        },
    {
      title: "Admission Diagnosis",
      key: "admitD",
      dataIndex: "admitDiag",
    },
    {
      title: "Bed Days",
      key: "tbd",
      dataIndex: "totalBedDay",
      sorter: (a, b) => a.totalBedDay - b.totalBedDay,
    },
    {
      title: "Care Flags",
      key: "careflag",
      dataIndex: "careFlags",
    },
    {
      title: "CCMD Assigned Provider",
      key: "admitP",
      dataIndex: "assignedProvider",
    },
    {
      title: "Facility Name",
      key: "facilityName",
      dataIndex: "facilityName",
    },
    {
      title: "NP/PA",
      key: "nppa",
      dataIndex: "npPa",
    },
  ];

  const filteredItems = searchData?.filter((item) => {
    return Object.keys(item).some((key) =>
      (item[key]?.toString()?.toLowerCase() ?? "").includes(
        query?.toLowerCase() ?? ""
      )
    );
  });

  const csvData = filteredItems?.map((item) => ({
    PatientName: item.fullName,
    Admitdate: item.admitDate,
    Age: item.age,
    DateofBirth: item.dateOfBirth,
    AdmitDiagnosis: item.admitDiag,
    BedDays: item.totalBedDay,
    CareFlags: item.careFlags,
    CCMDAssignedProvider: item.assignedProvider,
    FacilityName: item.facilityName,
    NPPA: item.npPa,
    DischargeDate: item.dischargeDate,
  }));

  const onSegmentChange = (value) => {
    setTabState(value);
    if (value === "admit") {
      setSearchData(admissionData);
    } else if (value === "discharge") {
      setSearchData(dischargeData);
    } else {
      setSearchData(allData);
    }
  };

  return (
    <div style={{ minHeight: "90vh" }}>
      <PageTitle title={`FFS Activity `} />
      {spinner && <MyBackdropSpinner show={spinner} />}
      <div style={{ color: "var(--bs-gray-800)" }}>
        <div className="tw-flex tw-justify-between tw-border tw-mb-6">
          <Segmented
            options={[
              { label: "Admission", value: "admit" },
              { label: "Discharge", value: "discharge" },
              { label: "Current Census", value: "all" },
            ]}
            onChange={onSegmentChange}
          />
          <div className="tw-flex tw-justify-end tw-space-x-2 tw-basis-2/5">
            <Select
              className="col-md-4"
              size="medium"
              options={[
                { value: "0", label: "Today" },
                { value: "1", label: "Yesterday" },
                { value: "2", label: "Day Before Yesterday" },
              ]}
              defaultValue="0"
              onChange={(e) => setDay(e)}
            />
            <Search
              className="col-md-6"
              size="medium"
              placeholder="Search"
              allowClear
              onChange={(e) => setQuery(e.target.value)}
            />
            <CSVLink data={csvData ? csvData : ""} filename={"OptumCensus.csv"}>
              <BsFiletypeCsv size={"2em"} />
            </CSVLink>
          </div>
        </div>

        <Table
          columns={columns}
          dataSource={filteredItems}
          size="large"
          pagination={{
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
        />
      </div>
    </div>
  );
};

export default OptumCensus;
