import React from "react";
import { FaRegUser } from "react-icons/fa";
import { RiCake2Line, RiMapPinLine } from "react-icons/ri";

const CCE = () => {
  return (
    <>
      <div className="tw-text-gray-700 tw-p-6 border tw-rounded-md tw-flex tw-space-x-6">
        <div className="tw-w-1/5 tw-font-medium tw-bg-gray-100 -tw-m-6 tw-mr-0 tw-p-6 tw-rounded-l-[5px]">
          <div className="tw-capitalize tw-text-2xl tw-text-gray-800">
            Abbott, Evan
          </div>
          <div>5C47X79HK61</div>
          <div className="tw-mt-6">
            <div className="tw-flex tw-items-center tw-space-x-3">
              <FaRegUser size={18} className="tw-text-gray-400" />
              <div>Male</div>
            </div>
            <div className="tw-mt-3 tw-flex tw-items-center tw-space-x-3">
              <RiCake2Line size={18} className="tw-text-gray-400" />
              <div>1947-02-08</div>
            </div>
            <div className="tw-ml-8">77 years</div>
            <div className="tw-mt-3 tw-flex tw-items-center tw-space-x-3">
              <RiMapPinLine size={18} className="tw-text-gray-400" />
              <div>VA</div>
            </div>
          </div>
        </div>

        <div className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3">
          <div>01</div>
          <div>02</div>
          <div>03</div>
          <div>04</div>
        </div>
      </div>
    </>
  );
};

export default CCE;
