import useSWR from "swr";
import useSWRImmutable from "swr/immutable";
import { fetcher } from "./fectcher";

export const usePickList = (code) => {
  return useSWRImmutable(`/PickListDetails?masterCode=${code}`, fetcher);
};

// export const usePatients = () => {
//   return useSWR("/GetPatientsList", fetcher);
// };
