import React, { useEffect, useState } from "react";
import { FcInfo } from "react-icons/fc";
import MyCustomSelect from "../../common/elements/myCustomSelect";
import TextArea from "antd/es/input/TextArea";
import { DatePicker, Input } from "antd";
import MyTextBox from "../../common/elements/myTextbox";
import dayjs from "dayjs";
import { useMyContext } from "../../common/elements/myContext";
import { postTuckInCall } from "../../api/CareEventsApi";
import { openNotification } from "../../common/elements/myNotification";

export const AddTuckInCall = ({
  callback,
  editRecord,
  pickList21,
  pickList22,
}) => {
  const [reqBody, setReqBody] = useState(
    editRecord.guid
      ? {
          ...editRecord,
          modifiedBy: sessionStorage.getItem("userName"),
        }
      : editRecord
  );
  const [pageError, setPageError] = useState({});
  const locationList = pickList21 || [];
  const locList = pickList22 || [];

  const [loc, setLoc] = useState();
  const [location, setLocation] = useState([]);
  const [locationOtherNotes, setLocationOtherNotes] = useState("");
  //const [medPtOption, setMedPatOption] = useState();

  const [newMedicationNotes, setNewMedicationNotes] = useState("");
  const [isMedArrived, setIsMedArrived] = useState("Yes");
  const [isMedArrivedNoText, setIsMedArrivedNoText] = useState("");

  const [medOrderOption, setMedOrderOption] = useState("No");
  const [codeStatus, setCodeStatus] = useState("No");
  const [pcpOption, setPCPOption] = useState("Yes");
  const [pcpNoText, setPCPNoText] = useState("");
  const [otherConcern, setOtherConcern] = useState("");
  const [selectedDateTime, setSelectedDateTime] = useState("");

  const [disable, setDisable] = useState(false);

  const { converttoDate } = useMyContext();

  useEffect(() => {
    let locationTemp = [];
    if (editRecord?.location) {
      locationTemp = locationList?.filter((item) => {
        return item.value === editRecord?.location;
      });
    }
    setLocation(locationTemp[0] || []);

    let tempLoc = [];
    if (editRecord?.levelOfCare) {
      const locArray = editRecord?.levelOfCare.split(",");
      locArray.forEach((care) => {
        const filteredCare = locList?.filter((loc) => loc.value === care);
        tempLoc.push(...filteredCare);
      });
    }
    setLoc(tempLoc);
    setMedOrderOption(editRecord?.isThereNewMedication || "No");
    setNewMedicationNotes(editRecord?.newMedicationNotes);
    setIsMedArrived(editRecord?.isMedicationArrived || "Yes");
    setPCPOption(editRecord?.isNCPFollowUpCallSetUpDone || "Yes");
    setLocationOtherNotes(editRecord?.locationOtherNotes || "");
    setPCPNoText(editRecord?.ncpFollowUpCallNotes);
    setIsMedArrivedNoText(editRecord?.medicationDeliveryNotes);
    setCodeStatus(editRecord?.codeStatusOnFile);
    const tempDate = editRecord?.followupDateTime;
    if (!tempDate || dayjs(tempDate).format("YYYY-MM-DD") === "1900-01-01") {
      setSelectedDateTime("");
    } else {
      setSelectedDateTime(dayjs(tempDate));
    }
    // if (editRecord.guid) {
    //   setReqBody({
    //     ...editRecord,
    //     modifiedBy: sessionStorage.getItem("userName"),
    //   });
    // }
    setOtherConcern(editRecord?.nurseSummaryNote);
  }, [editRecord]);

  const onLocationChange = (e) => {
    setLocation(e);
    setReqBody({ ...reqBody, location: e ? e.value : "" });
    setPageError({ ...pageError, location: e ? "" : "Required" });
    if (e?.value !== "Other") {
      setLocationOtherNotes("");
      setReqBody({
        ...reqBody,
        location: e ? e.value : "",
        locationOtherNotes: "",
      });
    }
  };
  const onLocationOtherChange = (e) => {
    setLocationOtherNotes(e.target.value);
    setReqBody({ ...reqBody, locationOtherNotes: e ? e.target.value : "" });
    setPageError({
      ...pageError,
      locationOtherNotes: e.target.value ? "" : "Required",
    });
  };

  const onLocChange = (e) => {
    setLoc(e);
    setReqBody({
      ...reqBody,
      levelOfCare: e ? e.map((item) => item.value).join(",") : "",
    });
    setPageError({ ...pageError, loc: e[0] ? "" : "Required" });
  };
  const medOrderOptionChange = (e) => {
    setMedOrderOption(e.currentTarget.value);
    setReqBody({
      ...reqBody,
      isThereNewMedication: e ? e.currentTarget.value : "",
    });
    if (e.currentTarget.value === "No") {
      setNewMedicationNotes("");
      setReqBody({
        ...reqBody,
        isThereNewMedication: e ? e.currentTarget.value : "",
        newMedicationNotes: "",
      });
      setPageError({ ...pageError, newMedicationNotes: "" });
    }
  };
  const codeStatusChange = (e) => {
    setCodeStatus(e.currentTarget.value);
    setReqBody({
      ...reqBody,
      codeStatusOnFile: e ? e.currentTarget.value : "",
    });
  };

  const medOrderYesTextChange = (e) => {
    setNewMedicationNotes(e?.target.value);
    setReqBody({
      ...reqBody,
      newMedicationNotes: e ? e.target.value : "",
    });
    setPageError({
      ...pageError,
      newMedicationNotes: e.target.value ? "" : "Required",
    });
  };
  const medArrivedOptionChange = (e) => {
    setIsMedArrived(e.currentTarget.value);
    setReqBody({
      ...reqBody,
      isMedicationArrived: e ? e.currentTarget.value : "",
    });
    if (e.currentTarget.value === "Yes") {
      setIsMedArrivedNoText("");
      setReqBody({
        ...reqBody,
        isMedicationArrived: e ? e.currentTarget.value : "",
        medicationDeliveryNotes: "",
      });
      setPageError({
        ...pageError,
        medicationDeliveryNotes: "",
      });
    }
  };

  const medArrivedNoTextChange = (e) => {
    setIsMedArrivedNoText(e.target.value);
    setReqBody({
      ...reqBody,
      medicationDeliveryNotes: e ? e.target.value : "",
    });
    setPageError({
      ...pageError,
      medicationDeliveryNotes: e.target.value ? "" : "Required",
    });
  };

  const onPCPChange = (e) => {
    setPCPOption(e.currentTarget.value);
    setReqBody({
      ...reqBody,
      isNCPFollowUpCallSetUpDone: e ? e.currentTarget.value : "",
    });
    if (e.currentTarget.value === "Yes") {
      setPCPNoText("");
      setReqBody({
        ...reqBody,
        isNCPFollowUpCallSetUpDone: e ? e.currentTarget.value : "",
        ncpFollowUpCallNotes: "",
      });
      setPageError({ ...pageError, ncpFollowUpCallNotes: "" });
    }
  };

  const pcpNoTextChange = (e) => {
    setPCPNoText(e.target.value);
    setReqBody({
      ...reqBody,
      ncpFollowUpCallNotes: e ? e.target.value : "",
    });
    setPageError({
      ...pageError,
      ncpFollowUpCallNotes: e.target.value ? "" : "Required",
    });
  };

  const onOtherConcernChange = (e) => {
    setOtherConcern(e.target.value);
    setReqBody({ ...reqBody, nurseSummaryNote: e ? e.target.value : "" });
  };
  const onDateChange = (date, dateString) => {
    setSelectedDateTime(date || "");
    setReqBody({
      ...reqBody,
      followupDateTime: date ? converttoDate(date) : "",
    });
  };

  const onSubmit = async (e) => {
    setPageError({});

    let pageErrorTemp = {};
    if (
      !reqBody?.location ||
      !reqBody?.levelOfCare ||
      (reqBody?.location === "Other" && !reqBody?.locationOtherNotes) ||
      (reqBody?.isThereNewMedication === "Yes" &&
        !reqBody?.newMedicationNotes) ||
      (reqBody?.isMedicationArrived === "No" &&
        !reqBody?.medicationDeliveryNotes) ||
      (reqBody?.isNCPFollowUpCallSetUpDone === "No" &&
        !reqBody?.ncpFollowUpCallNotes)
    ) {
      pageErrorTemp.location = reqBody?.location ? "" : "Required";

      pageErrorTemp.loc = reqBody?.levelOfCare ? "" : "Required";
      if (reqBody?.location === "Other") {
        pageErrorTemp.locationOtherNotes = reqBody?.locationOtherNotes
          ? ""
          : "Required";
      }
      if (reqBody?.isThereNewMedication === "Yes") {
        pageErrorTemp.newMedicationNotes = reqBody?.newMedicationNotes
          ? ""
          : "Required";
      }
      if (reqBody?.isMedicationArrived === "No") {
        pageErrorTemp.medicationDeliveryNotes = reqBody?.medicationDeliveryNotes
          ? ""
          : "Required";
      }
      if (reqBody?.isNCPFollowUpCallSetUpDone === "No") {
        pageErrorTemp.ncpFollowUpCallNotes = reqBody?.ncpFollowUpCallNotes
          ? ""
          : "Required";
      }

      setPageError(pageErrorTemp);
      return;
    }
    setDisable(true);
    try {
      const result = await postTuckInCall(reqBody);
      setLoc("");
      setIsMedArrived("");
      setLocation("");
      setLocationOtherNotes("");
      setIsMedArrivedNoText("");
      setNewMedicationNotes("");
      setMedOrderOption("");
      setCodeStatus("");
      setPCPOption("");
      setPCPOption("");
      setOtherConcern("");
      setReqBody(editRecord);
      if (result?.status === 200 && !reqBody?.guid) {
        openNotification(
          "success",
          "Added",
          "Tuck In Call Added Successfully!"
        );
      } else if (reqBody?.guid) {
        openNotification(
          "success",
          "Updated",
          "Tuck In Call Updated Successfully!"
        );
      } else {
        openNotification("error", "Error", "Tuck In Call Failed to Add!");
      }
    } catch (e) {
      return e;
    }
    setDisable(false);

    callback(true);
  };

  return (
    <div className="col-lg-12 col-md-12 tw-relative">
      {/* <-----   Form Feed   -----> */}
      <div className="  tw-text-base tw-flex tw-flex-col tw-space-y-5">
        <div className="tw-rounded-md tw-bg-blue-50 p-1">
          <div className="tw-flex">
            <div className="tw-flex-shrink-0 tw-ml-2">
              <FcInfo
                className="tw-h-5 tw-w-5 tw-text-blue-400"
                aria-hidden="true"
              />
            </div>
            <div className="tw-ml-3 tw-flex-1 tw-md:flex tw-md:justify-between">
              <div className="tw-text-sm tw-blue-700 tw-p-1">
                Reintroduce CCMD and remind to call with any issues at
                888-9690-9808
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-11 col-lg-11 ">
          <div className="form-group">
            <label htmlFor="location" className="fw-bold">
              Location
            </label>
            <MyCustomSelect
              selectOptions={locationList}
              myCallBack={onLocationChange}
              selectedValue={location}
              errorMessage={pageError?.location}
            />
          </div>
        </div>
        {location?.value === "Other" && (
          <div className="col-md-11 col-lg-11">
            <div className="form-group">
              <MyTextBox
                label={"Other Location"}
                setValue={onLocationOtherChange}
                value={locationOtherNotes}
                errorMessage={pageError?.locationOtherNotes}
              />
            </div>
          </div>
        )}
        <div className="col-md-11 col-lg-11 ">
          <div className="form-group">
            <label htmlFor="loc" className="fw-bold">
              Level of Care
            </label>
            <MyCustomSelect
              mode="true"
              selectOptions={locList}
              myCallBack={onLocChange}
              selectedValue={loc}
              errorMessage={pageError?.loc}
            />
          </div>
        </div>
        <div className="col-md-11 col-lg-11">
          <div className="form-group fw-bold">
            <label htmlFor="codeStatusOption" className="pb-1">
              POLST/AD/Code Status on File?
            </label>

            <fieldset className="fw-normal">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="codeStatusYes"
                  checked={codeStatus === "Yes"}
                  id="codeStatusYes"
                  value={"Yes"}
                  onChange={codeStatusChange}
                />
                <label className="form-check-label" htmlFor="codeStatusYes">
                  Yes
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="codeStatusNo"
                  checked={codeStatus === "No"}
                  id="codeStatusNo"
                  value={"No"}
                  onChange={codeStatusChange}
                />
                <label className="form-check-label" htmlFor="codeStatusNo">
                  No
                </label>
              </div>
            </fieldset>
          </div>
        </div>
        <div className="col-md-11 col-lg-11">
          <div className="form-group fw-bold">
            <label htmlFor="eligibleOption" className="pb-1">
              Are there any new medication orders?
            </label>

            <fieldset className="fw-normal">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="medOrderYes"
                  checked={medOrderOption === "Yes"}
                  id="medOrderYes"
                  value={"Yes"}
                  onChange={medOrderOptionChange}
                />
                <label className="form-check-label" htmlFor="medOrderYes">
                  Yes
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="medOrderNo"
                  checked={medOrderOption === "No"}
                  id="medOrderNo"
                  value={"No"}
                  onChange={medOrderOptionChange}
                />
                <label className="form-check-label" htmlFor="medOrderNo">
                  No
                </label>
              </div>
            </fieldset>
            {medOrderOption === "Yes" && (
              <Input
                className=" tw-mt-2"
                size="large"
                maxLength={200}
                onChange={medOrderYesTextChange}
                value={newMedicationNotes}
                style={{
                  border: pageError.newMedicationNotes ? "1px solid red" : "",
                }}
              />
            )}
          </div>
        </div>
        <div className="col-md-11 col-lg-11">
          <div className="form-group fw-bold">
            <label htmlFor="medPtOptions" className="pb-1">
              Have medications arrived to the patient?
            </label>

            <fieldset className="fw-normal">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="medpatientYes"
                  checked={isMedArrived === "Yes"}
                  id="medpatientYes"
                  value={"Yes"}
                  onChange={medArrivedOptionChange}
                />
                <label className="form-check-label" htmlFor="medpatientYes">
                  Yes
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="medpatientNo"
                  checked={isMedArrived === "No"}
                  id="medpatientNo"
                  value={"No"}
                  onChange={medArrivedOptionChange}
                />
                <label className="form-check-label" htmlFor="medpatientNo">
                  No
                </label>
              </div>
            </fieldset>
            {isMedArrived === "No" && (
              <Input
                className=" tw-mt-2"
                size="large"
                maxLength={200}
                onChange={medArrivedNoTextChange}
                value={isMedArrivedNoText}
                style={{
                  border: pageError.medicationDeliveryNotes
                    ? "1px solid red"
                    : "",
                }}
              />
            )}
          </div>
        </div>
        <div className="col-md-12 col-lg-12">
          <div className="form-group fw-bold">
            <label htmlFor="pcpOption" className="pb-1">
              Does the patient have a follow-up appointment with the PCP?
            </label>

            <fieldset className="fw-normal">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="pcpYes"
                  checked={pcpOption === "Yes"}
                  id="pcpYes"
                  value={"Yes"}
                  onChange={onPCPChange}
                />
                <label className="form-check-label" htmlFor="pcpYes">
                  Yes
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="pcpNo"
                  checked={pcpOption === "No"}
                  id="pcpNo"
                  value={"No"}
                  onChange={onPCPChange}
                />
                <label className="form-check-label" htmlFor="pcpNo">
                  No
                </label>
              </div>
            </fieldset>
            {pcpOption === "No" && (
              <Input
                className=" tw-mt-2"
                size="large"
                maxLength={200}
                onChange={pcpNoTextChange}
                value={pcpNoText}
                style={{
                  border: pageError.ncpFollowUpCallNotes ? "1px solid red" : "",
                }}
              />
            )}
          </div>
        </div>
        <div className="row ">
          <div className="col-auto fw-bold">
            <label htmlFor="dateOpt" className="col-form-label pb-1">
              Follow-up Date & Time
            </label>
          </div>
          <div className="col-auto">
            <DatePicker
              showTime
              use12Hours
              className="form-control"
              onChange={onDateChange}
              format={"YYYY-MM-DD hh:mm A"}
              value={selectedDateTime}
            />
          </div>
        </div>
        <div className="col-md-11 col-lg-11">
          <div className="form-group">
            <label
              htmlFor="priority"
              className="tw-fw-bold tw-flex tw-justify-between"
            >
              <div className="tw-flex tw-items-center">
                <div className="fw-bold">Nurse Summary Note</div>
              </div>
            </label>
            <TextArea
              className="form-control"
              maxLength={"1000"}
              onChange={onOtherConcernChange}
              value={otherConcern}
            />
          </div>
        </div>
      </div>
      <div className="form-group tw-fixed tw-top-2 tw-right-10">
        <button
          className={!disable ? "btn btn-success" : "btn btn-success disabled"}
          htmlFor="save"
          name={editRecord.guid ? "Update" : "Save"}
          onClick={onSubmit}
        >
          {editRecord.guid ? "Update" : "Save"}
        </button>
        <button
          className="btn btn-secondary tw-ml-4"
          htmlFor="cancel"
          name={"Back"}
          onClick={() => callback(true)}
        >
          {"Close"}
        </button>
      </div>
    </div>
  );
};
