import { axiosInstancepy } from "./AxiosInstance";

export async function postDashBoard(body) {
  return axiosInstancepy
    .post("/AddCmProviderGrpRecords", body)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
