import { axiosInstancehn } from "./AxiosInstance";

export async function postTuckInCall(body) {
  return axiosInstancehn
    .post("/AddTuckInCall", body)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function deleteTuckInCall(body) {
  return axiosInstancehn
    .delete("/DeleteTuckInCall", { data: body })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function postTurf(body) {
  return axiosInstancehn
    .post("/AddTherapyUtilizationReview", body)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function deleteTurf(body) {
  return axiosInstancehn
    .delete("/DeleteTherapyUtilizationReview", { data: body })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function postCCN(body) {
  return axiosInstancehn
    .post("/AddChangeInCondition", body)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function deleteCCN(body) {
  return axiosInstancehn
    .delete("/DeleteChangeInCondition", { data: body })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function postCMN(body) {
  return axiosInstancehn
    .post("/AddCareManagementNote", body)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function deleteCMN(body) {
  return axiosInstancehn
    .delete("/DeleteCareManagementNote", { data: body })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function postHRA(body) {
  return axiosInstancehn
    .post("/AddHighRiskAssessment", body)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function deleteHRA(body) {
  return axiosInstancehn
    .delete("/DeleteHighRiskAssessment", { data: body })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function postIPCommNote(body) {
  return axiosInstancehn
    .post("/AddIpHospitalCommunicationNote", body)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function deleteCommNote(body) {
  return axiosInstancehn
    .delete("/DeleteIpHospitalCommunicationNote", { data: body })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function postAdmRadm(body) {
  return axiosInstancehn
    .post("/AddTriggerAdmissionReadmission", body)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function deleteAdmRadm(body) {
  return axiosInstancehn
    .delete("/DeleteTriggerAdmissionReadmission", { data: body })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function postTriageCall(body) {
  return axiosInstancehn
    .post("/AddAfterHoursTriage", body)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function deleteTriageCall(body) {
  return axiosInstancehn
    .delete("/DeleteAfterHoursTriage", { data: body })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
