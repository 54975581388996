import 'bootstrap/dist/css/bootstrap.css';
import './index.css'
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import * as Settings from "./GlobalConfiguration";
import { msalConfig, msalConfigqa, msalConfigdev } from './components/authConfig';



const msalInstance = new PublicClientApplication( Settings.MODE === "PRODUCTION"? msalConfig : (  Settings.MODE === "QA" ? msalConfigqa :  msalConfigdev ) );

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
}

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
    }
});


const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <BrowserRouter basename={baseUrl} >
        <App instance={msalInstance} />
  </BrowserRouter>);
