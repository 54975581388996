import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import { useNavigate } from "react-router-dom";
import PageTitle from "./Common/PageTitle";
import * as reportsUrl from "./PowerBIReports";
import { Button } from "antd";

export const AuthenticatedHome = () => {
  const navigate = useNavigate();

  const homeItem = {
    title: "Clinical Dashboard -->",
    // description:      "https://app.powerbi.com/links/KwcRyPHTMA?ctid=54f55ff8-42e3-48db-a263-aa717e16fe12&pbi_source=linkShare",
  };

  return (
    <>
      <PageTitle title={"Clinical Executive Dashboard"} />

      <Card className="my-4" style={{ display: "flex", flexDirection: "row" }}>
        <Card.Img
          style={{ width: "400px", height: "auto" }}
          src="https://images.squarespace-cdn.com/content/v1/627d42d59ab8d10f8c9e684c/23d323c2-0c7b-4188-83a7-c980c198df26/main+circle+-+3.jpeg?format=1500w"
        />
        <Card.Body>
          <Card.Text>
            <span
              className="p-2"
              style={{ fontSize: "30px", fontFamily: "inherit" }}
            >
              {homeItem.title}
              <button
                className="btn btn-success"
                onClick={() => navigate("/ClinicalDashboard")}
              >
                Click to see Dashboard
              </button>
            </span>
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
};
