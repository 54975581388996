import React, { useEffect, useState } from "react";
import * as TocApi from "../../api/ToCareApi";
import { message } from "antd";
import MyCustomSelect from "../../common/elements/myCustomSelect";
import MyTextBox from "../../common/elements/myTextbox";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { openNotification } from "../../common/elements/myNotification";

const DeathEdit = ({ editRecord, callback, son, dlo, dlt }) => {
  const sonDeathOptions = son;
  const deathLocOptions = dlo;
  const deathLocTypeOptions = dlt;

  const [selectedSon, setSelectedSon] = useState([]);
  const [deathLocation, setDeathLocation] = useState([]);
  const [deathLocationType, setDeathLocationType] = useState([]);

  const [pageError, setPageError] = useState({});

  const [formData, setFormData] = useState(
    editRecord?.guid
      ? {
          ...editRecord,
          modifiedBy: sessionStorage.getItem("userName"),
        }
      : editRecord
  );
  useEffect(() => {
    if (editRecord.guid) {
      let sonTemp = [];
      if (editRecord?.notificationSource) {
        sonTemp = sonDeathOptions?.filter((item) => {
          return item.value === editRecord?.notificationSource;
        });
        setSelectedSon(sonTemp[0]);
      }

      let ddTemp = [];
      if (editRecord?.deathLocation) {
        ddTemp = deathLocOptions?.filter((item) => {
          return item.value === editRecord?.deathLocation;
        });
      }
      setDeathLocation(ddTemp[0]);

      let dltTemp = [];
      if (editRecord?.deathLocationType) {
        dltTemp = deathLocTypeOptions?.filter((item) => {
          return item.value === editRecord?.deathLocationType;
        });
      }
      setDeathLocationType(dltTemp[0]);
    }
  }, [editRecord]);

  const onSonChange = (e) => {
    setSelectedSon(e);
    setFormData({ ...formData, notificationSource: e ? e.value : "" });
    setPageError({
      ...pageError,
      notificationSource: e ? "" : "Required",
    });
    if (e?.value !== "Other") {
      setFormData({
        ...formData,
        notificationSource: e ? e.value : "",
        notificationSourceOtherNotes: "",
      });
    }
  };
  const onDeathLocChange = (e) => {
    setDeathLocation(e);
    setFormData({ ...formData, deathLocation: e ? e.value : "" });
    setPageError({
      ...pageError,
      deathLocation: e ? "" : "Required",
    });
    if (e?.value !== "Other") {
      setFormData({
        ...formData,
        deathLocation: e ? e.value : "",
        deathLocationOtherNotes: "",
      });
    }
  };
  const onDeathLocTypeChange = (e) => {
    setDeathLocationType(e);
    setFormData({ ...formData, deathLocationType: e ? e.value : "" });
    setPageError({
      ...pageError,
      deathLocationType: e ? "" : "Required",
    });
    if (e?.value !== "Other") {
      setFormData({
        ...formData,
        deathLocationType: e ? e.value : "",
        deathLocationTypeOtherNotes: "",
      });
    }
  };

  const onDeceasedDate = (date, dateString) => {
    setFormData({
      ...formData,
      deceasedDate: dateString ? dateString : "",
    });
    setPageError({
      ...pageError,
      deceasedDate: dateString ? "" : "Required",
    });
  };

  const handleInputChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
    setPageError({ ...pageError, [name]: value ? "" : "Required" });
  };

  const onSubmit = (e) => {
    let pageErrorTemp = {};
    if (
      !formData?.notificationSource ||
      !formData?.deceasedDate ||
      !formData?.deathLocationType ||
      !formData?.deathLocation ||
      (formData?.notificationSource === "Other" &&
        !formData?.notificationSourceOtherNotes) ||
      (formData?.deathLocation === "Other" &&
        !formData?.deathLocationOtherNotes) ||
      (formData?.deathLocationType === "Other" &&
        !formData?.deathLocationTypeOtherNotes)
    ) {
      pageErrorTemp.deceasedDate = formData?.deceasedDate ? "" : "Required";
      pageErrorTemp.deathLocationType = formData?.deathLocationType
        ? ""
        : "Required";
      pageErrorTemp.deathLocation = formData?.deathLocation ? "" : "Required";
      pageErrorTemp.notificationSource = formData?.notificationSource
        ? ""
        : "Required";
      if (formData?.notificationSource === "Other") {
        pageErrorTemp.notificationSourceOtherNotes =
          formData?.notificationSourceOtherNotes ? "" : "Required";
      }
      if (formData?.deathLocation === "Other") {
        pageErrorTemp.deathLocationOtherNotes =
          formData?.deathLocationOtherNotes ? "" : "Required";
      }
      if (formData?.deathLocationType === "Other") {
        pageErrorTemp.deathLocationTypeOtherNotes =
          formData?.deathLocationTypeOtherNotes ? "" : "Required";
      }
      setPageError(pageErrorTemp);
      return;
    }
    setPageError({});
    onSave(e);
  };

  const onSave = async () => {
    callback(true);
    try {
      const response = await TocApi.postDeath(formData);
      if (!editRecord?.guid) {
        setFormData([]);
        if (response?.status === 200) {
          openNotification("success", "Added", "Death Added Successfully!");
        } else {
          openNotification("error", "Error", "Death failed to add.");
        }
      } else {
        openNotification("success", "Updated", "Death Updated Successfully!");
      }
      callback(response);
    } catch (e) {
      console.log("e", e);
    }
  };

  return (
    <div className="tw-relative">
      <div className="col-md-10 col-lg-auto pb-4">
        <div className="form-group">
          <label htmlFor="selectedOption" className="fw-bold">
            Source of Notification
          </label>
          <MyCustomSelect
            selectOptions={sonDeathOptions}
            selectedValue={selectedSon}
            myCallBack={onSonChange}
            errorMessage={pageError?.notificationSource}
          />
        </div>
      </div>
      {selectedSon?.value === "Other" && (
        <div className="col-md-10 col-lg-auto pb-4">
          <div className="form-group">
            <MyTextBox
              maxLength={100}
              label={"Source of Notification Other"}
              value={formData.notificationSourceOtherNotes}
              setValue={(e) =>
                handleInputChange(
                  "notificationSourceOtherNotes",
                  e.target.value
                )
              }
              errorMessage={pageError?.notificationSourceOtherNotes}
            />
          </div>
        </div>
      )}
      <div className="col-md-10 col-lg-auto pb-4">
        <div className="form-group fw-bold">
          <label>Deceased Date</label>
          <DatePicker
            className="form-control"
            placeholder="YYYY-MM-DD"
            format={"YYYY-MM-DD"}
            value={formData?.deceasedDate ? dayjs(formData.deceasedDate) : ""}
            onChange={onDeceasedDate}
            style={{ borderColor: pageError?.deceasedDate ? "red" : "#ddd" }}
          />
        </div>
      </div>
      <div className="col-md-10 col-lg-auto pb-4">
        <div className="form-group">
          <label htmlFor="deathLocation" className="fw-bold">
            Death Location
          </label>
          <MyCustomSelect
            selectOptions={deathLocOptions}
            selectedValue={deathLocation}
            myCallBack={onDeathLocChange}
            errorMessage={pageError?.deathLocation}
          />
        </div>
      </div>
      {deathLocation?.value === "Other" && (
        <div className="col-md-10 col-lg-auto pb-4">
          <div className="form-group">
            <MyTextBox
              maxLength={100}
              label={"Death Location Other"}
              value={formData.deathLocationOtherNotes}
              setValue={(e) =>
                handleInputChange("deathLocationOtherNotes", e.target.value)
              }
              errorMessage={pageError?.deathLocationOtherNotes}
            />
          </div>
        </div>
      )}
      <div className="col-md-10 col-lg-auto pb-4">
        <div className="form-group">
          <label htmlFor="deathLocation" className="fw-bold">
            Death Location Type
          </label>
          <MyCustomSelect
            selectOptions={deathLocTypeOptions}
            selectedValue={deathLocationType}
            myCallBack={onDeathLocTypeChange}
            errorMessage={pageError?.deathLocationType}
          />
        </div>
      </div>
      {deathLocationType?.value === "Other" && (
        <div className="col-md-10 col-lg-auto pb-4">
          <div className="form-group">
            <MyTextBox
              maxLength={100}
              label={"Death Location Type Other"}
              value={formData.deathLocationTypeOtherNotes}
              setValue={(e) =>
                handleInputChange("deathLocationTypeOtherNotes", e.target.value)
              }
              errorMessage={pageError?.deathLocationTypeOtherNotes}
            />
          </div>
        </div>
      )}
      <div className="form-group tw-fixed tw-top-2 tw-right-10">
        <button className="btn btn-success" onClick={onSubmit}>
          {editRecord?.guid ? "Update" : "Save"}
        </button>
        <button
          className="btn btn-secondary tw-ml-4"
          onClick={() => callback(false)}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default DeathEdit;
