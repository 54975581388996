const TuckInCallColumns = [
  {
    title: "Location",
    dataIndex: "location",
  },
  {
    title: "Level of Care",
    dataIndex: "levelOfCareLabel",
  },
  {
    title: "POLST/AD/CodeStatus on File",
    dataIndex: "codeStatusOnFile",
  },
  {
    title: "Are there any new medication orders?",
    dataIndex: "isThereNewMedication",
  },
  {
    title: "Have medications arrived to the patient?",
    dataIndex: "isMedicationArrived",
  },
  {
    title: "Does the patient have a follow-up appointment with the PCP?",
    dataIndex: "isNCPFollowUpCallSetUpDone",
  },
  {
    title: "Nurse Summary Note",
    dataIndex: "nurseSummaryNote",
  },
  {
    title: "Follow-up Date & Time",
    dataIndex: "followupDateTime",
  },
];

const TherapyColumns = [
  {
    title: "Medicare A/B",
    dataIndex: "medicareA",
  },
  {
    title: "Has patient met maximum potential?",
    dataIndex: "hasPatientMetMaximumPotential",
  },
  {
    title: "Goals of Therapy",
    dataIndex: "therapyGoals",
  },
  {
    title: "Therapy Services and frequency",
    dataIndex: "therapyDetails",
  },
  {
    title: "Case Manager Recommendations",
    dataIndex: "caseManagerRecommendations",
  },
  {
    title: "Review Date",
    dataIndex: "reviewDate",
  },
];

const ChangeinConditionColumns = [
  {
    title: "Type of Exacerbation",
    dataIndex: "acuteOrChronic",
  },
  {
    title: "Change in Condition",
    dataIndex: "changeCondition",
  },
  {
    title: "Course/Plan of Treatment",
    dataIndex: "patientPlan",
  },
  {
    title: "Note Date & Time",
    dataIndex: "noteDateTime",
  },
];

const NotesColumns = [
  {
    title: "Note Type",
    dataIndex: "noteLabel",
  },
  {
    title: "Note",
    dataIndex: "note",
  },
  {
    title: "Note Date & Time",
    dataIndex: "noteDateTime",
  },
];

const IPHospitalCommColumns = [
  {
    title: "Hospital Name",
    dataIndex: "hospitalName",
  },
  {
    title: "Admission Date",
    dataIndex: "dateOfAdmission",
  },
  {
    title: "Attending Physician",
    dataIndex: "attendingPhysician",
  },
  {
    title: "Care/Case Manager",
    dataIndex: "hospitalCareManager",
  },
  {
    title: "Admit Dx",
    dataIndex: "admittingDiagnosis",
  },
  {
    title: "Expected LOS",
    dataIndex: "expectedLengthOfStay",
  },
  {
    title: "Discharge Disposition",
    dataIndex: "dischargeDisposition",
  },
  {
    title: "Notes",
    dataIndex: "notes",
  },
  {
    title: "IP Note Date & Time",
    dataIndex: "iPCommunicationNoteDateTime",
  },
];

const AdmReAdmColumns = [
  {
    title: "Admission / Re-Admission",
    dataIndex: "admissionType",
  },
  {
    title: "Date",
    dataIndex: "dateOfAdmission",
  },
  {
    title: "Skilled / Custodial",
    dataIndex: "skilledOrCustodial",
  },
  {
    title: "Patient Home Address",
    dataIndex: "patientLocation",
  },
  {
    title: "Facility",
    dataIndex: "facilityName",
  },
  {
    title: "Room #",
    dataIndex: "roomNumber",
  },
  {
    title: "Health Plan / Medical Group",
    dataIndex: "healthPlan",
  },
  {
    title: "Policy Number",
    dataIndex: "policyNumber",
  },
  {
    title: "SSN",
    dataIndex: "ssn",
  },
  {
    title: "NP/PA",
    dataIndex: "nporpaLabel",
  },
  {
    title: "Provider",
    dataIndex: "providerNameLabel",
  },
  {
    title: "Caller Name",
    dataIndex: "callerName",
  },
  {
    title: "Caller Relationship to Patient",
    dataIndex: "callerRelationshipPatient",
  },
  {
    title: "Nurse Station",
    dataIndex: "nurseStation",
  },
  {
    title: "Call back number",
    dataIndex: "callBackNumber",
  },
  {
    title: "Message",
    dataIndex: "message",
  },
  {
    title: "Message sent to",
    dataIndex: "messageSentTo",
  },
  {
    title: "Attachment Links",
    dataIndex: "attachmentLinks",
  },
  {
    title: "Note Date & Time",
    dataIndex: "createdDateTime",
  },
];

const HighRiskColumns = [
  {
    title: "Assessment Date & Time",
    dataIndex: "assessmentDateTime",
  },
  {
    title: "Summary",
    dataIndex: "summary",
  },
  {
    title: "High Risk Criteria",
    dataIndex: "highRiskCriteria",
  },
  {
    title: "Was a CCE completed within this year",
    dataIndex: "isCCECompletedThisYear",
  },
  {
    title: "Cognition",
    dataIndex: "cognition",
  },
  {
    title: "Physician",
    dataIndex: "physician",
  },
  {
    title: "NP",
    dataIndex: "nP",
  },
  {
    title: "Specialist",
    dataIndex: "specialist",
  },
  {
    title: "Family",
    dataIndex: "family",
  },
  {
    title: "DPOA",
    dataIndex: "dPOA",
  },
  {
    title: "Email",
    dataIndex: "eMail",
  },
  {
    title: "Best Contact",
    dataIndex: "bestContact",
  },
  {
    title: "POLST",
    dataIndex: "pOlst",
  },
  {
    title: "Advanced Directives",
    dataIndex: "advancedDirectives",
  },
  {
    title: "Number Of Medications",
    dataIndex: "numberOfMedicationsLabel",
  },
  {
    title: "ED / Hospital Visit",
    dataIndex: "isErHospitalVisit",
  },
  {
    title: "ED / Hospital Visit Notes",
    dataIndex: "erHospitalVisitNotes",
  },
  {
    title: "Care Coordination Needs",
    dataIndex: "isCareCoordinationNeeds",
  },
  {
    title: "Care Coordination Needs Notes",
    dataIndex: "careCoordinationNeedsNotes",
  },
  {
    title: "Social Worker Needs",
    dataIndex: "isSocialWorkerNeeds",
  },
  {
    title: "Social Worker Needs Notes",
    dataIndex: "socialWorkerNeedsNotes",
  },
  {
    title: "Notes",
    dataIndex: "notes",
  },
];

const GrievanceColumns = [
  {
    title: "Caller Name / Caller Contact #",
    dataIndex: "callerName",
  },
  {
    title: "Message",
    dataIndex: "callerMessage",
  },
  {
    title: "Created By",
    dataIndex: "createdBy",
  },
];

const EDColumns = [
  {
    title: "ED Name/Telephone#",
    dataIndex: "erName",
  },
  {
    title: "Visit Date",
    dataIndex: "erVisitDate",
  },
  {
    title: "Visit Symptom/Dx",
    dataIndex: "erVisitSymptonDx",
  },
  {
    title: "Medex Notes/Message",
    dataIndex: "medxNotes",
  },
  {
    title: "Created By",
    dataIndex: "createdBy",
  },
];

const TierChangeColumns = [
  {
    title: "Provider Name",
    dataIndex: "providerName",
  },
  {
    title: "Tier Change",
    dataIndex: "tierChange",
  },
  {
    title: "Reason of Tier Change",
    dataIndex: "reasonForTierChange",
  },
  {
    title: "Created By",
    dataIndex: "createdBy",
  },
];

const OrderColumns = [
  {
    title: "Urgent",
    dataIndex: "urgent",
  },
  {
    title: "Allergy",
    dataIndex: "allergy",
  },
  {
    title: "Palliative Provider's Order",
    dataIndex: "palliativeProviderOrder",
  },
  {
    title: "Date of Order Submission",
    dataIndex: "dateOfOrderSubmoission",
  },
  {
    title: "Name of Ordering Palliative Provider",
    dataIndex: "palliativeProviderName",
  },
  {
    title: "Reason for Order",
    dataIndex: "reasonForOrder",
  },
  {
    title: "IPA",
    dataIndex: "IPA",
  },
  {
    title: "Servicing Provider",
    dataIndex: "serviceProvider",
  },
  {
    title: "Authorization Number",
    dataIndex: "authoriastionNo",
  },
  {
    title: "Order Status",
    dataIndex: "orderStatus",
  },
  {
    title: "Comments",
    dataIndex: "comments",
  },
  {
    title: "Created By",
    dataIndex: "createdBy",
  },
];

const DMEColumns = [
  {
    title: "Urgent",
    dataIndex: "urgent",
  },
  {
    title: "Name of DME Requested",
    dataIndex: "nameOfDMERequest",
  },
  {
    title: "Date of DME Request Submission",
    dataIndex: "dateOfDMESubmission",
  },
  {
    title: "Palliative Provider Requesting DME",
    dataIndex: "palliativeProviderRequestingDME",
  },
  {
    title: "Reason why DME is being Requested",
    dataIndex: "reasonForDMERequest",
  },
  {
    title: "IPA",
    dataIndex: "IPA",
  },
  {
    title: "Facility Name",
    dataIndex: "facilityName",
  },
  {
    title: "Servicing Provider",
    dataIndex: "serviceProvider",
  },
  {
    title: "Authorization Number",
    dataIndex: "authoriastionNo",
  },
  {
    title: "Request Status",
    dataIndex: "requestStatus",
  },
  {
    title: "Delivery Confirmation",
    dataIndex: "deliveryConfirmation",
  },
  {
    title: "Created By",
    dataIndex: "createdBy",
  },
];

const NurseColumns = [
  {
    title: "Name of Requesting Nurse",
    dataIndex: "requestingNurseName",
  },
  {
    title: "Date request was sent to team",
    dataIndex: "dateRequestSent",
  },
  {
    title: "Request Details",
    dataIndex: "requestungDetails",
  },
  {
    title: "Created By",
    dataIndex: "createdBy",
  },
];

const HospAdmColumns = [
  {
    title: "Hospital Name / Hospital Telephone#",
    dataIndex: "hospitalName",
  },
  {
    title: "Hospital Admission Date",
    dataIndex: "hospitalAdmissionDate",
  },
  {
    title: "Hospital Admitting Symptoms / Dx",
    dataIndex: "hospitalAdmittingSymptoms",
  },
  {
    title: "Hospital Attending MD Name / Contact#",
    dataIndex: "hospitalAttendingMDName",
  },
  {
    title: "Hospital Case Manager Name / Contact#",
    dataIndex: "hospitalCaseManagerName",
  },
  {
    title: "MedEx Notes / Message",
    dataIndex: "medxNotes",
  },
  {
    title: "Created By",
    dataIndex: "createdBy",
  },
];

const HospDischColumns = [
  {
    title: "Hospital Name / Hospital Telephone#",
    dataIndex: "hospitalName",
  },
  {
    title: "Hospital Admission Date",
    dataIndex: "hospitalAdmissionDate",
  },
  {
    title: "Hospital Discharge Date",
    dataIndex: "hospitalDischargeDate",
  },
  {
    title: "Hospital Discharge Dx",
    dataIndex: "hospitalDischargeDx",
  },
  {
    title: "Location Discharged To",
    dataIndex: "locationDischargedTo",
  },
  {
    title: "Discharge Instructions",
    dataIndex: "dischargeInstructions",
  },
  {
    title: "MedEx Notes / Message",
    dataIndex: "medxNotes",
  },
  {
    title: "Created By",
    dataIndex: "createdBy",
  },
];

const OutboundColumns = [
  {
    title: "NP / PA",
    dataIndex: "nporpaLabel",
  },
  {
    title: "Provider",
    dataIndex: "providerNameLabel",
  },
  {
    title: "Phone Number",
    dataIndex: "phoneNumbers",
  },
  {
    title: "Team",
    dataIndex: "team",
  },
  {
    title: "Change of Condition",
    dataIndex: "changeOfCondition",
  },
  {
    title: "Last Nurse Visit",
    dataIndex: "lastNurseVisit",
  },
  {
    title: "ER Visit / Hospitalization",
    dataIndex: "erVisitHospitaliastion",
  },
  {
    title: "Call Frequency",
    dataIndex: "callFrequencyValue",
  },
  {
    title: "Comments",
    dataIndex: "comments",
  },
  {
    title: "Created By",
    dataIndex: "createdBy",
  },
];

const TriageColumns = [
  {
    title: "Call Type",
    dataIndex: "callType",
  },
  {
    title: "Date/Time Created",
    dataIndex: "dateTimeCreated",
  },
  {
    title: "Is this urgent",
    dataIndex: "isThisUrgent",
  },
  {
    title: "Is this a HN Patient",
    dataIndex: "isThisHnPatient",
  },
  {
    title: "Level Of Care",
    dataIndex: "skilledOrCustodial",
  },
  {
    title: "Patient Home Address",
    dataIndex: "patientHomeAddress",
  },
  {
    title: "NP/PA",
    dataIndex: "nporpaLabel",
  },
  {
    title: "Provider",
    dataIndex: "providerNameLabel",
  },
  {
    title: "Facility",
    dataIndex: "facilityName",
  },
  {
    title: "Health Plan / Medical Group",
    dataIndex: "healthPlan",
  },
  {
    title: "Policy Number",
    dataIndex: "policyNumber",
  },
  {
    title: "Caller Name",
    dataIndex: "callerName",
  },
  {
    key: 5,
    title: "Caller Relationship to Patient",
    dataIndex: "callerRelationshipPatient",
  },
  {
    title: "Room Number",
    dataIndex: "roomNumber",
  },
  {
    title: "Nurse Station",
    dataIndex: "nurseStation",
  },
  {
    title: "Call back number",
    dataIndex: "callBackNumber",
  },
  {
    title: "Does the caller need a call back",
    dataIndex: "callerNeedCallback",
  },
  {
    title: "Message",
    dataIndex: "message",
  },
  {
    title: "Message sent to",
    dataIndex: "messageSentTo",
  },
  {
    title: "Call Patched To",
    dataIndex: "callPatchedTo",
  },
  {
    title: "On Call Provider",
    dataIndex: "onCallProvider",
  },
  {
    title: "Provider Response",
    dataIndex: "onCallProviderResponse",
  },
  {
    title: "Attachment Links",
    dataIndex: "attachmentLinks",
  },
  {
    title: "Provider Response Date/Time",
    dataIndex: "providerResponseDateTime",
  },
];

const CEOptions = [
  {
    value: "All",
    label: "All",
  },
  {
    value: "Tuck In Call",
    label: "Tuck In Call",
  },
  {
    value: "Therapy Utilization Review",
    label: "Therapy Utilization Review",
  },
  {
    value: "Change in Condition Note",
    label: "Change in Condition Note",
  },
  {
    value: "General Notes",
    label: "General Notes",
  },
  {
    value: "Inpatient Hospital Communication Note",
    label: "Inpatient Hospital Communication Note",
  },
  {
    value: "Admission / Re-Admission",
    label: "Admission / Re-Admission",
  },
  {
    value: "High Risk Assessment",
    label: "High Risk Assessment",
  },
];
const SCPOptions = [
  {
    value: "All",
    label: "All",
  },
  {
    value: "Grievance Template",
    label: "Grievance Template",
  },
  {
    value: "ED Visit Template",
    label: "ED Visit Template",
  },
  {
    value: "Request for Tier Change",
    label: "Request for Tier Change",
  },
  {
    value: "Order / Request Template",
    label: "Order / Request Template",
  },
  {
    value: "DME Request",
    label: "DME Request",
  },
  {
    value: "Nurse Request Template",
    label: "Nurse Request Template",
  },
  {
    value: "Hospital Admission Template",
    label: "Hospital Admission Template",
  },
  {
    value: "Hospital Discharge Template",
    label: "Hospital Discharge Template",
  },
  {
    value: "Outbound Call",
    label: "Outbound Call",
  },
];
const RCAColumns = [
  {
    title: "Patient Name",
    dataIndex: "patientName",
  },
  {
    title: "Date of Birth",
    dataIndex: "dob",
  },
  {
    title: "Assigned Provider",
    dataIndex: "ccmdAssignedProvider",
  },
  {
    title: "Facility or Location",
    dataIndex: "facilityorLocation",
  },
  {
    title: "State",
    dataIndex: "patientState",
  },
  {
    title: "Region",
    dataIndex: "region",
  },
  {
    title: "Admission Type",
    dataIndex: "admissionType",
  },
  {
    title: "Source of Notification",
    dataIndex: "sourceofNotification",
  },
  {
    title: "Date of Admission",
    dataIndex: "dateofAdmission",
  },
  {
    title: "Date of Discharge",
    dataIndex: "dichargeDate",
  },
  {
    title: "Date of Death",
    dataIndex: "deceasedDate",
  },
  {
    title: "Discharge Disposition",
    dataIndex: "dichargeDisposistion",
  },
  {
    title: "Discharge Diagnosis",
    dataIndex: "dischargeDiagnosis",
  },
  {
    title: "RCA Date",
    dataIndex: "rcaDateandTime",
  },
  {
    title: "Patient's Chief Complaint or Admitting Dx",
    dataIndex: "wasAdmissionCheifComplaint",
  },
  {
    title: "Other Chief Complaint",
    dataIndex: "wasAdmissionCheifComplaintOther",
  },
  {
    title: "EMR Data Available?",
    dataIndex: "emrDataAvailable",
  },
  {
    title: "Hospital's Plan of Treatment or Notes",
    dataIndex: "planofTreatmentorNotes",
  },
  {
    title: "Code Status",
    dataIndex: "codeStatusDiffers",
  },
  {
    title: "Attending Provider",
    dataIndex: "attendingProvider",
  },
  {
    title: "Admitting Facility or Location",
    dataIndex: "admittingFacilityorLocation",
  },
  {
    title: "Patient was admitted from",
    dataIndex: "admittedFrom",
  },
  {
    title: "Admitted from Other",
    dataIndex: "admittedFromOther",
  },
  {
    title: "Patient's method of Transportation",
    dataIndex: "transportedVia",
  },
  {
    title: "Other method of Transportation",
    dataIndex: "transportedViaOther",
  },
  {
    title: "Contacted CCMD prior to Admission?",
    dataIndex: "contactedCCMDpriorAdmission",
  },
  {
    title: "Contact Date",
    dataIndex: "contactedCCMDpriorAdmissionDate",
  },
  {
    title: "Signs Symptoms Plans Discussed",
    dataIndex: "signSymptomsPlansDiss",
  },
  {
    title: "Change in condition prior to admission?",
    dataIndex: "changeinConditionPriorAdmission",
  },
  {
    title: "Describe the Communication",
    dataIndex: "changeinConditionPriorAdmissionNotes",
  },
  {
    title: "IP Admission in the past 30 days?",
    dataIndex: "ipAdmissionPast30Days",
  },
  {
    title: "IP Admission Date",
    dataIndex: "ipAdmissionPast30DaysDate",
  },
  {
    title: "ED in the past 30 days?",
    dataIndex: "eDInThePast30Days",
  },
  {
    title: "ED Visit Date",
    dataIndex: "eDInThePast30DaysDatec",
  },
  {
    title: "Temperature",
    dataIndex: "temperature",
  },
  {
    title: "Pulse",
    dataIndex: "pulse",
  },
  {
    title: "Respirations",
    dataIndex: "respirations",
  },
  {
    title: "Blood Pressure (Systolic/Diastolic)",
    dataIndex: "bloodPressureSystolicDiastolic",
  },
  {
    title: "Cardiac Medications?",
    dataIndex: "cardiacMedications",
  },
  {
    title: "Cardiac Medications Notes",
    dataIndex: "cardiacMedicationsNotes",
  },
  {
    title: "Patient on O2?",
    dataIndex: "patientOnO2",
  },
  {
    title: "O2 Saturation",
    dataIndex: "o2Saturation",
  },
  {
    title: "Blood Sugar Out of Range for Patient?",
    dataIndex: "bloodSugarRangeOutOfPatient",
  },
  {
    title: "Blood Sugar",
    dataIndex: "bloodSugar",
  },
  {
    title: "Recent Changes in Health Indicators",
    dataIndex: "healthIndicators",
  },
  {
    title: "Health Indicators Notes",
    dataIndex: "healthIndicatorsNotes",
  },
  {
    title: "Date of Last Provider Visit",
    dataIndex: "dateOfLastProvider",
  },
  {
    title: "Notes from EMR or Follow Up Discussion",
    dataIndex: "o2EmrNotes",
  },
  {
    title: "Possible Causes of admission",
    dataIndex: "possibleCausesOfAdmission",
  },
  {
    title: "Other Possible Causes Of Admission",
    dataIndex: "possibleCausesOfAdmissionOther",
  },
  {
    title: "Opportunity to treat the patient in place?",
    dataIndex: "opportunityToTreatThePatient",
  },
  {
    title: "Opportunity to treat the patient Notes",
    dataIndex: "opportunityToTreatThePatientNotes",
  },
  {
    title: "Potential Causes",
    dataIndex: "potentialCauses",
  },
  {
    title: "Recommendations for Improvement",
    dataIndex: "recommendationsForImprovement",
  },
  {
    title: "RCA Summary Notes",
    dataIndex: "medicalSummaryNotes",
  },
  {
    title: "TOC Created By",
    dataIndex: "createdBy",
  },
  {
    title: "RCA Last Edited By",
    dataIndex: "modifiedBy",
  },
];

export {
  TuckInCallColumns,
  TherapyColumns,
  ChangeinConditionColumns,
  NotesColumns,
  IPHospitalCommColumns,
  AdmReAdmColumns,
  HighRiskColumns,
  GrievanceColumns,
  EDColumns,
  TierChangeColumns,
  OrderColumns,
  DMEColumns,
  NurseColumns,
  HospAdmColumns,
  HospDischColumns,
  OutboundColumns,
  TriageColumns,
  CEOptions,
  SCPOptions,
  RCAColumns,
};
