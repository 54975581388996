import React from "react";
const PageTitle = ({ title }) => {
  return (
    <h4
      className="pb-2"
      style={{
        color: "#D2691E",
      }}
    >
      {title}
    </h4>
  );
};

export default PageTitle;
