import React, { useEffect, useState } from "react";
import { Table } from "antd";
import * as CCP from "../../api/ChronicCodePatientsApi";
import PageTitle from "../Common/PageTitle";
import { useNavigate } from "react-router-dom";
import MyBackdropSpinner from "../../common/elements/myBackdropSpinner";
import Search from "antd/es/input/Search";
import { CSVLink } from "react-csv";
import { BsFiletypeCsv } from "react-icons/bs";

export const CCMissedOppList = () => {
  const [ccMissedOppPatients, setCCMissedOppPatients] = useState([]);
  const [patientsColumns, setPatientsColumns] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [query, setQuery] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    getCCPatients();
  }, []);

  const getCCPatients = async () => {
    setSpinner(true);
    try {
      const response = await CCP.getCCMembers();
      setCCMissedOppPatients(response?.data);
      setSpinner(false);
    } catch (e) {
      return e;
    }

    setPatientsColumns([
      {
        title: "MBI",
        key: "mbiid",
        dataIndex: "mbiid",
        fixed: "left",
        width: 100,

        render: (columnItem, rowItem) => {
          return (
            <a
              onClick={(e) => {
                e.preventDefault();
                navigate(`/ccmo/${rowItem.mbiid}`);
                //navigate(`/CCMDPatientProfile/${rowItem.mbiid}`);
              }}
              href="#"
            >
              {rowItem.mbiid}
            </a>
          );
        },
      },
      {
        title: "Name",
        key: "patientName",
        dataIndex: "patientName",
        width: 100,
        sorter: (a, b) => {
          const patientNameA = a.patientName || ""; // Replace null with an empty string for comparison
          const patientNameB = b.patientName || ""; // Replace null with an empty string for comparison
          return patientNameA.localeCompare(patientNameB);
        },
      },

      {
        title: "Facility",
        dataIndex: "facilityName",
        key: "facilityName",
        width: 120,
      },

      {
        title: "CCMD Provider",
        dataIndex: "ccmdProvider",
        key: "ccmdProvider",
        width: 120,
      },

      {
        title: "CMS Aligned Provider",
        key: "renderingProvider",
        dataIndex: "renderingProvider",
        width: 120,
      },

      {
        title: "CCMD RAF",
        key: "ccmdraf",
        dataIndex: "ccmdraf",
        width: 30,
        sorter: (a, b) => a.ccmdraf - b.ccmdraf,
      },
      {
        title: "Potential RAF",
        key: "potentialraf",
        dataIndex: "potentialraf",
        width: 30,
        sorter: (a, b) => a.potentialraf - b.potentialraf,
      },
      {
        title: "Number of Missed Chronic Codes",
        key: "noofchroniccodes",
        dataIndex: "noofchroniccodes",
        width: 100,
        sorter: (a, b) => a.noofchroniccodes - b.noofchroniccodes,
      },
    ]);
  };

  const filteredItems = ccMissedOppPatients.filter((item) => {
    return Object.keys(item).some((key) =>
      (item[key]?.toString()?.toLowerCase() ?? "").includes(
        query?.toLowerCase() ?? ""
      )
    );
  });

  const csvData = filteredItems?.map((item) => ({
    MBI: item.mbiid,
    PatientName: item.patientName,
    Age: item.age,
    DateofBirth: item.dateOfBirth,
    Gender: item.gender,
    FacilityName: item.facilityName,
    CCMDProvider: item.ccmdProvider,
    RenderingProvider: item.renderingProvider,
    CCMDRAF: item.ccmdraf,
    PotentialRAF: item.potentialraf,
    NoOfChronicCodes: item.noofchroniccodes,
    CMSHrFlag: item.cmsHrFlag,
    CCMDHrFlag: item.mdsHsFlag,
  }));

  return (
    <>
      <div className="tw-flex tw-justify-between tw-mb-2">
        <PageTitle title={"Chronic Code Patients"} />
        {spinner && <MyBackdropSpinner show={spinner} />}
        <div className="d-grid gap-2 my-2 d-md-flex justify-content-end">
          <Search
            id="psearch"
            name="psearch"
            className="col-md-8"
            size="medium"
            placeholder="Search"
            onChange={(e) => setQuery(e.target.value)}
            allowClear
          />
          <CSVLink data={csvData ? csvData : ""} filename={"ccp.csv"}>
            <BsFiletypeCsv size={"2em"} />
          </CSVLink>
        </div>
      </div>
      <div className="row">
        <Table
          columns={patientsColumns}
          dataSource={filteredItems}
          size="middle"
          pagination={{
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
        />
      </div>
    </>
  );
};
