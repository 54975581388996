import React, { useEffect, useState } from "react";
import { AdmissionForm } from "./AdmissionForm";
import * as TocApi from "../../api/ToCareApi";
import { openNotification } from "../../common/elements/myNotification";
import dayjs from "dayjs";

const EDEdit = ({ editRecord, callback, son, ddo }) => {
  const [formData, setFormData] = useState(
    editRecord?.guid
      ? {
          ...editRecord,
          modifiedBy: sessionStorage.getItem("userName"),
        }
      : editRecord
  );

  const sourceOfNotificationOptions = son;
  const dischargeDispositionOptions = ddo;

  const [selectedSon, setSelectedSon] = useState([]);
  const [dischargeDisposition, setDischargeDisposition] = useState();

  const [pageError, setPageError] = useState({});

  useEffect(() => {
    if (editRecord.guid) {
      let sonTemp = [];
      if (editRecord?.notificationSource) {
        sonTemp = sourceOfNotificationOptions?.filter((item) => {
          return item.value === editRecord?.notificationSource;
        });
        setSelectedSon(sonTemp[0]);
      }

      let ddTemp = [];
      if (editRecord?.dischargeDisposition) {
        ddTemp = dischargeDispositionOptions?.filter((item) => {
          return item.value === editRecord?.dischargeDisposition;
        });
      }
      setDischargeDisposition(ddTemp[0]);
    }
  }, [editRecord]);

  const onAdmitDate = (date, dateString) => {
    setFormData({
      ...formData,
      admitDate: dateString ? dateString : "",
    });
    setPageError({
      ...pageError,
      admitDate: dateString ? "" : "Required",
    });
  };

  const onDeceasedDate = (date, dateString) => {
    setFormData({
      ...formData,
      deceasedDate: dateString ? dateString : "",
    });
    const deceasedDate = dateString ? dayjs(dateString) : null;
    const admitDate = formData.admitDate ? dayjs(formData.admitDate) : null;

    if (admitDate && deceasedDate && deceasedDate.isBefore(admitDate)) {
      setPageError({
        ...pageError,
        deceasedDate: "Deceased date cannot be before admit date",
      });
    } else {
      setPageError({
        ...pageError,
        deceasedDate: "",
      });
    }
  };

  const onDischargeDate = (date, dateString) => {
    setFormData({
      ...formData,
      dischargeDate: dateString ? dateString : "",
    });
    const dischargeDate = dateString ? dayjs(dateString) : null;
    const admitDate = formData.admitDate ? dayjs(formData.admitDate) : null;

    if (admitDate && dischargeDate && dischargeDate.isBefore(admitDate)) {
      setPageError({
        ...pageError,
        dischargeDate: "Discharge date cannot be before admit date",
      });
    } else {
      setPageError({
        ...pageError,
        dischargeDate: "",
      });
    }
  };

  const onSonChange = (e) => {
    setSelectedSon(e);
    setFormData({ ...formData, notificationSource: e ? e.value : "" });
    setPageError({
      ...pageError,
      notificationSource: e ? "" : "Required",
    });
    if (e?.value !== "Other" || e?.value !== "HIE_-_Other") {
      setFormData({
        ...formData,
        notificationSource: e ? e.value : "",
        notificationSourceOtherNotes: "",
      });
    }
  };

  const handleInputChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
    setPageError({ ...pageError, [name]: value ? "" : "Required" });
  };

  const onDischargeDispotionChange = (e) => {
    setDischargeDisposition(e);
    setFormData({ ...formData, dischargeDisposition: e ? e.value : "" });
    setPageError({
      ...pageError,
      dischargeDisposition: e ? "" : "Required",
    });
    if (e?.value !== "Other") {
      setFormData({
        ...formData,
        dischargeDisposition: e ? e.value : "",
        dischargeDispositionOtherNotes: "",
      });
    }
  };

  const onSubmit = (e) => {
    let pageErrorTemp = {};
    if (
      !formData?.wasERCalledFirst ||
      !formData?.wasERToIP ||
      !formData?.notificationSource ||
      ((formData?.notificationSource === "Other" ||
        formData?.notificationSource === "HIE_-_Other") &&
        !formData?.notificationSourceOtherNotes) ||
      !formData?.hospitalName ||
      !formData?.reasonAdmittingDx ||
      !formData?.admitDate ||
      (formData?.dischargeDisposition === "Other" &&
        !formData?.dischargeDispositionOtherNotes)
    ) {
      pageErrorTemp.wasERToIP = formData?.wasERToIP ? "" : "Required";
      pageErrorTemp.wasERCalledFirst = formData?.wasERCalledFirst
        ? ""
        : "Required";
      pageErrorTemp.notificationSource = formData?.notificationSource
        ? ""
        : "Required";
      pageErrorTemp.hospitalName = formData?.hospitalName ? "" : "Required";
      pageErrorTemp.reasonAdmittingDx = formData?.reasonAdmittingDx
        ? ""
        : "Required";
      pageErrorTemp.admitDate = formData?.admitDate ? "" : "Required";
      if (
        formData?.notificationSource === "Other" ||
        formData?.notificationSource === "HIE_-_Other"
      ) {
        pageErrorTemp.notificationSourceOtherNotes =
          formData?.notificationSourceOtherNotes ? "" : "Required";
      }
      if (formData?.dischargeDisposition === "Other") {
        pageErrorTemp.dischargeDispositionOtherNotes =
          formData?.dischargeDispositionOtherNotes ? "" : "Required";
      }
    }

    const deceasedDate = formData.deceasedDate
      ? dayjs(formData.deceasedDate)
      : null;
    const admitDate = formData.admitDate ? dayjs(formData.admitDate) : null;
    if (admitDate && deceasedDate && deceasedDate.isBefore(admitDate)) {
      pageErrorTemp.deceasedDate = "Deceased date cannot be before admit date";
    }

    const dischargeDate = formData.dischargeDate
      ? dayjs(formData.dischargeDate)
      : null;
    if (admitDate && dischargeDate && dischargeDate.isBefore(admitDate)) {
      pageErrorTemp.dischargeDate =
        "Discharge date cannot be before admit date";
    }
    setPageError(pageErrorTemp);

    if (Object.keys(pageErrorTemp).length === 0) {
      onSave(e);
    }
  };

  const onSave = async () => {
    callback(true);
    try {
      const response = await TocApi.postEDVisit(formData);
      if (!editRecord?.guid) {
        setFormData([]);
        if (response?.data?.mbi === formData?.mbi) {
          openNotification(
            "error",
            "Error",
            "There is a current ED Visit without a Discharge or Deceased Date. You cannot create a new record without closing the current one."
          );
        } else if (response?.status === 200) {
          openNotification("success", "Added", "ED Visit Added Successfully!");
        } else {
          openNotification("error", "Error", "ED Visit failed to Add");
        }
      } else {
        openNotification(
          "success",
          "Updated",
          "ED Visit Updated Successfully!"
        );
      }
      callback(response);
    } catch (e) {
      console.log("e", e);
    }
  };

  return (
    <div className="tw-relative">
      <AdmissionForm
        sourceOfNotificationOptions={sourceOfNotificationOptions}
        dischargeDispositionOptions={dischargeDispositionOptions}
        onSonChange={onSonChange}
        selectedSon={selectedSon}
        formData={formData}
        handleInputChange={handleInputChange}
        onAdmitDate={onAdmitDate}
        onDeceasedDate={onDeceasedDate}
        onDischargeDate={onDischargeDate}
        onDischargeDispotionChange={onDischargeDispotionChange}
        selectedDD={dischargeDisposition}
        pageError={pageError}
      />
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="hospitalname">
          ED Called First?
        </label>
        <fieldset className="fw-normal">
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="erCalledFirstYes"
              checked={formData?.wasERCalledFirst === "Yes"}
              id="erCalledFirstYes"
              value={"Yes"}
              onChange={(e) =>
                handleInputChange(
                  "wasERCalledFirst",
                  e.currentTarget.value ? "Yes" : "No"
                )
              }
            />
            <label
              className="form-check-label"
              htmlFor="isSocialWorkerNeedsYes"
            >
              Yes
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="erCalledFirstNo"
              checked={formData?.wasERCalledFirst === "No"}
              id="erCalledFirstNo"
              value={"No"}
              onChange={(e) =>
                handleInputChange(
                  "wasERCalledFirst",
                  e.currentTarget.value ? "No" : "Yes"
                )
              }
            />
            <label className="form-check-label" htmlFor="isSocialWorkerNeedsNo">
              No
            </label>
          </div>
          <span
            className="text-danger"
            style={{ fontWeight: "normal", paddingLeft: "5px" }}
          >
            {pageError?.wasERCalledFirst}
          </span>
        </fieldset>
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="hospitalname">
          ED to IP?
        </label>
        <fieldset className="fw-normal">
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="wasERToIPYes"
              checked={formData?.wasERToIP === "Yes"}
              id="wasERToIPYes"
              value={"Yes"}
              onChange={(e) =>
                handleInputChange(
                  "wasERToIP",
                  e.currentTarget.value ? "Yes" : "No"
                )
              }
            />
            <label
              className="form-check-label"
              htmlFor="isSocialWorkerNeedsYes"
            >
              Yes
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="wasERToIPNo"
              checked={formData?.wasERToIP === "No"}
              id="wasERToIPNo"
              value={"No"}
              onChange={(e) =>
                handleInputChange(
                  "wasERToIP",
                  e.currentTarget.value ? "No" : "Yes"
                )
              }
            />
            <label className="form-check-label" htmlFor="isSocialWorkerNeedsNo">
              No
            </label>
          </div>
          <span
            className="text-danger"
            style={{ fontWeight: "normal", paddingLeft: "5px" }}
          >
            {pageError?.wasERToIP}
          </span>
        </fieldset>
      </div>
      <div className="form-group tw-fixed tw-top-2 tw-right-10">
        <button className="btn btn-success" onClick={onSubmit}>
          {editRecord?.guid ? "Update" : "Save"}
        </button>
        <button
          className="btn btn-secondary tw-ml-4"
          onClick={() => callback(false)}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default EDEdit;
