import React, { useState } from "react";
import TextArea from "antd/es/input/TextArea";
import MyTextBox from "../../common/elements/myTextbox";
import { openNotification } from "../../common/elements/myNotification";
import { addTierChangeRequest } from "../../api/CareEventSCPApi";

export const RequestforTierChangeAdd = ({ editRecord, callback }) => {
  const [formData, setFormData] = useState(
    editRecord?.guid
      ? {
          ...editRecord,
          modifiedBy: sessionStorage.getItem("userName"),
        }
      : editRecord
  );
  const [pageError, setPageError] = useState({});
  const [disable, setDisable] = useState(false);

  const handleInputChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
    setPageError({ ...pageError, [name]: value ? "" : "Required" });
  };

  const onSubmit = (e) => {
    setPageError({});
    let pageErrorTemp = {};
    if (
      !formData?.providerName ||
      !formData?.tierChange ||
      !formData?.reasonForTierChange
    ) {
      pageErrorTemp.providerName = formData?.providerName ? "" : "Required";
      pageErrorTemp.tierChange = formData?.tierChange ? "" : "Required";
      pageErrorTemp.reasonForTierChange = formData?.reasonForTierChange
        ? ""
        : "Required";

      setPageError(pageErrorTemp);
      return;
    }
    setDisable(true);
    onSave(e);
  };

  const onSave = async (e) => {
    try {
      const response = await addTierChangeRequest(formData);
      if (response?.status === 200 && !formData?.guid) {
        openNotification(
          "success",
          "Added",
          "Request for Tier Change Added Successfully!"
        );
      } else if (formData?.guid) {
        openNotification(
          "success",
          "Updated",
          "Request for Tier Change Updated Successfully!"
        );
      } else {
        openNotification(
          "error",
          "Error",
          "Request for Tier Change Failed to Add!"
        );
      }

      setFormData(editRecord);
      setDisable(false);
      callback(response);
    } catch (e) {
      return e;
    }
  };

  return (
    <div className="tw-relative tw-px-2">
      <div className="col-md-11 col-lg-11">
        <div className="form-group tw-mb-3">
          <MyTextBox
            label={"Name of Provider Requesting Tier Change"}
            value={formData?.providerName}
            setValue={(e) => handleInputChange("providerName", e.target.value)}
            errorMessage={pageError?.providerName}
            maxLength={100}
          />
        </div>
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="tierChange">
          Tier Change
        </label>
        <fieldset className="fw-normal">
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="tierChange"
              checked={formData?.tierChange === "Low"}
              id="lowYes"
              value={"Low"}
              onChange={(e) =>
                handleInputChange(
                  "tierChange",
                  e.currentTarget.value ? "Low" : "High"
                )
              }
            />
            <label className="form-check-label" htmlFor="lowYes">
              Low
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="tierChange"
              checked={formData?.tierChange === "High"}
              id="HighYes"
              value={"High"}
              onChange={(e) =>
                handleInputChange(
                  "tierChange",
                  e.currentTarget.value ? "High" : "Low"
                )
              }
            />
            <label className="form-check-label" htmlFor="HighYes">
              High
            </label>
          </div>
          <span
            className="text-danger"
            style={{ fontWeight: "normal", paddingLeft: "5px" }}
          >
            {pageError?.tierChange}
          </span>
        </fieldset>
      </div>

      <div className="col-md-11 col-lg-11">
        <div className="form-group ">
          <label
            htmlFor="priority"
            className="tw-fw-bold tw-flex tw-justify-between"
          >
            <div className="tw-flex tw-items-center">
              <div className="fw-bold">Reason of Tier Change</div>
            </div>
          </label>
          <TextArea
            autoSize={{
              minRows: 10,
              maxRows: 20,
            }}
            className="form-control "
            maxLength={"1000"}
            value={formData?.reasonForTierChange}
            onChange={(e) =>
              handleInputChange("reasonForTierChange", e.target.value)
            }
            style={{
              border: pageError.reasonForTierChange ? "1px solid red" : "",
            }}
          />
        </div>
      </div>

      <div className="form-group tw-fixed tw-top-2 tw-right-10">
        <button
          className={!disable ? "btn btn-success" : "btn btn-success disabled"}
          onClick={onSubmit}
        >
          {editRecord?.guid ? "Update" : "Save"}
        </button>
        <button
          className="btn btn-secondary tw-ml-4"
          onClick={() => callback(false)}
        >
          Close
        </button>
      </div>
    </div>
  );
};
