import { axiosInstancepy } from "./AxiosInstance";

export async function getOptumCurrent(day) {
  return axiosInstancepy
    .get(`/OptumCensus?forTheDay=${day}`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
export async function getOptumAdmit(day) {
  return axiosInstancepy
    .get(`/OptumAdmissions?forTheDay=${day}`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
export async function getOptumDischarge(day) {
  return axiosInstancepy
    .get(`/OptumDischarges?forTheDay=${day}`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function GetOptumFacilities(day) {
  return axiosInstancepy
    .get(`/GetOptumFacilities`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
export async function postOptumFacility(body) {
  return axiosInstancepy
    .post("/AddEditOptumFacility", body)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function deleteOptumFacility(guid) {
  return axiosInstancepy
    .delete(`/DeleteOptumFacility?guid=${guid}`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
