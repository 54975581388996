import React, { useEffect, useState } from "react";
import { Table } from "antd";
import PageTitle from "../Common/PageTitle";
import { getAdvanceDirectives } from "../../api/AdvanceDirectivesApi";
import MyBackdropSpinner from "../../common/elements/myBackdropSpinner";

const AdvanceDirectives = ({ patientId }) => {
  const [advDirectivesData, setAdvanceDirectivesData] = useState();
  const [spinner, setSpinner] = useState(false);
  const [searchData, setSearchData] = useState();
  const [query, setQuery] = useState();

  useEffect(() => {
    setSpinner(true);
    const advDirectives = getAdvanceDirectives(patientId);

    Promise.all([advDirectives]).then((values) => {
      let tempAdvs = [];
      let commaSepValues = "";
      //debugger;
      values[0]?.data.forEach((data) => {
        data?.directives?.map((d) => {
          if (commaSepValues != "") {
            commaSepValues = commaSepValues.trim() + ", " + d;
          } else {
            commaSepValues = d;
          }
        });
        tempAdvs.push({
          commMethod: data.communicationMethod,
          consentStatus: data.consentStatus,
          description: data.description,
          directives: commaSepValues,
          source: "PointClickCare",
        });
      });
      setAdvanceDirectivesData(tempAdvs);
      setSearchData(advDirectivesData);
      setSpinner(false);
    });
  }, []);

  const advDirectivescolumns = [
    {
      title: "Communication Method",
      key: "commMethod",
      dataIndex: "commMethod",
      width: "25%",
    },
    {
      title: "Consent Status",
      key: "consentStatus",
      dataIndex: "consentStatus",
      width: "25%",
    },
    {
      title: "Description",
      key: "description",
      dataIndex: "description",
      width: "25%",
    },
    {
      title: "Directives",
      key: "directives",
      dataIndex: "directives",
    },
    {
      title: "Source",
      key: "source",
      dataIndex: "source",
      width: 130,
    },
  ];

  const filteredItems = searchData?.filter((item) => {
    return Object.keys(item).some((key) =>
      (item[key]?.toString()?.toLowerCase() ?? "").includes(
        query?.toLowerCase() ?? ""
      )
    );
  });

  return (
    <div>
      {/* {<PageTitle title={`PointClickCare Advance Directives`} />} */}
      {spinner && <MyBackdropSpinner show={spinner} />}
      <div style={{ color: "var(--bs-gray-800)" }}>
        <div className="tw-flex tw-justify-between tw-border tw-mb-6">
          <div className="tw-flex tw-justify-end tw-space-x-2 tw-basis-2/5"></div>
        </div>
        <div className="md">
          <Table
            columns={advDirectivescolumns}
            dataSource={advDirectivesData}
            size="large"
            bordered
          />
        </div>
      </div>
    </div>
  );
};

export default AdvanceDirectives;
