import axios from "axios";
import * as Settings from "../GlobalConfiguration";

//const token = sessionStorage.getItem("Bearer");
export const axiosInstance = axios.create({
  baseURL: Settings.Api_Url,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    // Add custom logic for POST requests
    const accessToken = sessionStorage.getItem("Bearer");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    if (config.method === "post") {
      config.data = JSON.stringify(config.data); // Convert request data to JSON string
    }
    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

export const axiosInstancepy = axios.create({
  baseURL:
    Settings.MODE === "PRODUCTION"
      ? Settings.cccgate_patient
      : Settings.cccgate_qatpatient,
  headers: {
    "Content-Type": "application/json",
    "Ocp-Apim-Subscription-Key": Settings.subscriptionKey,
  },
});

axiosInstancepy.interceptors.request.use(
  (config) => {
    // Add custom logic for POST requests
    const accessToken = sessionStorage.getItem("Bearer");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    if (config.method === "post") {
      config.data = JSON.stringify(config.data); // Convert request data to JSON string
    }
    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

export const axiosInstancehn = axios.create({
  baseURL:
    Settings.MODE === "PRODUCTION"
      ? Settings.cccgate_careevent
      : Settings.cccgate_qacareevent,
  headers: {
    "Content-Type": "application/json",
    "Ocp-Apim-Subscription-Key": Settings.subscriptionKey,
  },
});

axiosInstancehn.interceptors.request.use(
  (config) => {
    // Add custom logic for POST requests
    const accessToken = sessionStorage.getItem("Bearer");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    if (config.method === "post") {
      config.data = JSON.stringify(config.data); // Convert request data to JSON string
    }
    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

// export const axiosInstancepcc = axios.create({
//   baseURL: Settings.pccqa,

//   headers: {
//     "Content-Type": "application/json",
//   },
// });

// axiosInstancepcc.interceptors.request.use(
//   (config) => {
//     // Add custom logic for POST requests
//     const accessToken = sessionStorage.getItem("Bearer");
//     if (accessToken) {
//       config.headers.Authorization = `Bearer ${accessToken}`;
//     }
//     if (config.method === "post") {
//       config.data = JSON.stringify(config.data); // Convert request data to JSON string
//     }
//     return config;
//   },
//   (error) => {
//     // Handle request error
//     return Promise.reject(error);
//   }
// );
