import React, { useState } from "react";
import { Segmented, Table, Dropdown } from "antd";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdOutlineModeEditOutline } from "react-icons/md";
import Search from "antd/es/input/Search";
import PageTitle from "../Common/PageTitle";
import MyCustomSelectCreatable from "../../common/elements/myCustomSelectCreatable";

const CCMDChoiceList = [
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Inactive",
    value: "inactive",
  },
];

const ChronicCodeListReviewed = [
  {
    id: 1,
    ICDCode: "A4102",
    ICDDescription: "Sepsis due to Methicillin resistant Staphylococcus aureus",
    HCCCode: "A5440",
    HCCDescription:
      "Gonococcal infection of musculoskeletal system, unspecified",
    reviewed: "Active",
    reviewedDate: "08-25-2023",
    numberOfOccurrences: 3007,
    percentageOfOccurrences: "0.086%",
    AHRQ: false,
    CMS: true,
  },
  {
    id: 2,
    ICDCode: "C430",
    ICDDescription: "Malignant melanoma of lip",
    HCCCode: "B0224",
    HCCDescription: "Postherpetic myelitis",
    reviewed: "Inactive",
    reviewedDate: "09-10-2023",
    numberOfOccurrences: 107,
    percentageOfOccurrences: "0.0016%",
    AHRQ: false,
    CMS: true,
  },
  {
    id: 3,
    ICDCode: "C9301",
    ICDDescription: "Acute monoblastic/monocytic leukemia, in remission",
    HCCCode: "A0104",
    HCCDescription: "Typhoid arthritis",
    reviewed: "Active",
    reviewedDate: "11-07-2022",
    numberOfOccurrences: 890892,
    percentageOfOccurrences: "2.98%",
    AHRQ: false,
    CMS: true,
  },
];

const ChronicCodeListPending = [
  {
    id: 1,
    ICDCode: "A4102",
    ICDDescription: "Sepsis due to Methicillin resistant Staphylococcus aureus",
    HCCCode: "A5440",
    HCCDescription:
      "Gonococcal infection of musculoskeletal system, unspecified",
    numberOfOccurrences: 19073,
    percentageOfOccurrences: "1.086%",
    AHRQ: false,
    CMS: true,
  },
];

const RenderBooleanTag = ({ boolean }) => (
  <span
    className="rounded-1"
    style={{
      fontSize: "0.9rem",
      color: boolean ? "#15803d" : "#b91c1c",
      background: boolean ? "#f0fdf4" : "#fef2f2",
      border: boolean
        ? "1px solid rgb(22 163 74 / 0.2)"
        : "1px solid rgb(220 38 38 / 0.1)",
      padding: "0.1rem 0.6rem",
    }}
  >
    {boolean ? "Yes" : "No"}
  </span>
);

const ReviewChronicCodeList = () => {
  const [tabState, setTabState] = useState("all");

  const columns = [
    {
      title: "ICD details",
      key: "icd",
      render: (_, { ICDCode, ICDDescription }) => (
        <>
          <div
            style={{
              fontWeight: 500,
              fontSize: "1rem",
              color: "var(--bs-gray-800)",
            }}
          >
            {ICDCode}
          </div>
          <div
            style={{
              fontSize: "0.9rem",
              color: "var(--bs-gray-600)",
            }}
          >
            {ICDDescription}
          </div>
        </>
      ),
    },
    {
      title: "HCC details",
      key: "hcc",
      render: (_, { HCCCode, HCCDescription }) => (
        <>
          <div
            style={{
              fontWeight: 500,
              fontSize: "1rem",
              color: "var(--bs-gray-800)",
            }}
          >
            {HCCCode}
          </div>
          <div
            style={{
              fontSize: "0.9rem",
              color: "var(--bs-gray-600)",
            }}
          >
            {HCCDescription}
          </div>
        </>
      ),
    },
    {
      title: "Occurrences",
      key: "occurrences",
      render: (_, { numberOfOccurrences, percentageOfOccurrences }) => (
        <>
          <div
            style={{
              fontWeight: 500,
              fontSize: "1rem",
              color: "var(--bs-gray-800)",
            }}
          >
            {numberOfOccurrences}
          </div>
          <div
            style={{
              fontSize: "0.9rem",
              color: "var(--bs-gray-600)",
            }}
          >
            {percentageOfOccurrences}
          </div>
        </>
      ),
    },
    {
      title: "AHRQ",
      key: "AHRQ",
      render: (_, { AHRQ }) => <RenderBooleanTag boolean={AHRQ} />,
    },
    {
      title: "CMS",
      key: "CMS",
      render: (_, { CMS }) => <RenderBooleanTag boolean={CMS} />,
    },
    tabState === "all"
      ? {
          title: "Reviewed",
          key: "reviewed",
          width: 200,
          render: (_, { reviewed, reviewedDate }) => (
            <>
              <div
                style={{
                  fontWeight: 500,
                  fontSize: "1rem",
                  color: "var(--bs-gray-800)",
                }}
              >
                {reviewed}
              </div>
              <div
                style={{
                  fontSize: "0.9rem",
                  color: "var(--bs-gray-600)",
                }}
              >
                Since {reviewedDate}
              </div>
            </>
          ),
        }
      : {
          title: "Select Status",
          key: "selectStatus",
          render: () => (
            <MyCustomSelectCreatable selectOptions={CCMDChoiceList} />
          ),
        },
    tabState === "all"
      ? {
          key: "taskId",
          render: (_, { reviewed }) => (
            <Dropdown
              menu={{
                items: [
                  {
                    key: "1",
                    label: `Mark as ${
                      reviewed === "Active" ? "Inactive" : "Active"
                    }`,
                    icon: (
                      <MdOutlineModeEditOutline
                        style={{
                          width: "1rem",
                          height: "1rem",
                          lineHeight: 22,
                          color: "var(--bs-gray-600)",
                        }}
                      />
                    ),
                  },
                ],
              }}
              placement="bottomRight"
            >
              <div
                className="mx-auto"
                style={{ cursor: "pointer" }}
                onClick={(e) => e.preventDefault()}
              >
                <BsThreeDotsVertical />
              </div>
            </Dropdown>
          ),
        }
      : [],
  ];

  return (
    <div style={{ minHeight: "90vh" }}>
      <PageTitle title={`Chronic Code List`} />
      <div style={{ color: "var(--bs-gray-800)" }}>
        <div className="d-flex justify-content-between">
          <Segmented
            options={[
              { label: "Show all", value: "all" },
              { label: "Show pending review", value: "pending" },
            ]}
            style={{ marginBottom: "2rem" }}
            onChange={(value) => setTabState(value)}
          />
          <Search
            className="col-md-3"
            size="medium"
            placeholder="Search"
            allowClear
            // onChange={(e) => setQuery(e.target.value)}
          />
        </div>
        <Table
          columns={columns}
          dataSource={
            tabState === "all"
              ? ChronicCodeListReviewed
              : ChronicCodeListPending
          }
          size="large"
        />
      </div>
    </div>
  );
};

export default ReviewChronicCodeList;
