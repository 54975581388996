import React, { useEffect, useState } from "react";
import TextArea from "antd/es/input/TextArea";
import MyTextBox from "../../common/elements/myTextbox";
import { openNotification } from "../../common/elements/myNotification";
import { addOrderRequest } from "../../api/CareEventSCPApi";
import { DatePicker } from "antd";
import MyCustomSelect from "../../common/elements/myCustomSelect";
import dayjs from "dayjs";
const dateFormat = ["YYYY-MM-DD"];
const orderStatusList = [
  {
    value: "APPROVED",
    label: "APPROVED",
  },
  {
    value: "CANCELLED",
    label: "CANCELLED",
  },
  {
    value: "DENIED",
    label: "DENIED",
  },
  {
    value: "PENDING",
    label: "PENDING",
  },
  {
    value: "REDIRECTED",
    label: "REDIRECTED",
  },
  {
    value: "REQUESTED",
    label: "REQUESTED",
  },
];

export const OrderRequestAdd = ({ editRecord, callback }) => {
  const [formData, setFormData] = useState(
    editRecord?.guid
      ? {
          ...editRecord,
          modifiedBy: sessionStorage.getItem("userName"),
        }
      : editRecord
  );
  const [pageError, setPageError] = useState({});
  const [selectedDate, setSelectedDate] = useState();
  const [orderStatus, setOrderStatus] = useState();
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    if (editRecord?.guid) {
      let osTemp = [];
      if (editRecord?.orderStatus) {
        osTemp = orderStatusList?.filter((item) => {
          return item.value === editRecord?.orderStatus;
        });
      }
      setOrderStatus(osTemp);
    }
  }, [editRecord]);

  const handleInputChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
    setPageError({ ...pageError, [name]: value ? "" : "Required" });
  };
  const onDateChange = (date, dateString) => {
    setSelectedDate(date || "");
    setFormData({
      ...formData,
      dateOfOrderSubmoission: dateString ? dateString : "",
    });
    // setPageError({
    //   ...pageError,
    //   dateOfOrderSubmoission: dateString ? "" : "Required",
    // });
  };

  const onSubmit = (e) => {
    setPageError({});
    let pageErrorTemp = {};
    if (
      !formData?.urgent ||
      !formData?.palliativeProviderOrder ||
      !formData?.palliativeProviderName ||
      !formData?.reasonForOrder ||
      !formData?.IPA ||
      !formData?.serviceProvider ||
      !formData?.authoriastionNo ||
      !formData?.orderStatus ||
      !formData?.comments
    ) {
      pageErrorTemp.urgent = formData?.urgent ? "" : "Required";
      pageErrorTemp.palliativeProviderOrder = formData?.palliativeProviderOrder
        ? ""
        : "Required";
      pageErrorTemp.palliativeProviderName = formData?.palliativeProviderName
        ? ""
        : "Required";
      pageErrorTemp.reasonForOrder = formData?.reasonForOrder ? "" : "Required";
      pageErrorTemp.IPA = formData?.IPA ? "" : "Required";
      pageErrorTemp.serviceProvider = formData?.serviceProvider
        ? ""
        : "Required";
      pageErrorTemp.authoriastionNo = formData?.authoriastionNo
        ? ""
        : "Required";
      pageErrorTemp.orderStatus = formData?.orderStatus ? "" : "Required";
      pageErrorTemp.comments = formData?.comments ? "" : "Required";

      setPageError(pageErrorTemp);
      return;
    }
    setDisable(true);
    onSave(e);
  };

  const onSave = async (e) => {
    try {
      const response = await addOrderRequest(formData);
      if (response?.status === 200 && !formData?.guid) {
        openNotification(
          "success",
          "Added",
          "Order/Request Template Added Successfully!"
        );
      } else if (formData?.guid) {
        openNotification(
          "success",
          "Updated",
          "Order/Request Template Updated Successfully!"
        );
      } else {
        openNotification(
          "error",
          "Error",
          "Order/Request Template Failed to Add!"
        );
      }
      setFormData(editRecord);
      setDisable(false);
      callback(response);
    } catch (e) {
      return e;
    }
  };

  return (
    <div className="tw-relative tw-px-2">
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="urgent">
          Urgent?
        </label>
        <fieldset className="fw-normal">
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="urgent"
              checked={formData?.urgent === "Yes"}
              id="Yes"
              value={"Yes"}
              onChange={(e) =>
                handleInputChange(
                  "urgent",
                  e.currentTarget.value ? "Yes" : "No"
                )
              }
            />
            <label className="form-check-label" htmlFor="Yes">
              Yes
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="urgent"
              checked={formData?.urgent === "No"}
              id="No"
              value={"No"}
              onChange={(e) =>
                handleInputChange(
                  "urgent",
                  e.currentTarget.value ? "No" : "Yes"
                )
              }
            />
            <label className="form-check-label" htmlFor="No">
              No
            </label>
          </div>
          <span
            className="text-danger"
            style={{ fontWeight: "normal", paddingLeft: "5px" }}
          >
            {pageError?.urgent}
          </span>
        </fieldset>
      </div>
      <div className="col-md-11 col-lg-11">
        <div className="form-group tw-mb-3">
          <MyTextBox
            label={"Allergy"}
            value={formData?.allergy}
            setValue={(e) => handleInputChange("allergy", e.target.value)}
            //errorMessage={pageError?.allergy}
          />
        </div>
      </div>
      <div className="col-md-11 col-lg-11">
        <div className="form-group tw-mb-3">
          <MyTextBox
            label={"Palliative Provider's Order"}
            value={formData?.palliativeProviderOrder}
            setValue={(e) =>
              handleInputChange("palliativeProviderOrder", e.target.value)
            }
            errorMessage={pageError?.palliativeProviderOrder}
          />
        </div>
      </div>
      <div className="col-md-11 col-lg-11 form-group">
        <div className="tw-flex ">
          <label className="tw-font-bold" htmlFor="dateTime">
            Date of Order Submission
          </label>
        </div>
        <DatePicker
          use12Hours
          className="form-control tw-mb-3"
          onChange={onDateChange}
          format={dateFormat}
          value={
            formData?.dateOfOrderSubmoission
              ? dayjs(formData.dateOfOrderSubmoission)
              : ""
          }
          // style={{
          //   border: pageError?.dateOfOrderSubmoission ? "1px solid red" : "",
          // }}
        />
      </div>
      <div className="col-md-11 col-lg-11">
        <div className="form-group tw-mb-3">
          <MyTextBox
            label={"Name of Ordering Palliative Provider"}
            value={formData?.palliativeProviderName}
            setValue={(e) =>
              handleInputChange("palliativeProviderName", e.target.value)
            }
            errorMessage={pageError?.palliativeProviderName}
          />
        </div>
      </div>
      <div className="col-md-11 col-lg-11">
        <div className="form-group tw-mb-3">
          <MyTextBox
            label={"Reason for Order"}
            value={formData?.reasonForOrder}
            setValue={(e) =>
              handleInputChange("reasonForOrder", e.target.value)
            }
            errorMessage={pageError?.reasonForOrder}
          />
        </div>
      </div>
      <div className="col-md-11 col-lg-11">
        <div className="form-group tw-mb-3">
          <MyTextBox
            label={"IPA"}
            value={formData?.IPA}
            setValue={(e) => handleInputChange("IPA", e.target.value)}
            errorMessage={pageError?.IPA}
          />
        </div>
      </div>
      <div className="col-md-11 col-lg-11">
        <div className="form-group tw-mb-3">
          <MyTextBox
            label={"Servicing Provider"}
            value={formData?.serviceProvider}
            setValue={(e) =>
              handleInputChange("serviceProvider", e.target.value)
            }
            errorMessage={pageError?.serviceProvider}
          />
        </div>
      </div>
      <div className="col-md-11 col-lg-11">
        <div className="form-group tw-mb-3">
          <MyTextBox
            label={"Authorization Number"}
            value={formData?.authoriastionNo}
            setValue={(e) =>
              handleInputChange("authoriastionNo", e.target.value)
            }
            errorMessage={pageError?.authoriastionNo}
            maxLength={50}
          />
        </div>
      </div>
      <div className="col-md-11 col-lg-11 tw-mb-3">
        <label className="tw-font-bold" htmlFor="hospitalname">
          Order Status
        </label>
        <MyCustomSelect
          selectOptions={orderStatusList}
          myCallBack={(e) => {
            handleInputChange("orderStatus", e ? e.value : "");
            setOrderStatus(e);
          }}
          selectedValue={orderStatus}
          errorMessage={pageError?.orderStatus}
        />
      </div>
      <div className="col-md-11 col-lg-11">
        <div className="form-group ">
          <label
            htmlFor="priority"
            className="tw-fw-bold tw-flex tw-justify-between"
          >
            <div className="tw-flex tw-items-center">
              <div className="fw-bold">Comments</div>
            </div>
          </label>
          <TextArea
            autoSize={{
              minRows: 5,
              maxRows: 10,
            }}
            className="form-control "
            maxLength={"1000"}
            value={formData?.comments}
            onChange={(e) => handleInputChange("comments", e.target.value)}
            style={{ border: pageError.comments ? "1px solid red" : "" }}
          />
        </div>
      </div>

      <div className="form-group tw-fixed tw-top-2 tw-right-10">
        <button
          className={!disable ? "btn btn-success" : "btn btn-success disabled"}
          onClick={onSubmit}
        >
          {editRecord?.guid ? "Update" : "Save"}
        </button>
        <button
          className="btn btn-secondary tw-ml-4"
          onClick={() => callback(false)}
        >
          Close
        </button>
      </div>
    </div>
  );
};
