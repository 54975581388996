import React, { Component, createContext, useContext, useState } from "react";
import {
  MsalProvider,
  AuthenticatedTemplate,
  useMsal,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { Layout } from "./components/Layout";
import { MyContextProvider } from "./common/elements/myContext";
import "./custom.css";

export const App = ({ instance }) => {
  return (
    <MyContextProvider>
      <MsalProvider instance={instance}>
        <Layout />
      </MsalProvider>
    </MyContextProvider>
  );
};

export default App;
