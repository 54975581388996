import React, { useState } from "react";
import MyBackdropSpinner from "../../common/elements/myBackdropSpinner";
import dayjs from "dayjs";
import { BsFiletypeCsv } from "react-icons/bs";
import useSWR from "swr";
import { Table, DatePicker, Space, Radio } from "antd";
import { fetcher } from "../../services/fectcher";
import Search from "antd/es/input/Search";
import { CSVLink } from "react-csv";
import { WarningOutlined } from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import {
  formatOptionLabel,
  getLobColors,
} from "../../common/elements/myCustomSelect";
import PageTitle from "../Common/PageTitle";
import { lobList } from "../CCMDPatients/CCMDPatientsAll";
import DetailedCSV from "../../common/elements/DetailedCSV";


export const RCADeatilsReport = () => {
  const { RangePicker } = DatePicker;
  const location = useLocation();

  const disabledDate = (current, { from }) => {
    if (from) {
      return Math.abs(current.diff(from, "days")) >= 31;
    }
    return false;
  };

  const [fromDate, setFromDate] = useState(
    dayjs().subtract(7, "day").format("YYYY-MM-DD")
  );

  const [toDate, setToDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [query, setQuery] = useState("");
  const [lob, setLob] = useState(
    location.state?.lob ? location.state.lob : "HN"
  );

  const { data, isLoading } = useSWR(
    `GetRcaForDateRange?fromDate=${fromDate}&toDate=${toDate}&lob=${lob}`,
    fetcher
  );

  const columns = [
    {
      key: "patientName",
      title: "Patient Name",

      render: (_, rowItem) => {
        return (
          <div className="tw-flex tw-space-x-2">
            <Link to={`/rca/report/${rowItem.admitGuid}`} state={{ lob: lob }}>
              {formatOptionLabel({ label: rowItem.patientNameWithDOB })}
            </Link>
          </div>
        );
      },
    },
    {
      key: "admissionType",
      title: "Admission Type",
      dataIndex: "admissionType",
    },
    {
      key: 4,
      title: "TOC Created By",
      dataIndex: "createdBy",
      width: 150,
      render: (createdBy) => <div>{createdBy?.split("@")[0]}</div>,
    },
    {
      key: "dateofAdmission",
      title: "Date of Admission",
      dataIndex: "dateofAdmission",
    },
    {
      key: "dichargeDate",
      title: "Date of Discharge",
      dataIndex: "dichargeDate",
    },
    {
      key: 4,
      title: "RCA Last Edited By",
      dataIndex: "modifiedBy",
      width: 150,
      render: (modifiedBy) => <div>{modifiedBy?.split("@")[0]}</div>,
    },
    {
      key: 14,
      title: "RCA Date and Time",
      dataIndex: "rcaDateandTime",
      render: (_, { rcaDateandTime, createdBy }) => {
        return (
          <>
            <div>
              {rcaDateandTime !== "" ? (
                dayjs(rcaDateandTime).format("YYYY-MM-DD hh:mm A")
              ) : (
                <>
                  <div>
                    <span
                      className="rounded-1"
                      style={{
                        color: "rgb(185, 28, 28)",
                        background: "#fed7aa",
                        padding: "0.1rem 0.4rem",
                        marginTop: "6px",
                        width: "max-content",
                      }}
                    >
                      {"Complete RCA"}
                    </span>
                    <span className="tw-ml-2" style={{ color: "red" }}>
                      <WarningOutlined />
                    </span>
                  </div>
                </>
              )}
            </div>
            {/* <div>{createdBy?.split("@")[0]}</div> */}
          </>
        );
      },
    },
  ];

  const finalResult = data
    ? data.filter((item) => {
        return [
          "patientNameWithDOB",
          "dob",
          "admissionType",
          "dateofAdmission",
          "dichargeDate",
          "rcaDateandTime",
          "modifiedBy",
          "createdBy",
        ].some((key) => {
          if (key === "rcaDateandTime") {
            const rcaData =
              item[key] === ""
                ? "Complete RCA"
                : dayjs(item[key]).format("YYYY-MM-DD hh:mm A");
            return rcaData
              ?.toString()
              ?.toLowerCase()
              .includes(query?.toString()?.toLowerCase());
          } else
            return (item[key]?.toString()?.toLowerCase() ?? "").includes(
              query?.toLowerCase() ?? ""
            );
        });
      })
    : data;

  // Modify filteredDetailData to filter based on tableFiltered records
  const filteredDetailData = data?.filter((detailItem) => {
    // Find corresponding record in tableFiltered based on a common property (like patient or event)
    return finalResult?.some(
      (filteredItem) =>
        // Adjust condition to compare relevant fields between detailItem and filteredItem
        (filteredItem?.patientName === detailItem?.patientName) &&
        (filteredItem?.guid === detailItem?.guid)
    );
  });

  if (isLoading) {
    return <MyBackdropSpinner spinner={true} />;
  }
  return (
    <div>
      <div className="tw-flex tw-justify-between tw-mb-4 ">
        <Space>
          <PageTitle title={"RCA Details Report"} />

          <Radio.Group
            onChange={(e) => setLob(e.target.value)}
            value={lob}
            className="tw-mb-3 tw-ml-2"
          >
            {lobList.map(
              (i) =>
                i.value !== "SCP" && (
                  <Radio key={i.value} value={i.value}>
                    <span
                      className={`tw-py-0.5 tw-px-2 tw-text-xs tw-rounded ${getLobColors(
                        i.value
                      )}`}
                    >
                      {i.label}
                    </span>
                  </Radio>
                )
            )}
          </Radio.Group>
        </Space>

        <div className="tw-flex tw-justify-end ">
        <Space>
          <Search
            placeholder="Search"
            onChange={(e) => setQuery(e.target.value)}
            allowClear
            value={query}
          />
          <RangePicker
            className="tw-w-full"
            disabledDate={disabledDate}
            format="YYYY-MM-DD"
            onChange={(date, dateString) => {
              setFromDate(dateString[0]);
              setToDate(dateString[1]);
              setQuery("");
            }}
            value={[
              fromDate ? dayjs(fromDate) : "",
              toDate ? dayjs(toDate) : "",
            ]}
          />
          {/* <CSVLink data={csvData} filename={"report.csv"}>
            <BsFiletypeCsv size={"2em"} />
          </CSVLink> */}    
        </Space>
        <div className="tw-ml-2 tw-mt-2"> 
        <DetailedCSV                
                detailData={filteredDetailData || []}
                event={"RCA"}
                fromDate={fromDate}
                toDate={toDate}    
              />
        </div>
        </div>
       
      </div>
      <Table
        size="middle"
        dataSource={finalResult}
        columns={columns}
        pagination={{
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
        }}
      />
    </div>
  );
};
