import { axiosInstance } from "./AxiosInstance";

export async function getUserLogin() {
  return axiosInstance
    .get("/api/UserLogin")
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function getUsers() {
  return axiosInstance
    .get("/api/Users")
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
