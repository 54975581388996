import React, { useEffect, useState } from "react";
import * as ScoreApi from "../../api/scoreApi";
import * as FacilityApi from "../../api/FacilityApi";
import Eligibility from "./Eligibility";
import ScoreFilter from "./ScoreFilter";
import ScoreFilterSummary from "./ScoreFilterSummary";
import PageTitle from "../Common/PageTitle";
import { useParams } from "react-router-dom";
import { useMyContext } from "../../common/elements/myContext";
import MyBackdropSpinner from "../../common/elements/myBackdropSpinner";

const ScoreNew = (props) => {
  const params = useParams();

  const { getFormattedDateDayJs, CalculateAge, converttoDate } = useMyContext();

  const [scoreResultFinal, setScoreResultFinal] = useState({});
  const [loading, setLoading] = useState(false);
  const [spinner, setSpinner] = useState(false);

  const [resultMode, setResultMode] = useState(false);

  const [icd10Codes, setIcd10Codes] = useState([]);
  const [listOfSources, setListOfSources] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [facilityList, setFacilityList] = useState([]);
  const [genderList, setGenderList] = useState([]);
  const [renderingProviderList, setRenderingProviderList] = useState([]);

  const [guid, setGuid] = useState(params?.guid);

  const [requests, setRequest] = useState({
    guid: params?.guid || "",
    providerGroupID: "",
    renderingProviderID: "",
    facilityId: "",
    region: "",
    userName: "",
    fromDate: "2021-01-01T12:12:11.477Z",
    toDate: new Date(),
  });

  const [scoreFilter, setScoreFilter] = useState({
    ccmdProvider: "",
    mbi: params?.mbiID || "",
    firstName: params?.firstName || "",
    lastName: params?.lastName || "",
    dob: "",
    age: "",
    gender: null,
    icd10List: [],
    potentialIcd10List: [],
    recentHospitalizationInfo: "",
    facility: null,
    renderingProvider: null,
    providerGroup: params?.providerGroupName,
  });

  useEffect(() => {
    setSpinner(false);
    const icdPromise = ScoreApi.getIcd10CodesPromise();
    const regionPromise = ScoreApi.getPicklistPromise(1);
    const facilityPromise = FacilityApi.getFacilitiesPromise();
    const genderPromise = ScoreApi.getPicklistPromise(4);
    const renderingProviderPromise = ScoreApi.getPicklistPromise(2);
    const sourcePromise = ScoreApi.getPicklistPromise(5);
    const hccreviewPromise = ScoreApi.getScorePredictionReport(requests);

    const allPromise = [
      icdPromise,
      regionPromise,
      facilityPromise,
      genderPromise,
      renderingProviderPromise,
      sourcePromise,
    ];
    if (params?.guid) {
      allPromise.push(hccreviewPromise);
    }

    Promise.all(allPromise).then((values) => {
      //icd Code
      localStorage.setItem("apiData", JSON.stringify(values[0].data));
      const icdDataResult = values[0]?.data;
      let icdData = [];

      icdDataResult.forEach((item) => {
        icdData.push({
          value: item.code,
          label: item.code + " " + item.description,
        });
      });
      setIcd10Codes(icdData);

      //region
      const rList = [];
      values[1]?.data?.keyValuePair?.forEach((item) => {
        rList.push({
          label: item.value,
          value: item.key,
        });
      });
      setRegionList(rList);

      //facility
      const fList = [];
      values[2]?.data?.forEach((item) => {
        fList.push({
          label: item.facilityName,
          value: item.facilityId,
        });
      });

      let facilityTemp = [];
      if (params?.facilityID) {
        facilityTemp = fList.filter((item) => {
          return item.value === params?.facilityID;
        });
      }

      setFacilityList(fList);

      // gender
      const gList = [];
      values[3]?.data?.keyValuePair?.forEach((item) => {
        gList.push({
          label: item.value,
          value: item.key,
        });
      });

      let genderTemp = [];
      if (params?.gender) {
        genderTemp = gList.filter((item) => {
          return item.value === params?.gender;
        });
      }
      setGenderList(gList);
      // rendering Provider
      const rpList = [];
      values[4]?.data?.keyValuePair?.forEach((item) => {
        rpList.push({
          label: item.value,
          value: item.key,
        });
      });
      let rpTemp = [];
      if (params?.renderingProviderID) {
        rpTemp = rpList.filter((item) => {
          return item.value === params?.renderingProviderID;
        });
      }
      setRenderingProviderList(rpList);

      // Soruce
      const sList = [];
      values[5]?.data?.keyValuePair?.forEach((item) => {
        sList.push({
          label: item.value,
          value: item.key,
        });
      });
      setListOfSources(sList);

      //hccreview Edit

      const reviewList = values[6]?.data?.recordList[0];
      const reviewListi = reviewList?.icD10Codes?.filter(
        (i) => i.isPotential === "No"
      );
      const reviewListp = reviewList?.icD10Codes?.filter(
        (i) => i.isPotential === "Yes"
      );
      // console.log("rp", reviewListp);
      

      let defaultgender = [];
      if (reviewList?.gender === "M") {
        defaultgender = { label: "Male", value: "M" };
      } else {
        defaultgender = { label: "Female", value: "F" };
      }
      let tempeligible = "";
      if (reviewList?.isEligible === "Y" && reviewList?.isFull === "Y") {
        tempeligible = "Yes";
      } else if (reviewList?.isEligible === "Y" && reviewList?.isFull === "N") {
        tempeligible = "Partial";
      } else {
        tempeligible = "No";
      }

      let icd10 = [];
      let picd10 = [];
      reviewList?.icD10Codes?.forEach((item) => {
        if (item.isPotential !== "Yes") {
          icd10.push({
            label: item.icdDescription,
            value: item.icd10,
          });
        } else {
          picd10.push({
            label: item.icdDescription,
            value: item.icd10,
          });
        }
      });
      let isource = [];
      let psource = [];
      let itext;
      let ptext;
      reviewListi?.forEach((item, index) => {
        if (item.icD10SourceID === "6") {
          isource.push({
            label: "Other",
            value: item.icD10SourceID,
          });
          itext = { [index]: item.icD10SourceAddnlInfo };
        } else {
          isource.push({
            label: item.icD10SourceAddnlInfo,
            value: item.icD10SourceID,
          });
        }
      });
      reviewListp?.forEach((item, index) => {
        if (item.icD10SourceID === "6") {
          psource.push({
            label: "Other",
            value: item.icD10SourceID,
          });
          ptext = { [index]: item.icD10SourceAddnlInfo };
        } else {
          psource.push({
            label: item.icD10SourceAddnlInfo,
            value: item.icD10SourceID,
          });
        }
      });

      let rpReviewTemp = [];
      if (reviewList?.renderingProviderName) {
        rpReviewTemp = rpList.filter((item) => {
          return item.label === reviewList?.renderingProviderName;
        });
      }
      let facilityReviewTemp = [];
      if (reviewList?.facilityName) {
        facilityReviewTemp = fList.filter((item) => {
          return item.label === reviewList?.facilityName;
        });
      }
      let regionTemp = [];
      if (reviewList?.region) {
        regionTemp = rList.filter((item) => {
          return item.value === reviewList?.region;
        });
      }
      const paramsdob = getFormattedDateDayJs(params.dateOfBirth);
      const paramsage = CalculateAge(paramsdob);
      if (params.guid == null) {
        setScoreFilter({
          ...scoreFilter,
          facility: facilityTemp ? facilityTemp[0] : null,
          gender: genderTemp ? genderTemp[0] : null,
          renderingProvider: rpTemp ? rpTemp[0] : null,
          dob: paramsdob ? paramsdob : "1999-01-01",
          age: paramsage.age,
        });
      } else {
        setScoreFilter({
          guid: params.guid || "",
          mbi: reviewList.mbiid,
          ccmdProvider: reviewList.physicianName,
          lastName: reviewList.fullName.split(",")[0],
          firstName: reviewList.fullName.split(",")[1],
          potentialIcd10List: reviewList.icD10Codes,
          providerGroup: reviewList.providerGroupName,
          renderingProvider: rpReviewTemp ? rpReviewTemp[0] : null,
          age: reviewList.age,
          dob: getFormattedDateDayJs(reviewList.dob),
          gender: defaultgender,
          icd10Source: isource,
          icd10SourceText: itext,

          picd10Source: psource,
          picd10SourceText: ptext,
          icd10CodesOriginal: icd10,
          picd10CodesOriginal: picd10,
          recentHospitalizationInfo: reviewList.recentHospitalizationInfo,
          eligibleOption: tempeligible,
          notes: reviewList.notes,
          region: regionTemp ? regionTemp[0] : null,
          facility: facilityReviewTemp ? facilityReviewTemp[0] : null,
        });
      }
      setSpinner(true);
    });
  }, [params, requests]);

  const getPredictedWithPotential = async (request) => {
    setLoading(true);
    setResultMode(false);
    try {
      const response = await ScoreApi.getScoreWithPotential(request);
      setScoreResultFinal(response?.data);
      setLoading(false);
      setResultMode(true);
    } catch (error) {
      console.error(error);
      setScoreResultFinal({});
      setLoading(false);
      setResultMode(true);
    }
  };
  const submitClicked = (scoreFilterRequest) => {
        let request = {
      requestId: guid || "",
      physicianName: scoreFilterRequest.ccmdProvider,
      mbiid: scoreFilterRequest.mbi,
      firstName: scoreFilterRequest.firstName,
      lastName: scoreFilterRequest.lastName,
      age: "",
      dob: converttoDate(scoreFilterRequest.dob),
      gender: scoreFilterRequest.gender?.value,
      providerGroupID: scoreFilterRequest.providerGroup,
      renderingProviderID: scoreFilterRequest.renderingProvider?.value,
      icd10List: scoreFilterRequest?.icd10List,
      potentialIcd10List: scoreFilterRequest?.Picd10List,
      recentHospitalizationInfo: scoreFilterRequest.recentHospitalizationInfo,
      region: scoreFilterRequest.region?.value,
      facilityId: scoreFilterRequest.facility?.value,
      isEligible: scoreFilterRequest?.isEligible,
      isFull: scoreFilterRequest?.isFull,
      notes: scoreFilterRequest?.notes,
    };
    let eligibleOption = "";
    if (scoreFilterRequest?.isEligible && scoreFilterRequest?.isFull) {
      eligibleOption = "Yes";
    } else if (scoreFilterRequest?.isEligible && !scoreFilterRequest.isFull) {
      eligibleOption = "Partial";
    } else {
      eligibleOption = "No";
    }

    setScoreFilter({
      guid: params.guid || "",
      ccmdProvider: scoreFilterRequest.ccmdProvider,
      mbi: scoreFilterRequest.mbi,
      firstName: scoreFilterRequest.firstName,
      lastName: scoreFilterRequest.lastName,
      age: scoreFilterRequest.age?.toString(),
      dob: scoreFilterRequest.dob,
      gender: scoreFilterRequest.gender,
      providerGroup: scoreFilterRequest.providerGroup,
      renderingProvider: scoreFilterRequest.renderingProvider,

      icd10List: scoreFilterRequest.icd10List,
      icd10Source: scoreFilterRequest.icd10Source,
      icd10SourceText: scoreFilterRequest.icd10SourceText,

      potentialIcd10List: scoreFilterRequest.Picd10List,
      picd10Source: scoreFilterRequest.picd10Source,
      picd10SourceText: scoreFilterRequest.picd10SourceText,

      icd10CodesOriginal: scoreFilterRequest.icd10CodesOriginal,
      picd10CodesOriginal: scoreFilterRequest.picd10CodesOgiginal,
      recentHospitalizationInfo: scoreFilterRequest.recentHospitalizationInfo,
      region: scoreFilterRequest.region,
      facility: scoreFilterRequest.facility,
      eligibleOption: eligibleOption,
      notes: scoreFilterRequest.notes,
    });
    getPredictedWithPotential(request);
  };

  const cancelClick = () => {
    setResultMode(false);
    setGuid(scoreResultFinal.requestID);
  };

  const onEligibilitySaveFinished = () => {
    setScoreFilter({
      ccmdProvider: "",
      mbi: "",
      firstName: "",
      lastName: "",
      age: "",
      dob: "",
      gender: null,
      providerGroupID: "",
      renderingProviderID: null,
      icd10List: [],
      potentialIcd10List: [],
      recentHospitalizationInfo: "",
      region: "",
      facility: null,
    });

    cancelClick();
  };

  return (
    <div className="pb-5" key="scorePredictionPanelNew">
      <PageTitle title={"HCC Patient Worksheet"} />
      {!spinner && <MyBackdropSpinner show={spinner} />}
      <div
        className="row"
        style={{
          border: "1px solid lightgray",
          padding: "20px",
          boxShadow: "3px 0px 5px 3px #e3e8e5",
        }}
      >
        <div className="col-md-12">
          {!resultMode && (
            <ScoreFilter
              defalutFilter={scoreFilter}
              regionList={regionList}
              genderList={genderList}
              submitClicked={submitClicked}
              loading={loading}
              icd10Codes={icd10Codes}
              listOfSources={listOfSources}
              facilityList={facilityList}
              renderingProviderList={renderingProviderList}
            ></ScoreFilter>
          )}

          {resultMode && (
            <>
              <ScoreFilterSummary
                scoreFilter={scoreFilter}
              ></ScoreFilterSummary>
              <div className="row" style={{ marginBottom: "-70px" }}>
                <div className="col-md-12 text-center">
                  <button
                    type="button"
                    className="btn btn-primary round-6"
                    onClick={cancelClick}
                    style={{
                      borderRadius: "70%",
                      width: "100px",
                      height: "100px",
                    }}
                  >
                    Try Again
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <>
        <div
          className="row"
          style={{
            border: "1px solid lightgray",
            padding: "20px",
            boxShadow: "3px 0px 5px 3px #e3e8e5",
          }}
        >
          <div className="col-md-12">
            <h3>Score Summary</h3>

            {!loading && !resultMode && <div>No Data Available</div>}

            {loading && (
              <>
                <p className="placeholder-glow">
                  <span className="placeholder col-9"></span>
                </p>

                <p className="placeholder-wave">
                  <span className="placeholder col-7"></span>
                </p>
                <p className="placeholder-glow">
                  <span className="placeholder col-5"></span>
                </p>
              </>
            )}
            {resultMode && (
              <div className="my-3">
                <div className="row pb-2">
                  <h5>Known RAF</h5>
                  <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                      <label htmlFor="totalScore">Total Score</label>
                      <h4 className="px-2">
                        {scoreResultFinal?.riskFactorValuesNormal?.totalScore}
                      </h4>
                    </div>
                  </div>
                  <div className="col-lg-3  col-md-6">
                    <div className="form-group">
                      <label htmlFor="totalScore">
                        Age/Gender Based Risk Score
                      </label>
                      <h4 className="px-2">
                        {
                          scoreResultFinal?.riskFactorValuesNormal
                            ?.ageGenderBasedRiskFactors
                        }
                      </h4>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                      <label htmlFor="totalScore">
                        HCC Count Based Risk Score
                      </label>
                      <h4 className="px-2">
                        {
                          scoreResultFinal?.riskFactorValuesNormal
                            ?.hccCountBasedRiskFactors
                        }
                      </h4>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <h5>Potential RAF</h5>
                  <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                      <label htmlFor="totalScore">Total Score</label>
                      <h4 className="px-2">
                        {
                          scoreResultFinal?.riskFactorValuesWithPotentialICD
                            ?.totalScore
                        }
                      </h4>
                    </div>
                  </div>
                  <div className="col-lg-3  col-md-6">
                    <div className="form-group">
                      <label htmlFor="totalScore">
                        Age/Gender Based Risk Score
                      </label>
                      <h4 className="px-2">
                        {
                          scoreResultFinal?.riskFactorValuesWithPotentialICD
                            ?.ageGenderBasedRiskFactors
                        }
                      </h4>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                      <label htmlFor="totalScore">
                        HCC Count Based Risk Score
                      </label>
                      <h4 className="px-2">
                        {
                          scoreResultFinal?.riskFactorValuesWithPotentialICD
                            ?.hccCountBasedRiskFactors
                        }
                      </h4>
                    </div>
                  </div>
                </div>

                <div className="row pt-3">
                  <div className="col-md-12">
                    <h5> Details</h5>

                    <table className="table table-responsive">
                      <thead>
                        <tr>
                          <th scope="col">ICD10</th>
                          <th scope="col">ICD10 Description</th>
                          <th scope="col">Source</th>
                          <th scope="col">HCC</th>
                          <th scope="col">HCC Description</th>
                          <th scope="col">Relative Factor</th>
                          <th scope="col">Potential</th>
                        </tr>
                      </thead>

                      <tbody>
                        {scoreResultFinal?.hccRiskFactorsNormal?.map((item) => {
                          let foundItem = listOfSources.find(
                            (i) => i.value === item.icD10SourceID
                          );
                          let label = foundItem?.label;
                          return (
                            <tr key={"hccRiskFactorsNormal" + item.icd10}>
                              <td>{item.icd10}</td>
                              <td>{item.icd10Description}</td>
                              <td>{label}</td>
                              <td>{item.hcc}</td>
                              <td>{item.description}</td>
                              <td>{item.relativeFactors}</td>
                              <td>{"No"}</td>
                            </tr>
                          );
                        })}

                        {/* {!scoreResultFinal?.hccRiskFactorsNormal?.length >
                          0 && (
                          <tr className="py-2 text-mute">
                            <td colSpan={6}>No data available</td>
                          </tr>
                        )} */}
                        {scoreResultFinal?.hccRiskFactorsForPotentialICD?.map(
                          (item) => {
                            let foundItem = listOfSources.find(
                              (i) => i.value === item.icD10SourceID
                            );
                            let label = foundItem?.label;
                            return (
                              <tr
                                key={
                                  "hccRiskFactorsForPotentialICD" + item.icd10
                                }
                              >
                                <td>{item.icd10}</td>
                                <td>{item.icd10Description}</td>
                                <td>{label}</td>
                                <td>{item.hcc}</td>
                                <td>{item.description}</td>
                                <td>{item.relativeFactors}</td>
                                <td>{"Yes"}</td>
                              </tr>
                            );
                          }
                        )}

                        {!scoreResultFinal?.hccRiskFactorsNormal?.length >
                          0 && (
                          <tr className="py-2 text-mute">
                            <td colSpan={6}>No data available</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="row pt-3">
                  <div className="col-md-12">
                    <Eligibility
                      scoreInfo={scoreResultFinal}
                      scoreRequest={scoreFilter}
                      onEligibilitySaveFinished={onEligibilitySaveFinished}
                    ></Eligibility>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    </div>
  );
};

export default ScoreNew;
