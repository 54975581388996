import { axiosInstancepy } from "./AxiosInstance";

export async function getPickList(code) {
  return axiosInstancepy
    .get(`/PickListDetails?masterCode=${code}`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function postIPAdmit(body) {
  return axiosInstancepy
    .post("/AddIPAdmit", body)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function deleteIPAdmit(body) {
  return axiosInstancepy
    .delete("/DeleteIPAdmit", { data: body })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function postEDVisit(body) {
  return axiosInstancepy
    .post("/AddEDVisit", body)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
export async function deleteEDVisit(body) {
  return axiosInstancepy
    .delete("/DeleteEDVisit", { data: body })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
export async function postDeath(body) {
  return axiosInstancepy
    .post("/AddDeath", body)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
export async function deleteDeath(body) {
  return axiosInstancepy
    .delete("/DeleteDeath", { data: body })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
export async function postSnf(body) {
  return axiosInstancepy
    .post("/AddSnf", body)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
export async function deleteSnf(body) {
  return axiosInstancepy
    .delete("/DeleteSnf", { data: body })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
export async function postHospice(body) {
  return axiosInstancepy
    .post("/AddHospice", body)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
export async function deleteHospice(body) {
  return axiosInstancepy
    .delete("/DeleteHospice", { data: body })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
export async function getLastToc(id) {
  return axiosInstancepy
    .get(`/GetLastTOCForMember?mbi=${id}`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function getTocPatientList() {
  return axiosInstancepy
    .get("/GetMembersHavingEditableTOC")
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function getPatientsList() {
  return axiosInstancepy
    .get("/GetPatientsList")
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
export async function getIpAdmissionForDateRange() {
  return axiosInstancepy
    .get("/GetIPAdmissionForDateRange")
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function SaveRCA(body) {
  return axiosInstancepy
    .post("/SaveRCA", body)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
