import { Button, Form, Modal, Space, Table } from "antd";
import React, { useState } from "react";
import { Input, InputNumber, Popconfirm, Typography } from "antd";
import PageTitle from "../Common/PageTitle";
import MyTextBox from "../../common/elements/myTextbox";
import * as Optum from "../../api/OptumCensusApi";
import MyBackdropSpinner from "../../common/elements/myBackdropSpinner";
import useSWR from "swr";
import { fetcher } from "../../services/fectcher";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export const OptumFacility = () => {
  const intialValue = {
    guid: "",
    createdBy: sessionStorage.getItem("userName"),
    facilityName: "",
    admitProvider: "",
    npPa: "",
  };
  const [form] = Form.useForm();

  const { data, mutate, isLoading } = useSWR("/GetOptumFacilities", fetcher);

  const [editingKey, setEditingKey] = useState("");
  const [facilityName, setFacilityName] = useState("");
  const [admitProvider, setAdmitProvider] = useState("");
  const [npPa, setNPPA] = useState("");
  const [reqBody, setReqBody] = useState(intialValue);
  const [pageError, setPageError] = useState({});

  const [isModalOpen, setIsModalOpen] = useState(false);

  const isEditing = (record) => record.GUID === editingKey;

  const edit = (record) => {
    console.log("record", record);
    console.log("reqBody", reqBody);
    setReqBody({ ...reqBody, guid: record?.GUID });
    form.setFieldsValue({
      FACILITY_NAME: record?.FACILITY_NAME,
      NP_PA: record?.NP_PA,
      ADMIT_PROVIDER: record?.ADMIT_PROVIDER,
      CREATED_BY: sessionStorage.getItem("userName"),
      GUID: record?.GUID,
    });
    setEditingKey(record.GUID);
  };
  const cancel = () => {
    setEditingKey("");
  };

  const handleDelete = async (record) => {
    console.log("record", record);
    const deleteResult = await Optum.deleteOptumFacility(record.GUID);
    console.log("deleteResult", deleteResult);
    if (deleteResult.status) {
      mutate();
    }
  };

  const save = async (record) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];

      const index = newData.findIndex((item) => record.GUID === item.GUID);

      if (index > -1) {
        const returnedTarget = Object.assign(reqBody, {
          facilityName: row.FACILITY_NAME,
          admitProvider: row.ADMIT_PROVIDER,
          npPa: row.NP_PA,
        });
        console.log("returnedTarget", returnedTarget);
        const editResult = await Optum.postOptumFacility(returnedTarget);
        console.log("editResult", editResult);

        setReqBody(intialValue);
        setEditingKey("");
        mutate();
      } else {
        newData.push(row);
        //setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };
  const columns = [
    {
      key: 1,
      title: "Facility Name",
      dataIndex: "FACILITY_NAME",
      width: "40%",
      editable: true,
    },
    {
      key: 2,
      title: "Admit Provider",
      dataIndex: "ADMIT_PROVIDER",
      width: "25%",
      editable: true,
    },
    {
      key: 3,
      title: "NP/PA",
      dataIndex: "NP_PA",
      width: "25%",
      editable: true,
    },

    {
      title: "Operation",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Space>
            <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              Edit
            </Typography.Link>
            <Typography.Link>
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => handleDelete(record)}
              >
                <a>Delete</a>
              </Popconfirm>
            </Typography.Link>
          </Space>
        );
      },
    },
  ];

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setFacilityName("");
    setAdmitProvider("");
    setNPPA("");
    setReqBody(intialValue);
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const onHandleChange = (e) => {
    const { name, value } = e.target;
    if (name === "facilityName") {
      setFacilityName(value);
    } else if (name === "admitProvider") {
      setAdmitProvider(value);
    } else {
      setNPPA(value);
    }
    setReqBody({
      ...reqBody,
      [name]: value,
    });
    setPageError({
      ...pageError,
      [name]: value ? "" : "Required",
    });
  };

  const handleSubmit = (e) => {
    setPageError({});
    let pageErrorTemp = {};
    if (!reqBody?.facilityName || !reqBody?.admitProvider || !reqBody?.npPa) {
      pageErrorTemp.facilityName = reqBody?.facilityName ? "" : "Required";
      pageErrorTemp.admitProvider = reqBody?.admitProvider ? "" : "Required";
      pageErrorTemp.npPa = reqBody?.npPa ? "" : "Required";

      setPageError(pageErrorTemp);
      return;
    }
    handleOk(e);
  };

  const handleOk = async (e) => {
    //setLoading(true);
    setIsModalOpen(false);
    try {
      const result = await Optum.postOptumFacility(reqBody);
      console.log("result", result.data.guid);

      setAdmitProvider("");
      setFacilityName("");
      setNPPA("");
      setReqBody(intialValue);
    } catch (e) {
      return;
    }

    // let temp = [];
    // temp.push({
    //   GUID: result ? result.data.guid : "",
    //   FACILITY_NAME: reqBody.facilityName,
    //   ADMIT_PROVIDER: reqBody.admitProvider,
    //   NP_PA: reqBody.npPa,
    //   CREATED_BY: reqBody.createdBy,
    // });
    // let newData = [...data, ...temp];
    // console.log("newData", newData);
    mutate();
  };

  return (
    <>
      {isLoading && <MyBackdropSpinner show={isLoading} />}
      <div className=" tw-flex tw-justify-between">
        <PageTitle title={"NP/PA associate to Facility"} />
        <Button type="primary" onClick={showModal} className=" tw-mb-4">
          Add Facility
        </Button>
      </div>
      <Form form={form} component={false}>
        <Modal
          open={isModalOpen}
          title="Add Facility"
          onCancel={handleCancel}
          onOk={handleSubmit}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Cancel
            </Button>,
            <Button
              key="save"
              type="primary"
              // loading={loading}
              onClick={handleSubmit}
            >
              Save
            </Button>,
          ]}
        >
          <div className="form-group pb-4">
            <MyTextBox
              label="Facility Name"
              value={facilityName}
              id="facilityName"
              setValue={onHandleChange}
              errorMessage={pageError?.facilityName}
            />
          </div>
          <div className="form-group pb-4">
            <MyTextBox
              label="Admit Provider"
              id="admitProvider"
              value={admitProvider}
              setValue={onHandleChange}
              errorMessage={pageError?.admitProvider}
            />
          </div>
          <div className="form-group pb-4">
            <MyTextBox
              label="NP/PA"
              id="npPa"
              value={npPa}
              setValue={onHandleChange}
              errorMessage={pageError?.npPa}
            />
          </div>
        </Modal>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            onChange: cancel,
          }}
        />
      </Form>
    </>
  );
};
