import { DatePicker } from "antd";
import dayjs from "dayjs";
import React, { useState, useEffect } from "react";
import MyCustomSelect from "../../common/elements/myCustomSelect";
import { postTriageCall } from "../../api/CareEventsApi";
import { openNotification } from "../../common/elements/myNotification";
import MyTextBox from "../../common/elements/myTextbox";

const dateFormat = ["YYYY-MM-DD hh:mm A"];

const TriageCallAdd = ({
  editRecord,
  callback,
  nppa,
  LevelOfCareOptions,
  callTypeList,
}) => {
  const [formData, setFormData] = useState(
    editRecord?.guid
      ? {
          ...editRecord,
          modifiedBy: sessionStorage.getItem("userName"),
        }
      : {
          ...editRecord,
          dateTimeCreated: dayjs().format("YYYY-MM-DD hh:mm A"), // Set current time for dateTimeCreated
          providerResponseDateTime: "", // Ensure providerResponseDateTime is empty
        }
  );

  const [isPhoneValid, setIsPhoneValid] = useState(true);

  const [pageError, setPageError] = useState({});
  const [disable, setDisable] = useState(false);
  const [callType, setCallType] = useState();
  const [npPa, setNpPa] = useState();
  const [provider, setProvider] = useState();
  const [skilledCust, setSkilledCust] = useState();

  // const callTypeList = usePickList(25).data;

  useEffect(() => {
    if (editRecord.guid) {
      let callTypeTemp = [];
      if (editRecord?.callType) {
        callTypeTemp = callTypeList?.filter((item) => {
          return item.value === editRecord?.callType;
        });
        setCallType(callTypeTemp);
      }

      let npPaTemp = [];
      if (editRecord?.nporpaValue) {
        npPaTemp = nppa?.filter((item) => {
          return item.value === editRecord?.nporpaValue;
        });
        setNpPa(npPaTemp);
      }

      let providerTemp = [];
      if (editRecord?.providerNameValue) {
        providerTemp = nppa?.filter((item) => {
          return item.value === editRecord?.providerNameValue;
        });
        setProvider(providerTemp);
      }

      let SkilledCustTemp = [];
      if (editRecord?.skilledOrCustodial) {
        SkilledCustTemp = LevelOfCareOptions?.filter((item) => {
          return item.value === editRecord?.skilledOrCustodial;
        });
        setSkilledCust(SkilledCustTemp);
      }

      if (editRecord?.dateTimeCreated === "NaT") {
        setFormData({
          ...formData,
          dateTimeCreated: "",
        });
      }
    }
  }, [editRecord]);

  const onProviderResponseDateChange = (date, dateString) => {
    setFormData({
      ...formData,
      providerResponseDateTime: dateString ? dateString : "",
    });
    setPageError({
      ...pageError,
      providerResponseDateTime: dateString ? "" : "Required",
    });
  };

  const onDateChange = (date, dateString) => {
    setFormData({
      ...formData,
      dateTimeCreated: dateString ? dateString : "",
    });
    setPageError({
      ...pageError,
      dateTimeCreated: dateString ? "" : "Required",
    });
  };

  const handleInputChange = (name, value) => {
    if (name === "callBackNumberExt") {
      const numericValue = value.replace(/[^0-9]/g, "");
      const isValid = value === numericValue; // Check if the entire input is numeric

      setFormData({
        ...formData,
        [name]: numericValue,
      });

      setPageError({
        ...pageError,
        [name]: isValid ? "" : "Only numbers allowed",
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
      setPageError({ ...pageError, [name]: value ? "" : "Required" });
    }
  };

  const onCallTypeChange = (e) => {
    setCallType(e);
    setFormData({ ...formData, callType: e ? e.value : "" });
    setPageError({ ...pageError, callType: e ? "" : "Required" });
  };
  const onNpPaChange = (e) => {
    setNpPa(e);
    setFormData({
      ...formData,
      nporpaValue: e ? e.value : "",
      nporpaLabel: e ? e.label : "",
    });
    setPageError({ ...pageError, nppa: e ? "" : "Required" });
    if (e?.value !== "Other") {
      setFormData({
        ...formData,
        nporpaValue: e ? e.value : "",
        nporpaLabel: e ? e.label : "",
        nporpaOther: "",
      });
    }
  };

  const onProviderChange = (e) => {
    setProvider(e);
    setFormData({
      ...formData,
      providerNameLabel: e ? e.label : "",
      providerNameValue: e ? e.value : "",
    });
    setPageError({ ...pageError, provider: e ? "" : "Required" });
    if (e?.value !== "Other") {
      setFormData({
        ...formData,
        providerNameLabel: e ? e.label : "",
        providerNameValue: e ? e.value : "",
        providerNameOther: "",
      });
    }
  };

  const onSkilledCustChange = (e) => {
    setSkilledCust(e);
    setFormData({
      ...formData,
      skilledOrCustodial: e ? e.value : "",
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setPageError({});

    let pageErrorTemp = {};

    if (!formData?.callType) {
      pageErrorTemp.callType = "Required";
    }

    if (!formData?.dateTimeCreated) {
      pageErrorTemp.dateTimeCreated = "Required";
    }

    if (!formData?.isThisUrgent) {
      pageErrorTemp.isThisUrgent = "Required";
    }

    if (!formData?.callBackNumber) {
      pageErrorTemp.callBackNumber = "Required";
    } else if (!isPhoneValid) {
      pageErrorTemp.callBackNumber = "Invalid phone number";
    }

    if (!formData?.isThisHnPatient) {
      pageErrorTemp.isThisHnPatient = "Required";
    }

    if (!formData?.callerName) {
      pageErrorTemp.callerName = "Required";
    }

    if (
      formData?.providerNameValue === "Other" &&
      !formData?.providerNameOther
    ) {
      pageErrorTemp.providerNameOther = "Required";
    }

    if (formData?.nporpaValue === "Other" && !formData?.nporpaOther) {
      pageErrorTemp.nporpaOther = "Required";
    }

    if (Object.keys(pageErrorTemp).length > 0) {
      setPageError(pageErrorTemp);
      return;
    }

    onSave(e);
  };

  const onSave = async (e) => {
    setDisable(true);
    try {
      const response = await postTriageCall(formData);
      if (response?.status === 200 && !formData?.guid) {
        openNotification("success", "Added", "Triage Call Added Successfully!");
      } else if (formData?.guid) {
        openNotification(
          "success",
          "Updated",
          "Triage Call Updated Successfully!"
        );
      } else {
        openNotification("error", "Error", "Triage Call Failed to Add!");
      }
      setFormData(editRecord);

      callback(response);
    } catch (e) {
      return e;
    }
    setDisable(false);
  };

  return (
    <div className="tw-relative">
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="hospitalname">
          Call Type
        </label>
        <MyCustomSelect
          selectOptions={callTypeList}
          myCallBack={onCallTypeChange}
          selectedValue={callType}
          errorMessage={pageError?.callType}
          id={"callType"}
        />
      </div>
      <div className="tw-flex tw-flex-col">
        <label className="tw-font-bold" htmlFor="hospitalname">
          Date/Time Created
        </label>
        <DatePicker
          showTime
          use12Hours
          className="form-control tw-mb-3"
          onChange={onDateChange}
          format={dateFormat}
          value={
            formData?.dateTimeCreated ? dayjs(formData.dateTimeCreated) : ""
          }
          style={{
            border: pageError?.dateTimeCreated ? "1px solid red" : "",
          }}
          id="dateTimeCreated"
        />
      </div>

      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="hospitalname">
          Is this Urgent?
        </label>
        <fieldset className="fw-normal">
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="isThisUrgentYes"
              checked={formData?.isThisUrgent === "Yes"}
              id="isThisUrgentYes"
              value={"Yes"}
              onChange={(e) =>
                handleInputChange(
                  "isThisUrgent",
                  e.currentTarget.value ? "Yes" : "No"
                )
              }
            />
            <label className="form-check-label" htmlFor="isThisUrgentYes">
              Yes
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="isThisUrgentNo"
              checked={formData?.isThisUrgent === "No"}
              id="isThisUrgentNo"
              value={"No"}
              onChange={(e) =>
                handleInputChange(
                  "isThisUrgent",
                  e.currentTarget.value ? "No" : "Yes"
                )
              }
            />
            <label className="form-check-label" htmlFor="isThisUrgentNo">
              No
            </label>
            <span
              className="text-danger"
              style={{ fontWeight: "normal", paddingLeft: "5px" }}
            >
              {pageError?.isThisUrgent}
            </span>
          </div>
        </fieldset>
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="hospitalname">
          Is this a HN patient?
        </label>
        <fieldset className="fw-normal">
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="isThisHnPatientYes"
              checked={formData?.isThisHnPatient === "Yes"}
              id="isThisHnPatientYes"
              value={"Yes"}
              onChange={(e) =>
                handleInputChange(
                  "isThisHnPatient",
                  e.currentTarget.value ? "Yes" : "No"
                )
              }
            />
            <label className="form-check-label" htmlFor="isThisHnPatientYes">
              Yes
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="isThisHnPatientNo"
              checked={formData?.isThisHnPatient === "No"}
              id="isThisHnPatientNo"
              value={"No"}
              onChange={(e) =>
                handleInputChange(
                  "isThisHnPatient",
                  e.currentTarget.value ? "No" : "Yes"
                )
              }
            />
            <label className="form-check-label" htmlFor="isThisHnPatientNo">
              No
            </label>
          </div>
          <span
            className="text-danger"
            style={{ fontWeight: "normal", paddingLeft: "5px" }}
          >
            {pageError?.isThisHnPatient}
          </span>
        </fieldset>
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="provider">
          Level of Care
        </label>
        <MyCustomSelect
          selectOptions={LevelOfCareOptions}
          myCallBack={onSkilledCustChange}
          selectedValue={skilledCust}
          id={"skilledOrCustodial"}
        />
      </div>

      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="changeCondition">
          Patient Home Address
        </label>
        <input
          className=" form-control"
          id="patientHomeAddress"
          value={formData?.patientHomeAddress}
          onChange={(e) =>
            handleInputChange("patientHomeAddress", e.target.value)
          }
          maxLength={100}
        ></input>
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="hospitalname">
          NP/PA
        </label>
        <MyCustomSelect
          selectOptions={nppa}
          myCallBack={onNpPaChange}
          selectedValue={npPa}
          id={"npPa"}
          //errorMessage={pageError?.nppa}
        />
      </div>
      {formData?.nporpaValue === "Other" && (
        <div className="tw-flex tw-flex-col tw-mb-3">
          <label className="tw-font-bold" htmlFor="nppa">
            Other NP/PA
          </label>
          <input
            className=" form-control"
            type="text"
            maxLength={100}
            id="npOrPaOther"
            value={formData?.nporpaOther}
            onChange={(e) => handleInputChange("nporpaOther", e.target.value)}
            style={{
              border: pageError?.nporpaOther ? "1px solid red" : "",
            }}
          />
        </div>
      )}
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="provider">
          Provider
        </label>
        <MyCustomSelect
          selectOptions={nppa}
          myCallBack={onProviderChange}
          selectedValue={provider}
          id={"provider"}
          //errorMessage={pageError?.provider}
        />
      </div>
      {formData?.providerNameValue === "Other" && (
        <div className="tw-flex tw-flex-col tw-mb-3">
          <label className="tw-font-bold" htmlFor="providerOther">
            Other Provider
          </label>
          <input
            className=" form-control"
            type="text"
            id="providerNameOther"
            maxLength={100}
            value={formData?.providerNameOther}
            onChange={(e) =>
              handleInputChange("providerNameOther", e.target.value)
            }
            style={{
              border: pageError?.providerNameOther ? "1px solid red" : "",
            }}
          />
        </div>
      )}
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="hospitalname">
          Health Plan / Medical Group
        </label>
        <input
          className=" form-control"
          type="text"
          id="healthPlan"
          maxLength={100}
          value={formData?.healthPlan}
          onChange={(e) => handleInputChange("healthPlan", e.target.value)}
          // style={{
          //   border: pageError?.healthPlan ? "1px solid red" : "",
          // }}
        />
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="hospitalname">
          Policy Number
        </label>
        <input
          className=" form-control"
          type="text"
          id="policyNumber"
          maxLength={100}
          value={formData?.policyNumber}
          onChange={(e) => handleInputChange("policyNumber", e.target.value)}
          // style={{
          //   border: pageError?.policyNumber ? "1px solid red" : "",
          // }}
        />
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="hospitalname">
          Caller Name
        </label>
        <input
          className=" form-control"
          type="text"
          id="callerName"
          maxLength={200}
          value={formData?.callerName}
          onChange={(e) => handleInputChange("callerName", e.target.value)}
          style={{
            border: pageError?.callerName ? "1px solid red" : "",
          }}
        />
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="hospitalname">
          Caller relationship to patient
        </label>
        <input
          className=" form-control"
          type="text"
          id="callerRelationshipPatient"
          maxLength={200}
          value={formData?.callerRelationshipPatient}
          onChange={(e) =>
            handleInputChange("callerRelationshipPatient", e.target.value)
          }
        />
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="facility">
          Facility
          <span className="  tw-text-gray-600 tw-font-medium ">
            {" (If left blank this will pull available facility from EMR)"}
          </span>
        </label>
        <input
          className=" form-control"
          type="text"
          id="facility"
          value={formData?.facilityName}
          onChange={(e) => handleInputChange("facilityName", e.target.value)}
          maxLength={100}
        />
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="hospitalname">
          Room Number
        </label>
        <input
          className=" form-control"
          type="text"
          id="roomNumber"
          maxLength={100}
          value={formData?.roomNumber}
          onChange={(e) => handleInputChange("roomNumber", e.target.value)}
          // style={{
          //   border: pageError?.roomNumber ? "1px solid red" : "",
          // }}
        />
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="hospitalname">
          Nurse Station
        </label>
        <input
          className=" form-control"
          type="text"
          id="nurseStation"
          maxLength={200}
          value={formData?.nurseStation}
          onChange={(e) => handleInputChange("nurseStation", e.target.value)}
        />
      </div>
      <div className="tw-flex tw-mb-3 tw-space-x-6">
        <div className="tw-w-1/2">
          <MyTextBox
            label={"Call Back Number#"}
            value={formData?.callBackNumber}
            errorMessage={pageError?.callBackNumber}
            setValue={(e) =>
              handleInputChange("callBackNumber", e.target.value)
            }
            isPhoneNumber={true}
            onValidChange={(e) => setIsPhoneValid(e)}
            id="callBackNumber"
          />
        </div>
        <div className="">
          <label className="tw-font-bold" htmlFor="hospitalname">
            Ext
          </label>
          <input
            className=" form-control"
            type="text"
            id="callBackNumberExt"
            maxLength={6}
            value={formData?.callBackNumberExt}
            onChange={(e) =>
              handleInputChange("callBackNumberExt", e.target.value)
            }
            style={{
              border: pageError?.callBackNumberExt ? "1px solid red" : "",
            }}
          />
          {pageError?.callBackNumberExt && (
            <span
              className="text-danger"
              style={{ fontWeight: "normal", paddingLeft: "5px" }}
            >
              {pageError.callBackNumberExt}
            </span>
          )}
        </div>
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="hospitalname">
          Does the caller need a call back?
        </label>
        <fieldset className="fw-normal">
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="callerNeedCallbackYes"
              checked={formData?.callerNeedCallback === "Yes"}
              id="callerNeedCallbackYes"
              value={"Yes"}
              onChange={(e) =>
                handleInputChange(
                  "callerNeedCallback",
                  e.currentTarget.value ? "Yes" : "No"
                )
              }
            />
            <label className="form-check-label" htmlFor="callerNeedCallbackYes">
              Yes
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="callerNeedCallbackNo"
              checked={formData?.callerNeedCallback === "No"}
              id="callerNeedCallbackNo"
              value={"No"}
              onChange={(e) =>
                handleInputChange(
                  "callerNeedCallback",
                  e.currentTarget.value ? "No" : "Yes"
                )
              }
            />
            <label className="form-check-label" htmlFor="callerNeedCallbackNo">
              No
            </label>
          </div>
        </fieldset>
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="hospitalname">
          Message
        </label>
        <textarea
          className=" form-control"
          type="text"
          id="message"
          maxLength={1000}
          value={formData?.message}
          onChange={(e) => handleInputChange("message", e.target.value)}
        />
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="hospitalname">
          Message Sent To
        </label>
        <input
          className=" form-control"
          type="text"
          id="messageSentTo"
          maxLength={200}
          value={formData?.messageSentTo}
          onChange={(e) => handleInputChange("messageSentTo", e.target.value)}
        />
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="hospitalname">
          Call Patched To
        </label>
        <input
          className=" form-control"
          type="text"
          id="callPatchedTo"
          maxLength={200}
          value={formData?.callPatchedTo}
          onChange={(e) => handleInputChange("callPatchedTo", e.target.value)}
        />
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="hospitalname">
          On Call Provider
        </label>
        <input
          className=" form-control"
          type="text"
          id="onCallProvider"
          value={formData?.onCallProvider}
          onChange={(e) => handleInputChange("onCallProvider", e.target.value)}
          maxLength={100}
        />
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="hospitalname">
          On Call Provider Response
        </label>
        <textarea
          className=" form-control"
          type="text"
          id="onCallProviderResponse"
          maxLength={2000}
          value={formData?.onCallProviderResponse}
          onChange={(e) =>
            handleInputChange("onCallProviderResponse", e.target.value)
          }
        />
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="hospitalname">
          Date/Time of On Call Provider Response
        </label>
        <DatePicker
          showTime
          use12Hours
          className="form-control tw-mb-3"
          onChange={onProviderResponseDateChange}
          format={dateFormat}
          value={
            formData?.providerResponseDateTime &&
            dayjs(formData.providerResponseDateTime).format(
              "YYYY-MM-DD hh:mm A"
            ) !== "1900-01-01 12:00 AM"
              ? dayjs(formData.providerResponseDateTime)
              : null
          }
          id="providerResponseDateTime"
        />
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="hospitalname">
          Add Additional Links Here
        </label>
        <textarea
          className=" form-control"
          type="text"
          id="attachmentLinks"
          maxLength={1000}
          value={formData?.attachmentLinks}
          onChange={(e) => handleInputChange("attachmentLinks", e.target.value)}
        />
      </div>
      <div className="form-group tw-fixed tw-top-2 tw-right-10">
        <button
          className={
            !disable ? "btn btn-success " : "btn btn-success disabled "
          }
          onClick={onSubmit}
        >
          {editRecord?.guid ? "Update" : "Save"}
        </button>
        <button
          className="btn btn-secondary tw-ml-4"
          onClick={() => callback(false)}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default TriageCallAdd;
