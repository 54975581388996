import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  DatePicker,
  Dropdown,
  message,
  Modal,
  Checkbox,
  Select,
} from "antd";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  MdOutlineDeleteOutline,
  MdOutlineModeEditOutline,
  MdEventNote,
  MdDone,
  MdClear,
} from "react-icons/md";
import PageTitle from "../Common/PageTitle";
import * as Taskapi from "../../api/TaskManagerApi";
import dayjs from "dayjs";
import MyBackdropSpinner from "../../common/elements/myBackdropSpinner";
import { useMyContext } from "../../common/elements/myContext";
import Search from "antd/es/input/Search";
import { useNavigate } from "react-router-dom";
import colors from "tailwindcss/colors";

const items = [
  {
    key: "completed",
    label: "Mark as complete",
    icon: (
      <MdDone
        style={{
          width: "1rem",
          height: "1rem",
          color: "var(--bs-gray-600)",
        }}
      />
    ),
  },
  {
    key: "2",
    label: "Mark Incomplete",
    children: [
      {
        key: "Patient Refused",
        label: "Patient Refused",
      },
      {
        key: "Provider Refused",
        label: "Provider Refused",
      },
      {
        key: "Dispatched",
        label: "Dispatched",
      },
      {
        key: "Discharged",
        label: "Discharged",
      },
      {
        key: "Deceased",
        label: "Deceased",
      },
      {
        key: "Pending Approval CCMD",
        label: "Pending Approval CCMD",
      },
      {
        key: "Pending Approval Non-CCMD",
        label: "Pending Approval Non-CCMD",
      },
      {
        key: "Facility Refused",
        label: "Facility Refused",
      },
      {
        key: "Out of Area",
        label: "Out of Area",
      },
      {
        key: "Cannot locate patient",
        label: "Cannot locate patient",
      },
      {
        key: "Hold - per office request",
        label: "Hold - per office request",
      },
      {
        key: "Hold - other",
        label: "Hold - other",
      },
      {
        key: "Escalate to PP",
        label: "Escalate to PP",
      },
      {
        key: "Other - see notes",
        label: "Other - see notes",
      },
    ],
    icon: (
      <MdClear
        style={{
          width: "1rem",
          height: "1rem",
          color: "var(--bs-gray-600)",
        }}
      />
    ),
  },
  {
    key: "3",
    label: "Edit task",
    icon: (
      <MdOutlineModeEditOutline
        style={{
          width: "1rem",
          height: "1rem",
          color: "var(--bs-gray-600)",
        }}
      />
    ),
  },
  {
    key: "4",
    label: "Delete task",
    icon: (
      <MdOutlineDeleteOutline
        style={{
          width: "1rem",
          height: "1rem",
          color: "var(--bs-gray-600)",
        }}
      />
    ),
  },
];
const dateToday = new Date();
const keysToFilter = [
  "patientName",
  "assignedTo",
  "dueDateConverted",
  "notes",
  "taskPriority",
  "taskStatus",
  "taskType",
  "incompleteReason",
];
const AllTasks = () => {
  const [spinner, setSpinner] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState();
  const [selectedTab, setSelectedTab] = useState("tasks");

  const Navigate = useNavigate();

  const { converttoCompare } = useMyContext();

  const [tasks, setTasks] = useState([]);
  const [filteredItems, setFilteredItems] = useState();
  const [tasksColumn, setTasksColumn] = useState();
  const [patientList, setPatientList] = useState();
  const [query, setQuery] = useState();
  const [selectedDate, setSelectedDate] = useState();

  useEffect(() => {
    getallTasks();
  }, []);

  const getallTasks = async () => {
    try {
      setSpinner(true);
      const response = await Taskapi.getAllTasks();
      let tempTask = [];
      response?.data.forEach((e) => {
        tempTask.push({
          taskId: e.taskId,
          patientName: e.patientName,
          patientId: e.patientId,
          taskType: e.taskTypeName,
          dueDate: e.etc,
          dueDateConverted: converttoCompare(e.etc),
          assignedTo: e.assigneeDisplayName,
          assigneeId: e.assigneeId,
          notify: e.daysRemaining,
          notes: e.notes,
          taskPriority: e.taskPriority,
          taskStatus: e.taskStatus,
          modifiedDate: e.modifiedDate,
          incompleteReason: e.incompleteReason,
          actionTakenDate: e.actionTakenDate,
        });
        setTasks(tempTask);
        setFilteredItems(tempTask);
        let temppatientList = [];
        response?.data.forEach((e) => {
          temppatientList.push({
            label: e.patientName,
            value: e.patientId,
          });
        });
        setPatientList(temppatientList);
      });

      const DueDate = (dueDateConverted, actionTakenDate) => {
        return (
          <>
            <div>{dueDateConverted}</div>

            {converttoCompare(actionTakenDate) > dueDateConverted &&
              (dueDateConverted === converttoCompare(dateToday) ? (
                <div
                  className="rounded-1"
                  style={{
                    color: "rgb(133, 77, 14)",
                    background: "rgb(254, 249, 195)",
                    padding: "0.1rem 0.4rem",
                    marginTop: "6px",
                    width: "max-content",
                  }}
                >
                  Due
                </div>
              ) : dueDateConverted < converttoCompare(dateToday) ? (
                <div
                  className="rounded-1"
                  style={{
                    color: "rgb(185, 28, 28)",
                    background: "#fed7aa",
                    padding: "0.1rem 0.4rem",
                    marginTop: "6px",
                    width: "max-content",
                  }}
                >
                  Past Due
                </div>
              ) : (
                <div
                  className="rounded-1"
                  style={{
                    color: "rgb(21, 128, 61)",
                    background: "rgb(220, 252, 231)",
                    padding: "0.1rem 0.4rem",
                    marginTop: "6px",
                    width: "max-content",
                  }}
                >
                  Future
                </div>
              ))}
          </>
        );
      };

      setTasksColumn([
        {
          title: "Type",
          key: "type",
          width: 100,
          render: (_, { taskType }, index) => (
            <div
              style={{
                fontSize: "0.9rem",
                color: "var(--bs-gray-800)",
              }}
            >
              {taskType}
              {index === 0 && (
                <div className="tw-mt-1 tw-text-xs tw-rounded tw-bg-zinc-300 tw-text-zinc-600 tw-max-w-fit tw-py-0.5 tw-px-2">
                  Task list
                </div>
              )}
            </div>
          ),
          sorter: (a, b) => {
            if (a.taskType < b.taskType) {
              return -1;
            }
            if (a.taskType > b.taskType) {
              return 1;
            }
            return 0;
          },
        },
        {
          title: "Task Details",
          key: "taskDetails",
          width: 250,
          render: (_, { patientName, notify, notes }) => (
            <>
              <div
                style={{
                  fontWeight: 500,
                  fontSize: "1rem",
                  color: "var(--bs-gray-800)",
                }}
              >
                {patientName}
              </div>
              <div
                style={{
                  fontSize: "0.9rem",
                  color: "var(--bs-gray-600)",
                  marginBottom: "0.3rem",
                  marginTop: "0.3rem",
                  overflowWrap: "break-word",
                }}
              >
                <MdEventNote
                  size={20}
                  style={{
                    color: "var(--bs-gray-500)",
                    marginRight: "0.5rem",
                    marginTop: "-2px",
                  }}
                />
                {notes}
              </div>
            </>
          ),
          sorter: (a, b) => {
            if (a.patientName < b.patientName) {
              return -1;
            }
            if (a.patientName > b.patientName) {
              return 1;
            }
            return 0;
          },
        },
        {
          title: "Assignee",
          key: "assignee",
          dataIndex: "assignedTo",
          width: 100,
          sorter: (a, b) => {
            if (a.assignedTo < b.assignedTo) {
              return -1;
            }
            if (a.assignedTo > b.assignedTo) {
              return 1;
            }
            return 0;
          },
        },
        {
          title: "Priority",
          key: "taskPriority",
          dataIndex: "taskPriority",
          width: 100,
          render: (_, { taskPriority }) =>
            taskPriority === "Critical" ? (
              <div
                style={{
                  fontSize: "0.9rem",
                  marginBottom: "0.3rem",
                  marginTop: "0.3rem",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  style={{
                    width: 20,
                    marginTop: -4,
                    marginRight: 4,
                    color: "#dc2626",
                  }}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.5 12.75l7.5-7.5 7.5 7.5m-15 6l7.5-7.5 7.5 7.5"
                  />
                </svg>

                {taskPriority}
              </div>
            ) : taskPriority === "Low" ? (
              <span
                style={{
                  fontSize: "0.9rem",
                  marginBottom: "0.3rem",
                  marginTop: "0.3rem",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  style={{
                    width: 20,
                    marginTop: -4,
                    marginRight: 4,
                    color: "#16a34a",
                  }}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                  />
                </svg>
                {taskPriority}
              </span>
            ) : taskPriority === "High" ? (
              <span
                style={{
                  fontSize: "0.9rem",
                  marginBottom: "0.3rem",
                  marginTop: "0.3rem",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  style={{
                    width: 20,
                    marginTop: -4,
                    marginRight: 4,
                    color: "#ea580c",
                  }}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.5 15.75l7.5-7.5 7.5 7.5"
                  />
                </svg>

                {taskPriority}
              </span>
            ) : (
              <span
                style={{
                  fontSize: "0.9rem",
                  marginBottom: "0.3rem",
                  marginTop: "0.3rem",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  style={{
                    width: 20,
                    marginTop: -4,
                    marginRight: 4,
                    color: "#2563eb",
                  }}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 9h16.5m-16.5 6.75h16.5"
                  />
                </svg>

                {taskPriority}
              </span>
            ),
          sorter: (a, b) => {
            if (a.taskPriority < b.taskPriority) {
              return -1;
            }
            if (a.taskPriority > b.taskPriority) {
              return 1;
            }
            return 0;
          },
        },
        {
          title: "Due date",
          key: "dueDate",
          dataIndex: "dueDate",
          width: 120,
          render: (_, { dueDateConverted, actionTakenDate }) => {
            return DueDate(dueDateConverted, actionTakenDate);
          },

          sorter: (a, b) => {
            const aDate = converttoCompare(a.dueDate);
            const bDate = converttoCompare(b.dueDate);

            if (aDate < bDate) {
              return -1;
            }
            if (aDate > bDate) {
              return 1;
            }
            return 0;
          },
        },
        {
          title: "Status",
          key: "status",
          width: 180,
          render: (
            _,
            { taskStatus, modifiedDate, incompleteReason, actionTakenDate },
            index
          ) => (
            <>
              {index === 0 ? (
                <div>
                  <span style={{ fontWeight: "bold" }}>In Progress</span>
                  <div>2/11 done</div>
                </div>
              ) : taskStatus === "Incomplete" && actionTakenDate ? (
                <div>
                  <span style={{ fontWeight: "bold" }}> {taskStatus}</span> -{" "}
                  <span>{incompleteReason}</span>
                  <div>{`Since ${converttoCompare(actionTakenDate)}`}</div>
                </div>
              ) : taskStatus === "Edited" ? (
                <div>
                  <span style={{ fontWeight: "bold" }}> {taskStatus}</span>
                  <div>{`Since ${converttoCompare(modifiedDate)}`}</div>
                </div>
              ) : taskStatus === "Complete" ? (
                <div>
                  <span style={{ fontWeight: "bold" }}> {taskStatus}</span>
                  <div>{`Since ${converttoCompare(actionTakenDate)}`}</div>
                </div>
              ) : (
                <div style={{ fontWeight: "bold" }}>{taskStatus}</div>
              )}
            </>
          ),
          sorter: (a, b) => {
            if (a.taskStatus < b.taskStatus) {
              return -1;
            }
            if (a.taskStatus > b.taskStatus) {
              return 1;
            }
            return 0;
          },
        },
        {
          key: "taskId",
          width: 50,
          render: (rowItem, { taskId, taskStatus }) =>
            taskStatus !== "Complete" &&
            taskStatus !== "Incomplete" && (
              <Dropdown
                menu={{
                  items,
                  onClick: (e) => onStatusChange(e, taskId, rowItem),
                }}
                placement="bottomRight"
              >
                <div
                  className="mx-auto"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => e.preventDefault()}
                >
                  <BsThreeDotsVertical />
                </div>
              </Dropdown>
            ),
        },
      ]);
      setSpinner(false);
      return response?.data;
    } catch (error) {
      console.error(error);
    }
  };

  const onStatusChange = async (e, taskId, rowItem) => {
    if (e.key === "4") {
      message.error("Task Deleted Successfully!");
      const deleteResult = await Taskapi.deleteTask(taskId);
    } else if (e.key === "completed") {
      message.success("Task Completed Successfully!");
      const tempTask = {
        taskId: taskId,
        taskStatus: "Complete",
      };
      const updateresult = await Taskapi.updateTask(tempTask);
    } else if (e.key === "3") {
      const encodeTaskType = encodeURIComponent(rowItem.taskType);
      if (rowItem?.notes) {
        Navigate(
          `/addTask/${rowItem.taskId}/${rowItem.patientId}/${rowItem.taskPriority}/${rowItem.taskStatus}/${encodeTaskType}/${rowItem.notes}/${rowItem.assigneeId}/${rowItem.dueDate}`
        );
      } else {
        Navigate(
          `/addTask/${rowItem.taskId}/${rowItem.patientId}/${rowItem.taskPriority}/${rowItem.taskStatus}/${encodeTaskType}/${rowItem.assigneeId}/${rowItem.dueDate}`
        );
      }
    } else {
      message.warning(`Task Incomplete for the patient ${rowItem.patientName}`);
      const tempTask = {
        taskId: taskId,
        taskStatus: "Incomplete",
        incompleteReason: e.key,
      };
      const updateresult = await Taskapi.updateTask(tempTask);
    }
    getallTasks();
    setSelectedDate();
  };

  const onDateChange = (date, dateString) => {
    if (dateString !== "") {
      let Items = tasks.filter((item) => {
        const dueDateList = dayjs(item.dueDateConverted);
        const tempDueDate = dayjs(dateString);
        return dueDateList.isSame(tempDueDate, "day");
      });
      setSelectedDate(date);
      setFilteredItems(Items);
    } else {
      setFilteredItems(tasks);
      setSelectedDate(date);
    }
  };

  const finalResult = filteredItems?.filter((item) => {
    return keysToFilter.some((key) =>
      (item[key]?.toString()?.toLowerCase() ?? "").includes(
        query?.toLowerCase() ?? ""
      )
    );
  });

  const handleOk = () => {
    setIsModalOpen(false);
    setSelectedTask(undefined);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedTask(undefined);
  };

  return (
    <div style={{ minHeight: "90vh" }}>
      <div className="tw-flex tw-justify-between tw-mb-2">
        <PageTitle title={`All Tasks`} />
        {spinner && <MyBackdropSpinner show={spinner} />}

        <div className="d-grid gap-2 my-2 d-md-flex justify-content-end ">
          <DatePicker
            placeholder="Select due date"
            size="middle"
            format="YYYY-MM-DD"
            onChange={onDateChange}
            value={selectedDate ? selectedDate : null} // Pass the selected date from state
          />

          <Search
            className="col-md-6 col-lg-6"
            placeholder="Search"
            onChange={(e) => setQuery(e.target.value)}
            allowClear
          />
        </div>
      </div>

      <div style={{ color: "var(--bs-gray-800)" }}>
        <Table
          columns={tasksColumn}
          dataSource={finalResult}
          size="middle"
          onRow={(record) => ({
            onClick: () => {
              setSelectedTask(record);
              setIsModalOpen(true);
            },
          })}
          className="table-cursor"
        />
      </div>
      <Modal
        width={1000}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {selectedTask && (
          <>
            <div className="tw-flex tw-items-start tw-space-x-2">
              <div>
                <div className="tw-text-xl tw-font-medium tw-text-zinc-800">
                  {selectedTask.patientName}
                </div>
                <div className="tw-text-sm -tw-mt-0.5 tw-text-zinc-500 tw-font-light">
                  {selectedTask.patientId}
                </div>
              </div>
              <div className="tw-text-sm tw-mt-1 tw-px-2 tw-py-0.5 tw-bg-zinc-200 tw-border-2 tw-border-zinc-500 tw-text-zinc-800 tw-rounded">
                CCE Visit Tracking
              </div>
            </div>
            <div className="tw-my-6 tw-flex tw-justify-between tw-space-x-8 tw-items-start tw-h-[32rem] tw-overflow-scroll">
              {/* <-----   Activity Feed   -----> */}
              <div className="tw-w-3/5 tw-text-base">
                <div className="tw-font-medium tw-flex tw-mb-5 tw-space-x-2">
                  <Button
                    type={selectedTab === "tasks" ? "primary" : "text"}
                    onClick={() => setSelectedTab("tasks")}
                  >
                    Tasks
                  </Button>
                  <Button
                    type={selectedTab === "activity" ? "primary" : "text"}
                    onClick={() => setSelectedTab("activity")}
                  >
                    Activity
                  </Button>
                </div>

                {selectedTab === "tasks" ? (
                  <div className="tw-space-y-5">
                    <div>
                      <Checkbox>Census Activities Complete</Checkbox>
                    </div>
                    <div>
                      <Checkbox>Pre-CCE Coder Review Complete</Checkbox>
                      <div className="tw-block tw-ml-6 tw-mt-1">
                        <DatePicker />
                      </div>
                    </div>
                    <div>
                      <Checkbox>Pre-CCE Provider Review</Checkbox>
                      <div className="tw-block tw-ml-6 tw-mt-1">
                        <DatePicker />
                      </div>
                    </div>
                    <div>
                      <Checkbox>CCE Visit Scheduled</Checkbox>
                      <div className="tw-block tw-ml-6 tw-mt-1">
                        <DatePicker />
                      </div>
                    </div>
                    <div>
                      <Checkbox>CCE Visit Complete</Checkbox>
                      <div className="tw-block tw-ml-6 tw-mt-1">
                        <DatePicker />
                      </div>
                    </div>
                    <div>
                      <Checkbox>CCE Visit Incomplete</Checkbox>
                      <div className="tw-block tw-ml-6 tw-mt-1">
                        <Select
                          style={{ width: 240 }}
                          placeholder="Select reason"
                          options={[
                            {
                              value: "PatientRefused",
                              label: "Patient Refused",
                            },
                            {
                              value: "FacilityRefused",
                              label: "Facility Refused",
                            },
                            { value: "Discharged", label: "Discharged" },
                            { value: "Deceased", label: "Deceased" },
                            {
                              value: "UnableToLocate",
                              label: "Unable to locate",
                            },
                            { value: "Dispatch", label: "Dispatch" },
                            {
                              value: "Non-CCMDPendingApproval",
                              label: "Non-CCMD Pending Approval",
                            },
                            {
                              value: "CoordinatingVisits",
                              label: "Coordinating Visits",
                            },
                            {
                              value: "Chino",
                              label: "Chino - Do not pursue visit",
                            },
                            {
                              value: "Meador",
                              label: "Meador - Pending Update",
                            },
                            { value: "Other", label: "Other" },
                          ]}
                        />
                      </div>
                    </div>
                    <div>
                      <Checkbox>Post CCE Provider Review</Checkbox>
                      <div className="tw-block tw-ml-6 tw-mt-1">
                        <DatePicker />
                      </div>
                    </div>
                    <div>
                      <Checkbox>Note Signed</Checkbox>
                      <div className="tw-block tw-ml-6 tw-mt-1">
                        <DatePicker />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="tw-space-y-5">
                    <div className="tw-text-zinc-500 tw-text-sm tw-flex tw-justify-between tw-items-start tw-relative">
                      <div className="tw-w-0.5 tw-absolute tw-h-full tw-bg-zinc-300 tw-left-[11px] -tw-translate-x-1/2 tw-top-5" />
                      <div className="tw-flex tw-items-start">
                        <div class="tw-relative tw-flex tw-h-6 tw-w-6 tw-items-center tw-justify-center tw-shrink-0 tw-bg-white">
                          <div class="tw-h-1.5 tw-w-1.5 tw-rounded-full tw-bg-zinc-300"></div>
                        </div>
                        <div className="tw-flex tw-flex-wrap">
                          <div className="tw-text-zinc-900 tw-font-medium">
                            Poongundran Elavarasan
                          </div>
                          <div className="tw-mx-1">created this task</div>
                        </div>
                      </div>
                      <div className="tw-shrink-0">2024-01-10</div>
                    </div>

                    <div className="tw-text-zinc-500 tw-text-sm tw-flex tw-justify-between tw-items-start tw-relative">
                      <div className="tw-w-0.5 tw-absolute tw-h-full tw-bg-zinc-300 tw-left-[11px] -tw-translate-x-1/2 tw-top-5" />
                      <div className="tw-flex tw-items-start">
                        <div class="tw-relative tw-flex tw-h-6 tw-w-6 tw-items-center tw-justify-center tw-shrink-0">
                          <div class="tw-h-1.5 tw-w-1.5 tw-rounded-full tw-bg-zinc-300"></div>
                        </div>
                        <div className="tw-flex tw-flex-wrap">
                          <div className="tw-text-zinc-900 tw-font-medium">
                            Poongundran Elavarasan
                          </div>
                          <div className="tw-mx-1">completed</div>
                          <div className="tw-text-zinc-900 tw-font-medium">
                            Pre-CCE Coder Review
                          </div>
                        </div>
                      </div>
                      <div className="tw-shrink-0">2024-01-12</div>
                    </div>

                    <div className="tw-text-zinc-500 tw-text-sm tw-flex tw-justify-between tw-items-start tw-relative">
                      <div className="tw-flex tw-items-start">
                        <div class="tw-relative tw-flex tw-h-6 tw-w-6 tw-items-center tw-justify-center tw-shrink-0">
                          <div class="tw-h-1.5 tw-w-1.5 tw-rounded-full tw-bg-zinc-300"></div>
                        </div>
                        <div className="tw-flex tw-flex-wrap">
                          <div className="tw-text-zinc-900 tw-font-medium">
                            Rakesh Uthukadu Parthiban
                          </div>
                          <div className="tw-mx-1">edited and added notes</div>
                        </div>
                      </div>
                      <div className="tw-shrink-0">2024-01-13</div>
                    </div>
                  </div>
                )}
              </div>

              {/* <-----   Side bar   -----> */}
              <div
                className="tw-w-2/5 tw-rounded tw-px-3 tw-py-4 tw-sticky tw-top-0"
                style={{ border: `1px solid ${colors.zinc[300]}` }}
              >
                <div
                  className="tw-pb-3 tw-text-base -tw-mx-3 tw-px-3 tw-font-medium"
                  style={{ borderBottom: `1px solid ${colors.zinc[300]}` }}
                >
                  Details
                </div>

                <div className="tw-flex tw-pt-4 tw-justify-between tw-space-x-4">
                  <div className="tw-text-sm tw-text-zinc-500 tw-font-medium">
                    Patient
                  </div>
                  <div className="tw-text-sm text-zinc-950 tw-truncate tw-basis-3/5 tw-shrink-0">
                    Rakesh Uthukadu Parthiban
                  </div>
                </div>
                <div className="tw-flex tw-pt-4 tw-justify-between tw-space-x-4">
                  <div className="tw-text-sm tw-text-zinc-500 tw-font-medium">
                    CCE Provider
                  </div>
                  <div className="tw-text-sm text-zinc-950 tw-truncate tw-basis-3/5 tw-shrink-0">
                    Angie Health
                  </div>
                </div>
                <div className="tw-flex tw-pt-4 tw-justify-between tw-space-x-4">
                  <div className="tw-text-sm tw-text-zinc-500 tw-font-medium">
                    Alignment Date
                  </div>
                  <div className="tw-text-sm text-zinc-950 tw-truncate tw-basis-3/5 tw-shrink-0">
                    2024-01-10
                  </div>
                </div>
                <div className="tw-flex tw-pt-4 tw-justify-between tw-space-x-4">
                  <div className="tw-text-sm tw-text-zinc-500 tw-font-medium">
                    CCMD Provider
                  </div>
                  <div className="tw-text-sm text-zinc-950 tw-truncate tw-basis-3/5 tw-shrink-0">
                    Martinez
                  </div>
                </div>
                <div className="tw-flex tw-pt-4 tw-justify-between tw-space-x-4">
                  <div className="tw-text-sm tw-text-zinc-500 tw-font-medium">
                    CMS Aligned Provider
                  </div>
                  <div className="tw-text-sm text-zinc-950 tw-truncate tw-basis-3/5 tw-shrink-0">
                    !Fill this up!
                  </div>
                </div>
                <div className="tw-flex tw-pt-4 tw-justify-between tw-space-x-4">
                  <div className="tw-text-sm tw-text-zinc-500 tw-font-medium">
                    Status
                  </div>
                  <div className="tw-text-sm text-zinc-950 tw-truncate tw-basis-3/5 tw-shrink-0">
                    Complete
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Modal>
    </div>
  );
};

export default AllTasks;
