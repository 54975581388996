// SignaturePad.js
import React, { useRef } from "react";
import SignatureCanvas from "react-signature-canvas";

const SignaturePad = ({ onEnd, clear }) => {
  const sigCanvas = useRef({});

  return (
    <div>
      <SignatureCanvas
        penColor="black"
        backgroundColor="lightgrey"
        canvasProps={{
          width: 500,
          height: 100,
          className: "sigCanvas",
        }}
        ref={(ref) => {
          sigCanvas.current = ref;
        }}
        onEnd={() => onEnd(sigCanvas.current.toDataURL())}
      />
      <button
        className="btn btn-secondary mx-1"
        onClick={() => sigCanvas.current.clear()}
      >
        Clear
      </button>
    </div>
  );
};

export default SignaturePad;
