import React, { useEffect, useState } from "react";
import { Button, Calendar, Dropdown, message } from "antd";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

import { BsThreeDotsVertical } from "react-icons/bs";
import { BiTask } from "react-icons/bi";
import {
  MdOutlineDeleteOutline,
  MdOutlineModeEditOutline,
  MdEventNote,
  MdClear,
} from "react-icons/md";
import PageTitle from "../Common/PageTitle";
import * as Taskapi from "../../api/TaskManagerApi";
import MyBackdropSpinner from "../../common/elements/myBackdropSpinner";
import { useMyContext } from "../../common/elements/myContext";

const items = [
  {
    key: "2",
    label: "Mark Incomplete",
    children: [
      {
        key: "Patient Refused",
        label: "Patient Refused",
      },
      {
        key: "Provider Refused",
        label: "Provider Refused",
      },
      {
        key: "Dispatched",
        label: "Dispatched",
      },
      {
        key: "Discharged",
        label: "Discharged",
      },
      {
        key: "Deceased",
        label: "Deceased",
      },
      {
        key: "Pending Approval CCMD",
        label: "Pending Approval CCMD",
      },
      {
        key: "Pending Approval Non-CCMD",
        label: "Pending Approval Non-CCMD",
      },
      {
        key: "Facility Refused",
        label: "Facility Refused",
      },
      {
        key: "Out of Area",
        label: "Out of Area",
      },
      {
        key: "Cannot locate patient",
        label: "Cannot locate patient",
      },
      {
        key: "Hold - per office request",
        label: "Hold - per office request",
      },
      {
        key: "Hold - other",
        label: "Hold - other",
      },
      {
        key: "Escalate to PP",
        label: "Escalate to PP",
      },
      {
        key: "Other - see notes",
        label: "Other - see notes",
      },
    ],
    icon: (
      <MdClear
        style={{
          width: "1rem",
          height: "1rem",
          color: "var(--bs-gray-600)",
        }}
      />
    ),
  },
  {
    key: "3",
    label: "Edit task",
    icon: (
      <MdOutlineModeEditOutline
        style={{
          width: "1rem",
          height: "1rem",
          color: "var(--bs-gray-600)",
        }}
      />
    ),
  },
  {
    key: "4",
    label: "Delete task",
    icon: (
      <MdOutlineDeleteOutline
        style={{
          width: "1rem",
          height: "1rem",
          color: "var(--bs-gray-600)",
        }}
      />
    ),
  },
];

const TaskList = () => {
  const [filterdate, setFilterDate] = useState(dayjs());
  const [myTasks, setMyTasks] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const { converttoCompare } = useMyContext();
  const Navigate = useNavigate();

  const handleDateChange = (date) => {
    setFilterDate(date);
    let Items = myTasks.filter((item) => {
      const tempdueDate = dayjs(item.dueDateCoverted);
      const tempfilter = dayjs(date);
      return tempdueDate.isSame(tempfilter, "day");
    });
    setFilteredData(Items);
  };

  useEffect(() => {
    getMyTasks();
  }, []);

  const getMyTasks = async () => {
    try {
      setSpinner(true);
      const response = await Taskapi.getMyTasks();
      let tempTask = [];
      response?.data.forEach((e) => {
        tempTask.push({
          id: e.taskId,
          patientName: e.patientName,
          patientId: e.patientId,
          taskType: e.taskTypeName,
          dueDateCoverted: converttoCompare(e.etc),
          dueDate: e.etc,
          assigneeId: e.assigneeId,
          taskPriority: e.taskPriority,
          notes: e.notes,
          taskStatus: e.taskStatus,
        });
      });
      setMyTasks(tempTask);
      let Item = tempTask.filter((i) => {
        const tempdueDate = dayjs(i.dueDateCoverted);
        return tempdueDate.isSame(
          filterdate ? dayjs(filterdate) : dayjs(),
          "day"
        );
      });
      setFilteredData(Item);
      setSpinner(false);
      return response.data;
    } catch (e) {
      console.log("error", e);
    }
  };

  const onStatusChange = async (e, task) => {
    if (e.key === "4") {
      message.error("Task Deleted Successfully!");
      const deleteResult = await Taskapi.deleteTask(task.id);
    } else if (e.key === "3") {
      const encodeTaskType = encodeURIComponent(task.taskType);
      const referrer = "MyTask";
      if (task?.notes) {
        Navigate(
          `/addTask/${task.id}/${task.patientId}/${task.taskPriority}/${task.taskStatus}/${encodeTaskType}/${task.notes}/${task.assigneeId}/${task.dueDate}`,
          { state: { referrer } }
        );
      } else {
        Navigate(
          `/addTask/${task.id}/${task.patientId}/${task.taskPriority}/${task.taskStatus}/${encodeTaskType}/${task.assigneeId}/${task.dueDate}`,
          { state: { referrer } }
        );
      }
    } else {
      message.warning(`Task Incomplete for the patient ${task.patientName}`);
      const tempTask = {
        taskId: task.id,
        taskStatus: "Incomplete",
        incompleteReason: e.key,
      };
      const updateresult = await Taskapi.updateTask(tempTask);
    }
    getMyTasks();
  };

  const onComplete = async (e, task) => {
    message.success("Task Completed Successfully!");
    const tempTask = {
      taskId: task.id,
      taskStatus: "Complete",
    };
    const updateresult = await Taskapi.updateTask(tempTask);
    getMyTasks();
  };
  return (
    <div style={{ minHeight: "90vh" }}>
      <PageTitle title={`My Tasks for ${filterdate.format("dddd, MMMM DD")}`} />
      {spinner && <MyBackdropSpinner show={spinner} />}

      <div className="d-flex" style={{ color: "var(--bs-gray-800)" }}>
        <div className="col col-lg-8 me-5">
          {filteredData.length > 0 ? (
            filteredData.map((task) => (
              <div
                className="pb-3 pt-3 border-bottom d-flex justify-content-between align-items-start"
                key={task.id}
              >
                <div style={{ marginRight: "4rem" }}>
                  <div className="d-flex align-items-baseline">
                    <div style={{ fontWeight: 500 }}>{task.patientName}</div>
                    <span
                      className="rounded-1"
                      style={{
                        fontSize: "0.8rem",
                        color: "var(--bs-gray-800)",
                        background: "var(--bs-gray-200)",
                        border: "1px solid var(--bs-gray-400)",
                        padding: "0rem 0.4rem",
                        marginLeft: "0.7rem",
                      }}
                    >
                      {task.taskType}
                    </span>
                  </div>

                  <div
                    style={{
                      fontSize: "0.9rem",
                      color: "var(--bs-gray-600)",
                      marginBottom: "0.5rem",
                      marginTop: "0.5rem",
                      wordBreak: "break-all",
                    }}
                  >
                    <MdEventNote
                      size={20}
                      style={{
                        color: "var(--bs-gray-500)",
                        marginRight: "0.5rem",
                        marginTop: "-2px",
                      }}
                    />
                    {task.notes}
                  </div>

                  <div
                    style={{ fontSize: "0.9rem", color: "var(--bs-gray-600)" }}
                  >
                    {task.taskPriority === "Critical" && (
                      <div
                        style={{
                          fontSize: "0.9rem",
                          marginBottom: "0.3rem",
                          marginTop: "0.3rem",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          style={{
                            width: 20,
                            marginTop: -4,
                            marginRight: 4,
                            color: "#dc2626",
                          }}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M4.5 12.75l7.5-7.5 7.5 7.5m-15 6l7.5-7.5 7.5 7.5"
                          />
                        </svg>

                        {task.taskPriority}
                      </div>
                    )}{" "}
                    {task.taskPriority === "Low" && (
                      <span
                        style={{
                          fontSize: "0.9rem",
                          marginBottom: "0.3rem",
                          marginTop: "0.3rem",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          style={{
                            width: 20,
                            marginTop: -4,
                            marginRight: 4,
                            color: "#16a34a",
                          }}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                          />
                        </svg>
                        {task.taskPriority}
                      </span>
                    )}{" "}
                    {task.taskPriority === "High" && (
                      <span
                        style={{
                          fontSize: "0.9rem",
                          marginBottom: "0.3rem",
                          marginTop: "0.3rem",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          style={{
                            width: 20,
                            marginTop: -4,
                            marginRight: 4,
                            color: "#ea580c",
                          }}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M4.5 15.75l7.5-7.5 7.5 7.5"
                          />
                        </svg>

                        {task.taskPriority}
                      </span>
                    )}
                    {task.taskPriority === "Medium" && (
                      <span
                        style={{
                          fontSize: "0.9rem",
                          marginBottom: "0.3rem",
                          marginTop: "0.3rem",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          style={{
                            width: 20,
                            marginTop: -4,
                            marginRight: 4,
                            color: "#2563eb",
                          }}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3.75 9h16.5m-16.5 6.75h16.5"
                          />
                        </svg>

                        {task.taskPriority}
                      </span>
                    )}
                  </div>
                  <div>{task?.taskStatus}</div>
                </div>
                {task.taskStatus !== "Incomplete" &&
                  task.taskStatus !== "Complete" && (
                    <div className="d-flex">
                      <Button
                        className="me-3"
                        onClick={(e) => onComplete(e, task)}
                      >
                        Mark as complete
                      </Button>
                      <Dropdown
                        menu={{
                          items,
                          onClick: (e) => onStatusChange(e, task),
                        }}
                        placement="bottomRight"
                      >
                        <div className="mx-auto" style={{ cursor: "pointer" }}>
                          <BsThreeDotsVertical />
                        </div>
                      </Dropdown>
                    </div>
                  )}
              </div>
            ))
          ) : (
            <div
              className="d-flex justify-content-center"
              style={{ marginTop: "6rem" }}
            >
              <div className="text-center">
                <BiTask size={96} style={{ color: "var(--bs-gray-500)" }} />
                <p
                  style={{ color: "var(--bs-gray-600)" }}
                  className="fw-medium fs-4"
                >
                  No tasks
                </p>
              </div>
            </div>
          )}
        </div>
        <div
          className="col col-lg-4 border rounded-3"
          style={{ height: "max-content" }}
        >
          <Calendar
            fullscreen={false}
            onChange={handleDateChange}
            value={filterdate}
          />
        </div>
      </div>
    </div>
  );
};

export default TaskList;
