const MyTextArea = ({
  id,
  label,
  subLabel,
  value,
  setValue,
  isRequired,
  maxLength,
  placeholder,
  additionalStyle,
  disabled,
  rows,
  errorMessage,
}) => {
  return (
    <div className="form-group fw-bold">
      <label
        htmlFor={id}
        className={`${isRequired === true ? "required" : ""}`}
      >
        {label}
        <small
          style={{
            fontWeight: "normal",
            fontSize: "0.8rem",
            color: "var(--bs-gray-600)",
            paddingLeft: "6px",
          }}
        >
          {subLabel}
        </small>
      </label>

      <textarea
        style={{ border: errorMessage ? "1px solid red" : "" }}
        className="form-control"
        id="hccCodeList"
        onChange={(e) => {
          setValue(e.target.value);
        }}
        value={value}
        rows={rows}
        maxLength={maxLength ? maxLength : 3000}
        disabled={disabled === true}
      ></textarea>
      {/* <span
        className="text-danger"
        style={{ fontWeight: "normal", paddingLeft: "5px" }}
      >
        {errorMessage}
      </span> */}
    </div>
  );
};

export default MyTextArea;
