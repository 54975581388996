import React, { useState } from "react";
import useSWR from "swr";
import { fetcherhn } from "../../services/fectcher";
import {
  Button,
  Drawer,
  Popconfirm,
  Skeleton,
  Space,
  Table,
  Tooltip,
} from "antd";
import dayjs from "dayjs";
import Search from "antd/es/input/Search";
import { AddChangeinCondition } from "./AddChangeinCondition";
import { deleteCCN } from "../../api/CareEventsApi";
import { WarningOutlined } from "@ant-design/icons";
import { openNotification } from "../../common/elements/myNotification";
import { handleToggle } from "../../common/elements/toggleSeeMore";
import { FaEdit, FaRegTrashAlt } from "react-icons/fa";
import { FiSend } from "react-icons/fi";
import SendEmail from "../../common/elements/SendEmail";

export const ChangeinConditionNote = (props) => {
  const intialReqState = {
    key: "",
    guid: "",
    mbi: props.mbiid,
    acuteOrChronic: "",
    noteType: "",
    noteDateTime: dayjs().format("YYYY-MM-DD hh:mm A"),
    changeCondition: "",
    patientPlan: "",
    createdBy: sessionStorage.getItem("userName") || "",
    modifiedBy: "",
  };
  const [editRecord, setEditRecord] = useState(intialReqState);
  const [query, setQuery] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data, mutate, isLoading } = useSWR(
    `GetChangeInCondition?id=${props.mbiid}`,
    fetcherhn
  );

  const edit = (record) => {
    setIsModalOpen(true);
    setEditRecord(record);
  };

  const handleCancel = (e) => {
    setIsModalOpen(false);
    setEditRecord(intialReqState);
  };

  const handleOk = async (e) => {
    if (e) {
      mutate();
    }
    setIsModalOpen(false);
    setEditRecord(intialReqState);
  };

  const handleDelete = async (record) => {
    const body = { ...record, modifiedBy: sessionStorage.getItem("userName") };
    const deleteResult = await deleteCCN(body);
    if (deleteResult.status) {
      mutate();
      openNotification(
        "success",
        "Deleted",
        "Change in Condition Note Deleted Successfully!"
      );
    }
  };

  const [expandedRows, setExpandedRows] = useState([]);

  const [expandedRows2, setExpandedRows2] = useState([]);

  const [mail, setMail] = useState(false);

  const sendDatatoEmail = (record) => {
    setMail(true);
    setEditRecord(record);
  };

  const handleModalClose = (e) => {
    setEditRecord(intialReqState);
    setMail(false);
  };

  const columns = [
    {
      key: 1,
      title: "Type of Exacerbation",
      dataIndex: "acuteOrChronic",
    },
    {
      key: 2,
      title: "Change in Condition",
      dataIndex: "changeCondition",
      render: (text, record) => {
        const isExpanded = expandedRows.includes(record.key);
        const content =
          text.length > 100 ? (
            <span>
              {isExpanded ? text : `${text.substring(0, 100)}...`}
              <Button
                type="link"
                onClick={() => handleToggle(record.key, setExpandedRows)}
              >
                {isExpanded ? "See less" : "See more"}
              </Button>
            </span>
          ) : (
            text
          );

        return <div>{content}</div>;
      },
    },
    {
      key: 3,
      title: "Course/Plan of Treatment",
      dataIndex: "patientPlan",
      render: (text, record) => {
        const isExpanded = expandedRows2.includes(record.key);
        const content =
          text.length > 100 ? (
            <span>
              {isExpanded ? text : `${text.substring(0, 100)}...`}
              <Button
                type="link"
                onClick={() => handleToggle(record.key, setExpandedRows2)}
              >
                {isExpanded ? "See less" : "See more"}
              </Button>
            </span>
          ) : (
            text
          );

        return <div>{content}</div>;
      },
    },

    {
      key: 4,
      title: "Note Date & Time",
      width: 170,
      render: (_, { noteDateTime, createdBy }) => {
        const [name] = createdBy.split("@");
        return (
          <>
            <div>{dayjs(noteDateTime).format("YYYY-MM-DD hh:mm A")}</div>
            <div>{name}</div>
          </>
        );
      },
    },
    {
      key: "key",
      title: "Operation",
      dataIndex: "guid",
      fixed: "right",
      width: 110,
      render: (_, record) => (
        <div>
          <Space>
            <Tooltip title="Edit">
              <FaEdit
                size={20}
                className=" tw-cursor-pointer"
                onClick={() => edit(record)}
                color="blue"
              />
            </Tooltip>
            <Tooltip title="Delete">
              <Popconfirm
                title="Ok to Delete?"
                onConfirm={() => handleDelete(record)}
                icon={
                  <WarningOutlined
                    style={{
                      color: "red",
                    }}
                  />
                }
              >
                <FaRegTrashAlt
                  size={20}
                  className=" tw-cursor-pointer"
                  color="red"
                />
              </Popconfirm>
            </Tooltip>
            <Tooltip title="Send Email">
              <FiSend
                size={20}
                className=" tw-cursor-pointer"
                onClick={() => sendDatatoEmail(record)}
                color="green"
              />
            </Tooltip>
          </Space>
        </div>
      ),
    },
  ];

  const finalResult = data
    ? data.filter((item) => {
        return [
          "changeCondition",
          "acuteOrChronic",
          "patientPlan",
          "createdBy",
          "noteDateTime",
        ].some((key) =>
          (item[key]?.toString()?.toLowerCase() ?? "").includes(
            query?.toLowerCase() ?? ""
          )
        );
      })
    : data;

  if (isLoading) {
    return <Skeleton active={isLoading} />;
  }

  return (
    <div>
      <div className="tw-flex tw-justify-between ">
        <p
          className="pt-3"
          style={{
            color: "#D2691E",
            fontWeight: "600",
          }}
        >
          Change in Condition Note
        </p>

        <div className="tw-flex tw-justify-end  tw-mt-3 ">
          <Space>
            <Search
              className=" tw-mb-4"
              size="middle"
              placeholder="Search"
              onChange={(e) => setQuery(e.target.value)}
              allowClear
            />
            <Button
              type="primary"
              onClick={() => setIsModalOpen(true)}
              className="tw-mb-4 "
            >
              Add
            </Button>
          </Space>
        </div>
      </div>
      {isModalOpen && (
        <Drawer
          open={isModalOpen}
          title={"Add/Update Change in Condition Note"}
          onClose={handleCancel}
          width={550}
        >
          <AddChangeinCondition editRecord={editRecord} callback={handleOk} />
        </Drawer>
      )}
      <Table
        bordered
        size="middle"
        dataSource={finalResult}
        columns={columns}
      />

      {mail && (
        <SendEmail
          open={mail}
          close={handleModalClose}
          editRecord={editRecord}
          modalTitle={"Change in Condition Note"}
          patientName={props?.patient?.label || ""}
        />
      )}
    </div>
  );
};
