import { axiosInstancepy } from "./AxiosInstance";

export async function getCCMembers() {
  return axiosInstancepy
    .get(`/GetChronicCodeMemberList`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function getMemberCC(id) {
  return axiosInstancepy
    .get(`/GetMemberChronicCodeList?mbi=${id}`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
