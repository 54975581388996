import { axiosInstance } from "./AxiosInstance";

export async function getConditions(patientId) {
  return axiosInstance
    .get(`/api/PatientConditions?patientId=${patientId}`)
    .then(function (response) {
      //debugger;
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
