import { axiosInstancehn } from "./AxiosInstance";

export async function addGrievanceTemplate(body) {
  return axiosInstancehn
    .post("/AddGriveanceTemplate", body)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
export async function deleteGrievanceTemplate(body) {
  return axiosInstancehn
    .delete("/DeleteGriveanveTemplate", { data: body })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
export async function addERVisitTemplate(body) {
  return axiosInstancehn
    .post("/AddERVisit", body)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function deleteERVisitTemplate(body) {
  return axiosInstancehn
    .delete("/DeleteERVisit", { data: body })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
export async function addTierChangeRequest(body) {
  return axiosInstancehn
    .post("/AddRequestForTierChange", body)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
export async function deleteTierChangeRequest(body) {
  return axiosInstancehn
    .delete("/DeleteRequestForTierChange", { data: body })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
export async function addOrderRequest(body) {
  return axiosInstancehn
    .post("/AddOrderRequest", body)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
export async function deleteOrderRequest(body) {
  return axiosInstancehn
    .delete("/DeleteOrderRequest", { data: body })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
export async function addDmeRequest(body) {
  return axiosInstancehn
    .post("/AddDmeRequest", body)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
export async function deleteDmeRequest(body) {
  return axiosInstancehn
    .delete("/DeleteDmeRequest", { data: body })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
export async function addNurseRequest(body) {
  return axiosInstancehn
    .post("/AddNurseRequest", body)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
export async function deleteNurseRequest(body) {
  return axiosInstancehn
    .delete("/DeleteNurseRequest", { data: body })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function addHospitalAdmission(body) {
  return axiosInstancehn
    .post("/AddHospitalAdmissionTemplate", body)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
export async function deleteHospitalAdmission(body) {
  return axiosInstancehn
    .delete("/DeleteHospitalAdmissionTemplate", { data: body })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
export async function addHospitalDischarge(body) {
  return axiosInstancehn
    .post("/AddHospitalDischargeTemplate", body)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
export async function deleteHospitalDischarge(body) {
  return axiosInstancehn
    .delete("/DeleteHospitalDischargeTemplate", { data: body })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
export async function addOutboundCall(body) {
  return axiosInstancehn
    .post("/AddOutBoundCall", body)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
export async function deleteOutboundCall(body) {
  return axiosInstancehn
    .delete("/DeleteOutBoundCall", { data: body })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function sendEmail(body) {
  return axiosInstancehn
    .post("/SendMail", body)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
