import { DatePicker } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

const MyDatePicker = ({
  id,
  label,
  subLabel,
  value,
  setValue,
  isRequired,
  maxLength,
  placeholder,
  additionalStyle,
  disabled,
  errorMessage,
}) => {
  const onDobChange = (date, dateString) => {
    setValue(dateString);
  };

  const dateFormat = "YYYY-MM-DD";
  const dateFormatList = [
    "YYYY-MM-DD",
    "YY-MM-DD",
    "YYYY/MM/DD",
    "YY/MM/DD",
    "DD-MM-YYYY",
  ];

  return (
    <div className="form-group fw-bold">
      <label
        htmlFor={id}
        className={`${isRequired === true ? "required" : ""}`}
      >
        {label}
        <small style={{ fontWeight: "normal", paddingLeft: "5px" }}>
          {subLabel}
        </small>
      </label>

      {/* defaultValue={currentValue && dayjs(currentValue, dateFormat)} */}

      <DatePicker
        value={value ? dayjs(value, dateFormat) : null}
        defaultValue={("1999-01-01", dateFormatList[0])}
        onChange={onDobChange}
        className="form-control"
        format={dateFormat}
        disabledDate={disabled}
      />
      {errorMessage && (
        <span
          className="text-danger"
          style={{ fontWeight: "normal", paddingLeft: "5px" }}
        >
          {errorMessage}
        </span>
      )}
    </div>
  );
};

export default MyDatePicker;
