import {
  axiosInstance,
  axiosInstancehn,
  axiosInstancepy,
} from "../api/AxiosInstance";

export const fetch = (url) => axiosInstance.get(url).then((res) => res.data);

export const fetcher = (url) =>
  axiosInstancepy.get(url).then((res) => res.data);
export const poster = (url, body) =>
  axiosInstancepy.post(url, body).then((res) => res.data);
export const fetcherhn = (url) =>
  axiosInstancehn.get(url).then((res) => res.data);
