import { axiosInstance } from "./AxiosInstance";

export async function getFacilitiesPromise() {
  return axiosInstance
    .get("/api/Facilities")
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function SaveFacility(facilityData) {
  return axiosInstance
    .post("/api/Facilities", facilityData)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
