import { notification } from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  WarningOutlined,
} from "@ant-design/icons";

const openNotification = (type, message, description) => {
  let icon;
  if (type === "success") {
    icon = <CheckCircleOutlined style={{ color: "green" }} />;
  } else if (type === "error") {
    icon = <CloseCircleOutlined style={{ color: "red" }} />;
  } else if (type === "warning") {
    icon = <WarningOutlined style={{ color: "orange" }} />;
  }

  let bigMessage;
  bigMessage = <div className="tw-text-xl">{message}</div>;

  let desc;
  desc = <div className="tw-text-base">{description}</div>;

  notification.open({
    message: bigMessage,
    description: desc,
    icon: icon,
  });
};

export { openNotification };
