import { axiosInstance } from "./AxiosInstance";

export async function getPCCPatientlist() {
  return axiosInstance
    .get("/api/PatientList")
    .then(function (response) {
      //debugger;
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
