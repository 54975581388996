import React, { useState } from "react";
import * as saveEligibilityInfoApi from "../../api/scoreApi";

import MyTextArea from "../../common/elements/myTextArea";
import { notification } from "antd";

const Context = React.createContext({ name: "Default" });

const Eligibility = ({
  scoreInfo,
  scoreRequest,
  onEligibilitySaveFinished,
}) => {
  const [notes, setNotes] = useState(scoreRequest.notes || "");
  const [eligibiltyOption, setEligibilityOption] = useState(
    scoreRequest?.eligibleOption || "Yes"
  );
  const [apiLoading, setApiLoading] = useState(false);

  const [api, contextHolder] = notification.useNotification();

  //const [elibibiltyNo, setEligibilityNo] = useState(false);
  //const [elibibiltyPartial, setEligibilityPartial] = useState(false);

  const openNotification = (placement) => {
    alert("saved successfully");
    api.info({
      message: `Notification ${placement}`,
      description: (
        <Context.Consumer>{({ name }) => `Hello, ${name}!`}</Context.Consumer>
      ),
      placement,
    });
  };

  const onSave = async () => {
    setApiLoading(true);
    let eligibiltyRequest = {
      requestID: scoreInfo?.requestID,
      notes: notes,
      isEligible: eligibiltyOption === "Yes" || eligibiltyOption === "Partial",
      isFull: eligibiltyOption === "Yes",
    };

    const result = await saveEligibilityInfoApi.saveEligibilityInfo(
      eligibiltyRequest
    );
    setApiLoading(false);
    onEligibilitySaveFinished();
  };

  return (
    <>
      <div className="row">
        <div className="col">
          <h5>Eligibility Information</h5>
        </div>
      </div>
      <div className="row pb-2">
        <div className="col">
          <div className="form-group fw-bold">
            <label htmlFor="eligibleOption" className="pb-1">
              Eligible
            </label>

            <fieldset className="fw-normal">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="elibibiltyYes"
                  checked={eligibiltyOption === "Yes"}
                  id="inlineRadio1"
                  value={"Yes"}
                  onChange={(e) => setEligibilityOption(e.currentTarget.value)}
                />
                <label className="form-check-label" htmlFor="inlineRadio1">
                  Yes
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="elibibiltyPartial"
                  checked={eligibiltyOption === "Partial"}
                  id="elibibiltyPartial"
                  value={"Partial"}
                  onChange={(e) => setEligibilityOption(e.currentTarget.value)}
                />
                <label className="form-check-label" htmlFor="inlineRadio3">
                  Partial
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="elibibiltyNo"
                  checked={eligibiltyOption === "No"}
                  id="elibibiltyNo"
                  value={"No"}
                  onChange={(e) => {
                    setEligibilityOption(e.currentTarget.value);
                  }}
                />
                <label className="form-check-label" htmlFor="inlineRadio2">
                  No
                </label>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
      <div className="row pb-2">
        <div className="col">
          <MyTextArea
            label="Notes"
            id="notes"
            value={notes}
            isRequired="false"
            maxLength="50"
            setValue={setNotes}
          ></MyTextArea>
        </div>
      </div>

      <div className="row pt-3">
        <div className="col-md-12">
          <button className="btn btn-success" onClick={onSave}>
            Save
          </button>
          <button
            className="btn btn-secondary mx-1"
            onClick={onEligibilitySaveFinished}
          >
            Cancel
          </button>
          {apiLoading && (
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Eligibility;
