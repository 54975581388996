import React, { useEffect } from "react";

import Select from "react-select";
import AsyncSelect from "react-select/async";

const MyCustomSelectAsync = ({
  selectOptions,
  myCallBack,
  selectedValues,
  defaultOption,
  isMulti,
  index
}) => {

  const filterOptions = (inputValue: string) => {
    const intermediateFilter = selectOptions?.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );

    return intermediateFilter?.slice(0, 1000);
  };

  const onChange = (option: Option[], actionMeta: ActionMeta<Option>) => {
    myCallBack(option, index);
  };

  const loadOptions = (
    inputValue: string,
    callback: (options: selectOption[]) => void
  ) => {
    setTimeout(() => {
      callback(filterOptions(inputValue));
    }, 1000);
  };

  const menuDivider = (
    <span className="" style={{ color: "#f0f0f0" }}>
      <small>|</small>
    </span>
  );

  const listOfSources = [
    "PMHx/Problem List (visit notes)",
    "Medication List",
    " Facility EMR (POC, CCM, MedDiag, Orders)",
    " Previous Claims Data",
    "Hospital Records",
    "Other",
  ];

  const sourceItem = listOfSources.map((item) => {
    return (
      <div
        className="m-1 custom-source-item"
        style={{
          padding: "2px",
          minWidth: "150px",
          border: "1px solid gray",
          cursor: "pointer",
          borderRadius: "5px",
        }}
      >
        {item}
      </div>
    );
  });

  const MyOption = (props) => {
    const { innerProps, innerRef } = props;

    return (
      <article ref={innerRef} {...innerProps} className="custom-option p-2">
        <h6>{props.label}</h6>
        <div className="d-flex  justify-content-start flex-wrap">
          {sourceItem}
        </div>
      </article>
    );
  };

  return (
    <AsyncSelect
      defaultOptions={defaultOption}
      isMulti={isMulti}
      onChange={onChange}
      value={selectedValues}
      cacheOptions
      loadOptions={loadOptions}
    />
  );
};

export default MyCustomSelectAsync;
