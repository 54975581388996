import React, { useState } from "react";
import TextArea from "antd/es/input/TextArea";
import MyTextBox from "../../common/elements/myTextbox";
import { openNotification } from "../../common/elements/myNotification";
import { addERVisitTemplate } from "../../api/CareEventSCPApi";
import { DatePicker } from "antd";
import dayjs from "dayjs";

const dateFormat = ["YYYY-MM-DD"];

export const EDVisitAdd = ({ editRecord, callback }) => {
  const [formData, setFormData] = useState(
    editRecord?.guid
      ? {
          ...editRecord,
          modifiedBy: sessionStorage.getItem("userName"),
        }
      : editRecord
  );
  const [isPhoneValid, setIsPhoneValid] = useState(true);

  const [pageError, setPageError] = useState({});
  const [selectedDate, setSelectedDate] = useState();
  const [disable, setDisable] = useState(false);

  const handleInputChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
    setPageError({ ...pageError, [name]: value ? "" : "Required" });
  };

  const onDateChange = (date, dateString) => {
    setSelectedDate(date || "");
    setFormData({
      ...formData,
      erVisitDate: dateString ? dateString : "",
    });
    setPageError({
      ...pageError,
      erVisitDate: dateString ? "" : "Required",
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setPageError({});
    let pageErrorTemp = {};

    if (!formData?.erName) {
      pageErrorTemp.erName = "Required";
    }

    if (!formData?.erVisitSymptonDx) {
      pageErrorTemp.erVisitSymptonDx = "Required";
    }

    if (!formData?.erVisitDate) {
      pageErrorTemp.erVisitDate = "Required";
    }

    // Check if phone number is valid if it has been entered
    if (formData?.erTelephone && !isPhoneValid) {
      pageErrorTemp.erTelephone = "Required";
    }

    // If there are any errors, set the page errors and return
    if (Object.keys(pageErrorTemp).length > 0) {
      setPageError(pageErrorTemp);
      return;
    }

    setDisable(true);
    onSave(e);
  };

  const onSave = async (e) => {
    try {
      const response = await addERVisitTemplate(formData);
      if (response?.status === 200 && !formData?.guid) {
        openNotification(
          "success",
          "Added",
          "ED Visit Template Added Successfully!"
        );
      } else if (formData?.guid) {
        openNotification(
          "success",
          "Updated",
          "ED Visit Template Updated Successfully!"
        );
      } else {
        openNotification("error", "Error", "ED Visit Template Failed to Add!");
      }
      setFormData(editRecord);
      setDisable(false);
      callback(response);
    } catch (e) {
      return e;
    }
  };

  return (
    <div className="tw-relative tw-px-2">
      <div className="col-md-11 col-lg-11">
        <div className="form-group tw-mb-3">
          <MyTextBox
            label={"ED Name"}
            value={formData?.erName}
            maxLength={100}
            setValue={(e) => handleInputChange("erName", e.target.value)}
            errorMessage={pageError?.erName}
          />
        </div>
      </div>
      <div className="col-md-11 col-lg-11">
        <div className="form-group tw-mb-3">
          <MyTextBox
            label={"ED Telephone#"}
            value={formData?.erTelephone}
            setValue={(e) => handleInputChange("erTelephone", e.target.value)}
            isPhoneNumber={true}
            onValidChange={(e) => setIsPhoneValid(e)}
          />
        </div>
      </div>
      <div className="col-md-11 col-lg-11">
        <div className="form-group tw-mb-3">
          <label
            htmlFor="priority"
            className="tw-fw-bold tw-flex tw-justify-between"
          >
            <div className="tw-flex tw-items-center">
              <div className="fw-bold">ED Visit Symptom/Dx</div>
            </div>
          </label>
          <TextArea
            autoSize={{
              minRows: 5,
              maxRows: 10,
            }}
            className="form-control "
            maxLength={"1000"}
            value={formData?.erVisitSymptonDx}
            onChange={(e) =>
              handleInputChange("erVisitSymptonDx", e.target.value)
            }
            style={{
              border: pageError.erVisitSymptonDx ? "1px solid red" : "",
            }}
          />
        </div>
      </div>
      <div className="col-md-11 col-lg-11 form-group">
        <div className="tw-flex ">
          <label className="tw-font-bold" htmlFor="dateTime">
            ED Visit Date
          </label>
        </div>
        <DatePicker
          use12Hours
          className="form-control tw-mb-3"
          onChange={onDateChange}
          format={dateFormat}
          value={formData?.erVisitDate ? dayjs(formData.erVisitDate) : ""}
          style={{
            border: pageError?.erVisitDate ? "1px solid red" : "",
          }}
        />
      </div>
      <div className="col-md-11 col-lg-11">
        <div className="form-group ">
          <label
            htmlFor="priority"
            className="tw-fw-bold tw-flex tw-justify-between"
          >
            <div className="tw-flex tw-items-center">
              <div className="fw-bold">MEDEX NOTES/MESSAGE</div>
            </div>
          </label>
          <TextArea
            autoSize={{
              minRows: 5,
              maxRows: 10,
            }}
            className="form-control "
            maxLength={"1000"}
            value={formData?.medxNotes}
            onChange={(e) => handleInputChange("medxNotes", e.target.value)}
          />
        </div>
      </div>

      <div className="form-group tw-fixed tw-top-2 tw-right-10">
        <button
          className={!disable ? "btn btn-success" : "btn btn-success disabled"}
          onClick={onSubmit}
        >
          {editRecord?.guid ? "Update" : "Save"}
        </button>
        <button
          className="btn btn-secondary tw-ml-4"
          onClick={() => callback(false)}
        >
          Close
        </button>
      </div>
    </div>
  );
};
